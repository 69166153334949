import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FoodProgramReportsPage as FoodProgramReportsPageCommon } from '@wonderschool/common-food-program-ui';

import { getTitlePrefix } from '../../config/env';
import { PAGE_NAMES, useSegmentPage } from '../../segment';

export const FoodProgramReportsPage = () => {
  const { t } = useTranslation();
  const title = t('food.title');
  useSegmentPage(PAGE_NAMES.foodProgramReports);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  return (
    <>
      {renderPageHead()}
      <FoodProgramReportsPageCommon />
    </>
  );
};
export default FoodProgramReportsPage;
