import { useState } from 'react';

import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Button, Dropdown, Grid, Icon, Image, Label, Segment } from 'semantic-ui-react';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

import NoDataMessage from '../../Components/Shared/NoDataMessage';
import { DefaultDateFormat } from '../../helpers/dates';
import { formatCurrency } from '../../helpers/utils';

import { useTotalTransactionsCount, useTransactions } from '../transactionsHooks';

import ImageModal from './ImageModal';
import TransactionModal from './TransactionModal';
import TransactionsSearchBar from './TransactionsSearchBar';

import TransactionsStatsBar from './TransactionsStatsBar';

import DateTime from '../../Components/DateTime/DateTime';
import { TransactionType, isExpenseTransactionType } from '../types';

import styles from '../Transactions.module.scss';

import classNames from 'classnames/bind';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { exportTransactions } from '../../api/firebase/transactions';
import fStyle from '../../Components/Billing/FinanceAtGlance/FinanceAtGlance.module.scss';
import TransactionsAtGlance from '../../Components/Billing/FinanceAtGlance/TransactionsAtGlance';
import noTransactionsImage from '../../styles/img/wonderfolks/transactions-list-is-empty.png';

const fx = classNames.bind(fStyle);

export default function TransactionsList({ organization }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [transactionTypeForModal, setTransactionTypeForModal] = useState(null);

  const [searchConditions, setSearchConditions] = useState(null);
  const [startAfter, setStartAfter] = useState(null);
  const [extraFetchProps, setExtraFetchProps] = useState(null);

  const [transaction, setTransaction] = useState(null);
  const [receiptURL, setReceiptURL] = useState({});

  const totalTransactionsCount = useTotalTransactionsCount();

  const { loading, transactions } = useTransactions(organization.id, searchConditions, startAfter, extraFetchProps);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const { isFinanceAtAGlanceEnabled } = useFlags();

  const transactionList = transactions?.list ?? [];
  const downloadCsv = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transactions.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={transactionList}
        columns={getTableColumns(t)}
        key={currentLanguage}
      >
        {({ baseProps }) => {
          return (
            <Grid stackable divided="vertically">
              <Grid.Row columns={1}>
                {renderCommandsColumn(t)}
                <Grid.Column>
                  <h1 className="text-3xl font-bold" data-testid="transactions-header">
                    {t('Posted Transactions')}
                  </h1>
                  <p data-testid="transactions-description">
                    {t(
                      'See all transactions in your program. You can filter by specific transaction type, date range, or search for key words.'
                    )}
                  </p>
                </Grid.Column>
                {isFinanceAtAGlanceEnabled && (
                  <Grid.Column>
                    <TransactionsAtGlance />
                  </Grid.Column>
                )}
                <Grid.Column className={fx('mt-10')}>{renderPrivacyRow(t)}</Grid.Column>
              </Grid.Row>

              {renderTransactionsRow(baseProps, t)}
              {renderNoTransactionsRow(t)}
            </Grid>
          );
        }}
      </ToolkitProvider>
      <TransactionModal
        organization={organization}
        transaction={transaction}
        transactionType={transactionTypeForModal}
        onClose={onCloseTransactionModal}
        onSave={onSaveTransaction}
        open={!!transactionTypeForModal}
      />

      <ImageModal url={receiptURL.url} type={receiptURL.type} open={!!receiptURL.url} onClose={onCloseImageModal} />
    </>
  );

  function renderCommandsColumn(t) {
    if (!transactionsDoExist()) return null;

    return (
      <Grid.Column textAlign="right">
        <Button
          data-testid="export-transactions-button"
          onClick={async () => {
            setIsExportLoading(true);
            const result = await exportTransactions(organization.id, searchConditions);
            downloadCsv(result?.data);
            setIsExportLoading(false);
          }}
          style={{ marginRight: '0.5em' }}
          disabled={isExportLoading || transactionList.length == 0}
          loading={isExportLoading}
        >
          {t('Export to CSV')}
        </Button>
        {renderAddTransactionButton(t)}
      </Grid.Column>
    );
  }

  function renderAddTransactionButton(t) {
    return (
      <Dropdown
        data-testid="add-transaction-dropdown"
        text={t('Add transaction')}
        value=""
        className="button primary"
        style={{ zIndex: 20 }}
        options={[
          {
            key: 'expense',
            text: <span data-testid="add-expense-option">{t('Money out (expense)')}</span>,
            value: 'expense',
          },
          {
            key: 'revenue',
            text: <span data-testid="add-revenue-option"> {t('Money in (revenue)')}</span>,
            value: 'revenue',
          },
        ]}
        onChange={(e, { value }) => {
          e.stopPropagation();
          // Check if the target is a span element (indicating an option was clicked)
          // ToDo: This is a hacky way to determine if the option was clicked. We need to replace this with ws-common
          // but current dropdown design not matching with this requirement current ws-common dropdown as 3 vertical dots like options.
          if (e.target.tagName === 'SPAN') {
            value === 'revenue' ? onAddRevenue() : onAddExpense();
          }
        }}
      />
    );
  }

  function renderTransactionsRow(baseProps, t) {
    return (
      <>
        {transactionsDoExist() && (
          <Grid.Row>
            <Grid.Column>
              <TransactionsSearchBar onSearch={onSearch} loading={loading} />
            </Grid.Column>
          </Grid.Row>
        )}

        {transactionList.length > 0 && (
          <Grid.Row>
            <Grid.Column textAlign="center">
              <TransactionsStatsBar count={transactions.count} totalCount={totalTransactionsCount} />
              {renderTransactionsList(baseProps, t)}
              {transactions?.hasMoreData && (
                <Button loading={loading} onClick={onLoadMore} data-testid="load-more-transactions-link">
                  {t('Load More Transactions')}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </>
    );
  }

  function renderPrivacyRow(t) {
    if (!transactionsDoExist()) return null;

    return (
      <Grid.Row>
        <Grid.Column>{renderPrivacySegment(t)}</Grid.Column>
      </Grid.Row>
    );
  }
  function renderPrivacySegment(t) {
    return (
      <Segment>
        <Icon name="lock" color="grey" data-testid="privacy-icon" />
        <span data-testid="privacy-text">{t('Your payment information and data are private.')}</span>
      </Segment>
    );
  }

  function renderTransactionsList(baseProps, _t) {
    const rowEvents = {
      onClick: (e, row, _rowIndex) => {
        if (onSelectTransaction) onSelectTransaction(e, row);
      },
    };
    return (
      <div className="bootstrap-iso">
        <BootstrapTable
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          headerClasses={styles.transactionsListHeader}
          bordered={false}
          hover={true}
          rowClasses={styles.transactionsListRow}
          rowEvents={rowEvents}
          {...baseProps}
        />
      </div>
    );
  }

  function renderNoTransactionsRow(t) {
    return (
      <Grid.Row>
        <Grid.Column>
          {!transactionsDoExist() && renderNoTransactionsExistSegment(t)}
          {transactionsNotFound() && renderNoTransactionsFoundSegment(t)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  function renderNoTransactionsExistSegment(t) {
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={12}>
              <h1 className="text-3xl font-bold" data-testid="no-transactions-header">
                {t('Start tracking your expenses and revenue')}
              </h1>
              <span data-testid="no-transactions-description">
                {t('You have not posted any transactions yet. You can add a transaction by clicking the button below.')}
              </span>
              {renderPrivacySegment(t)}
              {renderAddTransactionButton(t)}
            </Grid.Column>
            <Grid.Column width={4}>
              <Image src={noTransactionsImage} size="medium" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  function renderNoTransactionsFoundSegment(t) {
    return (
      <Segment>
        <NoDataMessage
          message={t('No transactions found that match your search criteria. Clear your search and try again.')}
          image={noTransactionsImage}
          CallToActionComponent={() => (
            <Button primary size="large" onClick={forceTransactionsReload} data-testid="clear-search-button">
              {t('Clear Search Criteria')}
            </Button>
          )}
        />
      </Segment>
    );
  }

  function onViewReceipt(e, _receiptURL, _receiptType) {
    e.preventDefault();
    e.stopPropagation();
    setReceiptURL({ url: _receiptURL, type: _receiptType });
  }

  function onSelectTransaction(e, _transaction) {
    e.preventDefault();
    e.stopPropagation();
    setTransaction(_transaction);
    setTransactionTypeForModal(_transaction.transactionType);
  }

  function onAddRevenue() {
    setTransactionTypeForModal(TransactionType.REVENUE);
  }
  function onAddExpense() {
    setTransactionTypeForModal(TransactionType.EXPENSE);
  }
  function onCloseImageModal() {
    setReceiptURL({});
  }
  function onCloseTransactionModal() {
    setTransaction(null);
    setTransactionTypeForModal(null);
  }
  function onSaveTransaction(_transaction, wasAdded) {
    if (wasAdded) {
      forceTransactionsReload();
    } else {
      const index = transactions.list?.findIndex((t) => t.id === _transaction.id);
      if (index >= 0) {
        transactions.list[index] = _transaction;
      }
    }
  }
  function onSearch(_searchConditions) {
    setSearchConditions(_searchConditions);
    setStartAfter(null);
  }
  function onLoadMore() {
    setStartAfter(transactions?.lastDocument);
  }
  function forceTransactionsReload() {
    setStartAfter(null);
    setSearchConditions(null);
    setExtraFetchProps({ reload: true });
  }
  // if searchConditions is not set, and there were is no searchConditions, then no transactions exist in the db

  function transactionsDoExist() {
    return totalTransactionsCount > 0;
  }
  // There are transactions in the db, but none were found by the search or filter conditions
  function transactionsNotFound() {
    return (
      transactionsDoExist() && transactions?.count === 0 // we did a search, but none were found
    );
  }
  function getTableColumns(t) {
    return [
      {
        dataField: 'date',
        text: t('Date'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-date-column">{column.text}</span>
            <span data-testid="transaction-date-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        formatter: (cell, _, rowIndex) => {
          if (!isNaN(cell?.seconds)) {
            // It's an epoch date from a Firestore Timestamp object
            return (
              <span data-testid={`transaction-date-value-${rowIndex}`}>
                <DateTime epoch={cell.seconds * 1000} format={DefaultDateFormat} />
              </span>
            );
          } else {
            // Assuming cell is a date string or a JavaScript Date object
            const date = new Date(cell);
            return (
              <span data-testid={`transaction-date-value-${rowIndex}`}>
                <DateTime epoch={date.getTime()} format={DefaultDateFormat} />
              </span>
            );
          }
        },
      },
      {
        dataField: 'payee',
        text: t('Payee'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-payee-column">{column.text}</span>
            <span data-testid="transaction-payee-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        formatter: (cell, _, rowIndex) => {
          return <span data-testid={`transaction-payee-value-${rowIndex}`}>{cell}</span>;
        },
      },
      {
        dataField: 'payer',
        text: t('Payer'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-payer-column">{column.text}</span>
            <span data-testid="transaction-payer-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        formatter: (cell, _, rowIndex) => {
          const formattedCell = cell && cell.length > 100 ? `${cell.substring(0, 100)}...` : cell;
          return (
            <span data-testid={`transaction-payer-value-${rowIndex}`}>
              {cell && <div title={cell}>{formattedCell}</div>}
            </span>
          );
        },
      },
      {
        dataField: 'category',
        text: t('Category'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-category-column">{column.text}</span>
            <span data-testid="transaction-category-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        formatter: (cell, _, rowIndex) => {
          return (
            <span data-testid={`transaction-category-value-${rowIndex}`}>
              {Array.isArray(cell) ? cell.join(', ') : t(cell) ?? ''}
            </span>
          );
        },
      },
      {
        dataField: 'notes',
        text: t('Notes'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-notes-column">{column.text}</span>
            <span data-testid="transaction-notes-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        formatter: (cell, row, rowIndex) => {
          const formattedCell = cell && cell.length > 100 ? `${cell.substring(0, 100)}...` : cell;
          return (
            <>
              {cell && (
                <div data-testid={`transaction-notes-value-${rowIndex}`} title={cell}>
                  {formattedCell}
                </div>
              )}
              {row.receiptURL && (
                <div>
                  <Link
                    to="#"
                    onClick={(e) => onViewReceipt(e, row.receiptURL, row.receiptType)}
                    data-testid={`view-receipt-${rowIndex}`}
                  >
                    {t('transactionList.viewReceipt')}
                  </Link>
                </div>
              )}
            </>
          );
        },
      },
      {
        dataField: 'amount',
        text: t('Amount'),
        headerFormatter: (column, _, { sortElement }) => (
          <div>
            <span data-testid="transaction-amount-column">{column.text}</span>
            <span data-testid="transaction-amount-sort-column">{sortElement}</span>
          </div>
        ),
        sort: true,
        sortValue: (cell, row) => {
          return isExpenseTransactionType(row.transactionType) ? -cell : cell;
        },
        formatter: (cell, row, rowIndex) => {
          let text = formatCurrency(cell, { precision: 2 });
          let color = 'green';
          if (isExpenseTransactionType(row.transactionType)) {
            text = '-' + text;
            color = 'red';
          }
          return (
            <Label
              data-testid={`transaction-amount-value-${rowIndex}`}
              color={color}
              content={t(text)}
              className={styles.transactionAmount}
            />
          );
        },
      },
      {
        dataField: 'actions',
        text: '',
        isDummyField: true,
        formatter: (cell, row, rowIndex) => (
          <Link
            to="#"
            onClick={(e) => onSelectTransaction(e, row)}
            className={styles.transactionViewLink}
            data-testid={`view-edit-transaction-${rowIndex}`}
          >
            {t('transactionList.toggleViewEdit')}
          </Link>
        ),
        headerStyle: { width: '100px' },
      },
    ];
  }
}
