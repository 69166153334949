import { Button, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../config/env';
import { RouteNameEnum, useRoutes } from '../navigation';

const Unauthorized = () => {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('unauthorized.pageHead') })}
      </title>
      <body className="auth error" />
    </Helmet>
  );

  const handleTryAgain = () => {
    // go to home page '/'
    gotoRouteByName(RouteNameEnum.HOME);
  };

  return (
    <div className="w-96 p-0 m-auto">
      {renderPageHead()}

      <div className="p-6 bg-white border border-gray-300 rounded-lg">
        <h1 className="text-2xl font-bold mb-4">{t('unauthorized.ohNo')}</h1>

        <p>{t('unauthorized.ohNoMessage')}</p>

        <div className="mb-4">
          <img
            src="https://media.giphy.com/media/9Y5BbDSkSTiY8/giphy.gif"
            style={{ width: '100%' }}
            alt={t('common.YesExclamation')}
          />
        </div>

        <Button
          primary
          size={WidgetSizeEnum.MEDIUM}
          label={t('unauthorized.tryAgain')}
          onClick={handleTryAgain}
          extraClasses="w-full"
        />
      </div>
    </div>
  );
};

export default Unauthorized;
