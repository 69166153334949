import { firestore } from '../api/firebase/firebase';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { deleteDocument, saveDocument, startCollectionListener } from '../api/firebase/firestore';

import { QueryConditionType } from '../reports';
import { TimecardStatusEnum } from './enums';
import { TimecardType, TimecardsListType, TimesheetFiltersType } from './types';

const paths = {
  timecards: (organizationId: string) => `organizations/${organizationId}/timecards`,
};

type APIStaffInfoType = {
  organizationId: string;
  contactId: string;
  uid: string;
};

export async function fetchTimecards(filters: TimesheetFiltersType): Promise<TimecardType[]> {
  if (!filters.organizationId) throw new Error('OrganizationId is required');

  const startDate = moment(filters.startDate ?? new Date())
    .utc(false)
    .startOf('day')
    .toDate();
  const endDate = moment(filters.endDate ?? new Date())
    .utc(false)
    .endOf('day')
    .toDate();

  let query = firestore()
    .collection(paths.timecards(filters.organizationId))
    .where('clockedIn', '>=', startDate)
    .where('clockedIn', '<=', endDate);

  if (filters.roomId) query = query.where('roomId', '==', filters.roomId);
  if (filters.contactId) query = query.where('contactId', '==', filters.contactId);
  if (filters.uid) query = query.where('uid', '==', filters.uid); // needed for the firestore rules

  const snapshot = await query.get();
  return snapshot.docs.map((doc) => {
    const { clockedIn, clockedOut, ...rest } = doc.data();
    const clockedInDate = clockedIn.toDate();
    const clockedOutDate = clockedOut?.toDate(); // may be undefined
    return { id: doc.id, clockedIn: clockedInDate, clockedOut: clockedOutDate, ...rest } as TimecardType;
  });
}

export async function fetchClockedInTimecards(staffInfo: APIStaffInfoType) {
  const query = firestore()
    .collection(paths.timecards(staffInfo.organizationId))
    .where('contactId', '==', staffInfo.contactId)
    .where('uid', '==', staffInfo.uid)
    .where('status', '==', TimecardStatusEnum.CLOCKED_IN);

  const snapshot = await query.get();
  return snapshot.docs.map((doc) => {
    return doc.data() as TimecardType;
  });
}

export async function saveTimecard(timecard: TimecardType, firestoreOptions: any = { merge: true }) {
  if (!timecard) throw new Error('Timecard is required');
  if (!timecard.organizationId) throw new Error('Timecard.organizationId is required');

  return saveDocument({
    collectionPath: paths.timecards(timecard.organizationId),
    data: timecard,
    firestoreOptions,
  });
}
export async function deleteTimecard(timecard: TimecardType) {
  if (!timecard.id) throw new Error('Timecard.id is required');
  if (!timecard.organizationId) throw new Error('Timecard.organizationId is required');
  return deleteDocument({
    path: `organizations/${timecard.organizationId}/timecards/${timecard.id}`,
  });
}

export const timecardsOnListen = (
  { organizationId, conditions = [] }: { organizationId: string; conditions: QueryConditionType[] },
  next: (timecards: TimecardsListType) => void,
  error: (error: any) => void,
  complete: (() => void) | undefined
) => {
  return startCollectionListener(
    {
      path: paths.timecards(organizationId),
      conditions,
    },
    next,
    error,
    complete
  );
};

export async function createMockTimecard(timecard: TimecardType) {
  timecard.clockedIn = new Date();
  timecard.status = TimecardStatusEnum.CLOCKED_IN;
  return await saveTimecard(timecard);
}
