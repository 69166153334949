import { DailyAttendanceReportType } from '../types';
import { saveCSV } from '../../../common';

// Generate CSV functions
const COLUMNS = [
  { header: 'Name', dataKey: 'studentName' },
  { header: 'Room', dataKey: 'roomName' },
  { header: 'Date', dataKey: 'checkInDate' },
  { header: 'Check In', dataKey: 'checkInTime' },
  { header: 'Signee', dataKey: 'checkInName' },
  { header: 'Date', dataKey: 'checkOutDate' },
  { header: 'Check Out', dataKey: 'checkOutTime' },
  { header: 'Signee', dataKey: 'checkOutName' },
];
export function generateDailyAttendanceCSV(dailyAttendanceReport: DailyAttendanceReportType) {
  const { items: dailyAttendance, endDate, startDate } = dailyAttendanceReport;
  const filename = `Attendance Daily (${startDate} - ${endDate}).csv`;

  const headers = COLUMNS.map((column) => column.header).join(',') + '\n';
  const body = dailyAttendance
    .map((row) => {
      return [
        row.studentName,
        row.roomName,
        row.checkInDate,
        row.isAbsent ? 'Absent' : row.checkInTime,
        row.checkInName,
        row.checkOutDate,
        row.isAbsent ? 'Absent' : row.checkOutTime,
        row.checkOutName,
      ].join(',');
    })
    .join('\n');
  const csv = headers + body;
  saveCSV(csv, filename);
}
