export const NETWORKS_FETCHED = 'NETWORKS_FETCHED';
export const NETWORK_FETCHED = 'NETWORK_FETCHED';
export const NETWORK_SELECTED = 'NETWORK_SELECTED';
export const NETWORK_SELECTION_CLEARED = 'NETWORK_SELECTION_CLEARED';

export const networksFetchedAction = (networks) => ({
  type: NETWORKS_FETCHED,
  count: networks.list.length,
  list: networks.list,
});

export const networkFetchedAction = (network) => ({
  type: NETWORK_FETCHED,
  network: network,
});

const initialState = {
  network: null,
  count: 0,
  list: null,
};

export const networksReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORKS_FETCHED:
      return {
        ...state,
        count: action.count,
        list: action.list,
      };

    case NETWORK_FETCHED:
      return { ...state, currentNetwork: action.network };

    case NETWORK_SELECTED:
      return { ...state, selectedNetwork: action.network };

    case NETWORK_SELECTION_CLEARED:
      return { ...state, selectedNetwork: initialState.network };

    default:
      return state;
  }
};
