import { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Segment, Icon, Header, Button, Loader, Dimmer } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import StaffForm from '../Forms/StaffForm';
import StaffList from '../Staff/StaffList';

const SlidingStaffForm = withSlidingPanel(StaffForm, {
  title: 'Add Your Staff',
  width: '70%',
});

class SetupStaff extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      staff: { list },
      t,
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="heart" size="huge" color="red" />
          <Header as="h2" content={t('Add Your Staff')} />
          <p>{t('Add all your superstar staff that keep the ship running you have at your center.')}</p>
        </Segment>
        <Segment basic>
          <SlidingStaffForm
            isOpen={this.state.isOpen}
            onClose={(status) => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button content={t('Add staff')} primary onClick={() => this.setState({ isOpen: true })} />
        </Segment>
      </Segment>
    );
  };

  renderListBlock = () => {
    const {
      staff: { list },
    } = this.props;
    if (!list.length) return null;
    return <StaffList />;
  };

  /**
   * This loader will be displayed only during first staff creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
      t,
    } = this.props;

    if (this.state.added && !setup.firstStaffCreated)
      return (
        <Dimmer active inverted>
          <Loader inline="centered" indeterminate content={t('Just a moment while we add your first staff...')} />
        </Dimmer>
      );
  };

  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupStaff.propTypes = {};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  staff: state.staff,
});
export default withTranslation()(connect(mapStateToProps)(SetupStaff));
