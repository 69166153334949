import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { MealSchedulingPage } from '@wonderschool/common-food-program-ui';
import { getTitlePrefix } from '../config/env';
import { RouteNameEnum, useRoutes } from '../navigation';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Calendar = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.calendar);
  const { gotoRouteByName } = useRoutes();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Calendar') })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  const handleClickRecordMeal = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_RECORD_CREATE);
  };

  return (
    <MainContentLayout>
      {renderPageHead()}
      <MealSchedulingPage onClickRecordMeal={handleClickRecordMeal} />
    </MainContentLayout>
  );
};
export default Calendar;
