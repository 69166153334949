import { ClaimCreatePage } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

export const ClaimCreate = () => {
  const { t } = useTranslation();
  const title = t('food.claims.create');
  const { gotoRouteByName } = useRoutes();
  useSegmentPage(PAGE_NAMES.foodProgramClaimCreate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  const handleGoBack = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIMS);
  };

  return (
    <>
      {renderPageHead()}
      <ClaimCreatePage onClickBack={handleGoBack} onSubmit={handleGoBack} />
    </>
  );
};
export default ClaimCreate;
