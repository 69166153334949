import { useTranslation } from 'react-i18next';

import { WsModal } from '../../../common';
import { AttendanceType } from '../types';

type AttendanceModalProps = {
  attendance?: AttendanceType;
  onClose: () => void;
};

export function AttendanceDetailsModal({ attendance, onClose }: AttendanceModalProps) {
  const { t } = useTranslation();

  if (!attendance) return null;
  const { checkInDate, healthCheck, studentName } = attendance;
  return (
    <WsModal isOpen={!!attendance} onClose={onClose} title={t('Health Check Form')}>
      <div className="flex w-96 flex-col justify-center gap-2">
        <div className="flex flex-row justify-between border-b border-gray-300">
          <div data-testid="student-name">{studentName}</div>
          <div data-testid="checkin-date">{checkInDate}</div>
        </div>
        {healthCheck ? (
          <>
            <div className="flex flex-col justify-center">
              <div className="font-bold">{t('Health check - symptoms:')}</div>
              <div data-testid="health-check-notes">{healthCheck?.symptoms || '-'}</div>
            </div>
            <div className="flex flex-col justify-center">
              <div className="font-bold">{t('Health check - notes:')}</div>
              <div data-testid="health-check-notes">{healthCheck?.notes || '-'}</div>
            </div>
          </>
        ) : (
          <div className="font-bold" data-testid="health-check-empty">
            {t('A health check was not performed')}
          </div>
        )}
      </div>
    </WsModal>
  );
}
export default AttendanceDetailsModal;
