import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';

import WSA from '../../../WSA';
import { paths } from '../../paths';

const TIMEOUT = 10000;

const LoadingRoute = ({ children }) => {
  const { isLoading, isUserLoading, isFailed, isReady } = WSA.Components.useWSAuth();
  const [isTimeout, setIsTimeout] = useState(false);
  const isFinished = !isLoading && isReady && !isUserLoading;
  const isError = isFailed || isTimeout;

  // taking too long, give up and show error
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isFinished) {
        setIsTimeout(true);
      }
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  if (isError) {
    // error state
    return <Redirect to={paths.authError || paths.r404} />;
  } else if (isFinished) {
    return children;
  } else {
    // loading
    return <LoadingComponent />;
  }
};
export default LoadingRoute;
