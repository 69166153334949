import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getBoxTypeTitle, getFinanceAtGlanceTooltip } from '../../../helpers/financeAtGlance';
import fStyle from './FinanceAtGlance.module.scss';
import PaymentTooltip from './PaymentTooltip';

const fx = classNames.bind(fStyle);

export enum FinanceBoxTypeEnum {
  UPCOMING = 'upcoming',
  PROCESSING = 'processing',
  TRANSFERRING = 'transferring',
  DEPOSITED_OR_PAID = 'depositedPaid',
  PAST_DUE = 'pastDue',
  FAILED = 'failed',
  REVENUE = 'revenue',
  EXPENSES = 'expenses',
}

export type FinanceBoxProps = {
  boxType: FinanceBoxTypeEnum;
  amount: string;
  label: string;
};

function FinanceBox({ boxType, amount, label }: FinanceBoxProps) {
  const { t } = useTranslation();

  const boxTypeColors = useMemo(() => {
    switch (boxType) {
      case FinanceBoxTypeEnum.UPCOMING:
        return 'bg-yellow-200 border-yellow-400';
      case FinanceBoxTypeEnum.PROCESSING:
        return 'bg-purple-200 border-purple-400';
      case FinanceBoxTypeEnum.TRANSFERRING:
        return 'bg-blue-200 border-blue-400';
      case FinanceBoxTypeEnum.DEPOSITED_OR_PAID:
        return 'bg-green-200 border-green-400';
      case FinanceBoxTypeEnum.PAST_DUE:
        return 'bg-red-200 border-red-400';
      case FinanceBoxTypeEnum.FAILED:
        return 'bg-pink-200 border-pink-400';
      case FinanceBoxTypeEnum.REVENUE:
        return 'bg-green-200 border-green-400';
      case FinanceBoxTypeEnum.EXPENSES:
        return 'bg-blue-200 border-blue-400';
      default:
        return 'bg-gray-200 border-gray-400';
    }
  }, [boxType]);
  return (
    <div className={fx(boxTypeColors, 'tooltip', 'gap-1 border')} data-testid={label}>
      <span className={fx('boldFont')}>{amount}</span>
      <span className="rounded-full bg-white/60 px-2 py-1 text-xs font-thin">{label}</span>
      <PaymentTooltip title={t(getBoxTypeTitle(boxType))} tooltipText={t(getFinanceAtGlanceTooltip(boxType))} />
    </div>
  );
}

export default FinanceBox;
