import { firestore, functions } from './firebase';
import { addDocument, getDocument, getDocuments, getDocumentsAsArray, setDocument, updateDocument } from './firestore';

import { CONTACT_STATUS, CONTACT_TYPES } from '../../contacts/enums';
import { initializeOrganizationSetup } from '../../helpers/organizations';
export const ORGANIZATION_VERSION = {
  v1: '1.0',
  v2: '2.0',
};
const DIRECTOR_PIN = '5499';

export type UserProfileType = {
  uid: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  photoURL: string;
  defaultOrganization: string;
  organizations: { [key: string]: boolean };
  staffType: string[];
  allowStaffLogin: boolean;
  staff: boolean;
  staffPin: string;
  enabled: boolean;
  language: string;
  createdAt: any;
  currentRoom?: string;
  defaultRoom?: string;
  defaultLocation?: string;
  locations?: { [key: string]: boolean };
  rooms?: { [key: string]: boolean };
};

export const organizationOnListen = (organizationId, next, error, complete) =>
  firestore()
    .collection(`organizations`)
    .doc(organizationId)
    .onSnapshot((doc) => next(doc ? { id: doc.id, ...doc.data() } : {}), error, complete);

export const provisionOrganizationUserAccount = async (data) => {
  try {
    if (!data) throw Error('user data is missing');
    if (!data.uid) throw Error('UID is required');

    const { uid, organizationName, programType, networkId, ...userData } = data;

    // Create new organization.
    const organizationDocRef = firestore().collection('organizations').doc();
    const createdAt = firestore.FieldValue.serverTimestamp();

    const organizationData = {
      name: organizationName || `${userData.firstName} Program`,
      programType: programType || '',
      networks: networkId ? [networkId] : [],
      setup: initializeOrganizationSetup(),
      setupComplete: false,
      active: true,
      version: ORGANIZATION_VERSION.v2,
      createdBy: uid,
      createdAt,
      requireIdVerification: !networkId,
    };

    // Prepare user data to be saved.

    let userProfile: UserProfileType = {
      uid,
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      displayName: userData.displayName || '',
      email: userData.email || '',
      phoneNumber: userData.phoneNumber || '',
      photoURL: userData.photoURL || '',
      defaultOrganization: organizationDocRef.id,
      organizations: { [organizationDocRef.id]: true },
      staffType: ['organizationAdmin'],
      allowStaffLogin: true,
      staff: true,
      staffPin: DIRECTOR_PIN,
      enabled: true,
      language: userData.language || '',
      createdAt,
    };

    // Modify the payload if it's FCC.
    if (programType && programType === 'familyChildCare') {
      const locationId = `${organizationDocRef.id}-primaryLocation`;
      const roomId = `${organizationDocRef.id}-primaryRoom`;

      userProfile = {
        ...userProfile,
        currentRoom: roomId,
        defaultRoom: roomId,
        defaultLocation: locationId,
        locations: {
          [locationId]: true,
        },
        rooms: {
          [roomId]: true,
        },
      };
    }

    // Prepare contact data to be saved.
    const contact = {
      uid,
      type: CONTACT_TYPES.staff,
      status: CONTACT_STATUS.verified,
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      displayName: userData.displayName || '',
      email: userData.email || '',
      phoneNumber: userData.phoneNumber || '',
      photoURL: userData.photoURL || '',
      defaultOrganization: organizationDocRef.id,
      organizations: [organizationDocRef.id],
      staffType: ['organizationAdmin'],
      allowLoginWithPin: true,
      loginPin: DIRECTOR_PIN,
      language: userData.language || '',
      currentRoom: userProfile.currentRoom || '',
      defaultRoom: userProfile.defaultRoom || '',
      defaultLocation: userProfile.defaultLocation || '',
      locations: Object.keys(userProfile.locations || {}),
      rooms: Object.keys(userProfile.rooms || {}),
      createdAt,
    };
    const pinData = {
      active: true,
      recycled: false,
      organization: organizationDocRef.id,
      uid,
      type: 'staffPin',
      pin: DIRECTOR_PIN,
    };

    const batch = firestore().batch();

    const contactDocRef = firestore().collection(`organizations/${organizationDocRef.id}/contacts`).doc();
    const userDocRef = firestore().collection(`organizations/${organizationDocRef.id}/users`).doc(uid);
    const pinDocRef = firestore().collection(`organizations/${organizationDocRef.id}/tabletPins`).doc(DIRECTOR_PIN);

    batch.set(organizationDocRef, organizationData);
    batch.set(contactDocRef, contact);
    batch.set(userDocRef, {
      ...userProfile,
      contactId: contactDocRef.id,
    });
    batch.set(pinDocRef, pinData);

    await batch.commit();

    return userProfile;
  } catch (error: any) {
    console.error(error);
    throw Error(error?.message || 'Unable to complete provisioning');
  }
};

export const createOrganization = (organizationData) => {
  const data = {
    ...organizationData,
    setup: initializeOrganizationSetup(),
    setupComplete: false,
    active: true,
    shouldHideFromSearchResults: false,
    createdAt: Date.now(),
  };

  return addDocument({ path: 'organizations', data });
};

export const updateOrganization = (organizationId, organizationData) => {
  return updateDocument({
    path: `organizations/${organizationId}`,
    data: organizationData,
  });
};

export const getAllOrganizations = (limit = null, startAfter = null) =>
  getDocuments({
    path: 'organizations',
    orderBy: [{ field: 'createdAt', direction: 'asc' }],
    limit,
    startAfter,
  });

export const getAllOrganizationsAsArray = (limit = null, startAfter = null) =>
  getDocumentsAsArray({
    path: 'organizations',
    orderBy: [{ field: 'createdAt', direction: 'asc' }],
    limit,
    startAfter,
  });

export const getOrganizationById = async (organizationId) =>
  getDocument({
    path: `organizations/${organizationId}`,
  });

export const getCreatePayoutAccountUrl = async (organizationId) => {
  const { data } = await functions().httpsCallable('callables-stripe-createPayoutAccount')({ organizationId });
  return data;
};

export const getOrganizationSettings = async (organizationId) =>
  getDocuments({
    path: `organizations/${organizationId}/settings`,
  });

export const setOrganizationSettings = async (organizationId, settingsId, settings) =>
  setDocument({
    path: `organizations/${organizationId}/settings/${settingsId}`,
    data: settings,
  });
