import {
  filterAmounts,
  filterArchived,
  filterBillingInterval,
  filterDates,
  filterDescriptions,
  filterInvoiceStatus,
  filterStudentsAndParents,
} from '../../../../helpers/filters';

export function filterInvoicePlans(invoicePlansList, filters, timezone) {
  const {
    invoicePlanStatus,
    billingInterval,
    descriptionSearch,
    amountSearch,
    studentSearch,
    startDateRange,
    nextInvoiceDateRange,
    dueDateRange,
    hideArchived,
  } = filters;

  const filteredArchived = hideArchived ? filterArchived(invoicePlansList) : invoicePlansList;

  const filteredByPlanStatus = invoicePlanStatus
    ? filterInvoiceStatus(filteredArchived, invoicePlanStatus?.value)
    : filteredArchived;

  const filteredByBillingInterval = billingInterval
    ? filterBillingInterval(filteredByPlanStatus, billingInterval?.value)
    : filteredByPlanStatus;

  const filteredByDescription = descriptionSearch
    ? filterDescriptions(filteredByBillingInterval, descriptionSearch)
    : filteredByBillingInterval;

  const filteredByAmount = amountSearch ? filterAmounts(filteredByDescription, amountSearch) : filteredByDescription;

  const filteredByNames = studentSearch ? filterStudentsAndParents(filteredByAmount, studentSearch) : filteredByAmount;

  const filteredByStartDate = startDateRange
    ? filterDates(filteredByNames, 'dateStart', startDateRange, timezone)
    : filteredByNames;

  const filteredByNextInvoiceDate = nextInvoiceDateRange
    ? filterDates(filteredByStartDate, 'dateSend', nextInvoiceDateRange, timezone)
    : filteredByStartDate;

  return dueDateRange
    ? filterDates(filteredByNextInvoiceDate, 'dateDue', dueDateRange, timezone)
    : filteredByNextInvoiceDate;
}

export function isCurrentlyFiltered(filters) {
  return Object.keys(filters).some((field) => Boolean(filters[field]));
}
