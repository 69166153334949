import { firestore, functions } from '../api/firebase/firebase';
import { getDocument, getDocumentsAsArray, setDocument } from '../api/firebase/firestore';

const paths = {
  networks: () => 'networks',
  network: (networkId: string) => `networks/${networkId}`,
};

export async function seedNetworksData(networksData) {
  return await functions().httpsCallable('callables-networks-seedNetworksData')(networksData);
}

export async function fetchNetworks() {
  return await getDocumentsAsArray({
    path: paths.networks(),
    orderBy: [{ field: 'createdAt', direction: 'desc' }],
  });
}

export async function fetchNetwork(networkIdOrLegoId) {
  let network = await getDocument({ path: paths.network(networkIdOrLegoId) });
  if (!network) network = await fetchNetworkByLegoId(networkIdOrLegoId);
  return network;
}

export async function fetchNetworkByLegoId(id) {
  const querySnapshot = await firestore().collection(paths.networks()).where('id', '==', id).limit(1).get();
  const docRef = querySnapshot.docs.length ? querySnapshot.docs[0] : null;
  return docRef?.exists ? docRef.data() : null;
}

export async function saveNetwork(data, networkId) {
  if (!data.id) throw new Error('Invalid argument: data.id is required');

  if (!networkId) {
    const network = await getDocument({ path: paths.network(data.id) });
    if (network) throw new Error('Network ID already exists');
    data.createdAt = firestore.FieldValue.serverTimestamp();
  }
  data.updatedAt = firestore.FieldValue.serverTimestamp();

  return await setDocument({
    path: paths.network(data.id),
    data,
  });
}

export async function sendInvitation(emailAddress, networkId) {
  await functions().httpsCallable('callables-networks-sendInvitation')({
    emailAddress,
    networkId,
  });
}
