// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';
import WSA from '../../../WSA';
import { useOrganizationSetupState } from '../../../hooks/useOrganizations';
import { usePermissions } from '../../../hooks/useUser';
import { useRouteDebounce, useRoutes } from '../../navigationHooks';
import { paths } from '../../paths';
import { ApolloClientWrapper } from '../../../Components/Wrappers/ApolloClientWrapper';

const RestrictedRoute = ({ route, children }) => {
  const { isAuthenticated, isAuthenticatedProbably, didCheck, needsVerification, userClaims, authUser } =
    WSA.Components.useWSAuth();
  const { isRouteActive } = useRoutes();
  const permissions = usePermissions();
  const location = useLocation();
  const { needsOnboarding } = useOrganizationSetupState();

  const { path } = route;
  const hasUser = !!authUser;
  const hasClaims = !isEmpty(userClaims);
  const hasPermissions = !isEmpty(permissions);
  const hasClaimsAndPermissions = hasClaims && hasPermissions;
  const shouldRenderRoute = isRouteActive(route);

  // special routes where the user state is fluid but we want them to continue
  const isSetupRoute = path === paths.setup;
  const isCompleteSignupRoute = path === paths.completeSignup;
  const isHomeRoute = path === paths.home;
  const canViewRoute = shouldRenderRoute || isSetupRoute || isHomeRoute;
  const hasAuthenticated = isAuthenticated && hasUser && hasClaimsAndPermissions;

  const needsDebounce = !hasAuthenticated || needsVerification || isCompleteSignupRoute || !canViewRoute;
  const debounceString = JSON.stringify({
    isAuthenticated,
    isAuthenticatedProbably,
    didCheck,
    needsVerification,
    isCompleteSignupRoute,
    hasClaimsAndPermissions,
    canViewRoute,
    isHomeRoute,
  });

  const isDebounced = useRouteDebounce(debounceString, needsDebounce, 5000);

  if (!isDebounced) {
    return <LoadingComponent />;
  } else if (isAuthenticated) {
    if (needsVerification) {
      // engage verify user flow
      return (
        <Redirect
          to={{
            pathname: paths.verify,
            state: { from: location },
          }}
        />
      );
    } else if (isCompleteSignupRoute) {
      // special case for completing signup
      // the user and might not have claims/permissions yet
      return <ApolloClientWrapper>{children}</ApolloClientWrapper>;
    } else if (needsOnboarding) {
      // switch to org setup, complete signup route
      return (
        <Redirect
          to={{
            pathname: paths.completeSignup,
            state: { from: location },
          }}
        />
      );
    } else if (canViewRoute) {
      // the user can view the route, render it
      return <ApolloClientWrapper>{children}</ApolloClientWrapper>;
    } else {
      // the user can't view the route, send them somewhere safe
      return <Redirect to={paths.home} />;
    }
  } else if (!isAuthenticatedProbably && didCheck) {
    // not authenticated, go to signin
    return (
      <Redirect
        push={true}
        to={{
          pathname: paths.signin,
          state: { from: location },
        }}
      />
    );
  } else {
    return <LoadingComponent />;
  }
};

export default RestrictedRoute;
