import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Table } from 'semantic-ui-react';

// Import style
import { Card } from '@wonderschool/common-base-ui';
import DateTime from '../../../DateTime/DateTime';
import '../InvoiceDetail/InvoiceDetail.scss';

export function InvoicePlanDetails({ selectedInvoicePlan, responsibleForBilling }) {
  const { t } = useTranslation();
  return (
    <Card
      title={
        <div className="p-2 text-3xl font-bold text-black" data-testid="invoice-plan-detail-details-header">
          {t('Details')}
        </div>
      }
    >
      <div className="flex flex-col gap-4 p-4">
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell data-testid="invoice-plan-detail-id">{t('Id')}</Table.Cell>
              <Table.Cell data-testid="invoice-plan-detail-id-value">
                {selectedInvoicePlan && selectedInvoicePlan.id}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell data-testid="invoice-plan-detail-created-by">{t('Created')}</Table.Cell>
              <Table.Cell data-testid="invoice-plan-detail-created-by-value">
                {selectedInvoicePlan?.createdBy && selectedInvoicePlan?.createdBy?.displayName + ', '}
                {selectedInvoicePlan && selectedInvoicePlan.createdAt && (
                  <DateTime epoch={selectedInvoicePlan.createdAt} format={'MM/DD/YYYY h:mm A'} />
                )}
              </Table.Cell>
            </Table.Row>
            {selectedInvoicePlan?.updatedBy && selectedInvoicePlan?.updatedBy?.displayName && (
              <Table.Row>
                <Table.Cell data-testid="invoice-plan-detail-updated-by">{t('Updated')}</Table.Cell>
                <Table.Cell data-testid="invoice-plan-detail-updated-by-value">
                  {selectedInvoicePlan?.updatedBy?.displayName + ', '}
                  {selectedInvoicePlan?.updatedAt && (
                    <DateTime epoch={selectedInvoicePlan?.updatedAt} format={'MM/DD/YYYY h:mm A'} />
                  )}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell data-testid="invoice-plan-email-sent">{t('Emails invoice sent to')}</Table.Cell>
              <Table.Cell data-testid="invoice-plan-email-sent-value">
                {responsibleForBilling.map((responsible) => responsible.email).join(', ')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Card>
  );
}
