import './Integrations.scss';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Accordion } from 'semantic-ui-react';
import { useState } from 'react';

import KinderSystems from './KinderSystems/KinderSystems';

const Integrations = ({ locations, locationsToSaveData, onLocationsToSaveDataChange }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  if (!locations?.count) {
    return (
      <>
        <p>{t('No locations found')}</p>
      </>
    );
  }
  return (
    <Accordion className="integration">
      {locations?.list.map((location, index) => {
        return (
          <KinderSystems
            key={location.id}
            location={location}
            index={index}
            activeIndex={activeIndex}
            handleAccordionClick={handleAccordionClick}
            locationsToSaveData={locationsToSaveData}
            onLocationsToSaveDataChange={onLocationsToSaveDataChange}
          />
        );
      })}
    </Accordion>
  );
};

export default Integrations;
