import {
  INVOICE_NEW,
  INVOICE_EDIT,
  INVOICE_EDIT_DETAILS,
  INVOICE_EDIT_ITEMS,
  INVOICE_EDIT_STUDENTS,
  INVOICE_EDIT_SELECTED,
  INVOICE_EDIT_SELECTION_CLEARED,
} from '../../actions/types';

const initialState = {
  details: {
    isInvoice: true,
  },
  invoiceItems: [],
  students: [],
  charges: [],
};
export default function invoiceEditReducer(state = initialState, action) {
  switch (action.type) {
    case INVOICE_NEW:
      return { ...initialState, isInvoice: action.isInvoice };

    case INVOICE_EDIT: {
      const { details, invoiceItems, students } = action.invoice;
      return {
        details,
        invoiceItems,
        students,
      };
    }

    case INVOICE_EDIT_SELECTION_CLEARED: {
      return { ...initialState };
    }

    case INVOICE_EDIT_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    case INVOICE_EDIT_DETAILS:
      return { ...state, details: { ...state.details, ...action.details } };

    case INVOICE_EDIT_ITEMS:
      return { ...state, invoiceItems: action.invoiceItems };

    case INVOICE_EDIT_STUDENTS:
      return { ...state, students: action.students };

    default:
      return state;
  }
}
