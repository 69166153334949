import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@wonderschool/common-base-ui';

import { useStudents } from '../../../students/studentsHooks';
import { WsDropdownOptionType, WsDropdownProps } from '../widgets';

type DropdownOptionType = WsDropdownOptionType<string>;
type StudentDropdownProps = Omit<WsDropdownProps<string>, 'options'> & {
  filter?: (student: any) => boolean;
  kinderSystemsStudents?: boolean;
};

export const StudentDropdown: React.FC<StudentDropdownProps> = ({
  label,
  name,
  value,
  onChange,
  filter,
  'data-testid': dataTestId = 'student-select',
  kinderSystemsStudents = false,
}) => {
  const { t } = useTranslation();
  const studentsData: any = useStudents();

  const students = useMemo(() => {
    if (!filter) return studentsData?.list ?? [];

    const studentsList: any[] = studentsData?.list ?? [];
    if (kinderSystemsStudents) {
      return studentsList.filter((student) => student?.kinderSystems?.kinderConnectId);
    }
    return studentsList.filter((student) => filter(student) ?? true);
  }, [studentsData, filter, kinderSystemsStudents]);

  const options: DropdownOptionType[] = useMemo(() => {
    const optionsLocal = students?.map((student) => ({
      id: student.id,
      name: student.displayName ?? `${student.firstName} ${student.lastName}`,
    }));
    return optionsLocal ?? [];
  }, [students]);

  return (
    <Combobox
      label={label ?? t('Students')}
      placeholder={t('Select a student')}
      name={name ?? 'student'}
      options={options}
      value={value}
      onChange={onChange}
      data-testid={dataTestId}
    />
  );
};

export default StudentDropdown;
