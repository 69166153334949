import { OnboardingGoalIdEnum, OnboardingGoalStatusEnum } from '../Components/Onboarding/types';
import { isOnboardingBannerEnabled } from '../feature-flags';

export type OrganizationSetupFields = {
  firstInvoiceCreated: boolean;
  firstInvoicePlanCreated: boolean;
  firstLocationCreated: boolean;
  firstParentCreated: boolean;
  firstRoomCreated: boolean;
  firstStaffCreated: boolean;
  firstStudentCreated: boolean;
  permissionsGenerated: boolean;
  rolesGenerated: boolean;
  tabletPinsGenerated: boolean;
  websiteCreated: boolean;
  firstCheckinActivityCreated: boolean;
};

export function initializeOrganizationSetup(): OrganizationSetupFields {
  return {
    firstInvoiceCreated: false,
    firstInvoicePlanCreated: false,
    firstLocationCreated: false,
    firstParentCreated: false,
    firstRoomCreated: false,
    firstStaffCreated: false,
    firstStudentCreated: false,
    permissionsGenerated: false,
    rolesGenerated: false,
    tabletPinsGenerated: false,
    websiteCreated: false,
    firstCheckinActivityCreated: false,
  };
}

export function isOrganizationSetupComplete(organization: any): boolean {
  if (!organization) return false;

  if (organization?.setupComplete) {
    return organization?.setupComplete;
  }

  if (isOnboardingBannerEnabled()) {
    const onboarding = organization?.onboarding ?? {};

    const setupComplete = Object.values(OnboardingGoalIdEnum).every((value) => {
      if (value === 'unknown') return true; // should be removed once we add the UUID for FAMILIES
      return onboarding[value] === OnboardingGoalStatusEnum.COMPLETE;
    });
    return setupComplete;
  }
  return false;
}
