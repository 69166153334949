import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { MainContentLayout, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import {
  FileCategory,
  FileDocument,
  OrganizationProgramTypeEnum,
  USStateCodeEnum,
} from '@wonderschool/common-base-types';
import { FileStatus, useDownloadFiles, useGetAdminFiles, useUploadAdminFile } from '@wonderschool/file-service-client';

import { getTitlePrefix } from '../../../config/env';
import PageTitle from '../../Shared/PageTitle';
import { AdminDocument, AdminDocumentsTable } from './AdminDocumentsTable';
import { AdminDocumentsFilters } from './AdminDocumentsFilters';
import { AdminDocumentFormData, UploadAdminDocument } from './UploadAdminDocument';
import { logError } from '../../../rollbar';

// TODO: FileStatus should be exported in common-base-types or replaced with the existing MalwareStatus from there

export const AdminDocuments: React.FC = () => {
  const { t } = useTranslation();
  const [statesFilter, setStatesFilter] = useState<USStateCodeEnum[]>([]);
  const [programTypesFilter, setProgramTypesFilter] = useState<OrganizationProgramTypeEnum[]>([]);

  const { data, refetch: refetchAdminFiles } = useGetAdminFiles({
    states: statesFilter,
    programTypes: programTypesFilter,
  });
  const { uploadFile, reset: resetUploadHook } = useUploadAdminFile();

  // TODO: show download error [CCMS-5901]
  const { downloadFiles } = useDownloadFiles();

  const adminDocumentsList: AdminDocument[] = useMemo(() => {
    const docs = data?.pages[0]?.documents || [];
    return docs.map((doc) => ({
      id: doc.id,
      name: doc.name,
      fileCategory: doc.fileCategory as FileCategory,
      fileStatus: doc.fileStatus as FileStatus,
      updatedAt: doc.updatedAt,
      updatedBy: doc.updatedBy,
      states:
        doc.tags?.filter((tag) => tag.startsWith('state:')).map((tag) => tag.split(':')[1] as USStateCodeEnum) || [],
      programTypes:
        doc.tags
          ?.filter((tag) => tag.startsWith('programType:'))
          .map((tag) => tag.split(':')[1] as OrganizationProgramTypeEnum) || [],
    }));
  }, [data]);

  const onFileDownload = (id: FileDocument['id']) => {
    downloadFiles([id]);
  };

  const onStateChange = (states: USStateCodeEnum[]) => {
    setStatesFilter(states);
  };

  const onProgramTypeChange = (programTypes: OrganizationProgramTypeEnum[]) => {
    setProgramTypesFilter(programTypes);
  };

  const uploadAdminDocument = (data: AdminDocumentFormData) => {
    try {
      const stateTags: string[] = data.states.length ? data.states.map((state) => `state:${state}`) : ['state:ALL'];
      const programTypeTags: string[] = data.programTypes.length
        ? data.programTypes.map((programType) => `programType:${programType}`)
        : ['programType:ALL'];

      uploadFile(
        {
          file: data.file,
          category: data.category,
          tags: stateTags.concat(programTypeTags),
        },
        {
          onSuccess: () => {
            showToast(ToastTypeEnum.Success, t('admin.wonderschoolDocuments.uploadedSuccess'));
            resetUploadHook();
            refetchAdminFiles();
          },
          onError: (error) => {
            showToast(ToastTypeEnum.Error, t('admin.wonderschoolDocuments.uploadedError'));
            logError('Admin Documents - Upload error: ', {
              clientError: error,
            });
            resetUploadHook();
          },
        }
      );
    } catch (error) {
      // log it with rollbar
      logError('Admin Documents - Upload error: ', {
        clientError: error,
      });
    }
  };

  return (
    <MainContentLayout>
      <Helmet>
        <title>
          {getTitlePrefix()}
          {t('{{pageName}} - Wonderschool', { pageName: t('admin.wonderschoolDocuments') })}
        </title>
        <body />
      </Helmet>

      <PageTitle title={t('admin.wonderschoolDocuments')} />
      <UploadAdminDocument onUploadFile={uploadAdminDocument} />
      <AdminDocumentsFilters onStateChange={onStateChange} onProgramTypeChange={onProgramTypeChange} />
      <AdminDocumentsTable data={adminDocumentsList} onFileDownload={onFileDownload} />
    </MainContentLayout>
  );
};
