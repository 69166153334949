import { Dashboard } from '@uppy/react';
import { ChangeEvent, useCallback, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Grid, Modal, Segment } from 'semantic-ui-react';
import { useUppy } from '../../hooks/useUppy';
import Uppy, { UppyFile } from '@uppy/core';

const UPPY_OPTIONS = {
  restrictions: {
    maxFileSize: 3500000,
  },
};
const UPPY_SOURCE_CROP = 'crop';

interface Props {
  title: string;
  onUploaded: (x: string) => undefined;
  uploadPath: string;
}
export default function ProfilePictureUploader({ title, onUploaded, uploadPath }: Props) {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [rawImage, setRawImage] = useState<File | null>(null);
  const [editor, setEditor] = useState<AvatarEditor | null>(null);

  const onUploadedLocal = useCallback(
    ({ downloadUrl }: { downloadUrl: string }) => {
      if (onUploaded) onUploaded(downloadUrl);
      clearPicture();
      setModalOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUploaded]
  );

  const onAdded = useCallback((result: UppyFile<Record<string, unknown>, Record<string, unknown>>) => {
    if (result?.source === UPPY_SOURCE_CROP) {
      setRawImage(null);
    } else if (result?.data) {
      setRawImage(result.data as File);
    }
  }, []);

  const uppy = useUppy({
    uploadPath,
    onUploaded: onUploadedLocal,
    onAdded,
    options: UPPY_OPTIONS,
  }) as unknown as Uppy;

  return (
    <Modal
      trigger={
        <Button
          primary
          icon="cloud upload"
          content={t('Upload')}
          className="round"
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        />
      }
      closeIcon
      open={modalOpen}
      size="small"
      header={title || t('Profile Picture Uploader')}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => {
        setModalOpen(false);
        clearPicture();
        uppy?.cancelAll();
      }}
      content={rawImage ? renderCropWindow() : renderDashboard()}
    />
  );

  function renderCropWindow() {
    return (
      <Segment placeholder>
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={10}>
              <Segment basic textAlign="center">
                {rawImage && (
                  <AvatarEditor
                    ref={setEditor}
                    image={rawImage}
                    width={300}
                    height={300}
                    border={50}
                    scale={1}
                    rotate={0}
                  />
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Button.Group fluid>
                <Button
                  primary
                  content={t('Crop')}
                  onClick={async (e) => {
                    e.preventDefault();

                    // Generate blob from the editor canvas
                    const response = await fetch(editor?.getImage().toDataURL('image/jpeg') ?? '');
                    const blob = await response.blob();

                    clearPicture();
                    const previewUrl = window.URL.createObjectURL(blob);

                    // Add cropped file to Uppy
                    uppy.addFile({
                      type: blob.type,
                      name: rawImage?.name,
                      data: blob,
                      preview: previewUrl,
                      source: UPPY_SOURCE_CROP,
                    });
                  }}
                />
                <Button.Or />
                {rawImage && (
                  <Button
                    content={t('Clear')}
                    onClick={(e) => {
                      e.preventDefault();
                      clearPicture();
                    }}
                  />
                )}
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  function renderDashboard() {
    if (!uppy) return null;
    return (
      <Dashboard uppy={uppy} hideProgressAfterFinish proudlyDisplayPoweredByUppy={false} doneButtonHandler={onClose} />
    );
  }
  function onClose(e?: ChangeEvent) {
    if (e) e.preventDefault();
    clearPicture();
    setModalOpen(false);
  }
  function clearPicture() {
    if (uppy) {
      const currentFiles = uppy.getFiles();
      currentFiles.forEach((currentFile) => uppy.removeFile(currentFile.id));
    }
    setRawImage(null);
  }
}
