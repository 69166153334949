import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selectInvoiceEditDetails = createSelector(
  [(state) => state.invoiceEdit],
  (invoiceEdit) => invoiceEdit?.details ?? {}
);
const selectInvoiceEditInvoiceItems = createSelector(
  [(state) => state.invoiceEdit],
  (invoiceEdit) => invoiceEdit?.invoiceItems ?? []
);
const selectInvoiceEditStudents = createSelector(
  [(state) => state.invoiceEdit],
  (invoiceEdit) => invoiceEdit?.students ?? []
);
const selectInvoiceEditSelected = createSelector(
  [(state) => state.invoiceEdit],
  (invoiceEdit) => invoiceEdit?.selected
);

export function useInvoiceEditDetails() {
  return useSelector(selectInvoiceEditDetails);
}
export function useInvoiceEditItems() {
  return useSelector(selectInvoiceEditInvoiceItems);
}

export function useInvoiceEditStudents() {
  return useSelector(selectInvoiceEditStudents);
}
export function useInvoiceEditSelected() {
  return useSelector(selectInvoiceEditSelected);
}
