import React from 'react';

import NoDataIcon from '../assets/NoDataIcon';

import './NoDataMessageBilling.styles.css';

interface NoDataMessageBillingProps {
  title: string;
  subTitle?: string;
}

export default function NoDataMessageBilling(props: NoDataMessageBillingProps) {
  const { title, subTitle } = props;
  return (
    <div className="no-data-message-billing-container" data-testid="no-data-message-billing">
      <NoDataIcon />
      <div className="no-data-message-billing-text-wrapper">
        <h2 className="no-data-message-billing-title" data-testid="no-data-message-billing-title">
          {title}
        </h2>
        {subTitle ? (
          <p data-testid="no-data-message-billing-subtitle" className="no-data-message-billing-subtitle">
            {subTitle}
          </p>
        ) : null}
      </div>
    </div>
  );
}
