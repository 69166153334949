/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 */

export const TXT = 'text/plain';
export const CSV = 'text/csv';
export const PNG = 'image/png';
export const JPEG = 'image/jpeg';
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLS = 'application/vnd.ms-excel';
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const mimeTypes = {
  TXT,
  CSV,
  PNG,
  JPEG,
  PDF,
  DOC,
  DOCX,
  XLS,
  XLSX,
};

export default mimeTypes;
