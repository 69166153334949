import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageTitle from '../Components/Shared/PageTitle';
import { getTitlePrefix } from '../config/env';
import { ParentMain, StaffMain } from '../documents';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { useOrganization, useOrganizationUsersListener } from '../hooks/useOrganizations';
import { useUser } from '../hooks/useUser';

const FormsAndDocuments: React.FC = () => {
  const { t } = useTranslation();
  const { isParent } = useUser();
  const { id: currentOrganizationId } = useOrganization();
  useOrganizationUsersListener(currentOrganizationId);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Documents') })}
      </title>
      <body />
    </Helmet>
  );

  const renderPageTitle = () => <PageTitle title={t('Documents')} />;

  return (
    <MainContentLayout>
      {renderPageHead()}

      {renderPageTitle()}
      {isParent ? <ParentMain /> : <StaffMain />}
    </MainContentLayout>
  );
};

export default FormsAndDocuments;
