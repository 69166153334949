import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { isEmpty, omit } from 'lodash';

// eslint-disable-next-line no-restricted-imports
import { logError } from '../../rollbar';

import { OnSaveCallbackParamsType } from '../../common';
import { saveContact } from '../../contacts/contactsAPI';
import { CONTACT_STATUS, CONTACT_TYPES, INVITATION_TYPES } from '../../contacts/enums';
import { formatISOStringAsUtcDateOrNull, isTodayOrBefore } from '../../helpers/dates';
import { formatFullName, phoneNumberFormat } from '../../helpers/utils';
import { usePrimaryLocation } from '../../hooks/useLocations';
import { useOrganization, useOrganizationContactsListener } from '../../hooks/useOrganizations';
import { RouteNameEnum, useRoutes } from '../../navigation';

import { EnrollmentSourceEnum, EnrollmentStatusEnum } from '../../enrollments';
import { InterestedFamilyType, MarketplaceFilteredStatusType, useMarketplaceAPI } from '../../integrations';

import { MARKETPLACE_REVENUE_SOURCE_MAP, MARKETPLACE_STATUS_MAP } from '../consts';
import { saveStudent } from '../studentsAPI';
import { studentAdded } from '../studentsRedux';
import { EnrollmentInformationType, StudentAndParentsType } from '../types';

import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { Button } from '@wonderschool/common-base-ui';
import PageHeader from '../../Components/Shared/PageHeader';
import { createScheduledDaysFromInterestedFamily } from '../studentsUtils';
import EnrollmentInformationForm from './enrollment/EnrollmentInformationForm';
import StudentAndParentsForm from './StudentAndParentsForm';
import StudentAddedModal from './modals/StudentAddedModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

type StudentAddPageProps = {
  onClick?: () => void;
};
type OnSaveParamsType = OnSaveCallbackParamsType<EnrollmentInformationType | StudentAndParentsType>;

type StudentSaveDataType = {
  enrollmentInformation?: EnrollmentInformationType;
  studentAndParents?: StudentAndParentsType;
};

// TODO: This form uses a bunch of useState callbacks and is highly coupled, not ideal for a form.
// Migrate this to react-hook-form once we start on the enrollments rebuild in November 2024.
const StudentAddPage: React.FC<StudentAddPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [dataToSave, setDataToSave] = useState<StudentSaveDataType>({});
  const [interestedFamily, setInterestedFamily] = useState<InterestedFamilyType>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [studentSaved, setStudentSaved] = useState<any>();
  const { gotoRouteByName } = useRoutes();

  const params: any = useParams();
  const marketplaceChildId = params.marketplaceChildId ? parseInt(params.marketplaceChildId) : undefined;

  const organization = useOrganization() as any;
  const location = usePrimaryLocation();
  const { goBack } = useRoutes();
  const { sendEnrollmentStatus } = useMarketplaceAPI();
  const { enrollmentsV2 } = useFlags();

  const roomOptions = useSelector((state: any) => state.rooms?.list || []);

  const defaultRoomId = roomOptions.length === 1 ? roomOptions[0].id : undefined;

  useOrganizationContactsListener(organization.id);

  const contacts = useMemo<any[]>(() => {
    const contactsLocal: any[] = [];
    const room = dataToSave.enrollmentInformation?.room;
    const parents = dataToSave.studentAndParents?.parents ?? [];

    for (const parent of parents) {
      let contact;

      const status =
        parent.hasAuthUser && parent.uid
          ? CONTACT_STATUS.verified
          : parent.shouldSendInvitation
            ? CONTACT_STATUS.inviting
            : CONTACT_STATUS.new;

      if (parent.contact) {
        contact = parent.contact;
      } else {
        contact = {
          firstName: parent.firstName,
          lastName: parent.lastName,
          displayName: formatFullName(parent, true),
          email: parent.email ?? '',
          phone: phoneNumberFormat(parent.phone),
          type: CONTACT_TYPES.family,
          relationship: 'parent',
          status,
          defaultOrganization: organization?.id ?? '',
          defaultLocation: location?.id ?? '',
          defaultRoom: room ?? '',
          organizations: [organization.id],
          locations: location?.id ? [location.id] : [],
          rooms: room ? [room] : [],
          allowLoginWithPin: true,
          uid: parent.uid ?? '',
          invitationType: INVITATION_TYPES.parent,
        };
      }
      contactsLocal.push(contact);
    }
    return contactsLocal;
  }, [dataToSave, location?.id, organization?.id]);

  const desiredStartDate = useMemo<Date | undefined>(() => {
    if (!interestedFamily?.student?.desiredStartDate) return undefined;
    const date = formatISOStringAsUtcDateOrNull(interestedFamily.student.desiredStartDate);
    return date ?? undefined;
  }, [interestedFamily]);

  const enrollmentStatus = useMemo<EnrollmentStatusEnum>(() => {
    const status = interestedFamily?.status as MarketplaceFilteredStatusType;
    return status ? MARKETPLACE_STATUS_MAP[status] ?? EnrollmentStatusEnum.PENDING : EnrollmentStatusEnum.PENDING;
  }, [interestedFamily]);

  const enrollmentSource = useMemo<EnrollmentSourceEnum>(() => {
    return interestedFamily?.revenueSource
      ? MARKETPLACE_REVENUE_SOURCE_MAP[interestedFamily.revenueSource] ?? EnrollmentSourceEnum.PROVIDER
      : EnrollmentSourceEnum.PROVIDER;
  }, [interestedFamily]);

  // only save new contacts. No need to save existing contacts
  const saveContacts = useCallback(async () => {
    const newContacts = contacts.filter((contact) => !contact.id);
    try {
      for (const contact of newContacts) {
        const docRef = await saveContact(organization.id, contact);
        contact.id = docRef.id;
      }
    } catch (error) {
      logError('Error while saving contacts', error);
    }
  }, [contacts, organization]);

  const saveStudentLocal = useCallback(async () => {
    const studentLocal = dataToSave.studentAndParents?.student;
    const parents = dataToSave.studentAndParents?.parents;
    const room = dataToSave.enrollmentInformation?.room ?? '';

    // strip room from the enrollment information, since we store it in the student
    const enrollmentInformation = omit(dataToSave.enrollmentInformation, 'room') ?? {};
    const isEnrolled = !!(
      enrollmentStatus === EnrollmentStatusEnum.ENROLLED ||
      (enrollmentInformation.automaticallyEnroll && isTodayOrBefore(enrollmentInformation.startDate))
    );

    if (!studentLocal || !parents?.length) return null;

    await saveContacts();
    const family: any = {};
    const familyMembers: string[] = [];

    contacts.forEach((contact) => {
      family[contact.id] = contact;
      familyMembers.push(contact.id);
      if (contact.uid) familyMembers.push(contact.uid);
    });

    const studentData: any = {
      firstName: studentLocal.firstName ?? '',
      lastName: studentLocal.lastName ?? '',
      displayName: formatFullName(studentLocal, true),
      birthday: studentLocal.birthday ? new Date(studentLocal.birthday) : null,
      gender: studentLocal.gender ?? '',
      defaultOrganization: organization.id,
      defaultLocation: location?.id ?? '',
      defaultRoom: room ?? '',
      organizations: [organization.id],
      locations: location?.id ? [location.id] : [],
      rooms: room ? [room] : [],
      family,
      familyMembers,
      source: enrollmentSource,
      enrollmentStatus: isEnrolled, // this should be deprecated, but still including for now
      enrollmentDate: new Date(enrollmentInformation.startDate ?? '').valueOf() ?? null,
      enrollment: {
        ...enrollmentInformation,
        status: isEnrolled ? EnrollmentStatusEnum.ENROLLED : enrollmentStatus,
        source: enrollmentSource,
      },
    };

    if (interestedFamily) {
      let notes = interestedFamily.student?.notes ?? '';
      if (notes && interestedFamily.student?.needs) notes += ' ' + interestedFamily.student.needs;
      if (notes && interestedFamily.student?.medical) notes += ' ' + interestedFamily.student.medical;

      studentData.allergies = interestedFamily.student?.allergies ?? '';
      studentData.medications = interestedFamily.student?.medications ?? '';
      studentData.doctorName = '';
      studentData.notes = notes;
      studentData.enrollment.interestedFamily = interestedFamily;
      studentData.marketplaceId = interestedFamily.student?.id ?? '';
      studentData.enrollment.scheduledDays = createScheduledDaysFromInterestedFamily(interestedFamily);
    }
    const docRef = await saveStudent(organization.id, studentData, { merge: false });
    return { ...studentData, id: docRef.id };
  }, [
    dataToSave,
    organization,
    location,
    saveContacts,
    contacts,
    interestedFamily,
    enrollmentStatus,
    enrollmentSource,
  ]);

  const shouldSaveToFirestore = useCallback(() => {
    return isSaving && Object.keys(dataToSave).length === 2 && isEmpty(errors);
  }, [isSaving, dataToSave, errors]);

  useEffect(() => {
    const saveLocal = async () => {
      try {
        const studentSavedLocal = await saveStudentLocal();
        setStudentSaved(studentSavedLocal);
        dispatch(studentAdded(studentSavedLocal));
        gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT, [{ name: 'studentId', value: studentSavedLocal.id }]);
      } catch (error) {
        logError('Error while saving student', error);
      } finally {
        setIsSaving(false);
      }
    };
    if (shouldSaveToFirestore()) {
      saveLocal();
    }
  }, [isSaving, organization, shouldSaveToFirestore, dataToSave, saveStudentLocal, gotoRouteByName, dispatch]);

  useEffect(() => {
    const sendStatus = async () => {
      try {
        if (studentSaved?.enrollment?.interestedFamily?.id) {
          await sendEnrollmentStatus(studentSaved?.enrollment?.status, studentSaved.enrollment.interestedFamily.id);
        }
      } catch (error) {
        logError('Error while sending enrollment status', error);
      }
    };
    if (studentSaved) sendStatus();
  }, [sendEnrollmentStatus, studentSaved]);

  const onSave = useCallback(
    ({ data, errors }: OnSaveParamsType, fieldName: 'enrollmentInformation' | 'studentAndParents') => {
      if (!isSaving) return false;

      if (!isEmpty(errors)) {
        setIsSaving(false);
        setErrors((prev) => ({ ...prev, ...errors }));
        setDataToSave({});
      } else {
        setDataToSave((prev) => ({ ...prev, [fieldName]: data }));
      }
    },
    [isSaving]
  );

  return (
    <>
      <div className="p-4 bg-white shadow-md rounded-lg">
        <PageHeader pageName={'Add Student'} classes="add-student-page" />
        <h1 className="text-2xl font-bold mb-4">{t('students.addStudentPageTitle')}</h1>
        <div className="mb-6 p-4 bg-gray-50 rounded-md border-2 border-gray-300">
          <StudentAndParentsForm
            isSaving={isSaving && !dataToSave.studentAndParents}
            onSave={(d) => onSave(d, 'studentAndParents')}
            marketplaceChildId={marketplaceChildId}
            onInterestedFamilySelected={(interestedFamilyLocal) => {
              setInterestedFamily(interestedFamilyLocal);
            }}
          />
        </div>
        <div className="mb-6 p-4 bg-gray-50 rounded-md border-2 border-gray-300">
          <EnrollmentInformationForm
            isSaving={isSaving && !dataToSave.enrollmentInformation}
            onSave={(d) => onSave(d, 'enrollmentInformation')}
            desiredStartDate={desiredStartDate}
            desiredRoomId={defaultRoomId}
          />
        </div>
        <CommandButtons />
      </div>
      {!enrollmentsV2 && (
        <StudentAddedModal student={studentSaved} isOpen={!!studentSaved} onClose={() => setStudentSaved(undefined)} />
      )}
    </>
  );

  function CommandButtons() {
    return (
      <div className="ws-form-buttons" data-testid="as-form-btns">
        <Button
          primary
          disabled={isSaving}
          onClick={() => {
            goBack();
          }}
          data-testid="as-cancel-btn"
          preIcon={<XMarkIcon className="size-6" data-testid="as-cancel-icon" />}
        >
          {t('common.cancel')}
        </Button>
        <Button
          primary
          loading={isSaving && Object.keys(errors).length > 0}
          disabled={isSaving && Object.keys(errors).length > 0}
          onClick={() => {
            setIsSaving(true);
            setErrors({});
          }}
          postIcon={<ArrowRightIcon className="size-6" data-testid="as-finish-icon" />}
          data-testid="as-finish-btn"
        >
          {t('common.continue')}
        </Button>
      </div>
    );
  }
};

export default StudentAddPage;
