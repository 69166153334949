import { SlideOver } from '@wonderschool/common-base-ui';
import { StudentType as Student } from '@wonderschool/common-base-types';
import { useTranslation } from 'react-i18next';
import InvoicePlanDetailsContainer from './InvoicePlanDetailsContainer';

/**
 * This function returns the display name of the students, separated by commas.
 * It returns 'No students' if the list is empty.
 *
 * @param students The list of students
 * @returns The display name of the students, or 'No students' if the list is empty
 */
export function getStudentNames(students: Student[]): string {
  return (students && students.map((s) => s?.displayName).join(', ')) || 'No students';
}

export type InvoicePlanDetailSlideoverProps = {
  selectedInvoicePlan: any;
  onClose: () => void;
};
export function InvoicePlanDetailSlideover({ selectedInvoicePlan, onClose }: InvoicePlanDetailSlideoverProps) {
  const { t } = useTranslation();
  const displayName = getStudentNames(selectedInvoicePlan?.students);
  const invoiceId = selectedInvoicePlan?.id;

  return (
    <SlideOver
      title={
        <div className="flex flex-col gap-2">
          <span className="text-3xl font-bold text-navy-800">
            {t('Recurring Plan for {{displayName}}', { displayName })}
          </span>
          <span className="text-lg font-bold text-gray-700">{t('Recurring Plan # {{invoiceId}}', { invoiceId })}</span>
        </div>
      }
      hasOverlay
      isOpen={!!selectedInvoicePlan}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <InvoicePlanDetailsContainer invoicePlan={selectedInvoicePlan} />
      </div>
    </SlideOver>
  );
}
