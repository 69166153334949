import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { startChargesListener } from '../redux/actions/billingActions';
import { useListenerWithRedux } from './useListenerWithRedux';

const selectChargesList = createSelector([(state) => state.charges], (charges) => charges?.list ?? []);

export function useChargesListener(organizationId, _firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startChargesListener(organizationId);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

export function useCharges() {
  return useSelector(selectChargesList);
}
