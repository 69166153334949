import * as actionTypes from '../../actions/types';

const initialState = {
  employeesList: [],
};
const employeeReducer = (state = initialState.employeesList, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EMPLOYEES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default employeeReducer;
