import React from 'react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { connect } from 'react-redux';

import { Dropdown } from '../Shared/Dropdown';
import { getStaffRoles } from '../../redux/actions/roleActions';

class StaffPicker extends React.Component {
  state = {
    loading: false,
    options: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const roles = getStaffRoles();
    if (_.isEmpty(roles)) return;
    this.setState({ loading: true });
    const options = Object.keys(roles).map((key) => ({
      text: roles[key].name,
      value: key,
    }));
    this.setState({ loading: false, options });
  };

  render() {
    const { staticContext, currentOrganization, control, children, dispatch, ...rest } = this.props;

    return (
      <Dropdown
        {...rest}
        loading={this.state.loading}
        options={this.state.options}
        selectOnBlur={false}
        clearable
        renderLabel={(label) => ({
          color: 'blue',
          content: label.text,
          // icon: 'check',
        })}
        data-testid="staff-type-picker"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(StaffPicker);
