import { Checkbox, Input, Option, SearchIcon, Select } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { getTranslatedOptionsObj } from '../../../helpers/filters';
import { InvoicePlanStatus } from '../../../helpers/invoicePlans';
import { DateRangePicker } from '../../Shared/DatePicker';
import { BILLING_INTERVAL_OPTIONS } from './dropdownOptions';

const PageFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const { invoicePlanStatus, billingInterval, startDateRange, nextInvoiceDateRange, dueDateRange, hideArchived } =
    filters;

  const statusOptions = [
    {
      value: InvoicePlanStatus.ACTIVE,
      label: t('Active'),
    },
    {
      value: InvoicePlanStatus.PAUSED,
      label: t('Paused'),
    },
    {
      value: InvoicePlanStatus.ENDED,
      label: t('Ended'),
    },
    {
      value: InvoicePlanStatus.SCHEDULED,
      label: t('Scheduled'),
    },
  ];

  const handleChange = (updateField: string) => (option: Option) => {
    setFilters({ ...filters, [updateField]: option });
  };

  const onInputChange = (updateField) => (e) => {
    setFilters({ ...filters, [updateField]: e.currentTarget.value });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Select
            data-testid="invoice-plan-status-filter"
            placeholder={t('allStatusesLabel')}
            value={invoicePlanStatus}
            onChange={handleChange('invoicePlanStatus')}
            options={statusOptions}
          />
          <Select
            data-testid="invoice-plan-billing-interval-filter"
            placeholder={t('Billing Frequency')}
            value={billingInterval}
            onChange={handleChange('billingInterval')}
            options={getTranslatedOptionsObj(BILLING_INTERVAL_OPTIONS, t)}
          />
          <div />
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Input
            data-testid="invoice-plan-student-filter"
            placeholder={t('Search Student/Parent')}
            preIcon={<SearchIcon className="size-5 text-gray-800" />}
            onChange={onInputChange('studentSearch')}
          />
          <Input
            data-testid="invoice-plan-description-filter"
            placeholder={t('Search Description')}
            preIcon={<SearchIcon className="size-5 text-gray-800" />}
            onChange={onInputChange('descriptionSearch')}
          />
          <Input
            data-testid="invoice-plan-amount-filter"
            placeholder={t('Search Amount')}
            preIcon={<SearchIcon className="size-5 text-gray-800" />}
            onChange={onInputChange('amountSearch')}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-3">
          <DateRangePicker
            data-testid="invoice-plan-due-date-filter"
            placeholder={t('All Due Dates')}
            value={dueDateRange}
            clearable
            allowSameEndDate
            onChange={(_event, change) => {
              handleChange('dueDateRange')(change.value);
            }}
            className="date-range-picker"
            closeOnMouseLeave={false}
          />
          <DateRangePicker
            data-testid="invoice-plan-start-date-filter"
            placeholder={t('All Start Dates')}
            value={startDateRange}
            clearable
            allowSameEndDate
            onChange={(_event, change) => {
              handleChange('startDateRange')(change.value);
            }}
            className="date-range-picker"
            closeOnMouseLeave={false}
          />
          <DateRangePicker
            data-testid="invoice-plan-next-invoice-date-filter"
            placeholder={t('All Next Invoice Dates')}
            value={nextInvoiceDateRange}
            clearable
            allowSameEndDate
            onChange={(_event, change) => {
              handleChange('nextInvoiceDateRange')(change.value);
            }}
            className="date-range-picker"
            closeOnMouseLeave={false}
          />
        </div>
      </div>
      <div className="py-4">
        <Checkbox
          data-testid="invoice-plan-hide-archived-filter"
          label={t('recurringPlanList.hideArchiveInvoices')}
          onChange={(e) => setFilters({ ...filters, hideArchived: e.currentTarget.checked })}
          checked={hideArchived}
        />
      </div>
    </>
  );
};

export default PageFilters;
