import { ROLES_CHANGED, MOXIT_ORGANIZATIONS_FETCHED } from '../actions/types';

const initialState = {};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case ROLES_CHANGED:
      return { ...action.roles };

    case MOXIT_ORGANIZATIONS_FETCHED:
      return initialState;
    default:
      return state;
  }
};

export default roles;
