import { useTranslation } from 'react-i18next';
import { invoicePlanStatusFormatter } from '../../../../helpers/invoicePlans';

export type InvoicePlanStatusLabelProps = {
  status: string;
  'data-testid'?: string;
};
export function InvoicePlanStatusLabel({ status, 'data-testid': dataTestId }: InvoicePlanStatusLabelProps) {
  const { t } = useTranslation();

  const { text, color } = invoicePlanStatusFormatter({ status });
  if (!text || !color) return null;

  return (
    <div className={'rounded px-3 py-1.5 text-center text-base text-white ' + color} data-testid={dataTestId}>
      {t(text)}
    </div>
  );
}
