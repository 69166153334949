import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { WsModalProps } from './types';

/**
 * @deprecated The component should not be used anymore. Use Modal from ws-common instead.
 */
export function WsModal({ children, title, onClose, isOpen, hasCloseButton = true }: WsModalProps) {
  const { t } = useTranslation();

  const onCloseLocal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const titleElement = useMemo<React.ReactNode>(() => {
    return typeof title === 'string' ? (
      <h1 className="text-left text-lg font-semibold leading-6 text-gray-500">{title}</h1>
    ) : (
      title
    );
  }, [title]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" open={isOpen} onClose={onCloseLocal} className={`relative z-50`}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white p-2 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                {!!hasCloseButton && (
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => onClose?.()}
                      data-testid="modal-edit-close"
                    >
                      <span className="sr-only">{t('Close')}</span>
                      <XMarkIcon className="h-6 w-6 stroke-2" aria-hidden="true" />
                    </button>
                  </div>
                )}
                {!!titleElement && (
                  <Dialog.Title as="div" data-testid="modal-edit-title">
                    {titleElement}
                  </Dialog.Title>
                )}
                <div className="mt-4 text-gray-500">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
