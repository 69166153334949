import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useOrganizationImpersonation from './useOrganizationImpersonation';

const selectSwitchedOrganizationState = createSelector([(state) => state.organizations], (organizations) => ({
  switchedOrganizationId: organizations?.switchedOrganizationId,
  switchingOrganizationId: organizations?.switchingOrganizationId,
}));

export default function useOrganizationImpersonationResetListener() {
  const { impersonatedOrganizationId, activeOrganization } = useOrganizationImpersonation();
  const { switchedOrganizationId, switchingOrganizationId } = useSelector(selectSwitchedOrganizationState);
  const [savedImpersonateId, setSavedImpersonateId] = useState(undefined);

  useEffect(() => {
    if (savedImpersonateId === undefined && impersonatedOrganizationId !== undefined) {
      setSavedImpersonateId(impersonatedOrganizationId);
    } else if (
      savedImpersonateId !== undefined &&
      savedImpersonateId !== impersonatedOrganizationId &&
      !switchingOrganizationId
    ) {
      // externally changed, or in this app, after a delay
      window.location.reload();
    } else if (switchedOrganizationId !== undefined && !switchingOrganizationId) {
      // changed in this app
      window.location.reload();
    }
  }, [
    activeOrganization?.id,
    impersonatedOrganizationId,
    savedImpersonateId,
    switchingOrganizationId,
    switchedOrganizationId,
  ]);
}
