import { Configure, Stats } from 'react-instantsearch-dom';
import { CustomSearchBox } from '../../Search/CustomSearchBox';
import OrganizationAdminProvider from './OrganizationAdminProvider';
import { OrganizationFilters } from './OrganizationFilters';
import OrganizationList from './OrganizationList';

const HITS_PER_PAGE = 50;

const OrganizationAdmin = () => {
  return (
    <OrganizationAdminProvider>
      {/* TODO Add search page layout here if necessary. e.g. two column layout  */}
      <Configure hitsPerPage={HITS_PER_PAGE} />

      <CustomSearchBox fluid placeholder="Search providers..." data-testid="search-provider" />

      <Stats />

      <OrganizationFilters />

      <OrganizationList />
    </OrganizationAdminProvider>
  );
};

export default OrganizationAdmin;
