import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { MenuPlanningPage } from '@wonderschool/common-food-program-ui';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MenuPlanning = () => {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();
  const location = useLocation();
  const history = useHistory();
  useSegmentPage(PAGE_NAMES.foodProgramMenuPlanning);

  const queryParams = new URLSearchParams(location.search);
  const date = queryParams.get('date');
  const menuEventDate = date ? dayjs(date).toDate() : new Date();

  // strip off the query parameters
  useEffect(() => {
    // Check if there are any query parameters
    if (location.search) {
      // Remove the query parameters by navigating to the same path without them
      history.replace(location.pathname, { shallow: true });
    }
  }, [location, history]);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('food.menu.planning') })}
      </title>
      <body />
    </Helmet>
  );

  const handleClickEditMeals = (date: Date) => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING_EDIT, [
      {
        name: 'date',
        value: date.toISOString().split('T')[0],
      },
    ]);
  };

  return (
    <>
      {renderPageHead()}
      <MenuPlanningPage
        onClickEditMeals={handleClickEditMeals}
        onClickPrintMenu={() => null}
        menuEventDate={menuEventDate}
      />
    </>
  );
};
export default MenuPlanning;
