/* eslint-disable i18next/no-literal-string */
import { Helmet } from 'react-helmet-async';
import { getTitlePrefix } from '../config/env';
import { useAuthUser } from '../hooks/useUser';

const DebugPage = () => {
  const { token, currentUser } = useAuthUser();

  if (!token) return null;

  const result = window.navigator.userAgent;

  // Get current timezone
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get browser name and version
  const getBrowserInfo = () => {
    const userAgent = window.navigator.userAgent;
    let browserName = 'Unknown';
    let fullVersion = 'Unknown';
    let tempVersion;

    if (userAgent.indexOf('Chrome') > -1) {
      browserName = 'Chrome';
      tempVersion = userAgent.split('Chrome/')[1];
    } else if (userAgent.indexOf('Firefox') > -1) {
      browserName = 'Firefox';
      tempVersion = userAgent.split('Firefox/')[1];
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
      browserName = 'Safari';
      tempVersion = userAgent.split('Version/')[1];
    } else if (userAgent.indexOf('Edg') > -1) {
      browserName = 'Edge';
      tempVersion = userAgent.split('Edg/')[1];
    }

    if (tempVersion) {
      fullVersion = tempVersion.split(' ')[0];
    }

    return { browserName, fullVersion };
  };

  const { browserName, fullVersion } = getBrowserInfo();

  return (
    <>
      <Helmet>
        <title>{getTitlePrefix()} Debug Page</title>
      </Helmet>
      <div className="min-h-screen bg-gray-100 py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4">Debug Information</h1>
            <p className="text-gray-700 mb-6">
              You&apos;ve been sent to this page which means you need to debug something.
            </p>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-800">Current User Email:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{currentUser?.email}</pre>
              </div>
              <div>
                <strong className="text-gray-800">User Agent:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{result}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Browser:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{`${browserName} ${fullVersion}`}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Current Time:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{new Date().toISOString()}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Timezone:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{currentTimezone}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Git SHA:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{process.env.REACT_APP_GIT_SHA}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Git Tag:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{process.env.REACT_APP_GIT_TAG}</pre>
              </div>
              <div>
                <strong className="text-gray-800">Built At:</strong>
                <pre className="bg-gray-50 p-2 rounded text-gray-900 mt-1">{process.env.REACT_APP_BUILD_DATE}</pre>
              </div>
            </div>
            <div className="mt-6">
              You should take a screenshot of this page and send it to the developer or support agent.{' '}
              <a
                href="https://www.take-a-screenshot.org/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:underline"
              >
                How to take a screenshot?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DebugPage;
