import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DataTable, DataTableProps, Label } from '@wonderschool/common-base-ui';
import { FileDocument } from '@wonderschool/file-service-client';
import { MalwareStatus, OrganizationProgramTypeEnum, USStateCodeEnum } from '@wonderschool/common-base-types';

import { US_STATE_NAMES } from './consts';

export type AdminDocument = Pick<
  FileDocument,
  'id' | 'name' | 'fileCategory' | 'fileStatus' | 'updatedAt' | 'updatedBy'
> & {
  states: USStateCodeEnum[];
  programTypes: OrganizationProgramTypeEnum[];
};

type AdminDocumentsTableInnerProps = {
  onFileDownload: (id: FileDocument['id']) => void;
};

type AdminDocumentsTableProps = AdminDocumentsTableInnerProps & Pick<DataTableProps<AdminDocument>, 'data'>;

export const AdminDocumentsTable: React.FC<AdminDocumentsTableProps> = ({ onFileDownload, data }) => {
  const { t } = useTranslation();

  const getFileStatusTranslation = useCallback(
    (fileStatus: FileDocument['fileStatus']) => {
      return t(`common.documentFileStatus.${fileStatus}`);
    },
    [t]
  );

  const statesDisplayLabel = useCallback(
    (states: USStateCodeEnum[]) => {
      if (states.length === 0 || states.includes('ALL' as USStateCodeEnum)) return t('common.all');
      return states.map((state) => US_STATE_NAMES[state]).join(', ');
    },
    [t]
  );

  return (
    <DataTable
      columns={[
        {
          className: 'w-40',
          fieldName: 'name',
          label: t('common.name'),
          renderCell: (adminDocument) => {
            if (adminDocument.fileStatus !== MalwareStatus.CLEAN)
              return <Label extraClasses="inline">{adminDocument.name}</Label>;

            return (
              <a
                href={`#${adminDocument.id}`}
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onFileDownload(adminDocument.id);
                }}
                data-testid="docs-name"
                className="text-blue-800 hover:text-blue-950 hover:underline"
              >
                {adminDocument.name}
              </a>
            );
          },
        },
        {
          className: 'w-40',
          fieldName: 'fileCategory',
          label: t('Category'),
          renderCell: (adminDocument) => {
            return t(`common.fileCategory.${adminDocument.fileCategory}`);
          },
        },
        {
          className: 'w-40',
          fieldName: 'states',
          label: t('common.state', { count: 100 }),
          renderCell: (adminDocument) => {
            return statesDisplayLabel(adminDocument.states);
          },
        },
        {
          className: 'w-40',
          fieldName: 'programTypes',
          label: t('common.organizationProgramType', { count: 100 }),
          renderCell: (adminDocument) => {
            return adminDocument.programTypes
              .map((programType) => t(`common.organizationProgramType.${programType}`))
              .join(', ');
          },
        },
        {
          className: 'w-40',
          fieldName: 'updatedBy',
          label: t('common.updatedBy'),
          renderCell: (adminDocument) => {
            const updatedAt = dayjs(adminDocument.updatedAt).format('MM/DD/YYYY');

            return (
              <div>
                <h3>{t('admin.updatedAtBy', { updatedAt, updatedBy: adminDocument.updatedBy.displayName })}</h3>
                <h4>
                  {t('common.status')}: {getFileStatusTranslation(adminDocument.fileStatus)}
                </h4>
              </div>
            );
          },
        },
      ]}
      data={data}
    />
  );
};
