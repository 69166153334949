import * as actionTypes from '../../actions/types';

const initialState = {
  ajaxCallsInProgress: 0,
};

function actionTypeEndsInSuccess(type) {
  return type.search('_SUCCESS') > -1;
}

const ajaxStatusReducer = function (state = initialState.ajaxCallsInProgress, action) {
  if (action.type === actionTypes.AJAX_CALL_BEGIN) {
    return state + 1;
  } else if (action.type === actionTypes.AJAX_CALL_ERROR || actionTypeEndsInSuccess(action.type)) {
    if (state > 0) return state - 1;
  }

  return state;
};

export default ajaxStatusReducer;
