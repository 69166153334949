export const MONTH_LIST: string[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const QUARTER_LIST: string[] = ['q1', 'q2', 'q3', 'q4'];

export const REPORT_COLUMN_ORDER: string[] = [
  'title',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'total',
];
