import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Grid, Icon, Segment } from 'semantic-ui-react';

// Import components.
import AttendanceFilters from '../../Components/Reports/AttendanceFilters';
import AttendanceList from '../../Components/Reports/AttendanceList';
import TotalAttendanceReport from '../../Components/Reports/TotalAttendanceReport';
import PageHeader from '../../Components/Shared/PageHeader';
import PageTitle from '../../Components/Shared/PageTitle';

const Attendance = () => {
  const { t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const generateReport = (filters) => {
    if (!filters?.reportType) {
      setReportType('');
      return;
    }
    const enabled = filters.timePeriod.to && filters.timePeriod.from && filters.reportType && filters.location;
    if (enabled) {
      setReportType(filters?.reportType);
      setFilters(filters);
    } else {
      setReportType('');
    }
  };

  return (
    <Segment basic>
      <PageHeader pageName={'Attendance'} classes="attendance" />
      <PageTitle title={t('Attendance')} />
      <Grid.Row>
        <AttendanceFilters generateReport={generateReport} loading={loading} />
      </Grid.Row>
      {reportType === 'attendance_report' && (
        <AttendanceList filters={filters} loading={loading} setLoading={setLoading} />
      )}
      {reportType === 'total_attendance_report' && (
        <TotalAttendanceReport filters={filters} loading={loading} setLoading={setLoading} />
      )}
      {!reportType && (
        <Grid className="create-report" data-testid="att-create-report">
          <Grid.Row centered columns={4}>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Icon name="file" size="huge" color="yellow" />
              <p>{t('Select options above to create a report')}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Segment>
  );
};

export default Attendance;
