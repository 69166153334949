import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SummaryViewTitle } from '../widgets';
import { BillingIntervalEnum } from '../../enums';
import { currencyFormatter } from '../../../helpers/utils';
import { DAYS_OF_THE_WEEK } from '../../consts';

dayjs.extend(utc);

const EnrollmentInvoicesView = ({ tuition, fees }) => {
  const { t } = useTranslation();

  const getInvoiceDueDate = (): string => {
    if (!tuition.dueDate) return '';
    const date = dayjs.unix(tuition.dueDate.seconds).utc();

    switch (tuition.billingInterval) {
      case BillingIntervalEnum.WEEKLY:
        return t('enrollments.billingInterval.weekly', {
          day: t(DAYS_OF_THE_WEEK[date.day()]),
        });
      case BillingIntervalEnum.BIWEEKLY:
        return t('enrollments.billingInterval.biweekly', {
          day: t(DAYS_OF_THE_WEEK[date.day()]),
        });
      case BillingIntervalEnum.MONTHLY:
        return t('enrollments.billingInterval.monthly', {
          day: date.get('date'),
        });
      case BillingIntervalEnum.TWICE_PER_MONTH:
        return t('enrollments.billingInterval.twiceAMonth', {
          firstDate: date.get('date'),
          secondDate: date.add(15, 'day').get('date'),
        });
      default:
        return '';
    }
  };

  const getFormatedDate = (date) => {
    if (!date) return '';
    return dayjs.unix(date.seconds).utc().format('MM/DD/YYYY');
  };
  if (!tuition && !fees) return null;

  return (
    <div className="schedule-form">
      <SummaryViewTitle title={t('enrollments.invoicesPageTitle')} />
      {Object.keys(tuition).length > 0 && (
        <>
          <h3 className="text-xl pb-4">{t('enrollments.tuitionRecurring')}</h3>
          <div className="grid grid-cols-3 gap-3 py-4">
            <div className="flex flex-col gap-2">
              <h3>{t('enrollments.recurringTotal')}</h3>
              <div>{currencyFormatter(tuition.amount)}</div>
            </div>
            <div className="flex flex-col gap-2">
              <h3>{t('enrollments.billingsShedule')}</h3>
              <div>{tuition.billingInterval}</div>
            </div>
            <div className="flex flex-col gap-2">
              <h3>{t('enrollments.invoiceDueDate')}</h3>
              <div>{getInvoiceDueDate()}</div>
            </div>
            <div className="flex flex-col gap-2">
              <h3>{t('enrollments.firstMonthProrated')}</h3>
              <div>{currencyFormatter(tuition.proratedAmount)}</div>
            </div>
            <div className="flex flex-col gap-2">
              <h3>{t('enrollments.firstInvoiceDue')}</h3>
              <div>{getFormatedDate(tuition.dueDate)}</div>
            </div>
          </div>
        </>
      )}
      {fees.length > 0 && (
        <>
          <h3 className="text-xl py-4">{t('enrollments.enrollmentOneTime')}</h3>
          {fees.map((fee, index) => (
            <div key={index} className="grid grid-cols-3 gap-3 py-4">
              <div className="flex flex-col gap-2">
                <h3>{t('enrollments.totalAmount')}</h3>
                <div>{currencyFormatter(fee.amount)}</div>
              </div>
              <div className="flex flex-col gap-2">
                <h3>{t('enrollments.feeDescription')}</h3>
                <div>{fee.name}</div>
              </div>
              <div className="flex flex-col gap-2">
                <h3>{t('enrollments.dueDate')}</h3>
                <div>{getFormatedDate(fee.dueDate)}</div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default EnrollmentInvoicesView;
