import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// Import component
import { formatCurrency, getFormatter } from '../../../helpers/utils';

const InvoiceItem = ({ item, itemIndex }) => {
  const { t } = useTranslation();
  return (
    <>
      <List.Item data-testid={`invoice-item-${itemIndex}`}>
        <List.Content>
          <List.Header>
            <div data-testid={`invoice-item-label-${itemIndex}`}>{t(item.label)}</div>
            <div data-testid={`invoice-item-amount-${itemIndex}`}>{formatCurrency(item.amount)}</div>
          </List.Header>
          {item.discounts.length > 0 &&
            item.discounts.map((discount, index) => {
              return (
                <List.Description
                  key={`invoiceItemDiscount${index}`}
                  data-testid={`invoice-item-discount-${itemIndex}-${index}`}
                >
                  <div data-testid={`invoice-item-discount-label-${itemIndex}-${index}`}>{t(discount.label)}</div>
                  <div data-testid={`invoice-item-discount-amount-${itemIndex}-${index}`}>
                    (-{getFormatter(discount.amountType)(discount.amount)})
                  </div>
                </List.Description>
              );
            })}
        </List.Content>
      </List.Item>
    </>
  );
};
InvoiceItem.propTypes = {
  item: PropTypes.object,
};

export default InvoiceItem;
