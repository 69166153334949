import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Icon, Image, Popup } from 'semantic-ui-react';
import { storage } from '../../api/firebase/firebase';

export function SignatureModal({ row, type, textToDisplay }) {
  const { t } = useTranslation();
  const [urlSignature, setUrlSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  if (!row) return null;

  const getSignatureUrl = async (activity, type) => {
    const signatureUri = type === 'pickup' ? activity?.checkOut?.signatureUri : activity?.checkIn?.signatureUri;
    const url = signatureUri ? await storage().ref(signatureUri).getDownloadURL() : '';
    return url;
  };

  return (
    <Popup
      trigger={
        <Link
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {textToDisplay}
        </Link>
      }
      size="small"
      on="click"
      hideOnScroll
      mouseEnterDelay={500}
      onOpen={async () => {
        setLoading(true);
        const url = await getSignatureUrl(row, type);
        setUrlSignature(url);
        if (!url) {
          setLoading(false);
          setOpen(false);
        } else {
          setOpen(!!url);
        }
      }}
      onClose={() => setOpen(false)}
    >
      <Popup.Content>
        {loading && <Icon loading name="spinner" />}
        {open && (
          <Image
            height={50}
            src={urlSignature}
            onLoad={() => setLoading(false)}
            alt="signature"
            style={loading ? { display: 'none' } : { display: 'inline-block' }}
          />
        )}
        {!open && !loading && urlSignature === '' && <p>{t('No Signature')}</p>}
      </Popup.Content>
    </Popup>
  );
}

export default SignatureModal;
