import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames/bind';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { MouseEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { showSetupPayoutRibbon } from '../../../helpers/stripe';
import { useOrganization } from '../../../hooks/useOrganizations';
import { selectFirstLocation } from '../../DateTime/DateTime';
import fStyle from './FinanceAtGlance.module.scss';

const cx = classNames.bind(fStyle);

type FilterPerMonthProps = {
  onChangeMonth: (month: moment.Moment) => void;
};

function FilterPerMonth({ onChangeMonth }: FilterPerMonthProps) {
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const [filterMonth, setFilterMonth] = useState(moment().tz(timezone));
  const organization = useOrganization();
  const hasSetupPayout = !showSetupPayoutRibbon(organization);

  const fullMonthYear = filterMonth.format('MMMM YYYY');
  const todayMonth = moment().startOf('month').format('MMMM YYYY');
  const isCurrentMonth = fullMonthYear === todayMonth;

  const handleClickPreviousMonth = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!hasSetupPayout) {
        return;
      }
      const selectedPreviousMonth = moment(filterMonth).tz(timezone, true).subtract(1, 'months').endOf('month');
      setFilterMonth(selectedPreviousMonth);
      onChangeMonth(selectedPreviousMonth);
    },
    [filterMonth, onChangeMonth, hasSetupPayout, timezone]
  );
  const handleClickNextMonth = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!hasSetupPayout) {
        return;
      }
      const selectedNextMonth = moment(filterMonth).tz(timezone, true).add(1, 'months').endOf('month');
      if (fullMonthYear === moment().startOf('month').format('MMMM YYYY')) {
        return;
      }
      setFilterMonth(selectedNextMonth);
      onChangeMonth(selectedNextMonth);
    },
    [hasSetupPayout, filterMonth, timezone, fullMonthYear, onChangeMonth]
  );

  return (
    <span className="m-auto flex justify-between gap-2 text-lg sm:text-xl">
      <button
        type="button"
        onClick={handleClickPreviousMonth}
        className={cx('cursor-pointer', hasSetupPayout ? 'text-black' : 'text-gray-600')}
        data-testid="previousMonth"
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </button>
      <span data-testid="month" className="w-36 text-nowrap text-center">
        {filterMonth.format('MMMM YYYY')}
      </span>
      <button
        type="button"
        onClick={handleClickNextMonth}
        className={cx('cursor-pointer', isCurrentMonth ? 'text-gray-600' : 'text-black')}
        disabled={isCurrentMonth}
        data-testid="nextMonth"
      >
        <ChevronRightIcon className="h-6 w-6" />
      </button>
    </span>
  );
}

export default FilterPerMonth;
