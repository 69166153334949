import moment from 'moment-timezone';
import { logError } from '../../rollbar';
import { createQuerySnapshot } from './firestore';

const paths = {
  rootActivity: (activityId) => `activities/${activityId}`,
  activity: (organizationId, activityId) => `organizations/${organizationId}/activities/${activityId}`,
  activities: (organizationId) => `organizations/${organizationId}/activities`,
};

/**
 * Fetches all rooms for a given organization.
 */
export const fetchActivitiesForOrganization = async ({
  organizationId,
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
  totalHoursReport = false,
}) => {
  try {
    if (!organizationId) return null;

    const path = paths.activities(organizationId);

    const activitiesSnapshot = await createQuerySnapshot(path, conditions, orderBy, limit, startAfter);

    let activities = [];
    if (!totalHoursReport) {
      activities = matchFetchedActivities(activitiesSnapshot);
    } else {
      activities = matchFetchedActivitiesForTotalHour(activitiesSnapshot);
    }
    return activities;
  } catch (error) {
    logError('Error in fetchActivitiesForOrganization: ', error);
    throw Error(error);
  }
};

const matchFetchedActivities = (activitiesSnapshot) => {
  try {
    const matchActivities = [];
    const leftToMatch = {};

    activitiesSnapshot.forEach((doc) => {
      const data = { ...doc.data(), id: doc.id };
      if (data[data.type]?.data?.signature) delete data[data.type].data.signature;

      if (!data?.student?.id) {
        logError(`No StudentId found for activity - ${data.id}`);
        throw Error(`No StudentId found for activity - ${data.id}`);
      }

      if (data?.type && data.type === 'pickup') {
        const activityData = {
          id: data?.id,
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          activityTime: data?.activityTime,
          room: data?.room,
          checkOut: {
            id: data?.id,
            activityTime: data?.activityTime,
            addedBy: { ...data?.addedBy },
            signatureUri: data?.signatureUri,
            timezone: data?.timezone,
            type: data?.type,
          },
          checkIn: {},
        };

        matchActivities.push(activityData);
        leftToMatch[data?.student?.id] = {
          index: matchActivities.length - 1,
          type: 'pickup',
        };
      }

      if (data?.type && data.type === 'absent') {
        const activityData = {
          id: data?.id,
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          activityTime: data?.activityTime,
          room: data?.room,
          checkIn: {
            id: data?.id,
            activityTime: data?.activityTime,
            addedBy: { ...data?.addedBy },
            signatureUri: data?.signatureUri,
            timezone: data?.timezone,
            type: data?.type,
          },
          checkOut: {},
        };

        matchActivities.push(activityData);
        delete leftToMatch[data?.student?.id];
      }

      if (data?.type === 'dropoff') {
        const activityData = {
          id: data?.id,
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          activityTime: data?.activityTime,
          room: data?.room,
          checkIn: {
            id: data?.id,
            activityTime: data?.activityTime,
            addedBy: { ...data?.addedBy },
            signatureUri: data?.signatureUri,
            timezone: data?.timezone,
            preCheck: data[data.type]?.data?.preCheck,
            preCheckData: data[data.type]?.data?.preCheckData,
            type: data?.type,
          },
          checkOut: {},
        };
        if (data?.student?.id && leftToMatch[data.student.id] && leftToMatch[data.student.id].type === 'pickup') {
          matchActivities[leftToMatch[data.student.id].index] = {
            ...matchActivities[leftToMatch[data.student.id].index],
            id: matchActivities[leftToMatch[data.student.id].index].id + '-' + activityData.id,
            checkIn: { ...activityData.checkIn },
          };
          delete leftToMatch[data.student.id];
        } else {
          matchActivities.push(activityData);
          delete leftToMatch[data.student.id];
        }
      }
    });

    return matchActivities;
  } catch (error) {
    logError('Error in matchFetchedActivities: ', error);
    throw Error(error);
  }
};

const matchFetchedActivitiesForTotalHour = (activitiesSnapshot) => {
  try {
    const matchActivities = [];
    const leftToMatch = {};
    const totalStudentHours = [];
    const studentKeyMaping = {};

    activitiesSnapshot.forEach((doc) => {
      const data = { ...doc.data(), id: doc.id };
      if (data[data.type]?.data?.signature) delete data[data.type].data.signature;

      if (!data?.student?.id) {
        logError(`No StudentId found for activity - ${data.id}`);
        throw Error(`No StudentId found for activity - ${data.id}`);
      }

      if (!(data?.student?.id in studentKeyMaping)) {
        totalStudentHours.push({
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          room: data?.room,
          daysPresent: 0,
          daysAbsent: 0,
          totalMinutesAttended: 0,
        });
        studentKeyMaping[data?.student?.id] = totalStudentHours.length - 1;
      }

      if (data?.type && data.type === 'pickup') {
        const activityData = {
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          room: data?.room,
          checkOut: {
            id: data?.id,
            activityTime: data?.activityTime,
            timezone: data?.timezone,
            type: data?.type,
          },
          checkIn: {},
        };

        matchActivities.push(activityData);
        leftToMatch[data?.student?.id] = {
          index: matchActivities.length - 1,
          type: 'pickup',
        };
      }

      if (data?.type && data.type === 'absent') {
        const activityData = {
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          room: data?.room,
          checkIn: {
            id: data?.id,
            activityTime: data?.activityTime,
            timezone: data?.timezone,
            type: data?.type,
          },
          checkOut: {},
        };

        matchActivities.push(activityData);
        delete leftToMatch[data?.student?.id];

        const index = [studentKeyMaping[data?.student?.id]];
        totalStudentHours[index] = {
          ...totalStudentHours[index],
          daysAbsent: totalStudentHours[index].daysAbsent + 1,
        };
      }

      if (data?.type && data.type === 'dropoff') {
        const activityData = {
          organization: data?.organization,
          location: data?.location,
          student: { ...data?.student },
          room: data?.room,
          checkIn: {
            id: data?.id,
            activityTime: data?.activityTime,
            timezone: data?.timezone,
            type: data?.type,
          },
          checkOut: {},
        };
        if (data?.student?.id && leftToMatch[data.student.id] && leftToMatch[data.student.id].type === 'pickup') {
          matchActivities[leftToMatch[data.student.id].index] = {
            ...matchActivities[leftToMatch[data.student.id].index],
            id: matchActivities[leftToMatch[data.student.id].index].id + '-' + activityData.id,
            checkIn: { ...activityData.checkIn },
          };

          const diff =
            matchActivities[leftToMatch[data.student.id].index]?.checkIn?.timezone &&
            matchActivities[leftToMatch[data.student.id].index]?.checkOut?.timezone
              ? moment
                  .tz(
                    matchActivities[leftToMatch[data.student.id].index]?.checkOut?.activityTime,
                    matchActivities[leftToMatch[data.student.id].index]?.checkOut?.timezone
                  )
                  .diff(
                    moment.tz(
                      matchActivities[leftToMatch[data.student.id].index]?.checkIn?.activityTime,
                      matchActivities[leftToMatch[data.student.id].index]?.checkIn?.timezone
                    ),
                    'minutes'
                  )
              : moment
                  .utc(matchActivities[leftToMatch[data.student.id].index]?.checkOut?.activityTime)
                  .diff(
                    moment.utc(matchActivities[leftToMatch[data.student.id].index]?.checkIn?.activityTime),
                    'minutes'
                  );
          const index = [studentKeyMaping[data?.student?.id]];
          totalStudentHours[index] = {
            ...totalStudentHours[index],
            daysPresent: totalStudentHours[index].daysPresent + 1,
            totalMinutesAttended: totalStudentHours[index].totalMinutesAttended + diff,
          };
          delete leftToMatch[data.student.id];
        } else {
          const index = [studentKeyMaping[data?.student?.id]];
          matchActivities.push(activityData);
          totalStudentHours[index] = {
            ...totalStudentHours[index],
            daysPresent: totalStudentHours[index].daysPresent + 1,
          };
          delete leftToMatch[data.student.id];
        }
      }
    });

    return totalStudentHours;
  } catch (error) {
    logError('Error in matchFetchedActivitiesForTotalHour: ', error);
    throw Error(error);
  }
};
