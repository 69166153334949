// export default witAllowedRoles;
import { Component } from 'react';
import { connect } from 'react-redux';
import { userHasPermission } from '../../api/firebase/account';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';

const withPermission = (WrappedComponent, permissionKey) => {
  class HOC extends Component {
    state = { showComponent: true, allowedRoles: [] };

    componentDidMount = () => {
      this.checkPermission();
    };

    componentDidUpdate = () => {
      const { permissions } = this.props;

      if (permissions[permissionKey] && !_.isEqual(permissions[permissionKey].roles, this.state.allowedRoles)) {
        const allowedRoles = permissions[permissionKey].roles || [];
        this.setState({
          allowedRoles: allowedRoles,
          showComponent: userHasPermission(permissionKey),
        });
      }
    };

    checkPermission = () => {
      try {
        const { permissions } = this.props;

        const allowedRoles = permissions && permissions[permissionKey] ? permissions[permissionKey].roles : [];

        this.setState({
          showComponent: userHasPermission(permissionKey),
          allowedRoles,
        });
      } catch (error) {
        console.log(error);
      }
    };

    render() {
      const {
        user: { claims },
        permissions,
        dispatch,
        ...rest
      } = this.props;
      return !!claims?.isMoxitAdmin || this.state.showComponent ? <WrappedComponent {...rest} /> : null;
    }
  }
  const mapStateToProps = (state) => ({
    user: state.user,
    permissions: state.permissions,
  });
  return connect(mapStateToProps)(HOC);
};

export default withPermission;
