import { LDClient } from 'launchdarkly-js-client-sdk';

let ldClient: LDClient;

export function setLDClient(ldClientParam: LDClient) {
  ldClient = ldClientParam;
}

export function getLDClient(): LDClient {
  return ldClient;
}
