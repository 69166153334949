// This file is being converted to TypeScript. Not all types are defined properly. Please add if you know the shape of the missing files.
import firebase from 'firebase/compat/app';
import { FirestoreError } from 'firebase/firestore';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { chunk, flatten } from 'lodash';
import { QueryConditionType } from '../../reports';
import { auth, firestore, storage } from './firebase';
import { buildObject, buildObjectsTree } from './helpers/firestore';

import WriteBatch = firebase.firestore.WriteBatch;
import Transaction = firebase.firestore.Transaction;
import DocumentReference = firebase.firestore.DocumentReference;

export type CollectionOrderBy = { field: string; direction: 'asc' | 'desc' };

export const QUERY_CHUNK_SIZE = 10;

export const newDocument = ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    return firestore().collection(path).doc();
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

export const getDocument = async ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    const ref = firestore().doc(path);
    const doc = await ref.get();
    return buildObject(doc);
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

/**
 * @deprecated Does not seem to have usages
 */
export const startDocumentListener = ({ path }, next, error, complete) => {
  if (!path) throw Error('Path must be specified');
  try {
    const ref = firestore().doc(path);
    return ref.onSnapshot(
      (doc) =>
        next(
          doc
            ? {
                id: doc.id,
                ...doc.data(),
              }
            : {}
        ),
      error,
      complete
    );
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

type StartChunkedCollectionListener = Omit<StartCollectionListenerOptions, 'conditions'> & {
  conditionsBase?: QueryConditionType[];
  chunkableCondition?: any | null;
};

export const startChunkedCollectionListener = (
  {
    path, // collection path
    orderBy = [],
    limit = null,
    startAfter = null,
    conditionsBase = [], // non-chunkable query conditions
    chunkableCondition = null, // a query condition where the value is an array
  }: StartChunkedCollectionListener,
  next,
  error,
  complete,
  sortFn
) => {
  try {
    const query = {
      path,
      orderBy,
      limit,
      startAfter,
    };
    if (!chunkableCondition) {
      return startCollectionListener(
        {
          ...query,
          conditions: conditionsBase,
        },
        // resultProcessed is the response by startCollectionListener, not the standard firestore response
        // it is the snapshot but transformed into a map with count, list, last
        (resultProcessed) => {
          // if sortFn is provided, meaning sorting isn't part of the query
          // do the sort
          if (sortFn && resultProcessed.list) {
            // list might be null if no results
            resultProcessed.list.sort(sortFn); // in place
            resultProcessed.last = resultProcessed.list[resultProcessed.list.length - 1];
          }
          next(resultProcessed);
        },
        error,
        complete
      );
    }

    if (!chunkableCondition?.value?.length) {
      throw new Error('Invalid chunk condition');
    }

    const valueChunks = chunk(chunkableCondition.value, QUERY_CHUNK_SIZE);
    const conditionChunks = valueChunks.map((oneChunk) => {
      return [
        ...(conditionsBase || []),
        {
          ...chunkableCondition,
          value: oneChunk,
        },
      ];
    });

    let allResults = {};

    const unsubscribes = conditionChunks.map((conditions, index) => {
      // for each chunk, create a listener
      return startCollectionListener(
        {
          ...query,
          conditions, // chunked rooms
        },
        // chunkResultProcessed is the response by startCollectionListener, not the standard firestore response
        // it is the snapshot but transformed into a map with count, list, last
        (chunkResultProcessed) => {
          // join all listeners before responding to parent listener
          // into a map of lists
          allResults[index] = chunkResultProcessed.list || [];
          // extract into a list of lists
          const listOfLists = Object.values(allResults);
          // flatten into a single list, 1 level deep
          const combined = flatten(listOfLists);
          // if provided, sort the list based on custom operator
          if (sortFn) {
            combined.sort(sortFn); // in place
          }
          const reprocessedResults = {
            count: combined.length,
            list: combined,
            last: combined[combined.length - 1],
          };

          // respond to parent listener as one response
          next(reprocessedResults);
        },
        error
      );
    });

    return () => {
      allResults = {}; // remove all stored data as a precaution, should be cleared by GC anyway
      unsubscribes.forEach((unsubscribe) => unsubscribe?.()); // call all unsubscribes
    };
  } catch (error) {
    console.log(error);
    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

export type StartCollectionListenerOptions = {
  path?: any;
  conditions: QueryConditionType[];
  orderBy?: CollectionOrderBy[];
  limit?: number | null;
  startAfter?: ({ id: any } & object) | null;
};

export const startCollectionListener = (
  { path, conditions = [], orderBy = [], limit = null, startAfter = null }: StartCollectionListenerOptions,
  next: (...args: any[]) => void,
  error: (error: FirestoreError) => void,
  complete?: () => void
) => {
  try {
    if (!path) throw Error('Path must be specified');
    let ref: firebase.firestore.Query<firebase.firestore.DocumentData> = firestore().collection(path);

    if (startAfter && (!limit || !orderBy)) throw Error('OrderBy and limit are required during paginated calls');

    orderBy?.forEach((order) => {
      const { field, direction = 'asc' } = order;
      if (field && direction) ref = ref.orderBy(field, direction);
    });

    conditions?.forEach((condition) => {
      const { field, operation, value } = condition;
      if (field && operation && value) ref = ref.where(field, operation, value);
    });

    if (limit && typeof limit === 'number') ref = ref.limit(limit);

    if (startAfter && orderBy && startAfter.id) ref = ref.startAfter(startAfter);

    return ref.onSnapshot(
      (querySnapshot) =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                list: querySnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                })),
                last: querySnapshot.docs[querySnapshot.docs.length - 1],
              }
            : {}
        ),
      error,
      complete
    );
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

interface GetDocuments {
  path: string;
  conditions?: QueryConditionType[];
  orderBy?: CollectionOrderBy[];
  limit?: number | null;
  startAfter?: ({ id: any } & object) | null;
}

export const getDocuments = async ({
  path,
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
}: GetDocuments) => {
  try {
    const snapshot = await createQuerySnapshot(path, conditions, orderBy, limit, startAfter);
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];

    return {
      data: buildObjectsTree(snapshot.docs) || {},
      lastDocument: lastDocument || null,
    };
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

interface GetDocumentsAsArray {
  path: string;
  conditions?: QueryConditionType[];
  orderBy?: CollectionOrderBy[];
  limit?: number | null;
  startAfter?: ({ id: any } & object) | null;
}

export const getDocumentsAsArray = async ({
  path,
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
}: GetDocumentsAsArray) => {
  try {
    const querySnapshot = await createQuerySnapshot(path, conditions, orderBy, limit, startAfter);
    const lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      count: querySnapshot.size,
      list: querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })),
      lastDocument: lastDocument || null,
      hasMoreData: querySnapshot.size === limit,
    };
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

// Take 2 objects that where returned from fetchDocumentsAsArray and merge them into a new object
// with source.lastDocument as the lastDocument (used for pagination)
export const mergeDocumentsAsArray = (target, source) => {
  if (!target) return source;

  const list = [...target.list, ...source.list];
  return {
    list,
    lastDocument: source.lastDocument,
    count: list.length,
    hasMoreData: source.hasMoreData,
  };
};

export async function createQuerySnapshot(
  path: string,
  conditions: QueryConditionType[],
  orderBy: CollectionOrderBy[] = [],
  limit?: number | null,
  startAfter?: ({ id: any } & object) | null
) {
  let docRef: firebase.firestore.Query<firebase.firestore.DocumentData> = firestore().collection(path);

  if (startAfter && (!limit || !orderBy)) throw Error('OrderBy and limit are required during paginated calls');

  orderBy.forEach((order) => {
    const { field, direction } = order;
    if (field) docRef = docRef.orderBy(field, direction || 'asc');
  });

  conditions.forEach((condition) => {
    const { field, operation, value } = condition;
    if (field && operation && (value !== null || value !== undefined || value !== ''))
      docRef = docRef.where(field, operation, value);
  });

  if (limit && typeof limit === 'number') docRef = docRef.limit(limit);

  if (startAfter && orderBy && startAfter.id) {
    docRef = docRef.startAfter(startAfter);
  }
  return await docRef.get();
}

interface SaveDocument {
  collectionPath: ((...args: any[]) => string) | string;
  data: { id: any; createdAt: any; createdBy: any } & any;
  batch?: WriteBatch | null;
  transaction?: Transaction | null;
  firestoreOptions?: { merge: boolean };
}

// If the document contains an id, we concat it to the collectionPath and call
// 'set' to update the document. If the document doesn't contain an id, we call
// 'add' to create a new document. (or batch.create if batch is non null).
export const saveDocument = async ({
  collectionPath,
  data,
  batch = null,
  transaction = null,
  firestoreOptions = { merge: true },
}: SaveDocument) => {
  if (!collectionPath) throw Error('collectionPath must be specified');
  if (!data) throw Error('data must be specified');

  const { id, createdAt, createdBy, ...rest } = data;

  let path = typeof collectionPath === 'function' ? collectionPath(data) : collectionPath;

  let docRef: Promise<DocumentReference<any>> | DocumentReference<any> | null = null;

  // If id is specified, update the document
  if (id) {
    path += `/${id}`;

    const updateData = {
      ...rest,
      updatedAt: firestore.FieldValue.serverTimestamp(),
      updatedBy: getChangedBy(),
    };
    docRef = firestore().doc(path);

    if (batch) {
      batch.set(docRef, updateData, firestoreOptions);
    } else if (transaction) {
      transaction.set(docRef, updateData, firestoreOptions);
    } else {
      docRef.set(updateData, firestoreOptions);
    }

    // Otherwise, create a new document
  } else {
    const createData = {
      ...rest,
      createdAt: firestore.FieldValue.serverTimestamp(),
      createdBy: getChangedBy(),
    };
    const collectionRef = firestore().collection(path);

    if (batch) {
      docRef = collectionRef.doc();
      batch.set(docRef, createData);
    } else if (transaction) {
      docRef = collectionRef.doc();
      transaction.set(docRef, createData);
    } else {
      docRef = collectionRef.add(createData);
    }
  }
  return docRef;
};

export const addDocument = async ({ path, data }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');

    const ref = firestore().collection(path);
    return ref.add(data);
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

export const updateDocument = async ({ path, data }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    const ref = firestore().doc(path);
    return ref.update(data);
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

export const deleteDocument = async ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    const ref = firestore().doc(path);
    return ref.delete();
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

export const setDocument = async ({ path, data, merge = true }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    const ref = firestore().doc(path);
    return ref.set(data, { merge });
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

/**
 * Allows batching an unlimited number of documents in batches of size BATCH_SIZE.
 * collectionPath is either a string to the collection path where the documents will
 * be saved, or a function that returns the collection path.
 *
 * Usage:
 * batchSaveDocuments({
 *  collectionPath: 'students',
 *  documents: students
 * })
 *
 * OR
 * batchSaveDocuments({
 * collectionPath: (student) => `organizations/${organizationId}/students`,
 * documents: students
 * })
 */
const BATCH_SIZE = 450;
export const batchSaveDocuments = async ({ collectionPath, documents, firestoreOptions = { merge: true } }) => {
  if (!Array.isArray(documents)) throw new Error('Documents must be an array');

  if (typeof collectionPath !== 'function' && typeof collectionPath !== 'string')
    throw new Error('Collection path must be either a function or a string');

  console.log(`Saving ${documents.length} documents`);

  let counter = 0;
  let total = 0;
  let batch = firestore().batch();

  try {
    for (const document of documents) {
      saveDocument({ collectionPath, data: document, batch, firestoreOptions });

      counter++;
      total++;

      if (counter === BATCH_SIZE) {
        await batch.commit();
        batch = firestore().batch();
        counter = 0;
        console.log(`Saved ${total} of ${documents.length} documents`);
      }
    }
    if (counter > 0) {
      await batch.commit();
      console.log(`Saved ${total} of ${documents.length} documents`);
    }
  } catch (error) {
    console.error(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw new Error(error);
  }
};

/**
 * @deprecated It does not seem to have usages.
 */
export const downloadFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = storage().ref(path).child(refId);
    const downloadUrl = await storageRef.getDownloadURL();
    return downloadUrl;
  } catch (error: any & { code?: string }) {
    console.log(error);
    switch (error.code) {
      // File doesn't exist
      case 'storage/object-not-found':
        return error.message;

      // User doesn't have permission to access the object
      case 'storage/unauthorized':
        return error.message;

      // User canceled the upload
      case 'storage/canceled':
        return error.message;

      // Unknown error occurred, inspect the server response
      case 'storage/unknown':
        return error.message;
      default:
        return;
    }
  }
};

export const deleteFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = storage().ref(path).child(refId);
    return storageRef.delete();
  } catch (error) {
    console.log(error);

    // @ts-expect-error Error constructor expects "error" to be string or undefined; it may wrap non-Error error objects.
    throw Error(error);
  }
};

const getChangedBy = () => ({
  // @ts-expect-error auth() might return "null".
  uid: auth().currentUser.uid,
  // @ts-expect-error auth() might return "null".
  email: auth().currentUser.email,
  // @ts-expect-error auth() might return "null".
  name: auth().currentUser.displayName || auth().currentUser.email,
});
