import { ClaimsManagementPage } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { useRoutes } from '../../../navigation/navigationHooks';
import { RouteNameEnum } from '../../../navigation/types';
import useSegmentPage from '../../../segment/hooks/useSegmentPage';
import { PAGE_NAMES } from '../../../segment/types';

const ClaimsList = () => {
  const { t } = useTranslation();
  const title = t('food.claims.list');
  const { gotoRouteByName } = useRoutes();
  useSegmentPage(PAGE_NAMES.foodProgramClaims);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  return (
    <>
      {renderPageHead()}
      <ClaimsManagementPage
        onEditClaim={(claimId: string) => {
          gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIM_UPDATE, [{ name: 'claimId', value: claimId }]);
        }}
        onCreateClaim={() => gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIM_CREATE)}
      />
    </>
  );
};
export default ClaimsList;
