import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Option, SelectProps, MultiSelectProps, SingleSelectProps } from '@wonderschool/common-base-ui';
import { FileCategory } from '@wonderschool/common-base-types';

type FileCategorySelectProps = Omit<SelectProps, 'options'> & (MultiSelectProps | SingleSelectProps);

export const FileCategorySelect: FC<FileCategorySelectProps> = ({ ...selectProps }) => {
  const { t } = useTranslation();

  const fileCategoryOptions: Option[] = useMemo(
    () => [
      ...Object.values(FileCategory).map((category) => ({
        label: t(`common.fileCategory.${category}`),
        value: category,
      })),
    ],
    [t]
  );

  return (
    <Select
      label={t('common.fileCategory', { count: 100 })}
      placeholder={t('common.all')}
      options={fileCategoryOptions}
      {...selectProps}
    />
  );
};

export default FileCategorySelect;
