import { useTranslation } from 'react-i18next';
import SectionCard from '../Shared/SectionCard';
import withPermission from '../Shared/withPermission';
import ActivityFeed from '../Stats/ActivityFeed';

const TodaysActivityFeed = () => {
  const { t } = useTranslation();
  return (
    <div className={'full-width flex flex-row items-stretch'}>
      <div className={'full-width flex flex-col'}>
        <SectionCard header={t("Today's Activity Feed")} component={ActivityFeed} />
      </div>
    </div>
  );
};

export default withPermission(TodaysActivityFeed, 'can_view_activity_feed');
