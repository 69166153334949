import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { EnrollmentStatusEnum } from '../../enrollments';
import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';
import { logError } from '../../rollbar';
import { featureFlags } from './featureFlags';
import { fetchInterestedFamilies, sendEnrollmentStatus as sendEnrollmentStatusAPI } from './marketplaceAPI';
import { featureFlagsFetched, headlessTokensCreated, interestedFamiliesFetched } from './marketplaceRedux';
import { exchangeToken } from './token';
import { InterestedFamilyType } from './types';

const selectInterestedFamilies = createSelector(
  [(state) => state.marketplace?.interestedFamilies],
  (interestedFamilies) => interestedFamilies
);

const selectMarketplaceFeatures = createSelector(
  [(state) => state.marketplace?.featuresEnabled],
  (featuresEnabled) => featuresEnabled
);

const selectMarketplaceHeadlessConfig = createSelector(
  [(state) => state.marketplace?.headless],
  (headless) => headless
);

export function useMarketplaceAPI() {
  const organization = useOrganization();
  const { currentUser } = useAuthUser();
  const [token, setToken] = useState<string>();
  const { marketplaceSchoolId } = organization;

  useEffect(() => {
    if (currentUser && !token) {
      currentUser?.getIdTokenResult().then((idTokenResult) => {
        setToken(idTokenResult.token);
      });
    }
  }, [currentUser, token]);

  const sendEnrollmentStatus = useCallback(
    async (enrollmentStatus: EnrollmentStatusEnum, marketplaceLeadId: string): Promise<any> => {
      if (token && marketplaceSchoolId) {
        return sendEnrollmentStatusAPI({ organization, token, enrollmentStatus, marketplaceLeadId });
      }
    },
    [marketplaceSchoolId, organization, token]
  );
  return { token, marketplaceSchoolId, organization, sendEnrollmentStatus };
}

export function useInterestedFamilies(): InterestedFamilyType[] {
  const { token, marketplaceSchoolId, organization } = useMarketplaceAPI();
  const interestedFamilies = useSelector(selectInterestedFamilies);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        if (token && marketplaceSchoolId && !interestedFamilies?.fetched) {
          const interestedFamilies = await fetchInterestedFamilies({ organization, token });
          dispatch(interestedFamiliesFetched(interestedFamilies));
        }
      } catch (e) {
        logError(e);
      }
    })();
  }, [dispatch, interestedFamilies, marketplaceSchoolId, organization, token]);

  return interestedFamilies?.list ?? [];
}

export function useMarketplaceHeadless() {
  const { token, marketplaceSchoolId } = useMarketplaceAPI();

  const dispatch = useDispatch();
  const headless = useSelector(selectMarketplaceHeadlessConfig);

  useEffect(() => {
    (async () => {
      try {
        const anyTokenNull = !headless.refreshToken || !headless.accessToken;
        if (token && marketplaceSchoolId && anyTokenNull) {
          const tokens = await exchangeToken(token);
          dispatch(headlessTokensCreated(tokens));
        }
      } catch (e) {
        logError(e);
      }
    })();
  }, [dispatch, headless, marketplaceSchoolId, token]);

  return headless;
}

export function useMarketplaceFeaturesEnabled() {
  const { token, marketplaceSchoolId } = useMarketplaceAPI();

  const dispatch = useDispatch();
  const featuresEnabled = useSelector(selectMarketplaceFeatures);

  useEffect(() => {
    (async () => {
      try {
        if (token && marketplaceSchoolId && !featuresEnabled?.fetched) {
          const ff = await featureFlags(marketplaceSchoolId);
          dispatch(featureFlagsFetched(ff));
        }
      } catch (e) {
        logError(e);
      }
    })();
  }, [dispatch, featuresEnabled, marketplaceSchoolId, token]);

  return featuresEnabled;
}
