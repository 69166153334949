import { EnvironmentEnum } from '@wonderschool/common-food-program-data-access';
import WSA from '../../WSA';

/**
 * This function returns the current environment as an EnvironmentEnum.
 * It is used to determine the environment-specific URL for the CCMS API.
 *
 * @returns The current environment as an EnvironmentEnum
 */
export function getCurrentEnvironment(): EnvironmentEnum {
  const env = WSA.env;
  switch (env) {
    case 'local':
      return EnvironmentEnum.LOCAL;
    case 'dev':
      return EnvironmentEnum.DEVELOPMENT;
    case 'staging':
      return EnvironmentEnum.STAGING;
    case 'prod':
      return EnvironmentEnum.PRODUCTION;
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
}
