import { USER_STATUS } from './userStatus';

export const PAYOUT_STATUS = {
  PENDING: 'pending',
  IN_TRANSIT: 'in_transit',
  PAID: 'paid',
  FAILED: 'failed',
  CANCELED: 'canceled',
};

export type PayoutStatus = {
  text: string;
  color: string;
  popupText?: string;
  userStatus?: USER_STATUS;
};
const PAYOUT_STATUS_FORMAT: Record<string, PayoutStatus> = {
  [PAYOUT_STATUS.PENDING]: {
    text: 'Processing',
    color: 'yellow',
    popupText: 'payoutProcessingPopup',
    userStatus: USER_STATUS.TRANSFERRING,
  },
  [PAYOUT_STATUS.IN_TRANSIT]: {
    text: 'Processing',
    color: 'yellow',
    popupText: 'payoutProcessingPopup',
    userStatus: USER_STATUS.TRANSFERRING,
  },
  [PAYOUT_STATUS.PAID]: {
    text: 'status.deposited',
    color: 'green',
    popupText: 'payoutPaidPopup',
    userStatus: USER_STATUS.PAID,
  },
  [PAYOUT_STATUS.FAILED]: {
    text: 'Failed',
    color: 'red',
    popupText: 'payoutFailedPopup',
    userStatus: USER_STATUS.TRANSFER_FAILED,
  },
  [PAYOUT_STATUS.CANCELED]: { text: 'Canceled', color: 'pink', userStatus: USER_STATUS.TRANSFER_FAILED },
};

/**
 * This function formats the payout status to be displayed in the UI
 * @param payout The payout object
 * @returns The formatted payout status
 */
export function invoicePayoutStatusFormatter(payout) {
  const format = PAYOUT_STATUS_FORMAT[payout?.status];
  if (!format) return { text: 'Paid', color: 'green' };
  return format;
}
