import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useMemo } from 'react';
import { INVOICE_TYPE } from '../Components/Billing/Invoices/InvoicesList';
import { selectFirstLocation } from '../Components/DateTime/DateTime';
import { InvoiceFilters } from '../Components/Forms/Billing/InvoicePlans/InvoicePlansList';
import {
  filterAmounts,
  filterArchived,
  filterBalanceDues,
  filterDates,
  filterDescriptions,
  filterInvoiceStatus,
  filterStudents,
} from '../helpers/filters';
import { InvoiceTypeData, InvoicesState } from '../redux/reducers/billing/invoices';
// Define types for the state and invoices
interface RootState {
  invoices: InvoicesState;
}

const selectInvoicesState = createSelector(
  [(state: RootState) => state.invoices],
  (invoices): InvoicesState => invoices
);

/**
 * Grabs invoice data from redux store and applies filters/search.
 */
export const useInvoices = (filters?: InvoiceFilters): InvoicesState => {
  const invoices = useSelector(selectInvoicesState);
  const firstLocation = useSelector(selectFirstLocation);

  return useMemo(() => {
    const invoiceType = invoices.invoiceType || INVOICE_TYPE;
    const invoiceTypeData = invoices[invoiceType as keyof InvoicesState] as InvoiceTypeData;
    const { list: invoiceList } = invoiceTypeData;

    const {
      invoiceStatus,
      descriptionSearch,
      dueDateRange,
      amountSearch,
      balanceDueSearch,
      studentSearch,
      hideArchived,
    } = filters ?? {};

    const timezone = firstLocation?.timezone ?? 'utc';

    const filteredInvoices = [
      hideArchived ? filterArchived : null,
      invoiceStatus ? (list) => filterInvoiceStatus(list, invoiceStatus) : null,
      descriptionSearch ? (list) => filterDescriptions(list, descriptionSearch) : null,
      dueDateRange ? (list) => filterDates(list, 'dateDue', dueDateRange, timezone) : null,
      amountSearch ? (list) => filterAmounts(list, amountSearch) : null,
      balanceDueSearch ? (list) => filterBalanceDues(list, balanceDueSearch) : null,
      studentSearch ? (list) => filterStudents(list, studentSearch) : null,
    ].reduce((acc, filterFn) => (filterFn ? filterFn(acc) : acc), Object.values(invoiceList));

    return {
      ...invoices,
      [invoiceType]: {
        ...invoiceTypeData,
        list: filteredInvoices,
      },
    };
  }, [invoices, filters, firstLocation]);
};
