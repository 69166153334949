import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Header, Icon, Popup, Segment } from 'semantic-ui-react';

import { DataTable, SortByEnum, SortDirectionEnum } from '@wonderschool/common-base-ui';
import { useMemo } from 'react';
import { InvoiceStatus as InvoiceStatusComponent } from '../../Components/Billing/Invoices';
import DateTime from '../../Components/DateTime/DateTime';
import { LoadingIndicator } from '../../Components/Shared/BusyIndicator';
import { DefaultDateFormat } from '../../helpers/dates';
import { getProcessingFeeAmount, isV2Invoice } from '../../helpers/invoices';
import { currencyFormatter } from '../../helpers/utils';
import { useInvoices } from '../../hooks/useInvoices';
import { useOrganization, useOrganizationInvoicesListener } from '../../hooks/useOrganizations';
import { useSelectedStudent } from '../studentsHooks';

const LoadingIndicatorStyle = {
  marginTop: '28px',
};

function StudentInvoicesTable() {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const invoices = useInvoices();

  useOrganizationInvoicesListener(currentOrganization.id);

  const tableColumns = useMemo(
    () => [
      {
        fieldName: 'description',
        label: t('Description'),
        renderCell: (row) => {
          if (isV2Invoice(row)) {
            return <span data-testid={`description-column-value-${row.id}`}>{row.itemsDescription}</span>;
          }

          const invoiceItemsText = row.invoiceItemList
            .map((c) => {
              return t(c.item);
            })
            .join(', ');

          return <span data-testid={`description-column-value-${row.id}`}>{invoiceItemsText}</span>;
        },
      },
      {
        fieldName: 'isInvoice',
        label: '',
        renderCell: (row) =>
          !row.isInvoice ? (
            <Popup
              trigger={<Icon data-testid={`recurring-plan-icon-${row.id}`} name="sync" />}
              content={<span data-testid={`recurring-plan-tooltip-${row.id}`}>{t('Recurring plan')}</span>}
              offset={[-15, 0]}
            />
          ) : (
            ''
          ),
      },
      {
        fieldName: 'dateDue',
        label: t('Due Date'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => (
          <span data-testid={`due-date-column-value-${row.id}`}>
            <DateTime epoch={row.dateDue} format={DefaultDateFormat} />
          </span>
        ),
      },
      {
        fieldName: 'total',
        label: t('Amount'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => (
          <span data-testid={`amount-column-value-${row.id}`}>{currencyFormatter(row.total, { precision: 2 })}</span>
        ),
      },
      {
        fieldName: 'paidAmount',
        label: t('Balance Due'),
        sortBy: SortByEnum.CLIENT,
        sortFunction: (a, b) => a.total - a.paidAmount - (b.total - b.paidAmount),
        renderCell: (row) => {
          const processingFeeAmount = getProcessingFeeAmount(row);
          return (
            <span data-testid={`balance-due-column-value-${row.id}`}>
              {currencyFormatter(row.total + processingFeeAmount - (row.paidAmount ?? 0), { precision: 2 })}
            </span>
          );
        },
      },
      {
        fieldName: 'status',
        label: t('common.status'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => (
          <span data-testid={`status-column-value-${row.id}`}>
            <InvoiceStatusComponent invoice={row} withTooltip />
          </span>
        ),
      },
    ],
    [t]
  );

  if (!invoices.isLoaded) return renderLoading();

  const filteredInvoices = invoices.all.list.filter((invoice) => invoice.student.id === selectedStudent.id);

  return filteredInvoices.length ? (
    <DataTable
      hasPagination={false}
      headerClasses="px-2"
      rowClasses="[&>td:first-child]:w-4 h-16"
      data={filteredInvoices}
      defaultSortConfig={{ name: 'dateDue', direction: SortDirectionEnum.DESC }}
      columns={tableColumns}
    />
  ) : (
    renderNoData({ t })
  );
}

const renderLoading = () => {
  return (
    <Segment basic clearing textAlign="center" className="no-shadow no-border top-border">
      <LoadingIndicator style={LoadingIndicatorStyle} />
    </Segment>
  );
};

const renderNoData = ({ t }) => {
  return (
    <Segment basic clearing textAlign="center" className="no-shadow no-border top-border">
      <Header as={'h3'}>
        <Icon circular inverted name="info" color="grey" />
      </Header>
      <Header as={'h4'}>{t('No invoices found')}</Header>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organizations.currentOrganization,
    invoices: state.invoices,
  };
};

export default connect(mapStateToProps)(StudentInvoicesTable);
