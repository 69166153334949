import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';

const ShowSuccess = ({ iconName = 'check', header, content, onDismiss = undefined, hideHeader = false }) => {
  const { t } = useTranslation();
  const _header = !hideHeader ? header || t('Success!') : null;

  return !_.isEmpty(content) ? (
    <Message
      data-testid="success-message"
      positive
      icon={iconName}
      header={_header}
      content={content}
      onDismiss={onDismiss}
    />
  ) : null;
};

ShowSuccess.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDismiss: PropTypes.func,
};

export default ShowSuccess;
