export { getEnabled, setEnabled, isEnabled, getUri, setUri, initOrganizationPreCheck };

// Enabled checks
function getEnabled(obj) {
  return getPreCheck(obj)?.enabled;
}
function setEnabled(obj, enabled) {
  getOrSetPreCheck(obj).enabled = enabled;
}
function isEnabled(obj) {
  return !!getEnabled(obj);
}

// Uris
function getUri(obj) {
  return getPreCheck(obj)?.uri ?? null;
}
function setUri(obj, uri) {
  getOrSetPreCheck(obj).uri = uri;
}

function initOrganizationPreCheck(organization) {
  const preCheck = {};
  preCheck.enabled = getEnabled(organization) ?? false;
  preCheck.uri = getUri(organization) ?? 'https://forms.wonderschool.com/health-check/';
  return { preCheck: preCheck };
}

function getPreCheck(obj) {
  return obj.preCheck;
}
function getOrSetPreCheck(obj) {
  if (!obj.preCheck) {
    obj.preCheck = {};
  }
  return obj.preCheck;
}
