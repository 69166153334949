import moment from 'moment-timezone';
import jsPDF from 'jspdf';

import { initializePDFDoc } from '../../../common';
import { ActivitiesReportType } from '../../types';

// The generic type can be 'unknown' because we don't know what the type of the items are here, and don't care
export function initializeAttendancePDFDoc(title: string, report: ActivitiesReportType<unknown>): jsPDF {
  const { location, room } = report;

  const startDate = moment(report.startDate).format('DD/MM/YYYY');
  const endDate = moment(report.endDate).format('DD/MM/YYYY');

  const doc = initializePDFDoc(title);

  doc.setFont('helvetica', 'normal', 'normal');
  doc.setFontSize(11);

  doc.text([location.address1, `${location.city}, ${location.state}`], 10, 45);

  const timePeriod = `${startDate} - ${endDate}`;
  doc.setFont('helvetica', 'normal', 'bold');
  doc.text('Time Period:', 10, 57);
  doc.setFont('helvetica', 'normal', 'normal');
  doc.text(timePeriod, 40, 57);

  if (room?.name) {
    doc.setFont('helvetica', 'normal', 'bold');
    doc.text('Room:', 90, 57);
    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(room.name, 104, 57);
  }
  return doc;
}
