import {
  ALL_STUDENT_INVITATIONS_FETCHED,
  STUDENT_INVITATIONS_FETCHED,
  STAFF_INVITATIONS_FETCHED,
  INVITED_PARENTS_BY_IDS_FETCHED,
} from '../actions/types';

const initialState = {
  selectedInvitation: {},
  count: 0,
  selectedStudentList: [],
  list: [],
  invitedParentsForSelectedStudents: [],
};

const invitations = (state = initialState, action) => {
  switch (action.type) {
    case ALL_STUDENT_INVITATIONS_FETCHED:
      return {
        ...state,
        count: action.invitations.count,
        list: action.invitations.list,
      };

    case STAFF_INVITATIONS_FETCHED:
      return {
        ...state,
        count: action.invitations.count,
        list: action.invitations.list,
      };

    case STUDENT_INVITATIONS_FETCHED:
      return {
        ...state,
        selectedStudentList: action.invitations.list,
      };

    case INVITED_PARENTS_BY_IDS_FETCHED:
      return {
        ...state,
        invitedParentsForSelectedStudents: action.invitations.list,
      };

    default:
      return state;
  }
};

export default invitations;
