import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Card, Header, Label } from 'semantic-ui-react';

// Import style
import cardStyles from '../Shared/CardHelpers/CardStyles.module.scss';

const buildAgeRangeStr = ({ from, to }, t) => {
  // resolve legacy data ---------------
  if (!from || !to) {
    return null;
  }
  if (typeof from === 'string' && typeof to === 'string') {
    if (from === to) {
      return t(from);
    }
    return `${t(from)} - ${t(to)}`;
  }

  // display none
  if (from.months === 0 && from.years === 0 && to.months === 0 && to.years === 0) {
    return null;
  }

  // returns 1 :year, 2 :years or 12+ :years
  const formatYears = (years) => {
    if (years === 0) {
      return '';
    }
    return `${years < 13 ? years : '12+'} ${years === 1 ? ':year' : ':years'}`;
  };

  // Returns 1 :month or 2 :months
  const formatMonths = (months) => {
    if (months === 0) {
      return '';
    }
    return `${months} ${months === 1 ? ':month' : ':months'}`;
  };

  // replace :year, :years, :month, :months with translation
  const replaceDateParts = (str, abbreviate) => {
    return str
      .replace(/:years/g, t(abbreviate ? 'Yrs' : 'Years'))
      .replace(/:year/g, t(abbreviate ? 'Yrs' : 'Year'))
      .replace(/:months/g, t(abbreviate ? 'Mos' : 'Months'))
      .replace(/:month/g, t(abbreviate ? 'Mos' : 'Month'));
  };

  let fromStr = `${formatYears(from.years)} ${formatMonths(from.months)}`.trim();
  if (fromStr === '') {
    fromStr = '0';
  }

  const toStr = `${formatYears(to.years)} ${formatMonths(to.months)}`.trim();

  if (fromStr === toStr) {
    return replaceDateParts(fromStr, false);
  }

  // If both year or month are 0, the fromStr should only be a number and doesn't need abbreviation
  let abbreviate = true;
  if ((from.years === 0 && to.years === 0) || (from.months === 0 && to.months === 0)) {
    fromStr = (from.years || from.months).toString();
    abbreviate = false;
  }

  // join from and to if they are not empty
  const dateParts = [fromStr, toStr].filter((str) => str !== '');
  return dateParts.map((str) => replaceDateParts(str, abbreviate && dateParts.length > 1)).join(' - ');
};

const RoomCard = ({ room, onClick }) => {
  const { t } = useTranslation();

  const ageRange = buildAgeRangeStr(room.ageRange ?? {}, t);

  return (
    <Card className="center shadow" key={room.id} onClick={onClick} data-testid="room-card">
      <Card.Content className="center">
        <Avatar name={room.name} round size={75} className="left floated" style={{ float: 'unset', fontSize: '2em' }} />
      </Card.Content>

      <Card.Content className="no-border" extra>
        <Card.Header>
          <Header as={'h3'} className={cardStyles.textTruncate}>
            {room.name}
          </Header>
        </Card.Header>
        <Card.Meta>{ageRange || <>&nbsp;</>}</Card.Meta>
      </Card.Content>

      <Card.Content extra>
        <Label
          // legacy data - when FCC is converted to real-room type
          // room is only disabled if it is explicitly set to false
          content={room.enabled !== false ? t('Enabled') : t('Disabled')}
          color={room.enabled !== false ? 'green' : 'red'}
        />
      </Card.Content>
    </Card>
  );
};

RoomCard.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RoomCard;
