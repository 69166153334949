import { logError } from '../../rollbar';

const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_WS_MARKETPLACE_APP_URL}/graphql`;

export default function marketplaceGraphql(query: string, variables: any = {}) {
  if (!process.env.REACT_APP_WS_MARKETPLACE_APP_URL)
    throw new Error('No REACT_APP_WS_MARKETPLACE_APP_URL currently setup');

  return send({ query, variables }).then(handleGraphQLPayload);
}

function send(body: any): any {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  };

  return fetch(GRAPHQL_ENDPOINT, params).then(handleResponse).catch(handleError);
}

function handleGraphQLPayload(payload: { errors: any; data: any }) {
  if (payload.errors) {
    throw payload.errors;
  }

  return payload.data;
}

function handleResponse(response: any) {
  return parseResponse(response).then((parsed: any) => {
    if (!parsed.raw.ok) throw parsed;

    return parsed.json;
  });
}

function handleError(err: { raw: any }) {
  const response = err.raw;
  const message = response
    ? `POST ${response.url} ${response.status} ${response.statusText}`
    : `POST ${GRAPHQL_ENDPOINT} failed to respond`;

  logError(`Failed to make GraphQL request: ${message}`, err, { err });

  throw err;
}

function parseResponse(response: { json: () => Promise<any> }) {
  return response.json().then((json) => ({ json, raw: response }));
}
