import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Icon, Segment } from 'semantic-ui-react';

import { isOrganizationSetupComplete } from '../../helpers/organizations';
import { useOrganization } from '../../hooks/useOrganizations';
import { useUser } from '../../hooks/useUser';
import OnboardingGoalButtonsContainer from './OnboardingGoalButtonsContainer';
import sunnyCloudImage from './assets/SunnyCloud.svg';
import sunnyLeftImage from './assets/SunnyLeft.svg';

type OnboardingBannerProps = {
  collapsed?: boolean;
  onClickUpDown?: (isCollapsed: boolean) => void;
};

const OnboardingBanner: React.FC<OnboardingBannerProps> = ({ collapsed = true, onClickUpDown }) => {
  const { t } = useTranslation();
  const { isDirector } = useUser();
  const organizationData = useOrganization();
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const extraClass = isCollapsed ? 'collapsed' : 'expanded';

  function OnboardingCollapsedBanner() {
    return (
      <div className="onboarding-banner-footer">
        <img src={sunnyLeftImage} className="sunny-left-image" alt="" />
        <div className="footer-text">{t('onboarding.collapsedMessage')}</div>
        <div className="toggle">
          <Icon
            name="chevron down"
            onClick={() => {
              setIsCollapsed(false);
              if (onClickUpDown) onClickUpDown(false);
            }}
          />
        </div>
      </div>
    );
  }

  function OnboardingExpandedBanner() {
    return (
      <>
        <div className="onboarding-wrapper">
          <div className="onboarding-banner-header">
            <div className="onboarding-inner-wrapper">
              <img src={sunnyCloudImage} className="sunny-cloudy-image" alt="Sunny Cloud Image" />
            </div>
            <h1>{t('onboarding.welcome')}</h1>
            <p>{t('onboarding.iAmSunny')}</p>
          </div>
          <Segment className="buttons-section">
            <p>{t('onboarding.buttonsHeader')}</p>
            <OnboardingGoalButtonsContainer />
          </Segment>
        </div>
        <div className="onboarding-banner-footer">
          <img src={sunnyLeftImage} className="sunny-left-image" alt="" />
          <div
            className="footer-text"
            onClick={() => {
              setIsCollapsed(true);
              if (onClickUpDown) onClickUpDown(true);
            }}
          >
            {t('onboarding.exploreLink')}
          </div>
          <div className="toggle">
            <Icon
              name="chevron up"
              onClick={() => {
                setIsCollapsed(true);
                if (onClickUpDown) onClickUpDown(true);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  return (
    isDirector &&
    !isOrganizationSetupComplete(organizationData) && (
      <div className={`onboarding-banner ${extraClass}`}>
        {isCollapsed ? <OnboardingCollapsedBanner /> : <OnboardingExpandedBanner />}
      </div>
    )
  );
};

export default OnboardingBanner;
