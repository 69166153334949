import { useTranslation } from 'react-i18next';
import './InvoiceList.scss';
import { WsEmail } from '../../../icons';
// eslint-disable-next-line no-restricted-imports
import { Dimmer } from 'semantic-ui-react';
import { SEGMENT_EVENTS, useSegmentTrack } from '../../../segment';
import { BillingPageEnum } from '../../../common';

type EnablePaymentsModalProps = {
  page: string;
};

const EnablePaymentsModal: React.FC<EnablePaymentsModalProps> = ({ page }) => {
  const { t } = useTranslation();
  const segmentTrack = useSegmentTrack();

  function handleClick() {
    const segmentEventMap = {
      [BillingPageEnum.INVOICE]: SEGMENT_EVENTS.addPayoutAccountInvoiceEmail,
      [BillingPageEnum.INVOICE_PLAN]: SEGMENT_EVENTS.addPayoutAccountInvoicePlanEmail,
    };

    const segmentEvent = segmentEventMap[page] || '';

    segmentTrack(segmentEvent);
  }
  return (
    <>
      <Dimmer active inverted />
      <div className="enable-payments-modal">
        <b className="top-text">{t('billing.enablePayModal.topText')}</b>
        <>
          <li>{t('billing.enablePayModal.bullet1')}</li>
          <li>{t('billing.enablePayModal.bullet2')}</li>
        </>
        <div className="link-section">
          <a
            href="mailto:support@wonderschool.com"
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex' }}
            onClick={handleClick}
          >
            <WsEmail className="email-icon" />
            {t('support@wonderschool.com')}
          </a>
        </div>
      </div>
    </>
  );
};

export default EnablePaymentsModal;
