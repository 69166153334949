import firebase from 'firebase/compat/app';
import jsPDF from 'jspdf';
import FieldPath = firebase.firestore.FieldPath;
import WhereFilterOp = firebase.firestore.WhereFilterOp;

import { ActivityTypeEnum, ReportTypeEnum } from './enums';

export type ActivitiesFiltersType = {
  organizationId: string;
  roomId?: string;
  studentId?: string;
  startDate: Date;
  endDate: Date;
  activityTypes: ActivityTypeEnum | ActivityTypeEnum[];
  reportType: ReportTypeEnum;
  kinderSystemsStudents?: string[];
};

export type ReportType = {
  startDate: Date;
  endDate: Date;
  organization: any;
  location: any;
  room?: any; // if this report was filtered by room
};

// use this to pass all data into the csv and pdf generators
export type ActivitiesReportType<T> = ReportType & {
  items: T[];
};

export type ActivityType = any; // yes, this is BS

export type ActivitiesTableProps<T extends ActivitiesFiltersType> = {
  filters: T;
  activities?: ActivityType[];
  isLoading?: boolean;
  kinderSystemsReport?: boolean;
};

export type QueryConditionType = {
  field: string | FieldPath;
  operation: WhereFilterOp;
  value: string | number | boolean | string[] | null | ActivityTypeEnum[] | Date;
};

export interface PDFGeneratorFunction<T> {
  (report: T): Promise<{ filename: string; pdf: jsPDF }>;
}
