import { EnrollmentFormDataType } from '../../../../enrollments';

export type StudentEnrollmentFormPotentialErrors = {
  room?: string;
  startDate?: string;
  unenrollmentDate?: string;
  enrollmentStatus?: string;
};

export function validateStudentEnrollmentForm(formData: EnrollmentFormDataType): StudentEnrollmentFormPotentialErrors {
  const validationErrors: StudentEnrollmentFormPotentialErrors = {};

  if (!formData.room) {
    validationErrors.room = 'students.enrollment.validation.pleaseSelectARoom';
  }

  if (!formData.startDate) {
    validationErrors.startDate = 'errors.validation.startDateRequired';
  }

  if (!formData.isEnrolled && !formData.isPending && !formData.unenrollmentDate) {
    validationErrors.unenrollmentDate = 'students.enrollment.validation.unenrollmentDateRequired';
  }

  return validationErrors;
}
