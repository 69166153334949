import { Helmet } from 'react-helmet-async';
// eslint-disable-next-line no-restricted-imports
import { Segment, Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { FeatureFlagsTable } from './FeatureFlagsTable';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

export const FeatureFlagsPage = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.featureFlags);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Feature Flags') })}
      </title>
      <body className="feature-flags" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}
      <Segment basic>
        <FeatureFlagsTable />
      </Segment>
    </Container>
  );
};
