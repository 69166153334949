import { ParentHandbookPage } from '@wonderschool/ccms-ui';
import { RouteNameEnum, useRoutes } from '../navigation';
import { useParams } from 'react-router-dom';
import { Dialog } from '@wonderschool/common-base-ui';

const ParentHandbook = () => {
  const { gotoRouteByName } = useRoutes();

  const handleRouteToDocuments = () => {
    gotoRouteByName(RouteNameEnum.DOCUMENTS);
  };

  return (
    <Dialog
      isOpen={true}
      onClose={handleRouteToDocuments}
      extraDialogWrapperClasses="fixed inset-0 z-50 flex items-center justify-center"
      fullScreenOnMobile={true}
      fullScreenOnDesktop={true}
    >
      <ParentHandbookPage useParams={useParams} routeToDocuments={handleRouteToDocuments} />
    </Dialog>
  );
};

export default ParentHandbook;
