import { Component } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Container, Segment, Header, Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';

// Import components
import ShowError from '../Components/Messages/ShowError';
import ShowSuccess from '../Components/Messages/ShowSuccess';
import { getTitlePrefix } from '../config/env';

import WSA from '../WSA';

class EmailSent extends Component {
  state = {
    error: '',
    icon: '',
    header: '',
    content: '',
    messageType: '',
  };

  renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {this.props.t('{{pageName}} - Wonderschool', {
          pageName: this.props.t('Email is Sent'),
        })}
      </title>
      <body className="email-sent" />
    </Helmet>
  );

  resendConfirmationEmail = async () => {
    try {
      const result = await WSA.Actions.sendVerificationEmail(this.props.authUser.uid);
      if (!result || result.errorCode) {
        throw new Error(this.props.t('Failed to send verification email'));
      }
      this.setState({
        content: this.props.t('We just resent another verification email.'),
        messageType: this.props.t('Success'),
      });
    } catch (error) {
      this.setState({
        verifying: false,
        content: error.message,
        messageType: 'error',
      });
    }
  };

  renderEmailResendAlert = () => {
    const { content, messageType } = this.state;

    if (!content || !messageType) return null;

    return <>{messageType === 'error' ? <ShowError content={content} /> : <ShowSuccess content={content} />}</>;
  };

  render() {
    const { t, authUser } = this.props;

    return (
      <Container style={{ width: 500 }}>
        {this.renderPageHead()}

        {this.renderEmailResendAlert()}

        <Segment textAlign="center" className="auth-wrapper">
          <Header>
            <Icon circular inverted name="mail" size="small" />
          </Header>

          <Header as="h1" content={t('Confirm your account')} />

          <Segment basic>
            {t(
              'Thanks for signing up with us. Your account is almost ready. Please check your inbox to confirm your email address'
            )}
            : <strong>{authUser.email}</strong>
          </Segment>

          <Segment basic>
            {t('Did not get it? Check your spam folder or')}{' '}
            <a
              href="#resent"
              onClick={(e) => {
                e.preventDefault();
                this.resendConfirmationEmail();
              }}
            >
              {t('resend the confirmation email again.')}
            </a>
          </Segment>
        </Segment>
      </Container>
    );
  }
}
const mapAuthContextToProps = ({ authUser }) => {
  return {
    authUser,
  };
};

export default WSA.Components.mapAuthContextToProps(mapAuthContextToProps, withTranslation()(EmailSent));
