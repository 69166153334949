import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selectLocationsState = createSelector([(state) => state.locations], (locations) => locations ?? {});
const selectSelectedLocation = createSelector([(state) => state.locations], (locations) => locations?.selectedLocation);

export function useLocations() {
  return useSelector(selectLocationsState);
}

export function useSelectedLocation() {
  return useSelector(selectSelectedLocation);
}

// Search the redux store for a location that is marked as 'primary'
// If no primary location is found, return the first location that is marked 'active'
// If no active location is found, return the first location in the list
export function usePrimaryLocation() {
  const locations = useLocations();
  if (!locations) return null;

  let location = locations.list.find((location) => !!location.primary);
  if (!location) location = locations.list.find((location) => !!location.active);
  if (!location && locations.list) location = locations.list[0];
  return location;
}
