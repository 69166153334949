import BasePlugin from '@uppy/core/lib/BasePlugin.js';
import CSVFileValidator from 'csv-file-validator';

/**
 * This plugin validates CSV files and shows uppy.info(<message>) if the file does not
 * match the provided template.
 * Note: in order to use this plugin, you need to provide a csvTemplate.
 * opts.csvTemplate = {string} csvTemplate - The CSV template URL or path.
 * e.g. /static/csv/students-onboarding.csv   or https://example.com/students-onboarding.csv
 */
class UppyCSV extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = 'UppyCSV';
    this.type = 'csv-validator';
    this.csvTemplate = opts.csvTemplate; // CSV template URL.
    this.translate = opts.translate; // Translation function.
    this.setIsShowModalImportStudentDetails = opts.setIsShowModalImportStudentDetails;
    this.setResultData = opts.setResultData;

    this.prepareUpload = this.prepareUpload.bind(this);
  }

  prepareUpload = async (fileIDs) => {
    try {
      const template = await fetch(this.csvTemplate).then((response) => response.text());

      // Formats csvHeader as [{ name: string, inputName: string }]
      // This format is required by CSVFileValidator.
      const csvHeaders = template
        ?.split('\n')[0] // get first line
        ?.split(',') // split by comma
        .map((header) => ({
          name: header,
          inputName: header,
        }));

      // CSV validation header not provided. Skip pre-processing.
      if (!csvHeaders?.length) Promise.resolve();

      for (const fileID of fileIDs) {
        const file = this.uppy.getFile(fileID);

        // Skip if file is not a CSV.
        if (file.type !== 'text/csv') continue;

        const csvResult = await CSVFileValidator(file.data, {
          headers: csvHeaders,
        });

        let validationError = null;
        let errorCSV = {};

        // CSV has invalid header.
        if (csvResult.inValidMessages?.length)
          validationError = 'Invalid CSV header detected. Please use the provided template and try again.';

        // CSV has no data.
        if (!csvResult.data?.length)
          validationError = 'It looks like the file you are trying to upload is empty. Please add data and try again.';

        if (validationError) {
          errorCSV.errorCSVMessage = validationError;
          this.uppy.removeFile(fileID);
          this.setResultData(errorCSV);
          this.setIsShowModalImportStudentDetails(true);
          return Promise.resolve();
        }
      }

      // All good, proceed with upload.
      return Promise.resolve();
    } catch (error) {
      const errorMessage = this.translate(error.message || 'Unable to validate CSV file.');

      this.uppy.info(errorMessage, 'error', 7000);
      return Promise.reject(errorMessage);
    }
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export default UppyCSV;
