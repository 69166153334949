// eslint-disable-next-line no-restricted-imports
import { Container } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import components.
import StudentsTable from '../students/components/StudentsTable';

import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';
import { useParams } from 'react-router-dom';

interface RouteParams {
  filter: string;
}

const Students = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.students);

  const { filter } = useParams<RouteParams>();
  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Students') })}
      </title>
      <body className="students" />
    </Helmet>
  );

  return (
    <Container fluid className="no-padding no-margin">
      {renderPageHead()}
      <StudentsTable filter={filter} />
    </Container>
  );
};

export default Students;
