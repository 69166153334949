import { ONBOARDING_BANNER_COLLAPSED } from '../actions/types';

const initialState = {
  isCollapsed: false,
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_BANNER_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.isCollapsed,
      };

    default:
      return state;
  }
};
