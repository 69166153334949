import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth as getAuthF, connectAuthEmulator, Auth } from 'firebase/auth';
import {
  getFirestore as getFirestoreF,
  connectFirestoreEmulator,
  initializeFirestore,
  Firestore,
} from 'firebase/firestore';
import { getFunctions as getFunctionsF, connectFunctionsEmulator, Functions } from 'firebase/functions';
import { getStorage as getStorageF, connectStorageEmulator, FirebaseStorage } from 'firebase/storage';

import { firebaseConfig } from '../firebase/config';
import { isTest, isLocal, shouldEmulateFirebase } from '../../config/env';

let firebase: FirebaseApp;
let auth: Auth;
let firestore: Firestore;
let functions: Functions;
let storage: FirebaseStorage;

/*
Everything within `src/api/firebase` uses the "old" namespaced API.
`src/api/firebase2` uses the "new" modular API.

Having both exist at the same time allows us to gradually replace 
all the code using firebase(1) with firebase2 without having to do
it all at once.

Also, we should ensure that all files within `src/api/firebase2`
uses Typescript
*/

const FIREBASE_V9_APP_NAME = 'FirebaseApp2';

const initAuth = (fireApp: FirebaseApp) => {
  return getAuthF(fireApp);
};
const initFirestore = (fireApp: FirebaseApp) => {
  initializeFirestore(fireApp, {
    // https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettings_interface
    ignoreUndefinedProperties: true,
  });
  return getFirestoreF(fireApp);
};

const initFunctions = (fireApp: FirebaseApp) => {
  return getFunctionsF(fireApp);
};

const initStorage = (fireApp: FirebaseApp) => {
  return getStorageF(fireApp);
};

const initFirebase = () => {
  // Only initialize if it isn't already initialized.
  if (!firebase) {
    firebase = initializeApp(firebaseConfig, FIREBASE_V9_APP_NAME);
    auth = initAuth(firebase);
    firestore = initFirestore(firebase);
    functions = initFunctions(firebase);
    storage = initStorage(firebase);

    if (isLocal() && !isTest()) {
      // logger not yet loaded here so we have to use normal one
      console.log('🔥  🔥  🔥 FIREBASE INITIALIZED 🔥  🔥  🔥');
    }
    /**
     * To use firebase emulator
     * start firebase emulator: firebase emulators:start
     * start react app: yarn start:with-emulator
     */
    if (shouldEmulateFirebase()) {
      // Auth Emulator
      connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
        disableWarnings: isTest(),
      });
      // Firestore Emulator
      connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      // Functions Emulator
      connectFunctionsEmulator(functions, '127.0.0.1', 5001);
      // Storage emulator
      connectStorageEmulator(storage, '127.0.0.1', 9199);
    }
  }
  return { auth, firestore, functions, storage };
};

const getAuth = () => auth || initFirebase().auth;
const getFirestore = () => firestore || initFirebase().firestore;
const getFunctions = () => functions || initFirebase().functions;
const getStorage = () => storage || initFirebase().storage;

initFirebase(); // run at least once

export { getAuth, getFirestore, getFunctions, getStorage };
