import { PipeConfig } from 'react-text-mask';
import { WsInputMasked, WsInputMaskedProps } from '../../../common';

type TimeInputProps = Omit<WsInputMaskedProps, 'guide' | 'mask'>;

const MASK = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /\d/, ' ', /a|p/, 'm'];
const _REGEX = /^(1[0-2]|0?[1-9]):([0-5][0-9]) ?([ap][m])/;

function pipe(
  conformedValue: string,
  _config: PipeConfig
): false | string | { value: string; indexesOfPipedChars: number[] } | undefined {
  //return REGEX.test(conformedValue) ? conformedValue : false;
  return conformedValue;
}
export default function TimeInput(timeInputProps: TimeInputProps) {
  return (
    <WsInputMasked
      {...timeInputProps}
      mask={MASK}
      pipe={pipe as any}
      guide={false}
      placeholder="00:00 am"
      keepCharPositions={false}
    />
  );
}
