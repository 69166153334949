import { ONBOARDING_BANNER_COLLAPSED } from './types';

export const expandOnboardingBanner = () => ({
  type: ONBOARDING_BANNER_COLLAPSED,
  isCollapsed: false,
});

export const collapseOnboardingBanner = () => ({
  type: ONBOARDING_BANNER_COLLAPSED,
  isCollapsed: true,
});
