import { useTranslation } from 'react-i18next';
import SectionCard from '../Shared/SectionCard';
import withPermission from '../Shared/withPermission';
import PhotoGallery from '../Stats/PhotoGallery';

const RecentPhotos = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="w-full">
        <SectionCard header={t('Recent Photos')} component={PhotoGallery} />
      </div>
    </div>
  );
};

export default withPermission(RecentPhotos, 'can_view_recent_photos');
