import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Divider } from 'semantic-ui-react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { FormComponentProps, OnSaveCallbackParamsType } from '../../common';
import { DEFAULT_PARENT } from '../consts';
import { ParentType } from '../types';

import { WsAddCircle } from '../../icons';

import ParentForm from './ParentForm';

type ParentsFormProps = FormComponentProps<ParentType | ParentType[]> & {
  parents: ParentType[];
};

function ParentsForm({ title, hasTitle = true, isSaving, onSave, parents: parentsParam }: ParentsFormProps) {
  const { t } = useTranslation();
  const [parents, setParents] = useState<ParentType[]>(parentsParam?.length ? parentsParam : [{ ...DEFAULT_PARENT }]);
  // parentsChanged is used to keep track of the parents that have been in ParentEditForm for validation purposes
  const [parentsChanged, setParentsChanged] = useState<ParentType[]>(
    parentsParam?.length ? parentsParam : [{ ...DEFAULT_PARENT }]
  );
  const [dataToSave, setDataToSave] = useState<Record<number, ParentType>>({});

  const viewTitle = hasTitle ? t(title || 'students.parentsFormTitle') : '';

  if (!isSaving && !isEmpty(dataToSave)) {
    setDataToSave({});
  }

  useEffect(() => {
    setParents(parentsParam ?? [{ ...DEFAULT_PARENT }]);
  }, [parentsParam]);

  useEffect(() => {
    setParentsChanged([...(parents ?? [])]);
  }, [parents]);

  useEffect(() => {
    if (isSaving && onSave && Object.keys(dataToSave).length === parents.length) {
      onSave({ data: Object.values(dataToSave) });
    }
  }, [dataToSave, isSaving, onSave, parents?.length]);

  const onSaveLocal = useCallback(
    ({ data, errors }: OnSaveCallbackParamsType<ParentType>, index: number) => {
      if (!isSaving) return false;

      if (!isEmpty(errors)) {
        if (onSave) onSave({ errors });
      } else if (data) {
        setDataToSave((prev) => ({ ...prev, [index]: data }));
      }
    },
    [isSaving, onSave]
  );
  const onChangeParent = useCallback(
    (parent: ParentType, index: number) => {
      if (index >= parentsChanged?.length) return;
      setParentsChanged((prev) => {
        const newParents = [...prev];
        newParents[index] = parent;
        return newParents;
      });
    },
    [parentsChanged?.length]
  );
  return (
    <div className="parents-form">
      {!!viewTitle && (
        <h2 className="text-2xl font-bold mb-4" data-testid="parent-form-title">
          {viewTitle}
        </h2>
      )}
      {parents.map((parent, index) => (
        <div key={index}>
          <h3 data-testid="parent-form-title">
            {index > 0 && !parent.isInterestedFamily && (
              <Button
                basic
                className="ws-icon-button"
                floated="right"
                size="large"
                icon="trash alternate outline"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setParents(() => parentsChanged.filter((f, i) => i !== index));
                }}
              />
            )}

            {t('students.parentsFormTitle') + ' ' + (index + 1)}
          </h3>
          <ParentForm
            parent={parent}
            parents={parentsChanged}
            isSaving={isSaving && !dataToSave[index]}
            onSave={(d) => onSaveLocal(d, index)}
            onChangeParent={(parent) => onChangeParent(parent, index)}
          />
          <Divider />
        </div>
      ))}
      <div>
        <a
          href="#"
          className="ws-icon-link"
          onClick={() => {
            setParents(() => {
              return [...parentsChanged, { ...DEFAULT_PARENT, uuid: uuidv4() }];
            });
          }}
          data-testid="add-another-parent-link"
        >
          <WsAddCircle data-testid="add-another-parent-icon" />
          <span>{t('students.parentsAddParentLabel')}</span>
        </a>
      </div>
    </div>
  );
}

export default ParentsForm;
