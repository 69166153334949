import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Form, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import Avatar from 'react-avatar';
import { useTranslation, Trans } from 'react-i18next';

// Import components
import InlineError from '../Messages/InlineError';
import LanguagePicker from '../Shared/LanguagePicker';
import ProfilePictureUploader from '../Upload/ProfilePictureUploader';

// Import Actions
import { updateOrganizationUser } from '../../api/firebase/users';
import { saveContact } from '../../contacts/contactsAPI';
import { formatFullName, phoneNumberParse, phoneNumberFormat } from '../../helpers/utils';

import { changeLanguage } from '../../i18n';

import { useOrganization } from '../../hooks/useOrganizations';
import { useUser } from '../../hooks/useUser';

export default function UserProfileForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const currentOrganization = useOrganization();
  const currentUser = useUser();
  const { id, uid, contactId, firstName, middleName, lastName, phone, email, photoURL, language } = currentUser;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    id: id || '',
    uid: uid || '',
    firstName: firstName || '',
    middleName: middleName || '',
    lastName: lastName || '',
    phone: phoneNumberParse(phone || ''),
    photoURL: photoURL || '',
    language: language || '',
  });

  const showEmail = !!email && email.indexOf('moxit_') === -1;

  const validate = (data) => {
    const errors = {};

    // if (!data.salutation) errors.salutation = 'Salutation is required';
    if (!data.firstName) errors.firstName = t('common.firstNameRequired');
    if (!data.lastName) errors.lastName = t('common.lastNameRequired');

    if (data.phone) if (!Validator.isMobilePhone(data.phone, 'en-US')) errors.phone = t('common.invalidPhone');

    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const _errors = validate(data);
      setErrors(_errors);
      if (Object.keys(_errors).length > 0) return;

      if (contactId) {
        await saveContact(currentOrganization.id, {
          ...data,
          id: contactId,
          phone: phoneNumberFormat(data.phone),
        });
      } else {
        await updateOrganizationUser(currentOrganization.id, uid, {
          ...data,
          phone: phoneNumberFormat(data.phone),
        });
      }
      // Change language
      if (data.language) changeLanguage(data.language);

      setLoading(false);
      history.goBack();
    } catch (error) {
      console.error(`UserProfileForm error`, error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e, eventData) => {
    if (eventData?.name) {
      setData({ ...data, [eventData.name]: eventData.value ?? '' });
    } else if (e.target?.name && e.target?.value) {
      setData({ ...data, [e.target.name]: e.target.value ?? '' });
    }
  };

  const onUploaded = (imageUrl) => setData({ ...data, photoURL: imageUrl });

  return (
    <Form onSubmit={onSubmit} noValidate loading={loading}>
      <Form.Group widths="equal">
        <Form.Field error={!!errors.firstName}>
          <Form.Input
            required
            type="text"
            id="firstName"
            name="firstName"
            value={data.firstName}
            onChange={onChange}
            label={t('common.firstName')}
            placeholder={t('common.firstName')}
          />
          {errors.firstName && <InlineError text={errors.firstName} />}
        </Form.Field>

        <Form.Field error={!!errors.middleName}>
          <Form.Input
            type="text"
            id="middleName"
            name="middleName"
            value={data.middleName}
            onChange={onChange}
            label={t('common.middleName')}
            placeholder={t('common.middleName')}
          />
          {errors.middleName && <InlineError text={errors.middleName} />}
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={data.lastName}
            onChange={onChange}
            label={t('common.lastName')}
            placeholder={t('common.lastName')}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </Form.Field>
      </Form.Group>

      {showEmail && (
        <Form.Field className={'relative'}>
          <Form.Input
            className={'opacity-100'}
            type="text"
            disabled
            value={email}
            label={t('common.email')}
            placeholder={t('common.email')}
          />
          <span className={'relative left-1 right-0 top-0 text-xs md:absolute md:left-auto md:top-1'}>
            <Trans i18nKey="Contact support to change your email">
              Contact{' '}
              <a href="mailto:support@wonderschool.com" target="_blank" rel="noreferrer">
                support@wonderschool.com
              </a>{' '}
              to change your email
            </Trans>
          </span>
        </Form.Field>
      )}

      <Form.Field error={!!errors.phone}>
        <Form.Input
          type="text"
          id="phone"
          name="phone"
          onChange={onChange}
          label={t('common.cellPhone')}
          control={MaskedInput}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          guide={false}
          value={data.phone}
          placeholder={'(123) 456-7890'}
        />
        {errors.phone && <InlineError text={errors.phone} />}
      </Form.Field>

      <Form.Field error={!!errors.language}>
        <Form.Field
          id="language"
          name="language"
          label={t('Language')}
          control={LanguagePicker}
          placeholder={t('Language')}
          value={data.language}
          selection
          search
          onChange={onChange}
        />
        {errors.language && <InlineError text={errors.language} />}
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field>
          <label>{t('Profile Photo')}</label>

          <Grid columns="equal" className={'flex-col content-center md:flex-row'}>
            <Grid.Column className={'self-auto md:self-center'}>
              <Avatar
                round
                name={formatFullName(data, true)}
                src={data.photoURL}
                size={140}
                className="left floated"
                style={{ float: 'unset', fontSize: '2em', marginRight: '20px' }}
              />
            </Grid.Column>
            <Grid.Column width={12} className={'self-auto md:self-center'}>
              <Form.Field
                error={!!errors.photoURL}
                control={ProfilePictureUploader}
                uploadPath={`organizations/${currentOrganization.id}/contacts/${data.id}`}
                title={t('Uploading Profile Photo')}
                onUploaded={onUploaded}
              >
                {errors.photoURL && <InlineError text={errors.photoURL} />}
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form.Group>

      <br />
      <br />
      <Form.Group>
        <Form.Button primary content={t('Update')} loading={loading} />
        <Form.Button
          basic
          content={t('common.cancel')}
          loading={loading}
          onClick={(e) => {
            if (e) e.preventDefault();
            history.goBack();
          }}
        />
      </Form.Group>
    </Form>
  );
}
