import { ClaimUpdatePage, ClaimUpdatePageProps } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

export const ClaimUpdate = () => {
  const { t } = useTranslation();
  const title = t('food.claims.update');
  const { gotoRouteByName } = useRoutes();
  const { claimId } = useParams<Pick<ClaimUpdatePageProps, 'claimId'>>();
  useSegmentPage(PAGE_NAMES.foodProgramClaimUpdate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  const handleGoBack = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIMS);
  };

  return (
    <>
      {renderPageHead()}
      <ClaimUpdatePage claimId={claimId} onClickBack={handleGoBack} onSubmit={() => null} />
    </>
  );
};
export default ClaimUpdate;
