// Map with network ids and menu names
export const hideMenuForNetworks = [];

/**
 * This config will consist of menu which we need to show for each network.
 *
 * Example:
 * {
 *   [NETWORK_ID]: {
 *     [RouteNameEnum.TOUR_REQUESTS]: false // hide
 *   }
 * }
 */
export const menuConfigForNetworks = {};
