import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Combobox, Input } from '@wonderschool/common-base-ui';
import { RoomDropdown, StudentDropdown, WsDropdownOptionType, WsInputEventType } from '../../../common';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useStudents } from '../../../students/studentsHooks';
import { convertDateToInputSpecificString } from '../../../timecards/components/utils/convertDateToInputSpecificString';
import { ActivityTypeEnum, ReportTypeEnum } from '../../enums';
import { AttendanceFiltersType } from '../types';

type AttendanceFiltersProps = {
  onFilter: (filters: AttendanceFiltersType) => void;
  onClear?: () => void;
  loading?: boolean;
  filters?: AttendanceFiltersType; // for testing or passing in different default values
  hideFoodProgramFilter?: boolean;
  kinderSystemsReport?: boolean;
};

type DropdownOptionType = WsDropdownOptionType<ReportTypeEnum>;
dayjs.extend(utc);
dayjs.extend(customParseFormat);
export const AttendanceFiltersForm: React.FC<AttendanceFiltersProps> = ({
  onFilter,
  onClear,
  loading = false,
  filters: filtersParam,
  hideFoodProgramFilter = false,
  kinderSystemsReport = false,
}) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const studentsData = useStudents();

  const defaultFilters = useMemo<AttendanceFiltersType>(() => {
    const NOW = new Date();
    return {
      startDate: NOW,
      endDate: NOW,
      organizationId: organization?.id ?? '1',
      roomId: '',
      studentId: '',
      reportType: ReportTypeEnum.ATTENDANCE_DAILY,
      isEnrolledInFoodProgram: false,
      activityTypes: [ActivityTypeEnum.DROPOFF, ActivityTypeEnum.PICKUP, ActivityTypeEnum.ABSENT],
      kinderSystemsStudents: [],
      ...(filtersParam ?? {}),
    };
  }, [organization?.id, filtersParam]);

  const [filters, setFilters] = useState(defaultFilters);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const options: DropdownOptionType[] = useMemo(
    () => [
      { id: ReportTypeEnum.ATTENDANCE_DAILY, name: t('Daily Check in/Check out') },
      { id: ReportTypeEnum.ATTENDANCE_TOTAL, name: t('Total Attendance') },
    ],
    [t]
  );
  const { foodProgramFlag } = useFlags();

  const onClearLocal = useCallback(() => {
    setFilters({ ...defaultFilters });
    onClear?.();
  }, [defaultFilters, onClear]);

  const filterKinderSystemsStudents = useCallback(() => {
    const kinderSystemsStudentsId = (
      studentsData?.list.filter((student) => student?.kinderSystems?.kinderConnectId) ?? []
    ).map((student) => student.id);
    if (kinderSystemsStudentsId.length) {
      setFilters((prev) => ({ ...prev, kinderSystemsStudents: kinderSystemsStudentsId }));
    }
  }, [studentsData?.list]);

  useEffect(() => {
    const startTime = filters.startDate?.getTime();
    const endTime = filters.endDate?.getTime();
    if (startTime && endTime && startTime > endTime) {
      setFilters((prev) => ({
        ...prev,
        endDate: filters.startDate,
      }));
    }
    if (kinderSystemsReport) {
      filterKinderSystemsStudents();
    }
  }, [filters.endDate, filters.startDate, kinderSystemsReport, filterKinderSystemsStudents]);

  const onChange = useCallback(({ name, value }: WsInputEventType<string | Date | boolean>) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  }, []);

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!filters.startDate || !dayjs(filters.startDate).isValid()) {
      errorsLocal.startDate = t('errors.validation.startDateRequired');
    }
    if (!filters.endDate || !dayjs(filters.endDate).isValid()) {
      errorsLocal.endDate = t('errors.validation.endDateRequired');
    }
    if (!filters.reportType) {
      errorsLocal.reportType = t('errors.validation.reportTypeRequired');
    }
    return errorsLocal;
  }, [t, filters]);

  const onFilterLocal = useCallback(() => {
    const errorsLocal = validate();
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      return;
    }
    onFilter?.(filters);
  }, [filters, onFilter, validate]);

  const filterStudents = useCallback(
    (student: any): boolean => {
      if (filters.reportType !== ReportTypeEnum.FOOD_PROGRAM_ATTENDANCE) return true;
      return !!student; //.foodProgramTier;
    },
    [filters.reportType]
  );

  return (
    <div className="container flex h-full w-full flex-col items-center gap-2">
      <div className="items-top mt-2 flex h-full w-full flex-row gap-5">
        {!kinderSystemsReport && (
          <Combobox
            label={t('Report Type')}
            name="reportType"
            options={options}
            placeholder={t('Select Report')}
            required
            value={filters.reportType}
            onChange={onChange}
            error={errors.reportType}
            data-testid="report-type-select"
          />
        )}
        <RoomDropdown
          name="roomId"
          key={`room-id-${filters.roomId}`}
          value={filters.roomId}
          onChange={onChange}
          data-testid="room-select"
        />
        <StudentDropdown
          name="studentId"
          key={`studend-id-${filters.studentId}`}
          value={filters.studentId}
          onChange={onChange}
          filter={filterStudents}
          data-testid="student-select"
          kinderSystemsStudents={kinderSystemsReport}
        />
      </div>
      <div className="items-top mt-2 flex h-full w-full flex-row gap-5">
        <Input
          name="startDate"
          label={t('Start Date')}
          inputType="date"
          value={convertDateToInputSpecificString(filters.startDate)}
          required
          onChange={(event) => {
            onChange({ name: 'startDate', value: dayjs(event.target.value).utc().toDate() });
          }}
          max={convertDateToInputSpecificString(filters.endDate)}
          data-testid="start-date-select"
          error={errors.startDate}
        />
        <Input
          name="endDate"
          label={t('End Date')}
          inputType="date"
          value={convertDateToInputSpecificString(filters.endDate)}
          required
          onChange={(event) => {
            onChange({ name: 'endDate', value: dayjs(event.target.value).utc().toDate() });
          }}
          min={convertDateToInputSpecificString(filters.startDate)}
          max={convertDateToInputSpecificString(new Date())}
          error={errors.endDate}
          data-testid="end-date-select"
        />
      </div>
      {!!foodProgramFlag && !hideFoodProgramFilter && (
        <div className="items-top mt-2 flex h-full w-full flex-row gap-5">
          <Checkbox
            label={`CACFP ${t('checkInCheckOutLabel')}`}
            id="isEnrolledInFoodProgram"
            name="isEnrolledInFoodProgram"
            checked={filters.isEnrolledInFoodProgram}
            onChange={(event) => {
              onChange({ name: 'isEnrolledInFoodProgram', value: event.target.checked });
            }}
            data-testid="food-program-checkbox"
          />
        </div>
      )}

      <div className="mt-2 flex h-full w-full flex-row items-center justify-start gap-5">
        <Button
          onClick={() => onFilterLocal()}
          primary
          loading={loading}
          disabled={loading}
          data-testid="filter-button"
          label={t('Create Report')}
        />
        <Button
          onClick={() => {
            onClearLocal();
          }}
          loading={loading}
          disabled={loading}
          data-testid="clear-button"
          label={t('Clear All')}
        />
      </div>
    </div>
  );
};

export default AttendanceFiltersForm;
