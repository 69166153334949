import { useEffect, useState } from 'react';
// import moment from 'moment-timezone';
import { logError } from '../../../../rollbar';
import { FinancialSummaryFiltersType, FormattedRow } from './types';
import { fetchTransactionsForFinancialSummary } from './financialSummaryAPI';
import { formatData } from './FinancialSummaryUtils';
import { usePrimaryLocation } from '../../../../hooks/useLocations';
export function useFinancialSummary(filters?: FinancialSummaryFiltersType) {
  const location = usePrimaryLocation();
  const [financialSummary, setFinancialSummary] = useState<FormattedRow[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const doFetch = async () => {
      try {
        if (!filters) {
          setFinancialSummary([]);
          return;
        }
        setLoading(true);
        const transactions = await fetchTransactionsForFinancialSummary(filters);
        const result = formatData(transactions, filters.selectedMonths || [], location?.timezone);
        setFinancialSummary(result);
      } catch (error: any) {
        logError('Error while getting Financial Summary report data: ', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (!isLoading) doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  return { financialSummary, isLoading, error };
}
