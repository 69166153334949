import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Form, Checkbox, Icon } from 'semantic-ui-react';

import { getOrganizationUserProfile, updateOrganizationUser } from '../../../api/firebase/users';
import { showErrorToast } from '../../Shared/showToast';

type FlagsMap = Record<string, boolean>;
type UserPartial = {
  id: string;
  settings?: Record<string, Record<string, any>>;
  defaultOrganization?: string;
};

type UserSettingsFlagProps = {
  organizationId: string;
  userId: string;
};

const flagsDefault = {
  BottomTabsFlag: false,
  FeatureFlagsUIFlag: false,
  InvoicesFlag: false,
  MessagingFlag: false,
  SettingsFlag: false,
  WizardsFlag: false,
};
const UserSettingsFlag = ({ organizationId, userId }: UserSettingsFlagProps) => {
  const { t } = useTranslation();
  const isMountedRef = useRef(false);
  const [orgUser, setOrgUser] = useState<UserPartial | undefined>();
  const [flagChangingKey, setFlagChangingKey] = useState<string | undefined>();

  const fetchOrgUser = useCallback(async () => {
    const userProfile = await getOrganizationUserProfile(organizationId, userId);
    // don't set if unmounted
    if (isMountedRef.current) {
      setOrgUser(userProfile);
    }
  }, [organizationId, userId]);

  // mounted/unmounted
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  // flags are saved on the org user, not root
  useEffect(() => {
    fetchOrgUser();
  }, [fetchOrgUser]);

  const flagsMap: FlagsMap = { ...flagsDefault, ...(orgUser?.settings?.Flags ?? {}) };
  const flagRows = Object.entries(flagsMap).sort((a, b) => a[0].localeCompare(b[0]));

  const onFlagToggle = async (organizationId: string, userId: string, flagKey: string, newValue: boolean) => {
    try {
      // write lock
      setFlagChangingKey(flagKey);
      // update data
      await updateOrganizationUser(organizationId, userId, {
        settings: {
          ...(orgUser?.settings ?? {}),
          Flags: {
            ...flagsMap,
            [flagKey]: newValue,
          },
        },
      });
      // fetch the updated state so we can re-render
      await fetchOrgUser();
    } catch (err) {
      showErrorToast(t('Oops, something went wrong!'), err);
    } finally {
      // unlock the flag
      setFlagChangingKey(undefined);
    }
  };

  if (!orgUser) {
    return <Icon loading name="spinner" size="massive" color="blue" />;
  }

  return (
    <>
      <Form.Field>
        <label>{t('Organization ID')}</label>
        <div>{organizationId}</div>
      </Form.Field>
      <div
        style={{
          marginTop: '1em',
          marginBottom: '2em',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {flagRows.map(([flagKey, enabled]) => {
          return (
            <Form.Radio
              style={{
                flex: '1 1 250px',
                minWidth: '250px',
                marginRight: '25px',
              }}
              key={flagKey}
              toggle
              disabled={flagChangingKey === flagKey}
              name={flagKey}
              label={flagKey}
              checked={enabled}
              onChange={() => onFlagToggle(organizationId, userId, flagKey, !enabled)}
              control={Checkbox}
            />
          );
        })}
      </div>
    </>
  );
};

export default UserSettingsFlag;
