import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Form, Icon, Segment, TextArea } from 'semantic-ui-react';
import { Button } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Timestamp } from 'firebase/firestore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { logError } from '../../../rollbar';

import { sendMail } from '../../../api/firebase/mail';

import { useOrganization } from '../../../hooks/useOrganizations';
import { usePaths, useRoutes, RouteNameEnum } from '../../../navigation';
import { useStudent } from '../../../students/studentsHooks';
import { useDefaultBusinessHours } from '../../enrollmentsHooks';
import { useContacts } from '../../../contacts/contactsHooks';

import {
  DocumentType,
  FeeType,
  InvitationRecipientType,
  ScheduledDayType,
  TuitionAndFeesType,
  TuitionType,
} from '../../types';
import {
  getDocuments,
  getInvitationRecipients,
  getNextInvoiceDate,
  getScheduledDays,
  getTuitionAndFees,
} from '../../enrollmentsUtils';

import PageHeader from '../../../Components/Shared/PageHeader';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';

import {
  DocumentsView,
  EnrollmentInfoView,
  InvitationMessageView,
  InvitationRecipientsView,
  ScheduleView,
  StudentView,
} from '../forms';
import { EnrollmentInvitationConfirmationModal, EnrollmentSentModal } from '../modals';
import { CONTACT_STATUS, INVITATION_TYPES } from '../../../contacts';
import { inviteContact } from '../../../contacts/contactsAPI';
import { newOrganizationInvoice, saveOrganizationInvoicePlan } from '../../../redux/actions/invoiceActions';
import { useUser } from '../../../hooks/useUser';
import EnrollmentInvoicesView from '../forms/EnrollmentInvoicesView';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type EnrollmentsPageProps = {
  onClick?: () => void;
};

const EnrollmentPage: React.FC<EnrollmentsPageProps> = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const organization = useOrganization();
  const loggedInUser = useUser();

  const defaultBusinessHours = useDefaultBusinessHours();
  const student = useStudent(params.studentId);
  const { goBack, gotoRouteByName } = useRoutes();
  const { paths } = usePaths();
  const { enrollmentsV2 } = useFlags();

  const { familyContacts, directorContact } = useContacts();
  const dispatch = useDispatch();

  const invitationRecipients: InvitationRecipientType[] = useMemo(() => getInvitationRecipients(student), [student]);
  const documents: DocumentType[] = useMemo(() => getDocuments(student), [student]);
  const tuitionAndFees: TuitionAndFeesType = useMemo(() => getTuitionAndFees(student), [student]);
  const scheduledDays: ScheduledDayType[] = useMemo(
    () => getScheduledDays(student, defaultBusinessHours),
    [student, defaultBusinessHours]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [extraNotes, setExtraNotes] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [_errors, setErrors] = useState<string[]>([]);

  const sendEnrollmentInvitation = useCallback(
    async (contact: any) => {
      try {
        contact.invitationType = INVITATION_TYPES.enrollment;
        contact.invitationNotes = extraNotes;
        await inviteContact(contact);
      } catch (error: any) {
        logError('Error saving contact', contact, error);
        setErrors((prev) => [...prev, error.message]);
      }
    },
    [extraNotes]
  );

  const sendLoginEmail = useCallback(
    async (contact: any) => {
      if (!contact?.email) return;

      try {
        const mailData = {
          to: contact.email,
          template: {
            name: 'enrollment-parent-share-docs',
            data: {
              subject: "Please login to view your child's documents",
              parentName: contact.firstName,
              directorName: directorContact?.displayName,
              organizationName: organization.name,
              documentsLink: `${window.location.origin}${paths.documents}`, // paths.documents starts with a `/`
              notes: extraNotes || '-',
            },
          },
        };
        return sendMail(mailData);
      } catch (error: any) {
        logError('Error saving contact', contact, error);
        setErrors((prev) => [...prev, error.message]);
      }
    },
    [directorContact?.displayName, extraNotes, organization.name, paths.documents]
  );

  // iterate throught the recipients and set state in the contact to resend invitations, but using the
  // enrollment email template. If the contact is verified, instead of sending an invitation, we will
  // send an email to login and view their documents
  const sendEnrollmentInvitations = useCallback(async () => {
    for (const recipient of invitationRecipients) {
      const contact = familyContacts.find((c: any) => c.id === recipient.contactId);
      // If the contact is new or invited, we can re-send the invitation, but with an invitation type of enrollment
      // and with the extra notes
      if (contact?.status === CONTACT_STATUS.new || contact?.status === CONTACT_STATUS.invited) {
        await sendEnrollmentInvitation(contact);
      } else if (contact?.status === CONTACT_STATUS.verified) {
        await sendLoginEmail(contact);
      }
      setIsModalOpen(true);
    }
  }, [familyContacts, invitationRecipients, sendEnrollmentInvitation, sendLoginEmail]);

  const formatDate = (date: Timestamp | Date | undefined) => {
    if (!date) return null;
    let currentDate;
    if (date instanceof Timestamp) {
      currentDate = dayjs.unix(date.seconds).utc().format('YYYY-MM-DD');
    } else {
      currentDate = dayjs(date).utc().format('YYYY-MM-DD');
    }
    const d = currentDate.split('-');
    const convertedLocal = dayjs(`${d[0]}-${d[1]}-${d[2]}`, 'YYYY-MM-DD');
    return convertedLocal.valueOf();
  };

  const formatInvoiceItemList = (fee: FeeType) => {
    return {
      category: fee.name,
      item: fee.name,
      notes: '',
      amount: fee.amount,
      discounts: [],
    };
  };

  const formatInvoiceItems = (fee: FeeType) => {
    return {
      amount: fee.amount,
      currency: 'usd',
      description: fee.name,
    };
  };

  const formatPlanItemsList = (tuition: TuitionType) => {
    return {
      amount: tuition.amount,
      item: `Tuition - ${student?.enrollment?.scheduledDays?.length} days`,
      notes: '',
      discounts: [],
      category: 'Tuition',
    };
  };

  const convertDataToInvoice = (fee) => {
    return {
      details: {
        dateDue: formatDate(fee.dueDate),
        dateServiceStart: null,
        dateServiceEnd: null,
        invoiceItemList: [formatInvoiceItemList(fee)],
        total: fee.amount,
        isInvoice: true,
        alreadyPaid: false,
        manuallyPaid: false,
        paidAmount: 0,
        status: 'draft',
        createdBy:
          loggedInUser.uid && loggedInUser.displayName
            ? { id: loggedInUser.uid, displayName: loggedInUser.displayName }
            : {},
      },
      items: [formatInvoiceItems(fee)],
      students: [student],
    };
  };

  const converDataToPlan = (tuition) => {
    const nextInvoiceDate = getNextInvoiceDate(tuition.dueDate, tuition.billingInterval, tuition.proratedAmount);
    return {
      isInvoice: false,
      createdBy:
        loggedInUser.uid && loggedInUser.displayName
          ? { id: loggedInUser.uid, displayName: loggedInUser.displayName }
          : {},
      notificationDays: 1,
      billingInterval: tuition.billingInterval,
      dateStart: formatDate(tuition.dueDate),
      dateEnd: null,
      dateDue: formatDate(nextInvoiceDate),
      dateSend: formatDate(Timestamp.now()),
      status: 'active',
      total: tuition.amount,
      invoiceItemList: [formatPlanItemsList(tuition)],
      students: [student],
    };
  };

  const convertDataToProrateInvoice = (amount, dueDate) => {
    if (!dueDate) return {};
    return {
      details: {
        dateDue: formatDate(dueDate),
        dateServiceStart: null,
        dateServiceEnd: null,
        invoiceItemList: [
          {
            amount: amount,
            item: 'Prorated tuition',
            notes: '',
            discounts: [],
            category: 'Fee',
          },
        ],
        total: amount,
        isInvoice: true,
        alreadyPaid: false,
        manuallyPaid: false,
        paidAmount: 0,
        status: 'draft',
        createdBy:
          loggedInUser.uid && loggedInUser.displayName
            ? { id: loggedInUser.uid, displayName: loggedInUser.displayName }
            : {},
      },
      items: [
        {
          amount: amount,
          currency: 'usd',
          description: 'Prorate',
        },
      ],
      students: [student],
    };
  };

  const sendInvoices = async () => {
    if (Object.keys(tuitionAndFees).length > 0 && Object.keys(tuitionAndFees.tuition).length > 0) {
      const planPayload = converDataToPlan(tuitionAndFees.tuition);
      await dispatch(saveOrganizationInvoicePlan(organization.id, planPayload));

      if (tuitionAndFees.tuition.proratedAmount) {
        const proratePayload = convertDataToProrateInvoice(
          tuitionAndFees.tuition.proratedAmount,
          tuitionAndFees.tuition.dueDate
        );
        await dispatch(newOrganizationInvoice(organization.id, proratePayload));
      }
    }

    if (Object.keys(tuitionAndFees).length > 0 && tuitionAndFees.fees.length > 0) {
      for (const fee of tuitionAndFees.fees) {
        const invoicePayload = convertDataToInvoice(fee);
        await dispatch(newOrganizationInvoice(organization.id, invoicePayload));
      }
    }
  };

  const onSubmit = async () => {
    setIsSaving(true);
    if (enrollmentsV2) {
      await sendInvoices();
    }
    await sendEnrollmentInvitations();
  };

  if (!student) return <LoadingComponent />;

  return (
    <>
      <Segment loading={isSaving} basic clearing>
        <PageHeader pageName={'Enrollment'} classes="enrollments-page" />
        <h1 className="text-3xl" data-testid="dig-enroll-invitation">
          {t('enrollments.pageTitleSummary')}
        </h1>
        <Segment>
          <StudentView student={student} />
        </Segment>
        <Segment>
          <InvitationRecipientsView invitationRecipients={invitationRecipients} />
        </Segment>
        {!enrollmentsV2 && (
          <Segment>
            <EnrollmentInfoView tuitionAndFees={tuitionAndFees} student={student} />
          </Segment>
        )}
        <Segment>
          <DocumentsView documents={documents} />
        </Segment>
        <Segment>
          <ScheduleView scheduledDays={scheduledDays} />
        </Segment>
        {enrollmentsV2 && Object.keys(tuitionAndFees).length > 0 && (
          <Segment>
            <EnrollmentInvoicesView tuition={tuitionAndFees.tuition} fees={tuitionAndFees.fees} />
          </Segment>
        )}
        <Segment>
          <InvitationMessageView />
          {renderExtraNotesForm()}
        </Segment>
        <CommandButtons />
      </Segment>
      {!enrollmentsV2 && (
        <EnrollmentSentModal
          student={student}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            gotoRouteByName(RouteNameEnum.STUDENTS);
          }}
        />
      )}
      {enrollmentsV2 && (
        <EnrollmentInvitationConfirmationModal
          student={student}
          invitationRecipients={invitationRecipients}
          isOpen={isModalOpen}
          onClose={() => gotoRouteByName(RouteNameEnum.STUDENTS)}
        />
      )}
    </>
  );

  function CommandButtons() {
    return (
      <div className="ws-form-buttons" data-testid="es-ws-form-buttons">
        <Button
          primary
          loading={isSaving}
          onClick={() => {
            gotoRouteByName(RouteNameEnum.HOME);
          }}
          data-testid="es-cancel-btn"
        >
          <Icon name="cancel" />
          {t('common.cancel')}
        </Button>
        <div className="flex gap-4">
          <Button onClick={goBack} data-testid="es-goback-btn">
            <Icon name="arrow alternate circle left outline" data-testid="es-prev-icon" />
            {t('common.previous')}
          </Button>
          <Button primary loading={isSaving} onClick={onSubmit} data-testid="es-send-invite">
            {t('enrollments.enrollmentInfoSendEnrollmentButton')}
            <Icon name="arrow alternate circle right outline" data-testid="es-icon-info-send-btn" />
          </Button>
        </div>
      </div>
    );
  }

  function renderExtraNotesForm() {
    return (
      <Form>
        <p data-testid="extra-notes">
          <strong>{t('enrollments.enrollmentInfoExtraNotesLabel')}</strong>
        </p>
        <TextArea
          rows={5}
          value={extraNotes}
          onChange={(e, { value }) => {
            if (value) setExtraNotes(value as string);
          }}
          data-testid="extra-notes-textarea"
        />
      </Form>
    );
  }
};

export default EnrollmentPage;
