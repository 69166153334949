import React, { useEffect, useMemo, useState } from 'react';

import {
  FileCategory,
  FileDocument,
  MalwareStatus,
  useDownloadFiles,
  useGetFiles,
} from '@wonderschool/file-service-client';

import { Button } from '@wonderschool/common-base-ui';
import BootstrapTable, { SelectRowProps, SortOrder } from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Header, Icon, Message } from 'semantic-ui-react';

// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { capitalize } from 'lodash';
import { logError } from '../../rollbar';
import { renderDate } from '../../utils/date';
import { humanizeBytes } from '../../utils/file';

import { QueryCondition } from '@wonderschool/file-service-client/dist/file/types';
import { useUser } from '../../hooks/useUser';
import { WsDelete } from '../../icons';
import { documentTestIds } from '../dictionary';
import { TableChangeType } from '../types';
import DeleteFileModal from './modals/DeleteFileModal';
import SharedWithModal from './modals/SharedWithModal';

type FileDocumentFieldValue = FileDocument[keyof FileDocument];

interface IOrderBy {
  field: string;
  direction: SortOrder;
}

interface IDefaultFilter {
  limit: 10;
  conditions?: QueryCondition[];
  orderBy: IOrderBy[];
}

const DEFAULT_FILTER: IDefaultFilter = {
  limit: 10,
  conditions: [
    {
      field: 'fileCategory',
      operator: '==',
      value: FileCategory.FORM,
    },
  ],
  orderBy: [
    {
      field: 'createdAt',
      direction: 'desc',
    },
  ],
};

interface IStaffDocumentsTable {
  hideBanner?: boolean;
  onRowSelect: (selectedDocuments: FileDocument[]) => void;
  selectedDocumentIds?: string[];
}

const StaffDocumentsTable: React.FC<IStaffDocumentsTable> = ({
  onRowSelect,
  hideBanner = false,
  selectedDocumentIds = [],
}) => {
  const { t } = useTranslation();
  const { isOrganizationAdmin, isLocationAdmin } = useUser();
  const [filters, setFilters] = useState<IDefaultFilter>(DEFAULT_FILTER);
  const [selectedRows, setSelectedRows] = useState<FileDocument[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileDocument | null>(null);
  const [fileToDelete, setFileToDelete] = useState<FileDocument | null>(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorContent, setErrorContent] = useState({
    header: '',
    message: '',
  });

  const {
    data,
    isLoading,
    isError: isGetFilesError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetFiles(filters);

  const { downloadFiles, error: downloadError } = useDownloadFiles();

  useEffect(() => {
    if (!isGetFilesError && !downloadError) return;

    setErrorOccurred(true);

    logError('Documents Error: ', {
      errorType: isGetFilesError ? 'fetch' : 'download',
      message: error?.message || downloadError?.message || '',
    });

    if (isGetFilesError) {
      setErrorContent({
        header: t('Error occurred while fetching documents'),
        message: error?.message || '',
      });
    } else if (downloadError) {
      setErrorContent({
        header: t('Error occurred while downloading documents'),
        message: downloadError?.message || '',
      });
    }
  }, [isGetFilesError, downloadError, error, setErrorOccurred, t]);

  const getRowClasses = (row: FileDocument) => {
    if (row.fileStatus !== MalwareStatus.CLEAN) return 'disabled';
    return '';
  };

  const handleTableChange = (type: string, { sortField, sortOrder }: TableChangeType) => {
    // FIXME:  **Sort**, react-bootstrap-table2 does not support sorting by multiple fields
    // Therefore we replace the current orderBy with the new one.
    // https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/basic-sort.html
    if (type === 'sort') {
      const field = sortField === 'file.size' ? 'sort.size' : `sort.${sortField}`;

      setFilters((prev) => ({
        ...prev,
        orderBy: [{ field, direction: sortOrder }],
      }));
    }
  };

  const tableData: FileDocument[] = useMemo(() => {
    return data?.pages?.flatMap((page: any) => page.documents) ?? [];
  }, [data]);

  useEffect(() => {
    if (tableData.length && selectedDocumentIds.length && !selectedRows.length) {
      setSelectedRows(tableData.filter((doc) => selectedDocumentIds.includes(doc.id)));
    }
  }, [selectedDocumentIds, isLoading, tableData, selectedRows]);

  const columns = useMemo(
    () => [
      {
        dataField: 'sharedWith',
        text: '',
        headerStyle: {
          width: '30px',
        },
        align: 'center',
        formatter: (_cell: FileDocumentFieldValue, row: FileDocument) => {
          return (
            <Icon
              onClick={(e: any) => {
                e.stopPropagation();
                if (row.fileStatus !== MalwareStatus.CLEAN) {
                  return null;
                }
                setSelectedFile(row);
              }}
              color="grey"
              name="ellipsis vertical"
              data-testid="docs-sharewith-icon"
            />
          );
        },
      },
      {
        dataField: 'name',
        text: t('common.name'),
        sort: true,
        formatter: (cell: FileDocumentFieldValue, row: FileDocument) => {
          const fileStatus = row.fileStatus;
          if (fileStatus !== MalwareStatus.CLEAN) return cell as string;

          return (
            <a
              href={`#${row.id}`}
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                downloadFiles([row.id]);
              }}
              data-testid="docs-name"
            >
              {cell as string}
            </a>
          );
        },
      },
      {
        dataField: 'updatedAt',
        text: t('Updated'),
        sort: true,
        formatter: (_cell: any, row: FileDocument) => {
          const fileStatus = row.fileStatus;
          const date = renderDate(row.updatedAt, 'MM/DD/YYYY');
          const updatedBy = row.isAdmin ? t('Wonderschool') : row.updatedBy?.displayName;

          return (
            <Header as="h5">
              <Header.Content>
                {/*  eslint-disable-next-line i18next/no-literal-string */}
                {date} by {updatedBy}
                {/*  eslint-disable-next-line i18next/no-literal-string */}
                <Header.Subheader data-testid="file-status">Status: {capitalize(fileStatus)}</Header.Subheader>
              </Header.Content>
            </Header>
          );
        },
      },
      {
        dataField: 'file.size',
        text: t('Size'),
        sort: true,
        formatter: (cell: number) => humanizeBytes(cell, 2),
      },
      {
        dataField: 'fileAction',
        text: t('File Type'),
        formatter: (cell: string) => {
          return capitalize(t(cell));
        },
      },
      {
        dataField: 'id',
        text: '',
        formatter: (_cell: string, row: FileDocument) => {
          if (!row.isAdmin && (isOrganizationAdmin || isLocationAdmin)) {
            return (
              <div className="action-btn-column">
                <Button
                  extraClasses={'rounded-full p-0 bg-red-900 hover:bg-red-950 h-8 !min-w-8 m-0'}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setFileToDelete(row);
                  }}
                  data-testid="docs-table-row-delete-btn"
                >
                  <WsDelete className={'font-bold text-white'} />
                </Button>
              </div>
            );
          }
          return <div></div>;
        },
      },
    ],
    [downloadFiles, t, isLocationAdmin, isOrganizationAdmin]
  );

  const selectRow: SelectRowProps<FileDocument> = useMemo(
    () => ({
      mode: 'checkbox',
      style: {},
      clickToSelect: true,
      selectionHeaderRenderer: ({ mode, rowKey, rowIndex, ...rest }: any) => (
        <Checkbox {...rest} data-testid="docs-selectionheader" />
      ),
      selectionRenderer: ({ mode, rowKey, rowIndex, ...rest }: any) => (
        <Checkbox {...rest} data-testid="docs-selectionrender" />
      ),
      onSelect: (row: FileDocument, isSelected: boolean) => {
        const selected = isSelected ? [...selectedRows, row] : selectedRows.filter((c) => c.id !== row.id);

        setSelectedRows(selected);
        onRowSelect?.(selected);
      },
      onSelectAll: (isSelected: boolean, rows: FileDocument[]) => {
        const selected = isSelected ? rows : [];
        setSelectedRows(selected);
        onRowSelect?.(selected);
      },
      nonSelectable: tableData?.reduce(
        (prev: any, curr: any) => (curr.fileStatus !== MalwareStatus.CLEAN ? [curr.id, ...prev] : prev),
        [] as string[]
        // required hack to handle an apparent bug in the library typings
        // expected return type is number[], but it refers to keyField which is a string[]
      ) as unknown as number[],
    }),
    [tableData, selectedRows, onRowSelect]
  );

  return (
    <>
      {errorOccurred && !hideBanner && (
        <Message negative icon="cancel" header={errorContent.header} content={errorContent.message} />
      )}

      {/* modals */}
      <SharedWithModal isOpen={!!selectedFile} selectedFile={selectedFile} closeModal={() => setSelectedFile(null)} />
      <DeleteFileModal
        isModalOpen={!!fileToDelete}
        selectedDocument={fileToDelete}
        closeModal={() => setFileToDelete(null)}
      />

      <ToolkitProvider bootstrap4 keyField="id" data={tableData} columns={columns}>
        {({ baseProps }) => (
          <div className="bootstrap-iso">
            <BootstrapTable
              remote
              hover
              classes="documents-table"
              bordered={false}
              selectRow={selectRow}
              headerClasses="table-header"
              rowClasses={getRowClasses}
              noDataIndication={
                <>
                  {isLoading ? (
                    <div>{t('Loading data')}...</div>
                  ) : (
                    <div data-testid="no-forms-or-docs">{t('No forms/documents found')}</div>
                  )}
                </>
              }
              defaultSorted={
                filters?.orderBy?.map((o) => ({
                  dataField: o.field,
                  order: o.direction,
                })) as [{ dataField: string; order: SortOrder }]
              }
              onTableChange={handleTableChange}
              {...baseProps}
              data-testid={documentTestIds.docsTable}
            />
          </div>
        )}
      </ToolkitProvider>
      {hasNextPage && (
        <Button
          disabled={isFetchingNextPage}
          label={t('Load more')}
          primary
          onClick={() => fetchNextPage()}
          data-testid="load-more"
        />
      )}
    </>
  );
};

export default StaffDocumentsTable;
