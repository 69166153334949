export const TransactionType = {
  EXPENSE: 'Expense',
  REVENUE: 'Revenue',
  ANY: 'Any',
};
export function isRevenueTransactionType(transactionType) {
  return transactionType === TransactionType.REVENUE;
}
export function isExpenseTransactionType(transactionType) {
  return transactionType === TransactionType.EXPENSE;
}
export function isAnyTransactionType(transactionType) {
  return !transactionType || transactionType === TransactionType.ANY;
}
