import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../helpers/utils';

import { ViewComponentProps } from '../../../common';
import { ScheduledDayType } from '../../types';
import { SummaryViewTitle } from '../widgets';
import { formatHourForDisplay } from '../../enrollmentsUtils';

type ScheduleViewProps = ViewComponentProps & {
  scheduledDays: ScheduledDayType[];
};

const ScheduleView: React.FC<ScheduleViewProps> = ({ title, scheduledDays, hasTitle = true }) => {
  const { t } = useTranslation();
  const viewTitle = hasTitle ? t(title || 'enrollments.scheduleFormTitle') : '';

  return (
    <div className="schedule-form" data-testid="schedule-view">
      <SummaryViewTitle title={viewTitle} />
      <div className="schedule-items ws-view-form">
        {scheduledDays.map((scheduledDay) => {
          const startTime = scheduledDay.startTime ? formatHourForDisplay(scheduledDay.startTime) : 'N/A';
          const endTime = scheduledDay.endTime ? formatHourForDisplay(scheduledDay.endTime) : 'N/A';
          return (
            <div key={scheduledDay.day} className="row">
              <label>{t(capitalize(scheduledDay.day))}</label>
              <div className="value">{`${startTime} - ${endTime}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ScheduleView;
