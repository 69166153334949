import { connect } from 'react-redux';

// Import utils.
import UserProfileForm from '../Forms/UserProfileForm';

const Profile = () => {
  return (
    <div className="p-4 bg-white border border-gray-300 rounded-lg">
      <div className="grid grid-cols-1 items-center">
        <div className="col-span-1">
          <UserProfileForm />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Profile);
