import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal } from 'semantic-ui-react';

import ProviderFigure from '../Shared/assets/ProviderFigure';

import { useOrganization } from '../../hooks/useOrganizations';

import { dismissSetupPayoutModalFlag, showSetupPayoutModal } from '../../helpers/stripe';
import { getCreatePayoutAccountUrl } from '../../api/firebase/organizations';

import './SetupPayoutModal.styles.css';

export default function SetupPayoutModal() {
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
  const [isSetupRequestInProgress, setIsSetupRequestInProgress] = React.useState(false);

  const { t } = useTranslation();
  const currentOrganization = useOrganization();

  const shouldShowModal = showSetupPayoutModal(currentOrganization);
  if (!shouldShowModal && !isSetupRequestInProgress) return null;

  const handleSetupClick = async () => {
    setIsSetupRequestInProgress(true);
    try {
      await dismissSetupPayoutModalFlag(currentOrganization.id);
      const setupPayoutUri = await getCreatePayoutAccountUrl(currentOrganization.id);
      window.location.href = setupPayoutUri;
    } catch (error) {
      console.error(error);
    }
  };
  const handleDismissClick = async () => {
    setIsRequestInProgress(true);
    try {
      await dismissSetupPayoutModalFlag(currentOrganization.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsRequestInProgress(false);
    }
  };
  return (
    <Modal
      open={shouldShowModal || isSetupRequestInProgress}
      onClose={() => handleDismissClick()}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Content>
        <div className="setup-payout-modal-content">
          <ProviderFigure />
          <Modal.Description>
            <div className="setup-payout-modal-description">
              <h2>{t('setupPayoutModal.title')}</h2>
              <p>{t('setupPayoutModal.subTitle')}</p>
            </div>
          </Modal.Description>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          loading={isRequestInProgress}
          disabled={isRequestInProgress || isSetupRequestInProgress}
          onClick={() => handleDismissClick()}
        >
          {t('setupPayoutModal.dismissButton')}
        </Button>
        <Button
          primary
          loading={isSetupRequestInProgress}
          disabled={isRequestInProgress || isSetupRequestInProgress}
          onClick={() => handleSetupClick()}
        >
          {t('setupPayoutModal.setupButton')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
