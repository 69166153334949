import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContacts } from '../../contacts';
import { useUser } from '../../hooks/useUser';

import { DatepickerDateType, RoomDropdown, StaffDropdown, WsInputEventType } from '../../common';

import { Button, Input } from '@wonderschool/common-base-ui';
import { ReportTypeEnum } from '../enums';
import { TimesheetFiltersType } from '../types';
import { ReportTypeDropdown } from './widgets';
import { convertDateToInputSpecificString } from './utils/convertDateToInputSpecificString';

type TimesheetFiltersFormProps = {
  onFilter?: (timesheetFilters: TimesheetFiltersType) => void;
  onClear?: () => void;
  loading?: boolean;
};

function createEmptyFilters(): TimesheetFiltersType {
  return {
    startDate: convertDateToInputSpecificString(new Date()),
    endDate: convertDateToInputSpecificString(new Date()),
    reportType: ReportTypeEnum.DAILY_HOURS,
    roomId: '',
    contactId: '',
    uid: '',
  };
}

const TimesheetFiltersForm: React.FC<TimesheetFiltersFormProps> = ({ onFilter, onClear, loading = false }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { staffContacts } = useContacts();

  const [filters, setFilters] = useState<TimesheetFiltersType>(createEmptyFilters());
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    // org admins can view reports for all staff. Other staff types can only view their own reports,
    // so we set the contactId and the uid so that only their reports are returned.
    if (user?.isStaffOrganizationAdmin) return;
    setFilters((prev) => ({ ...prev, contactId: user?.contactId ?? '', uid: user?.uid ?? '' }));
  }, [user?.contactId, user?.isStaffOrganizationAdmin, user?.uid]);

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!filters.startDate) {
      errorsLocal.startDate = t('errors.validation.startDateRequired');
    }
    if (!filters.endDate) {
      errorsLocal.endDate = t('errors.validation.endDateRequired');
    }
    if (!filters.reportType) {
      errorsLocal.reportType = t('errors.validation.reportTypeRequired');
    }
    return errorsLocal;
  }, [filters.endDate, filters.reportType, filters.startDate, t]);

  const onFilterLocal = useCallback(() => {
    const errorsLocal = validate();
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      return;
    }
    onFilter?.(filters);
  }, [filters, onFilter, validate]);

  type OnChangeType = DatepickerDateType | ReportTypeEnum | string;
  const onChange = useCallback(({ name, value }: WsInputEventType<OnChangeType>) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  }, []);

  const onChangeStaff = useCallback(
    ({ name, value }: WsInputEventType<string>) => {
      const staffContact = staffContacts?.find((staff: any) => staff.id === value);
      setFilters((prev) => ({ ...prev, [name]: value, ['uid']: staffContact?.uid ?? '' }));
      setErrors((prev) => ({ ...prev, [name]: '' }));
    },
    [staffContacts]
  );

  return (
    <div className="container flex h-full w-full flex-col items-center gap-2">
      <div className="items-top mt-2 block h-full w-full flex-row gap-5 space-y-4 sm:flex sm:space-y-0">
        <ReportTypeDropdown
          name="reportType"
          value={filters.reportType}
          onChange={onChange}
          error={errors.reportType}
          data-testid="timesheet-filter-report-type"
        />
        <RoomDropdown
          name="roomId"
          key={`room-${filters.roomId}`}
          value={filters.roomId || ''}
          onChange={onChange}
          data-testid="timesheet-filter-room"
        />
        {!!user?.isStaffOrganizationAdmin && (
          <StaffDropdown
            name="contactId"
            key={`staff-${filters.roomId}`}
            value={filters.contactId}
            onChange={onChangeStaff}
            data-testid="timesheet-filter-staff"
          />
        )}
      </div>
      <div className="items-top mt-2 flex h-full w-full flex-row gap-5">
        <Input
          name="startDate"
          label={t('Start Date')}
          inputType="date"
          onChange={(event) => {
            onChange({ name: 'startDate', value: event.target.value });
          }}
          value={filters.startDate}
          max={filters.endDate}
          required
          placeholder="Select Date"
          error={errors.startDate}
          data-testid="timesheet-filter-start-date"
        />
        <Input
          name="endDate"
          label={t('End Date')}
          inputType="date"
          onChange={(event) => {
            onChange({ name: 'endDate', value: event.target.value });
          }}
          value={filters.endDate}
          min={filters.startDate}
          max={convertDateToInputSpecificString(new Date())}
          required
          placeholder="Select Date"
          error={errors.endDate}
          data-testid="timesheet-filter-end-date"
        />
      </div>
      <div className="mt-2 flex h-full w-full flex-row items-center justify-start gap-5">
        <Button
          primary
          onClick={onFilterLocal}
          loading={loading}
          data-testid="timesheet-filter-create-report"
          label={t('Create Report')}
        />
        <Button
          onClick={() => {
            setFilters(createEmptyFilters());
            onClear?.();
          }}
          data-testid="timesheet-filter-clear"
          label={t('Clear All')}
        />
      </div>
    </div>
  );
};

export default TimesheetFiltersForm;
