import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selectRoomsList = createSelector([(state) => state.rooms], (rooms) => rooms?.list ?? []);

/**
 * Grabs rooms data from redux store.
 * @returns {any[]}
 */
export function useRooms() {
  return useSelector(selectRoomsList);
}

export default useRooms;
