// import types.
import {
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  USER_PROFILE_FETCHED,
  USER_SIGNED_UP,
  USER_SAVE_SIGNUP_DATA,
  USER_PROFILE_UPDATED,
  ORGANIZATION_PROVISIONING_STARTED,
  ORGANIZATION_PROVISIONING_COMPLETE,
  ORGANIZATION_SELECTED,
  ORGANIZATION_MISSING,
  USER_PROFILE_LISTENER_STARTED,
  USER_PIN_UPDATED,
} from '../actions/types';

// Import API
import {
  getUserProfile,
  userOnListen,
  updateOrganizationUser,
  updateOrganizationUserPin,
} from '../../api/firebase/users';

import { startProvisioning } from '../../api/firebase/account';
import { assignPin } from '../../api/firebase/tabletPins';

export const userSignedIn = ({ uid, email, displayName, phoneNumber, photoURL }, claims) => ({
  type: USER_SIGNED_IN,
  user: {
    uid,
    email,
    displayName,
    phoneNumber,
    photoURL,
    claims,
  },
});
export const userSignedOut = () => ({
  type: USER_SIGNED_OUT,
});

export const userSignedUp = () => ({
  type: USER_SIGNED_UP,
});

export const userSaveSignUpData = (signupData) => ({
  type: USER_SAVE_SIGNUP_DATA,
  signupData,
});

export const userProfileFetched = (user) => ({
  type: USER_PROFILE_FETCHED,
  user,
});

export const userProfileUpdated = (user) => ({
  type: USER_PROFILE_UPDATED,
  user,
});

export const organizationProvisioningStarted = () => ({
  type: ORGANIZATION_PROVISIONING_STARTED,
});

export const organizationProvisioningComplete = () => ({
  type: ORGANIZATION_PROVISIONING_COMPLETE,
});

export const userNeedsSetupOrg = () => ({
  type: ORGANIZATION_MISSING,
});

export const userOrganizationSelected = (organization) => ({
  type: ORGANIZATION_SELECTED,
  organization,
});

export const userProfileListenerStarted = (uid) => ({
  type: USER_PROFILE_LISTENER_STARTED,
  uid,
});

export const userPinUpdated = () => ({
  type: USER_PIN_UPDATED,
});

export const completeUserSignup =
  (userData, doCreateOrg = false) =>
  async (dispatch) => {
    // Only during first organization user signup
    if (doCreateOrg) {
      if (!(await dispatch(provisionOrganizationProfile(userData)))) {
        throw new Error('Failed to complete user signup');
      }
    }

    return dispatch(userSignedUp());
  };

export const startUserProfileListener = (uid) => (dispatch) => {
  dispatch(userProfileListenerStarted());
  return userOnListen(
    uid,
    (user) => {
      if (user) {
        dispatch(userProfileFetched(user));
        if (!user?.defaultOrganization) {
          dispatch(userNeedsSetupOrg());
        } else {
          dispatch(
            userOrganizationSelected({
              id: user?.impersonatedOrganization || user.defaultOrganization,
            })
          );
        }
      }
    },
    (error) => console.log(error.message)
  );
};

export const fetchUserProfile = (uid) => async (dispatch) => {
  try {
    const userData = await getUserProfile(uid);
    if (userData) return dispatch(userProfileFetched(userData));

    return null;
  } catch (error) {
    console.log(error);
  }
};

export const provisionOrganizationProfile = (userData) => async (dispatch) => {
  try {
    dispatch(organizationProvisioningStarted());
    const createdUser = await startProvisioning(userData);
    if (createdUser) {
      dispatch(userProfileUpdated(createdUser));
      dispatch(organizationProvisioningComplete());
    }
    return createdUser;
  } catch (error) {
    console.log('provisionOrganizationProfile error', error);
    return false;
  }
};

export const updateUser = (organizationId, userId, data) => async (_dispatch) => {
  try {
    return updateOrganizationUser(organizationId, userId, data);
  } catch (error) {
    throw new Error(error.message || 'Unable to update user');
  }
};

export const changeUserPin = (organizationId, userId, type) => async (dispatch) => {
  try {
    const pin = await assignPin(userId, organizationId, type);
    await updateOrganizationUserPin(organizationId, userId, type, pin);
    dispatch(userPinUpdated());
    return pin;
  } catch (error) {
    console.log(error);
  }
};
