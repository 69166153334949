// eslint-disable-next-line no-restricted-imports
import { Container, Segment } from 'semantic-ui-react';

// Import component.
import StudentsImporter from '../Components/Upload/StudentsImporter';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import PageHeader from '../Components/Shared/PageHeader';

const Importer = () => {
  useSegmentPage(PAGE_NAMES.importer);

  return (
    <Container className="no-padding no-margin">
      <PageHeader pageName={'Import your students list'} classes="importer" />

      <Segment basic>
        <StudentsImporter />
      </Segment>
    </Container>
  );
};

export default Importer;
