import { firestore } from './firebase';

import { logError } from '../../rollbar';

export const assignPin = async (uid, organizationId, type) => {
  if (!uid && !organizationId) return 'uid and organizationId are required';

  try {
    const querySnapshot = await firestore()
      .collection(`organizations/${organizationId}/tabletPins`)
      .where('active', '==', false)
      .limit(1)
      .get();

    const pinObject = querySnapshot.docs.map((doc) => ({
      doc,
      data: doc.data(),
    }))[0];

    if (pinObject) {
      const { doc } = pinObject;

      // Recycle previously used pins if any.
      await recycleTabletPins(uid, organizationId, type);

      // Add new assign new pin
      doc.ref.set({ uid, active: true, type }, { merge: true });
      return pinObject.data ? pinObject.data.pin || '' : '';
    }
    return '';
  } catch (error) {
    logError(`Could not assign a PIN: ${error.message}`, error, {
      organizationId,
      uid,
      type,
    });
    throw new Error(error.message || 'Unable to assign pin');
  }
};

const recycleTabletPins = async (uid, organizationId, type) => {
  if (!uid && !organizationId) return 'uid and organizationId are required';

  const querySnapshot = await firestore()
    .collection(`organizations/${organizationId}/tabletPins`)
    .where('active', '==', true)
    .where('uid', '==', uid)
    .where('type', '==', type)
    .get();

  return Promise.allSettled(
    querySnapshot.docs.map((doc) => doc.ref.set({ active: false, uid: '', recycled: true, type: '' }, { merge: true }))
  );
};
