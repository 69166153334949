import { useTranslation } from 'react-i18next';
import NoDataIcon from './assets/NoDataIcon';

const NoResults = ({ title, subTitle }) => {
  const { t } = useTranslation();
  const noDataContainer = {
    marginTop: '5em',
  };

  return (
    <div style={noDataContainer}>
      <NoDataIcon />
      <div className="no-data-text-wrapper">
        {title ? <h2 className="no-data-message-title">{t(title)}</h2> : null}
        {subTitle ? <p className="no-data-message-subtitle">{t(subTitle)}</p> : null}
      </div>
    </div>
  );
};

export default NoResults;
