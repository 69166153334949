import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Divider, Form } from 'semantic-ui-react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import { FormComponentProps, GenderEnum, OnSaveCallbackParamsType } from '../../common';
import { dateFormatter, formatISOStringAsUtcDateOrNull } from '../../helpers/dates';

import { canSendInvitation, useContacts } from '../../contacts';
import { useInterestedFamilies } from '../../integrations/marketplace/marketplaceHooks';
import { InterestedFamilyType } from '../../integrations/marketplace/types';

import { DEFAULT_PARENT } from '../consts';
import { ParentType, StudentAndParentsType, StudentType } from '../types';
import InterestedFamiliesForm from './InterestedFamiliesForm';
import ParentsForm from './ParentsForm';
import StudentEditForm from './StudentEditForm';

type StudentAndParentsFormProps = FormComponentProps<StudentAndParentsType> & {
  studentAndParents?: StudentAndParentsType;
  marketplaceChildId?: number;
  onInterestedFamilySelected?: (interestedFamily: InterestedFamilyType | undefined) => void;
};
type OnSaveParamsType = OnSaveCallbackParamsType<StudentType | ParentType[] | InterestedFamilyType>;

function StudentAndParentsForm({
  title,
  hasTitle = true,
  isSaving,
  onSave,
  onInterestedFamilySelected,
  studentAndParents: studentAndParentsParam,
  marketplaceChildId,
}: StudentAndParentsFormProps) {
  const { t } = useTranslation();
  const { familyContacts } = useContacts(); // get all existing org/contacts
  const interestedFamilies = useInterestedFamilies();

  const [newOrInterested, setNewOrInterested] = useState<string>('new');
  const [wasInterestedFamilySelected, setWasInterestedFamilySelected] = useState<boolean>(false);
  const [studentAndParents, setStudentAndParents] = useState<StudentAndParentsType>(
    studentAndParentsParam ?? ({ student: {}, parents: [{ ...DEFAULT_PARENT }] } as StudentAndParentsType)
  );
  const [dataToSave, setDataToSave] = useState<StudentAndParentsType>({} as StudentAndParentsType);

  const viewTitle = hasTitle ? t(title || 'students.studentInformationFormTitle') : '';

  if (!isSaving && !isEmpty(dataToSave)) {
    setDataToSave({} as StudentAndParentsType);
  }

  const onSelectInterestedFamily = useCallback(
    (interestedFamily: InterestedFamilyType) => {
      setStudentAndParents(createStudentAndParentsFromInterestedFamily(interestedFamily, familyContacts));
      setWasInterestedFamilySelected(true);
      onInterestedFamilySelected?.(interestedFamily);
    },
    [onInterestedFamilySelected, familyContacts]
  );
  useEffect(() => {
    if (isSaving && onSave && dataToSave.student && dataToSave.parents) {
      onSave({ data: dataToSave });
    }
  }, [isSaving, onSave, dataToSave]);

  useEffect(() => {
    if (marketplaceChildId && interestedFamilies) {
      const interestedFamily = interestedFamilies.find((family) => family.student?.id === marketplaceChildId);
      if (interestedFamily) onSelectInterestedFamily(interestedFamily);
    }
  }, [marketplaceChildId, interestedFamilies, onSelectInterestedFamily]);

  const onSaveLocal = useCallback(
    ({ data, errors }: OnSaveParamsType, fieldName: 'student' | 'parents' | 'interested') => {
      if (!isSaving) return false;
      if (!isEmpty(errors) && onSave) {
        onSave({ errors });
      } else {
        setDataToSave((prev) => ({ ...prev, [fieldName]: data }));
      }
    },
    [isSaving, onSave]
  );

  const onSelectNewOrInterested = useCallback(
    (e: any, { value }: any) => {
      setNewOrInterested(value);
      if (value === 'new') {
        setStudentAndParents({
          student: {} as StudentType,
          parents: [{} as ParentType],
        });
        setWasInterestedFamilySelected(false);
        onInterestedFamilySelected?.(undefined);
      }
    },
    [onInterestedFamilySelected]
  );

  const shouldShowInterestedFamiliesForm = useMemo(() => {
    return newOrInterested === 'interested' && !wasInterestedFamilySelected;
  }, [newOrInterested, wasInterestedFamilySelected]);

  return (
    <Form className="student-and-parents-form" data-testid="student-and-parents-form">
      {!!viewTitle && (
        <h2 data-testid="student-and-parents-form-title" className="text-lg font-semibold mb-4">
          {viewTitle}
        </h2>
      )}
      <Form.Group>
        <Form.Field>
          <Checkbox
            radio
            label={t('students.newStudentLabel')}
            name="newOrInterested"
            value="new"
            checked={newOrInterested === 'new'}
            onChange={onSelectNewOrInterested}
            data-testid="onSelectNew"
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label={t('students.interestedFamiliesLabel')}
            name="newOrInterested"
            value="interested"
            checked={newOrInterested === 'interested'}
            onChange={onSelectNewOrInterested}
            data-testid="onSelectInterested"
          />
          {newOrInterested === 'interested' && wasInterestedFamilySelected && (
            <>
              <span> (</span>
              <a href="#" onClick={() => setWasInterestedFamilySelected(false)} data-testid="selectedinterestedfamily">
                {t('students.interestedFamiliesSelectDifferentLabel')}
              </a>
              <span>) </span>
            </>
          )}
        </Form.Field>
      </Form.Group>
      {!shouldShowInterestedFamiliesForm && (
        <div key="newStudent">
          <StudentEditForm
            hasTitle={false}
            isSaving={isSaving && !dataToSave.student}
            onSave={(d) => onSaveLocal(d, 'student')}
            student={studentAndParents?.student}
          />
          <Divider />
          <ParentsForm
            isSaving={isSaving && !dataToSave.parents}
            onSave={(d) => onSaveLocal(d, 'parents')}
            parents={studentAndParents?.parents ?? []}
          />
        </div>
      )}
      {shouldShowInterestedFamiliesForm && (
        <InterestedFamiliesForm
          isSaving={isSaving}
          onSave={(d) => onSaveLocal(d, 'interested')}
          key="newStudent"
          hasTitle={false}
          onSelect={onSelectInterestedFamily}
        />
      )}
    </Form>
  );
}

function createStudentAndParentsFromInterestedFamily(
  interestedFamily: InterestedFamilyType,
  familyContacts: any[]
): StudentAndParentsType {
  const { student, parents, family_id } = interestedFamily;
  const newParents = parents?.map((p) => {
    const existingContact = familyContacts?.find((c) => c.email === p.email);
    return {
      contact: existingContact,
      firstName: p.firstName,
      lastName: p.lastName,
      email: p.email,
      phone: p.phone,
      isExisting: !!existingContact, // is this needed anymore?
      isInterestedFamily: true,
      shouldSendInvitation: canSendInvitation(existingContact),
      marketplaceFamilyId: family_id,
    };
  }) as ParentType[];
  return {
    student: {
      firstName: student?.firstName ?? '',
      lastName: student?.lastName ?? '',
      birthday: dateFormatter(formatISOStringAsUtcDateOrNull(student?.dob)) ?? '',
      gender:
        student?.gender === 'boy'
          ? GenderEnum.MALE
          : student?.gender === 'girl'
            ? GenderEnum.FEMALE
            : student?.gender === 'other'
              ? GenderEnum.OTHER
              : undefined,
    },
    parents: newParents ?? ([] as ParentType[]),
    interestedFamily: interestedFamily,
  };
}
export default StudentAndParentsForm;
