import find from 'lodash/find';

import {
  STUDENTS_LISTENER_STARTED,
  STUDENTS_FETCHED,
  STUDENT_ADDED,
  STUDENT_ADD_CLEARED,
  STUDENT_SELECTED,
  STUDENT_SELECTION_CLEARED,
  STUDENT_UPDATED,
  USER_EMAIL_RESET_LINK_SENT,
} from '../redux/actions/types';

import { parentStudentsOnListen, organizationStudentsOnListen, saveStudent } from './studentsAPI';

import store from '../redux/store';
import { getUsersRoomsOrLocations } from '../helpers/utils';

const identifyStudentLocation = (studentData) => {
  let location = '';
  if (!studentData?.rooms?.length) return location;
  const room = studentData.rooms[0];
  if (!room?.length) return location;

  const roomStore = store.getState()?.rooms || [];
  if (roomStore?.list?.length) {
    // Extract current location from room store
    const roomObject = find(roomStore.list, (r) => r.id === room);
    if (roomObject?.location?.length) location = roomObject.location;
  }

  return location;
};

export const studentsListenerStarted = () => ({
  type: STUDENTS_LISTENER_STARTED,
});

export const studentsFetched = (students) => ({
  type: STUDENTS_FETCHED,
  students,
});

export const studentAdded = (student) => ({
  type: STUDENT_ADDED,
  student,
});

export const studentAddCleared = () => ({
  type: STUDENT_ADD_CLEARED,
});

export const studentUpdated = () => ({
  type: STUDENT_UPDATED,
});

export const studentSelected = (student) => ({
  type: STUDENT_SELECTED,
  student,
});

export const studentSelectionCleared = () => ({
  type: STUDENT_SELECTION_CLEARED,
});

export const emailResetLinkSent = () => ({
  type: USER_EMAIL_RESET_LINK_SENT,
});

export const startOrganizationStudentsListener = (organizationId, parentUserId) => (dispatch) => {
  dispatch(studentsListenerStarted());
  const validRoomIds = getUsersRoomsOrLocations('rooms');

  // parents must ONLY query for students they are associated with
  if (parentUserId) {
    return parentStudentsOnListen(
      organizationId,
      parentUserId,
      (data) => {
        if (data) dispatch(studentsFetched(data));
      },
      (error) => console.log(error.message)
    );
  }

  return organizationStudentsOnListen(
    { organizationId, validRoomIds },
    (data) => {
      if (data) dispatch(studentsFetched(data));
    },
    (error) => console.log(error.message)
  );
};

export const organizationAddStudent =
  (organizationId, { combinedFamily, ...studentData }) =>
  (dispatch) => {
    const location = identifyStudentLocation(studentData);
    return saveStudent(organizationId, {
      ...studentData,
      location,
    }).then((student) => dispatch(studentAdded(student)));
  };

export const organizationUpdateStudent =
  (
    organizationId,
    // combinedFamily is used on the front end only and should not be stored in the db
    { combinedFamily, ...studentData }
  ) =>
  async (dispatch) => {
    const location = identifyStudentLocation(studentData);
    await saveStudent(organizationId, {
      ...studentData,
      location,
    });
    return dispatch(studentUpdated());
  };

const initialState = {
  selectedStudent: null,
  count: 0,
  list: [],
};

export const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENTS_LISTENER_STARTED:
      return { ...state };

    case STUDENTS_FETCHED: {
      let selectedStudent = state?.selectedStudent;

      if (selectedStudent) {
        selectedStudent = action.students.list.find((student) => student.id === selectedStudent.id);
      }

      return {
        ...state,
        count: action.students.count,
        list: action.students.list,
        selectedStudent,
      };
    }

    case STUDENT_SELECTED:
      return { ...state, selectedStudent: action.student };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case STUDENT_UPDATED: {
      return {
        ...state,
      };
    }

    case STUDENT_ADDED: {
      return {
        ...state,
        addedStudent: action.student,
      };
    }

    case STUDENT_ADD_CLEARED: {
      return {
        ...state,
        addedStudent: {},
      };
    }
    case STUDENT_SELECTION_CLEARED:
      return { ...state, selectedStudent: {} };

    default:
      return state;
  }
};
