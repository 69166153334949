import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getTitlePrefix } from '../../config/env';

const PageHeader = ({ pageName, classes }) => {
  const { t } = useTranslation();

  const title = t('{{pageName}} - Wonderschool', { pageName: t(pageName) });
  return (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {title}
      </title>
      <body className={classes} />
    </Helmet>
  );
};

export default PageHeader;
