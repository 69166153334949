import { CSSProperties } from 'react';

const defaultStyle: CSSProperties = {
  color: '#ae5856',
  textAlign: 'left',
  minWidth: '100%',
};

interface Props {
  text: string;
  style?: Record<string, string>;
}

const InlineError = ({ text = '', style = {} }: Props) => {
  return (
    <div style={{ ...defaultStyle, ...style }} data-testid="error-message">
      {text}
    </div>
  );
};

export default InlineError;
