import { ClockIcon, CrossCircleIcon, SuccessIcon, Tooltip } from '@wonderschool/common-base-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrimaryLocation } from '../../../hooks/useLocations';
import { useOrganization } from '../../../hooks/useOrganizations';
import useRooms from '../../../hooks/useRooms';
import { ExportToolbar } from '../../components';

import {
  SortDirectionEnum,
  SortDirectionType,
  TABLE_CELL_CLASSES,
  WsTableHeader,
  sortCollection,
} from '../../../common';
import { PDFModal } from '../../components/PDFModal';
import { useDailyAttendance } from '../attendanceHooks';
import { generateDailyAttendanceCSV, generateDailyAttendancePDF } from '../attendanceUtils';
import {
  AttendanceTableProps,
  AttendanceType,
  DailyAttendanceReportType,
  KINDERSYSTEMS_REPORTS_STATUS,
} from '../types';
import { CheckInBadge, CheckOutBadge } from './AttendanceBadges';
import { AttendanceDetailsModal } from './AttendanceDetailsModal';
import { SignatureModal } from './SignatureModal';

const DailyAttendanceTable: React.FC<AttendanceTableProps> = ({
  filters,
  activities = [],
  kinderSystemsReport = false,
}) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();
  const rooms = useRooms();

  const attendance = useDailyAttendance(activities);
  const [attendanceSorted, setAttendanceSorted] = useState<AttendanceType[]>(attendance);
  const [selectedAttendance, setSelectedAttendance] = useState<AttendanceType | undefined>();
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>({
    name: '',
    direction: SortDirectionEnum.NONE,
  });

  const onSort = useCallback((sortDirection: SortDirectionType) => {
    setSortDirection(sortDirection);
  }, []);

  const attendanceReport = useMemo<DailyAttendanceReportType>(() => {
    return {
      startDate: filters.startDate,
      endDate: filters.endDate,
      organization,
      location,
      room: filters.roomId ? rooms.find((room) => room.id === filters.roomId) : null,
      items: attendanceSorted,
    };
  }, [attendanceSorted, filters, location, organization, rooms]);

  const onExportPDF = useCallback(() => {
    setIsPDFModalOpen(true);
  }, []);

  const onExportCSV = useCallback(() => {
    generateDailyAttendanceCSV(attendanceReport);
  }, [attendanceReport]);

  const onClick = useCallback((attendance: AttendanceType) => {
    setSelectedAttendance(attendance);
  }, []);
  const onCloseAttendanceDetailsModal = useCallback(() => {
    setSelectedAttendance(undefined);
  }, []);

  const onClosePDFModal = useCallback(() => {
    setIsPDFModalOpen(false);
  }, []);

  useEffect(() => {
    if (!attendance.length) setAttendanceSorted(attendance);
    else setAttendanceSorted(sortCollection<AttendanceType>(attendance, sortDirection));
  }, [attendance, sortDirection]);

  return (
    <>
      <ExportToolbar onExportPDF={onExportPDF} onExportCSV={onExportCSV} disabled={!attendanceSorted?.length} />
      <div className="mt-4 overflow-x-scroll sm:overflow-x-hidden">
        <table className="min-h-full min-w-full" data-testid="daily-attendance-table">
          <thead className="bg-gray-100" data-test-id="daily-attendance-thead">
            <tr className="border-y-2 border-gray-300">
              <WsTableHeader label={t('Name')} sortKey="studentName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader label={t('Room')} sortKey="roomName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader label={t('Date')} sortKey="checkInDate" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader
                label={t('Check In')}
                sortKey="checkInTimeSort"
                onSort={onSort}
                sortDirection={sortDirection}
              />
              <WsTableHeader label={t('Signee')} sortKey="checkInName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader
                label={t('Check Out')}
                sortKey="checkOutTimeSort"
                onSort={onSort}
                sortDirection={sortDirection}
              />
              <WsTableHeader label={t('Signee')} sortKey="checkOutName" onSort={onSort} sortDirection={sortDirection} />
              {kinderSystemsReport && <WsTableHeader label={t('kinderConnect.status')} />}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300">
            {attendanceSorted.map((attendance) => {
              const {
                activityId,
                checkInDate,
                checkInTime,
                checkInName,
                checkInSignatureUri,
                checkOutDate,
                checkOutName,
                checkOutTime,
                checkOutSignatureUri,
                isAbsent,
                roomName,
                studentName,
                healthCheck,
                kinderSystemsTransferred,
              } = attendance;

              return (
                <tr key={activityId} className="cursor-pointer" onClick={() => onClick(attendance)}>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-student-name-${activityId}`}>
                    {studentName || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-room-name-${activityId}`}>
                    {roomName || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-date-${activityId}`}>
                    {checkInDate ? checkInDate : checkOutDate || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-time-${activityId}`}>
                    <CheckInBadge time={checkInTime} isAbsent={isAbsent} emptyText="-" healthCheck={healthCheck} />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-name-${activityId}`}>
                    <SignatureModal signatureUri={checkInSignatureUri} name={checkInName} />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkout-time-${activityId}`}>
                    <CheckOutBadge time={checkOutTime} isAbsent={isAbsent} emptyText="-" />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkout-name-${activityId}`}>
                    <SignatureModal signatureUri={checkOutSignatureUri} name={checkOutName} />
                  </td>
                  {kinderSystemsReport && (
                    <td className={TABLE_CELL_CLASSES}>
                      {kinderSystemsTransferred?.status ? (
                        <Tooltip action="hover" content={kinderSystemsTransferred?.status}>
                          {kinderSystemsTransferred.status === KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED && (
                            <span className="flex flex-row bg-green-200 rounded-full px-2 py-2 w-30">
                              <SuccessIcon className="mr-1 h-5 w-5 text-green-700" />
                              {t('Transferred')}
                            </span>
                          )}
                          {kinderSystemsTransferred.status === KINDERSYSTEMS_REPORTS_STATUS.ERROR && (
                            <span className="flex flex-row bg-red-200 rounded-full px-2 py-2 w-20">
                              <CrossCircleIcon className="mr-1 h-5 w-5 text-red-700" />
                              {t('Error')}
                            </span>
                          )}
                          {kinderSystemsTransferred.status !== KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED &&
                            kinderSystemsTransferred.status !== KINDERSYSTEMS_REPORTS_STATUS.ERROR && (
                              <span className="flex flex-row bg-yellow-200 rounded-full px-2 py-2 w-20">
                                <ClockIcon className="mr-1 h-5 w-5 text-yellow-700" />
                                {t('Out of Sync')}
                              </span>
                            )}
                        </Tooltip>
                      ) : (
                        <span className="flex flex-row bg-yellow-200 rounded-full px-2 py-2 w-20">
                          <ClockIcon className="mr-1 h-5 w-5 text-yellow-700" />
                          {t('Out of Sync')}
                        </span>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <AttendanceDetailsModal attendance={selectedAttendance} onClose={onCloseAttendanceDetailsModal} />
        <PDFModal
          isOpen={isPDFModalOpen}
          report={attendanceReport}
          pdfGenerator={generateDailyAttendancePDF as any}
          onClose={onClosePDFModal}
        />
      </div>
    </>
  );
};

export default DailyAttendanceTable;
