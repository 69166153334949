// eslint-disable-next-line no-restricted-imports
import { Image } from 'semantic-ui-react';
import logo from '../../styles/img/moxit-logo.png';

interface NetworkLogoProps {
  network?: {
    logoUrl?: string;
  };
}

export default function NetworkLogo({ network }: NetworkLogoProps) {
  const logoUrl = network?.logoUrl || logo;
  return <Image src={logoUrl} size="medium" centered />;
}
