import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { isLocal } from './config/env';

export const DEFAULT_LANGUAGE = 'en';

import {
  enTranslation as enTranslationBaseUi,
  esTranslation as esTranslationBaseUi,
} from '@wonderschool/common-base-ui';

import { enTranslation as enTranslationCcmsUi, esTranslation as esTranslationCcmsUi } from '@wonderschool/ccms-ui';

import {
  enTranslation as enTranslationFoodProgram,
  esTranslation as esTranslationFoodProgram,
} from '@wonderschool/common-food-program-ui';

async function loadTranslations() {
  const [enResponse, esResponse] = await Promise.all([
    fetch('/locales/en/translation.json'),
    fetch('/locales/es/translation.json'),
  ]);

  if (!enResponse.ok || !esResponse.ok) {
    throw new Error('Failed to load translations');
  }

  const [enTranslations, esTranslations] = await Promise.all([enResponse.json(), esResponse.json()]);

  return {
    en: {
      web: { ...enTranslations },
      ccms: { ...enTranslationCcmsUi }, // Placeholder for ccmsUi translations
      baseUi: { ...enTranslationBaseUi }, // Placeholder for baseUi translations
      foodProgram: { ...enTranslationFoodProgram }, // Placeholder for foodProgram translations
    },
    es: {
      web: { ...esTranslations },
      ccms: { ...esTranslationCcmsUi }, // Placeholder for ccmsUi translations
      baseUi: { ...esTranslationBaseUi }, // Placeholder for baseUi translations
      foodProgram: { ...esTranslationFoodProgram }, // Placeholder for foodProgram translations
    },
  };
}
loadTranslations().then((translations) => {
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        // Initialize i18next with namespaces and defaultNS
        ns: ['web', 'baseUi', 'ccms', 'foodProgram'], // List of all namespaces
        defaultNS: 'web', // Default namespace
        resources: translations,
        fallbackLng: DEFAULT_LANGUAGE,
        debug: isLocal(),

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },

        parseMissingKeyHandler: (key) => {
          return key;
        },
      },
      () => {
        // eslint-disable-next-line no-restricted-imports
        moment.locale(i18next.language);
      }
    );
});

export const changeLanguage = (lang: string) => {
  i18next.changeLanguage(lang);
  moment.locale(lang);
};

export default i18next;
