/* eslint-disable i18next/no-literal-string */
import { Breadcrumbs, Tab, TabGroup } from '@wonderschool/common-base-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getTitlePrefix } from '../../../config/env';
import KinderConnectIcon from '../assets/kinder-systems.svg';
import Credentials from './credentials';
import { KinderConnectReports } from './reports';
import { KinderConnectStudentMappings } from './StudentMappings';

export const KinderConnectPage = () => {
  const { t } = useTranslation();
  const title = 'KinderConnect';

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
    </Helmet>
  );

  return (
    <>
      <div className="container mx-auto px-5">
        {renderPageHead()}
        <Breadcrumbs
          items={[
            {
              current: false,
              href: '/appstore',
              label: 'Apps',
            },
            {
              current: true,
              href: '/kinderconnect',
              label: 'KinderConnect',
            },
          ]}
        />
        <div className="container py-10">
          <div className="grid grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center gap-x-6">
              <div className="h-16 w-16 place-content-center rounded-full ring-1 ring-gray-900/10">
                <img className="mx-auto" src={KinderConnectIcon} alt="KinderConnect" />
              </div>
              {/*  eslint-disable-next-line i18next/no-literal-string */}
              <h1 className="my-auto text-3xl font-bold text-gray-900">KinderConnect</h1>
            </div>
          </div>
          <div className="flex flex-col gap-x-4 sm:gap-x-6 pt-10">
            <TabGroup>
              <Tab label={t('Students')}>
                <KinderConnectStudentMappings />
              </Tab>
              <Tab label={t('Reports')}>
                <KinderConnectReports />
              </Tab>
              <Tab label={t('Settings')}>
                <Credentials />
              </Tab>
            </TabGroup>
          </div>
        </div>
      </div>
    </>
  );
};
