// To add more userflow goals, we have to login to Userflow.com and find them there
// https://userflow.com/docs/guides/starting-flows-checklists#start-from-an-embedded-button-or-javascript-code
export enum OnboardingGoalIdEnum {
  ENROLLMENTS = '58243f92-f3be-4f7f-be42-ccfde8603591', // not a userflow goal
  FAMILIES = 'unknown', // userflow content ids
  PAYMENTS = 'ad0de3f4-7f94-4170-b0f6-7202aa4c09eb',
  OPERATIONS = 'e8c0446f-4c21-401f-aaff-c67e7420cbfb',
}
export enum OnboardingGoalStatusEnum {
  START = 'start',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}
export type OnboardingGoalType = {
  id: OnboardingGoalIdEnum;
  status: OnboardingGoalStatusEnum;
};
