export enum ActivityTypeEnum {
  ABSENT = 'absent',
  DROPOFF = 'dropoff',
  FEEDING = 'feeding', // legacy meal logging activity prior to mobile version 2.9.0
  MEAL_RECORD = 'mealRecord',
  PICKUP = 'pickup',
}
export enum ReportTypeEnum {
  ATTENDANCE_DAILY = 'attendance daily',
  ATTENDANCE_TOTAL = 'attendance totals',
  FOOD_PROGRAM_ATTENDANCE = 'food program attendance',
  FOOD_PROGRAM_MEAL_COUNTS_DAILY = 'food program daily counts',
  FOOD_PROGRAM_MEAL_COUNTS_MONTHLY = 'food program monthly counts',
}
