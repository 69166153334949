import { connect } from 'react-redux';
import withPermission from '../Shared/withPermission';
import CenterOverview from '../Stats/CenterOverview';

const StatSummary = () => {
  return (
    <div className="w-full">
      <div className="block w-full">
        <CenterOverview />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default withPermission(connect(mapStateToProps)(StatSummary), 'can_view_stats');
