const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const fetchTimezoneWithGoogleApi = async ({ lat, long }) => {
  if (lat && long) {
    const googleTimezoneApiUrl = new URL('https://maps.googleapis.com/maps/api/timezone/json');
    const params = new URLSearchParams();

    // the google api requires this format or it'll return an error
    const unixEpochTimestamp = Math.floor(Date.now() / 1000).toString();

    params.append('location', `${lat},${long}`);
    params.append('timestamp', unixEpochTimestamp);
    params.append('key', GOOGLE_MAPS_API_KEY);

    googleTimezoneApiUrl.search = params.toString();

    try {
      const request = await fetch(googleTimezoneApiUrl);
      const response = await request.json();

      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      return response;
    } catch (error) {
      console.log('Google timezone API failed: ', error);
      return null;
    }
  }

  return null;
};

export default fetchTimezoneWithGoogleApi;
