import { Dialog } from '@wonderschool/common-base-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StudentProgramsForm from '../../../students/components/StudentProgramsForm';

interface StudentMappingModalProps {
  isOpen: boolean;
  onClose: () => void;
  student: any;
}

export const StudentMappingModal: React.FC<StudentMappingModalProps> = ({ isOpen, onClose, student }) => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>{t('Update KinderSystems ID')}</Dialog.Title>
        <Dialog.Description>{t('Enter the KinderSystems ID for this student.')}</Dialog.Description>
        <div className="pt-4">
          <StudentProgramsForm student={student} onClose={onClose} showPrograms="kinderConnect" />
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
