import { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Segment, Icon, Header, Button } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import LocationForm from '../Forms/Location/LocationForm';
import LocationsList from '../Locations/LocationsList';

const SlidingLocationsForm = withSlidingPanel(LocationForm, {
  title: 'Add A Location',
  width: '70%',
});

class SetupLocations extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      locations: { list },
      t,
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="location arrow" size="huge" color="red" />
          <Header as={'h1'} content={t('Add your program information')} />
          <Header as={'h3'} className="no-weight">
            <Trans i18nKey="Tell us where your program is located so we can properly configure your account.">
              Tell us where your program is located so we can properly configure your account.
              <br />
              {"Ready? Let's start."}
            </Trans>
          </Header>
        </Segment>
        <Segment basic>
          <SlidingLocationsForm
            isOpen={this.state.isOpen}
            onClose={(status) => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content={t('Add Program Information')}
            primary
            size="large"
            onClick={() => this.setState({ isOpen: true })}
          />
        </Segment>
      </Segment>
    );
  };

  renderFCCAddBlock = () => {
    const {
      locations: { list },
      t,
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="location arrow" size="huge" color="red" />
          <Header as={'h1'} content={t('Add your location information')} />
          <Header as={'h3'} className="no-weight">
            <Trans i18nKey="Tell us where your program is located fcc">
              Tell us where your home care is located so we can properly configure your account.
              <br />
              {"Ready? Let's start."}
            </Trans>
          </Header>
        </Segment>
        <Segment basic>
          <SlidingLocationsForm
            isOpen={this.state.isOpen}
            onClose={(status) => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content={t('Add Location Information')}
            primary
            size="large"
            onClick={() => this.setState({ isOpen: true })}
          />
        </Segment>
      </Segment>
    );
  };

  renderListBlock = () => {
    const {
      locations: { list },
    } = this.props;

    if (!list.length) return null;
    return <LocationsList />;
  };

  render() {
    const {
      currentOrganization: { programType },
    } = this.props;

    // Render a different setup for FCCs.
    if (programType && programType === 'familyChildCare') {
      return (
        <Segment basic>
          {this.renderFCCAddBlock()}
          {this.renderListBlock()}
        </Segment>
      );
    }

    return (
      <Segment basic>
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupLocations.propTypes = {};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  locations: state.locations,
});
export default withTranslation()(connect(mapStateToProps)(SetupLocations));
