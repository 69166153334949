import { BillingIntervalEnum, HourEnum, WEEKDAYS } from './enums';
import {
  BusinessHoursType,
  DocumentType,
  EnrollmentType,
  InvitationRecipientType,
  ScheduledDayType,
  TuitionAndFeesType,
} from './types';

import {
  DAY_ENUM_TO_DAYJS_DAY,
  DAY_ENUM_TO_LEGACY_MAP,
  DOCUMENTS_EMPTY,
  INVITATION_RECIPIENTS_EMPTY,
  LEGACY_TO_DAY_ENUM_MAP,
  SCHEDULED_DAYS_EMPTY,
  TUITION_AND_FEES_EMPTY,
} from './consts';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DayEnum } from '@wonderschool/common-base-types';
import { DiscountAmountType } from '../helpers/invoices';

dayjs.extend(utc);

function createInvitationRecipientsFromFamily(student: any): InvitationRecipientType[] {
  const family = student?.family;
  if (!family) return INVITATION_RECIPIENTS_EMPTY;

  const invitationRecipients: InvitationRecipientType[] = [];

  Object.keys(family).forEach((key: string) => {
    const contact = family[key];
    invitationRecipients.push({
      contactId: key,
      uid: contact.uid ?? null,
      email: contact.email ?? null,
      displayName: contact.displayName ?? null,
      phone: contact.phone ?? null,
    });
  });
  return invitationRecipients;
}

// if the recipients are already defined on the enrollment, use those
// otherwise, create them from the family
function getInvitationRecipients(student: any): InvitationRecipientType[] {
  return student?.enrollment?.invitationRecipients ?? INVITATION_RECIPIENTS_EMPTY;
}

function getDocuments(student: any): DocumentType[] {
  return student?.enrollment?.documents ?? DOCUMENTS_EMPTY;
}

function getScheduledDays(student: any, defaultBusinessHours: BusinessHoursType): ScheduledDayType[] {
  const enrollment: EnrollmentType = student?.enrollment;
  const studentSchedule: string[] = student?.schedule;

  if (enrollment?.scheduledDays) {
    return enrollment.scheduledDays;
  } else if (studentSchedule && Array.isArray(studentSchedule)) {
    // legacy case
    return studentSchedule.map((day: string) => {
      return {
        day: LEGACY_TO_DAY_ENUM_MAP[day],
        startTime: defaultBusinessHours.startTime,
        endTime: defaultBusinessHours.endTime,
      };
    });
  }
  return SCHEDULED_DAYS_EMPTY;
}

function getScheduledDaysAbbreviated(student: any): string[] {
  if (!student.enrollment?.scheduledDays) {
    return student.schedule ?? [];
  } else if (student.enrollment?.scheduledDays) {
    return student.enrollment.scheduledDays.map(
      (scheduledDay: ScheduledDayType) => DAY_ENUM_TO_LEGACY_MAP[scheduledDay.day]
    );
  } else return [];
}

function getTuitionAndFees(student: any): TuitionAndFeesType {
  const enrollment = student?.enrollment;
  const studentTuitionAndFees = student?.tuitionAndFees;

  if (enrollment?.tuitionAndFees) {
    return enrollment.tuitionAndFees;
  } else if (studentTuitionAndFees) {
    // legacy case
    return studentTuitionAndFees;
  } else {
    return TUITION_AND_FEES_EMPTY;
  }
}

function formatHourForDisplay(hour: HourEnum): string {
  const hourSplit: string[] = hour.split(':');
  const hours = parseInt(hourSplit[0]);
  const minutes = parseInt(hourSplit[1]);
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hours12 = hours % 12;
  const hours12Display = hours12 === 0 ? 12 : hours12;
  const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12Display}:${minutesDisplay} ${ampm}`;
}

function calculateNextInvoiceDate(firstInvoiceDate: Timestamp | undefined, interval: BillingIntervalEnum | undefined) {
  if (!firstInvoiceDate) return undefined;
  switch (interval) {
    case BillingIntervalEnum.WEEKLY:
      return dayjs.unix(firstInvoiceDate.seconds).utc().add(7, 'day').toDate();
    case BillingIntervalEnum.BIWEEKLY:
      return dayjs.unix(firstInvoiceDate.seconds).utc().add(14, 'day').toDate();
    case BillingIntervalEnum.MONTHLY:
      return dayjs.unix(firstInvoiceDate.seconds).utc().add(1, 'month').toDate();
    case BillingIntervalEnum.TWICE_PER_MONTH:
      return dayjs.unix(firstInvoiceDate.seconds).utc().add(14, 'day').toDate();
    default:
      return dayjs.unix(firstInvoiceDate.seconds).utc().toDate();
  }
}

function getNextInvoiceDate(
  dueDate: Timestamp | undefined,
  billingInterval: BillingIntervalEnum | undefined,
  proratedAmount = 0
) {
  if (!dueDate) return undefined;
  const nextInvoiceDate =
    proratedAmount > 0
      ? calculateNextInvoiceDate(dueDate, billingInterval)
      : dayjs.unix(dueDate.seconds).utc().toDate();

  return nextInvoiceDate;
}

function countSessions(periodStart: Date, periodEnd: Date, weekdays: number[]) {
  const start = dayjs(periodStart);
  const end = dayjs(periodEnd);

  // Get zero-based day of the week for start and end dates
  const firstWeekday = start.day();
  const lastWeekday = end.day();

  // Calculate the difference in days and add 1 for inclusive count
  const days = end.diff(start, 'day') + 1;

  // Calculate the number of complete weeks
  const completeWeeks = Math.floor((days - (7 - firstWeekday) - (lastWeekday + 1)) / 7);

  // Calculate sessions in the first and last weeks
  const firstWeekSessions = weekdays.filter((day) => day >= firstWeekday).length || 0;
  const lastWeekSessions = weekdays.filter((day) => day <= lastWeekday).length || 0;

  return Math.trunc(completeWeeks * weekdays.length + firstWeekSessions + lastWeekSessions);
}

function calculateProrateAmount(
  weekdays: number[],
  billBeginsOn: Date,
  billEndsOn: Date,
  rangeBeginsOn: Date,
  rangeEndsOn: Date,
  amount: number
) {
  const sessions = countSessions(billBeginsOn, billEndsOn, weekdays);
  const totalSessions = countSessions(rangeBeginsOn, rangeEndsOn, weekdays);
  const ratio = totalSessions === 0 ? 0 : totalSessions / sessions;
  return Math.round(amount * ratio);
}

function convertWeekdayToDayjsDay(weekdayIndex: number): number {
  return weekdayIndex === 6 ? 0 : weekdayIndex + 1;
}

function isEnrollmentDateSameAsChargeDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date
): boolean {
  const enrollmentDay = dayjs(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, check if enrollment day matches charge date
    const chargeDayOfMonth = parseInt(chargeDate);
    return enrollmentDay.date() === chargeDayOfMonth;
  } else {
    // For weekly/biweekly, check if enrollment weekday matches charge weekday
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    return enrollmentDay.day() === convertWeekdayToDayjsDay(selectedDayIndex);
  }
}

function calculateFirstInvoiceDue(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date
): dayjs.Dayjs {
  const enrollmentDay = dayjs(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    return chargeDay.isSameOrBefore(enrollmentDay) ? chargeDay.add(1, 'month') : chargeDay;
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    const chargeDayThisWeek = enrollmentDay.day(convertWeekdayToDayjsDay(selectedDayIndex));

    return chargeDayThisWeek.isSameOrBefore(enrollmentDay) ? chargeDayThisWeek.add(2, 'week') : chargeDayThisWeek;
  } else {
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    const chargeDayThisWeek = enrollmentDay.day(convertWeekdayToDayjsDay(selectedDayIndex));

    return chargeDayThisWeek.isSameOrBefore(enrollmentDay) ? chargeDayThisWeek.add(1, 'week') : chargeDayThisWeek;
  }
}

function calculateStartBillingDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date,
  billFor: 'Prior' | 'Next'
) {
  const enrollmentDay = dayjs(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, get the charge date for enrollment month
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, subtract a month
      return chargeDay.isSameOrBefore(enrollmentDay) ? chargeDay.toDate() : chargeDay.subtract(1, 'month').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add a month
      return chargeDay.isSameOrBefore(enrollmentDay) ? chargeDay.add(1, 'month').toDate() : chargeDay.toDate();
    }
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    // For biweekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, subtract two weeks
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.toDate()
        : chargeDayThisWeek.subtract(2, 'week').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add two weeks
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(2, 'week').toDate()
        : chargeDayThisWeek.toDate();
    }
  } else {
    // For weekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, subtract a week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.toDate()
        : chargeDayThisWeek.subtract(1, 'week').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add a week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(1, 'week').toDate()
        : chargeDayThisWeek.toDate();
    }
  }
}

function calculateEndBillingDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date,
  billFor: 'Prior' | 'Next'
) {
  const enrollmentDay = dayjs.utc(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, get the charge date for enrollment month
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, subtract a month
      return chargeDay.isSameOrBefore(enrollmentDay)
        ? chargeDay.add(1, 'month').subtract(1, 'day').toDate()
        : chargeDay.subtract(1, 'day').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add two months
      return chargeDay.isSameOrBefore(enrollmentDay)
        ? chargeDay.add(2, 'month').subtract(1, 'day').toDate()
        : chargeDay.add(1, 'month').subtract(1, 'day').toDate();
    }
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    // For biweekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, use this week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(2, 'week').subtract(1, 'day').toDate()
        : chargeDayThisWeek.add(1, 'day').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add four weeks
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(4, 'week').subtract(1, 'day').toDate()
        : chargeDayThisWeek.add(2, 'week').subtract(1, 'day').toDate();
    }
  } else {
    // For weekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, use this week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(1, 'week').toDate()
        : chargeDayThisWeek.toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add two weeks
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(2, 'week').subtract(1, 'day').toDate()
        : chargeDayThisWeek.add(1, 'week').subtract(1, 'day').toDate();
    }
  }
}

function convertScheduledDaysToWeekdayNumbers(scheduledDays: ScheduledDayType[] = []): number[] {
  return scheduledDays.map((scheduleDay) => DAY_ENUM_TO_DAYJS_DAY[scheduleDay.day]).sort((a, b) => a - b);
}

function calculateRecurringInvoiceTotal(fees, tuition = 0) {
  if (!fees?.length) return tuition;

  return fees.reduce((sum, fee) => {
    const amount = fee.amount || 0;
    const isDiscount = fee.type === 'Discount';
    const isCurrency = fee.amountType === DiscountAmountType.CURRENCY;

    if (isDiscount) {
      return isCurrency ? sum - amount : sum - (sum * amount) / 100;
    }
    return isCurrency ? sum + amount : sum + (sum * amount) / 100;
  }, tuition);
}

export {
  createInvitationRecipientsFromFamily,
  formatHourForDisplay,
  getDocuments,
  getInvitationRecipients,
  getScheduledDays,
  getScheduledDaysAbbreviated,
  getTuitionAndFees,
  getNextInvoiceDate,
  calculateProrateAmount,
  calculateFirstInvoiceDue,
  calculateStartBillingDate,
  calculateEndBillingDate,
  convertScheduledDaysToWeekdayNumbers,
  isEnrollmentDateSameAsChargeDate,
  calculateRecurringInvoiceTotal,
};
