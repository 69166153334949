import { useTranslation } from 'react-i18next';

const Disclaimer = ({ type }) => {
  const { t } = useTranslation();

  if (type === 'terms')
    return (
      <div>
        {t('By performing this action, you agree to our')}{' '}
        <a href="https://www.wonderschool.com/terms-of-use" target="_blank" rel="noreferrer">
          {t('Terms of Use')}
        </a>
        <span> {t('and')} </span>
        <a href="https://www.wonderschool.com/privacy-policy" target="_blank" rel="noreferrer">
          {t('Privacy Policy')}
        </a>
        <span>.</span>
      </div>
    );

  return <div>{/* TODO: Future Disclaimer Language Goes here */}</div>;
};

export default Disclaimer;
