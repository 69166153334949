// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectFirstLocation } from '../Components/DateTime/DateTime';
import { Transaction, getAllInvoicesPerMonth, getAllTransactionsPerMonth } from '../api/firebase/financeAtGlance';
import { useOrganization } from './useOrganizations';

export type FinanceAtGlance = {
  invoicesData: any;
  transactionData?: Transaction;
  isLoadingInvoices: boolean;
  isLoadingTransactions: boolean;
};
export function useFinanceAtGlance(getSelectedMonth, financeType = '', isRefreshToggle = false): FinanceAtGlance {
  const [invoicesAtGlance, setInvoicesAtGlance] = useState({});
  const [transactionsAtGlance, setTransactionsAtGlance] = useState<Transaction>();
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);

  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  const selectedMonthMoment = moment(getSelectedMonth).tz(timezone, true);

  const firstDate = selectedMonthMoment.clone().startOf('month');
  const lastDate = selectedMonthMoment.clone().endOf('month');

  const organization = useOrganization();
  const { t } = useTranslation();

  // whenever the finance type, orgId, selected month changes
  // or when a force refresh happens
  // re-fetch invoice and/or transactions data
  // making sure to update loading state
  useEffect(() => {
    const invoiceData = async () => {
      setIsLoadingInvoices(true);
      const invoicesAtGlanceObj = await getAllInvoicesPerMonth(
        organization.id,
        {
          firstdate: firstDate.valueOf(),
          lastdate: lastDate.valueOf(),
        },
        t
      );
      setInvoicesAtGlance(invoicesAtGlanceObj);
      setIsLoadingInvoices(false);
    };

    const transactionData = async () => {
      setIsLoadingTransactions(true);
      const months = { firstDate, lastDate };
      const transactionsAtGlanceObj = await getAllTransactionsPerMonth(organization.id, months);
      setTransactionsAtGlance(transactionsAtGlanceObj);
      setIsLoadingTransactions(false);
    };

    switch (financeType) {
      case 'invoices':
        invoiceData();
        break;
      case 'transactions':
        transactionData();
        break;
      default:
        invoiceData();
        transactionData();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeType, organization.id, firstDate.valueOf(), lastDate.valueOf(), isRefreshToggle]);

  return {
    invoicesData: invoicesAtGlance,
    transactionData: transactionsAtGlance,
    isLoadingInvoices: isLoadingInvoices,
    isLoadingTransactions: isLoadingTransactions,
  };
}
