import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from 'semantic-ui-react';

import { calculateTotalFromInvoiceItems } from '../../../helpers/invoices';
import { useInvoiceEditDetails, useInvoiceEditItems, useInvoiceEditStudents } from '../../../hooks/useInvoiceEdit';

// Import components

import { getInvoicePlanStatus, getUpdatedBillingDates } from '../../../helpers/invoicePlans';
import { StudentsListWidget } from './Widgets';

import InvoicePlanDetailsContainer from './InvoicePlans/InvoicePlanDetailsContainer';
import { StepsContainer } from './Steps';

const selectFirstLocation = createSelector([(state) => state.locations], (locations) => locations?.list[0]);

export default function StepConfirmAndSave({ isInvoice = true, onSave }) {
  const { t } = useTranslation();

  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  const details = useInvoiceEditDetails();
  const invoiceItems = useInvoiceEditItems();
  const students = useInvoiceEditStudents();
  const [alreadyPaid, setAlreadyPaid] = useState(false);

  const loggedInUser = useSelector((state) => state.user);

  const isEdit = !!details?.id;

  const dataToSave = useMemo(
    () => createDataToSave(isInvoice, details, invoiceItems, students, loggedInUser, timezone, isEdit),
    [isInvoice, details, invoiceItems, students, loggedInUser, timezone, isEdit]
  );

  return (
    <>
      <StepsContainer
        isInvoice={isInvoice}
        isEdit={isEdit}
        CurrentForm={ConfirmForm}
        Widgets={Widgets}
        onDone={onSaveLocal}
      />
    </>
  );

  function ConfirmForm() {
    return <>{!isInvoice && <InvoicePlanDetailsContainer showActionButtons={false} invoicePlan={dataToSave} />}</>;
  }

  function Widgets() {
    return (
      <>
        {isInvoice && (
          <Checkbox
            onChange={onChangeAlreadyPaid}
            name={'alreadyPaid'}
            checked={alreadyPaid}
            label={t('Mark invoice as already paid')}
          />
        )}
        {<StudentsListWidget students={students} onRemove={onRemoveStudent} allowDelete={false} />}
      </>
    );
  }
  function onRemoveStudent(_student) {
    // TODO, will use in future feature
    //  setSelectedStudents (selectedStudents.filter (s => s.id !== student.id));
  }
  function onChangeAlreadyPaid(e, { checked }) {
    setAlreadyPaid(checked);
  }
  function onSaveLocal() {
    if (onSave) onSave(dataToSave, alreadyPaid);
  }
}

function createDataToSave(isInvoice, details, invoiceItems, students, loggedInUser, timezone, isEdit) {
  if (isInvoice) {
    return {};
  } else {
    return createInvoicePlan(details, invoiceItems, students, loggedInUser, timezone, isEdit);
  }
}
function createInvoicePlan(details, invoiceItems, students, loggedInUser, timezone, isEdit) {
  return {
    ...prepareInvoicePlanDetailsForSave(details, invoiceItems, loggedInUser, timezone, isEdit),
    invoiceItemList: invoiceItems,
    students,
  };
}

function prepareInvoicePlanDetailsForSave(details, invoiceItems, loggedInUser, timezone, isEdit) {
  const loggedInUserInfo = { id: loggedInUser.uid, displayName: loggedInUser.displayName };
  if (details?.id) details.updatedBy = loggedInUserInfo;
  else details.createdBy = loggedInUserInfo;

  const updatedBillingDates = getUpdatedBillingDates(details, timezone, isEdit);
  const status = getInvoicePlanStatus(updatedBillingDates.dateDue, updatedBillingDates.dateEnd);

  const data = {
    ...details,
    ...updatedBillingDates,
    isInvoice: false,
    status,
    total: calculateTotalFromInvoiceItems(invoiceItems),
  };
  return data;
}
