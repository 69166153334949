import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Icon } from 'semantic-ui-react';
import { FILE_ACTIONS_CONSTS } from '../dictionary';

interface IStaffActionButtonsProps {
  areMultipleSelected: boolean;
  handleFileActionClicked: (action: FILE_ACTIONS_CONSTS) => void;
}

const StaffActionButtons: React.FC<IStaffActionButtonsProps> = ({ handleFileActionClicked }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        basic
        color="blue"
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.download)}
        data-testid="download-action-btn"
      >
        <Icon name="download" />
        {t('Download')}
      </Button>
      <Button primary onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.share)} data-testid="share-action-btn">
        <Icon name="users" />
        {t('Share')}
      </Button>
    </>
  );
};

export default StaffActionButtons;
