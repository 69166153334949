import { Button, SlideOver } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../api/firebase/firebase';
import { usePrimaryLocation } from '../../hooks/useLocations';
import { useAuthUser, useUser } from '../../hooks/useUser';
import { logError } from '../../rollbar';
import { convertTo24Hour, toDisplayTime } from '../timecardsUtils';
import { FormDataType, FormErrorType, StaffType, TimecardType } from '../types';
import TimecardModalContent from './TimecardModalContent';
import { calculateClockInOutTimeDifference } from './utils/calculateClockInOutTimeDifference';
import { getClockedOutStatus } from './utils/getClockedOutStatus';
import { validateTimeCards } from './utils/validateTimeCards';

type TimecardModalProps = {
  timecard?: TimecardType;
  staff?: StaffType;
  onClose?: () => void;
  onSave: (timecard: TimecardType) => void;
  switchDeleteModal?: (shouldDelete: boolean) => void;
  setOpenCancelModal?: (shouldCancel: boolean) => void;
  openEditModal: boolean;
  setOpenEditModal: (shouldOpen: boolean) => void;
  openCancelModal?: boolean;
  openDeleteModal?: boolean;
  setRefreshTimeCard?: () => void;
  isEdit?: boolean;
};

const TimecardModal = ({
  onSave,
  setRefreshTimeCard,
  timecard,
  staff,
  onClose,
  switchDeleteModal,
  setOpenCancelModal,
  openEditModal,
  openCancelModal,
  openDeleteModal,
  setOpenEditModal,
  isEdit = false,
}: TimecardModalProps) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<FormErrorType>({});
  const [formData, setFormData] = useState<FormDataType>(getInitialTimecardValues(timecard));
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [notClockedOut, setNotClockedOut] = useState<{ clockedIn: Timestamp } | null>(null);
  const [cancelModal] = useState(openCancelModal);
  const { defaultOrganization } = useUser();
  const { currentUser } = useAuthUser();
  const location = usePrimaryLocation();
  const title = isEdit ? t('timecards.editTimesheet') : t('timecards.addTimecards');

  function getInitialTimecardValues(timecard?: TimecardType): FormDataType {
    if (!timecard) {
      return {
        clockedInTime: convertTo24Hour(dayjs(new Date()).format('hh:mm a')),
        clockedInDate: new Date(),
        clockedOutDate: new Date(),
      };
    }

    const { clockedIn, clockedOut } = timecard;
    const { clockedInTime, clockedOutTime } = toDisplayTime(timecard, true);

    return {
      staffId: staff?.id,
      roomId: timecard.roomId,
      clockedInDate: clockedIn,
      clockedInTime: clockedInTime ?? '',
      clockedOutDate: clockedOut || new Date(),
      clockedOutTime: clockedOutTime ?? '',
    };
  }

  const handleCloseSlider = () => {
    if (isEdit) {
      if (!cancelModal) {
        setOpenEditModal(false);
        onClose?.();
        return;
      }

      if (openDeleteModal) {
        return;
      }

      if (setOpenCancelModal) {
        setOpenCancelModal(true);
      }
    } else {
      setOpenEditModal(false);
      setTimeout(() => {
        setFormData({
          clockedInTime: convertTo24Hour(dayjs(new Date()).format('hh:mm a')),
          clockedInDate: new Date(),
          clockedOutDate: new Date(),
        });
        setErrors({});
        setNotClockedOut(null);
      }, 500);
    }
  };

  const onCancel = () => {
    if (setOpenCancelModal) {
      setOpenCancelModal(true);
    }
    if (!isEdit) {
      handleCloseSlider();
    }
  };

  const onCloseLocal = () => {
    setIsSaving(false);
    setErrors({});
    setFormData(getInitialTimecardValues(timecard));
    onClose?.();
    handleCloseSlider();
  };

  const handleEdit = (event) => {
    event.preventDefault();

    const { totalTime } = calculateClockInOutTimeDifference(formData);
    const resultingErrors = validateTimeCards({
      formData,
      timeDifferenceInMinutes: totalTime,
    });

    if (Object.keys(resultingErrors).length > 0) {
      setErrors(resultingErrors);
      return;
    }

    setIsSaving(true);
    try {
      const { clockedIn, clockedOut, status } = getClockedOutStatus(formData);
      const timecardLocal = {
        ...timecard,
        roomId: formData.roomId,
        clockedIn,
        clockedOut,
        status,
      } as TimecardType;
      onSave?.(timecardLocal);
      onCloseLocal();
    } catch (error) {
      logError('TimecardEditModal: failed saving timecard', timecard, error);
    } finally {
      setIsSaving(false);
      setErrors({});
      setOpenEditModal(false);
      if (setOpenCancelModal) {
        setOpenCancelModal(false);
      }
    }
  };

  const handleAdd = () => {
    const { totalTime } = calculateClockInOutTimeDifference(formData);
    const errorsLocal = validateTimeCards({ formData, timeDifferenceInMinutes: totalTime, notClockedOut });
    const locationId = formData.locationId || staff?.defaultLocation || location.id;

    if (!locationId) {
      errorsLocal.locationId = 'errors.validation.locationRequired';
    }

    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      return;
    }
    if (!currentUser?.uid && !currentUser?.displayName) {
      return;
    }
    setIsSaving(true);
    const { clockedIn, clockedOut, status } = getClockedOutStatus(formData);
    try {
      const timecardLocal = {
        uid: formData.uid || currentUser.uid,
        contactId: formData.contactId,
        organizationId: defaultOrganization,
        locationId,
        roomId: formData.roomId!,
        status,
        clockedIn,
        clockedOut,
        createdAt: firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          displayName: currentUser?.displayName,
        },
        updatedAt: firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          displayName: currentUser.displayName,
        },
      };
      if (onSave) {
        onSave(timecardLocal);
      }
    } catch (error) {
      logError('AddTimecard: failed saving timecard', error);
    } finally {
      setIsSaving(false);
      if (setRefreshTimeCard) {
        setRefreshTimeCard();
      }
      setTimeout(() => onCancel(), 500);
    }
  };

  const disabled = () => {
    return !(!isSaving && formData.roomId && formData.contactId && formData.clockedInDate && formData.clockedInTime);
  };

  return (
    <>
      <div className="md:w-50 w-full">
        <SlideOver hasOverlay onClose={handleCloseSlider} isOpen={openEditModal} title={title}>
          <TimecardModalContent
            errors={errors}
            setErrors={setErrors}
            formData={formData}
            setFormData={setFormData}
            staff={staff}
            switchDeleteModal={switchDeleteModal}
            isEdit={isEdit}
            setNotClockedOut={setNotClockedOut}
            notClockedOut={notClockedOut}
          />
          <div className="flex w-full flex-row items-center justify-end gap-3 bg-white px-2 pt-2">
            <div className="gap-3 w-24">
              <Button onClick={onCancel} extraClasses="w-full text-center py-2 px-8" data-testid="timecard-add-cancel">
                {t('common.cancel')}
              </Button>
            </div>
            <div className="gap-3 w-24">
              {isEdit ? (
                <Button
                  primary
                  onClick={handleEdit}
                  disabled={isSaving}
                  extraClasses="w-full text-center"
                  data-testid="timecard-edit-save"
                >
                  {t('timecards.update')}
                </Button>
              ) : (
                <Button
                  primary
                  onClick={handleAdd}
                  disabled={disabled()}
                  extraClasses="w-full text-center py-2 px-8"
                  data-testid="timecard-add-save"
                >
                  {t('Add')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver>
      </div>
    </>
  );
};

export default React.memo(TimecardModal);
