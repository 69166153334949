import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { logWarning } from '../../../rollbar';
import { paths } from '../../paths';

const DefaultRoute = ({ path }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== paths.featureFlags) {
      const str = `Default Route activated for ${location.pathname}`;
      logWarning(str, { location });
    }
  }, [location]);
  return <Redirect to={path} />;
};
export default DefaultRoute;
