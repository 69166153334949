import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import RoomsList from '../Components/Rooms/RoomsList';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Rooms = (_props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.rooms);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Rooms') })}
      </title>
      <body className="rooms" />
    </Helmet>
  );

  return (
    <MainContentLayout>
      {renderPageHead()}
      <RoomsList />
    </MainContentLayout>
  );
};

export default Rooms;
