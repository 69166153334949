export const UNKNOWN_TEXT = 'Unknown';

export const PAGE_NAMES = {
  addStudent: 'Add Student',
  calendar: 'Calendar',
  contacts: 'Family And Contacts',
  dashboard: 'Dashboard',
  devices: 'Devices',
  enrolledFamilies: 'Enrolled Families',
  error: 'Error',
  featureFlags: 'Feature Flags',
  foodProgramClaims: 'Claims Management',
  foodProgramClaimCreate: 'Claim Create',
  foodProgramClaimUpdate: 'Claim Update',
  foodProgramMeals: 'Meal Planning',
  foodProgramMealCreate: 'Meal Create',
  foodProgramMealUpdate: 'Meal Update',
  foodProgramMealRecord: 'Meal Record',
  foodProgramMealRecordCreate: 'Meal Record Create',
  foodProgramMenuPlanning: 'Menu Planning',
  foodProgramMenuPlanningUpdate: 'Edit Menu',
  foodProgramReports: 'Food Program Reports',
  importer: 'Importer',
  invoicePlanEdit: 'Invoice Plan Edit',
  invoicePlans: 'Invoice Plans',
  invoiceReports: 'Invoice Reports',
  invoices: 'Invoices',
  locations: 'Locations',
  organizationDetail: 'Organization Detail',
  organizations: 'Organizations',
  profile: 'Profile',
  rooms: 'Rooms',
  staff: 'Staff',
  students: 'Students',
  transactions: 'Transactions',
  home: 'Home',
  finance: 'Finance',
  welcome: 'Welcome',
  formsAndDocuments: 'Documents',
  calculator: 'Time/Space Percentage Calculator',
  debug: 'Debug Environment',
};
export const PAGE_CATEGORIES = {
  default: 'CCMS 2.0 Page',
};

export const SEGMENT_EVENTS = {
  parentInvited: 'Parent Invited',
  parentUpdated: 'Parent Updated',
  staffInvited: 'Staff Invitation Created',
  staffCreated: 'Staff Created',
  staffUpdated: 'Staff Updated',
  studentCreated: 'Student Created',
  transactionCreated: 'Transaction Created',
  firstInvoiceStart: 'First Invoice - Start',
  firstInvoiceCancel: 'First Invoice - Cancel',
  firstInvoiceDetailsError: 'First Invoice - Details Error',
  firstInvoiceDetailsSuccess: 'First Invoice - Details Success',
  firstInvoiceSelectStudents: 'First Invoice - Select Students',
  firstInvoiceComplete: 'First Invoice - Complete',
  addPayoutAccountInvoiceEmail: 'Add Payout Account - Invoice Email',
  addPayoutAccountInvoicePlanEmail: 'Add Payout Account - Invoice Plan Email',
  addPayoutAccountInvoiceConnectAccount: 'Add Payout Account - Invoice Connect Account',
  addPayoutAccountInvoicePlanConnectAccount: 'Add Payout Account - Invoice Plan Connect Account',
  addPayoutAccountTransactionConnectAccount: 'Add Payout Account - Transaction Connect Account',
  addPayoutAccountHomeConnectAccount: 'Add Payout Account - Home Connect Account',
  addPayoutAccountInvoiceFixIssues: 'Add Payout Account - Invoice Fix Issues',
  addPayoutAccountInvoicePlanFixIssues: 'Add Payout Account - Invoice Plan  Fix Issues',
  addPayoutAccountSettings: 'Add Payout Account - Settings',
};
