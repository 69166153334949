import BasePlugin from '@uppy/core/lib/BasePlugin.js';
import { v4 as uuidv4 } from 'uuid';

export default class FirebaseCloudStorage extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    if (!opts.storageRef) {
      throw Error(
        'Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files'
      );
    }
    this.type = 'uploader';
    this.id = 'FirebaseCloudStorage';
    this.title = 'Firebase Cloud Storage';
    this.storageRef = opts.storageRef;
    this.refId = opts.refId || '';
    this.renameFile = opts.rename;
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map((id) => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);

          const refId = this.renameFile ? `${this.refId || uuidv4()}.${file.extension}` : file.name;

          this.uppy.setFileMeta(id, { refId });
          const fileRef = this.storageRef.child(refId);
          const metaData = {
            ...file.meta,
            contentType: file.type,
          };
          this.uppy.emit('upload-started', file);
          const uploadTask = fileRef.put(file.data, metaData);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progressInfo = {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes,
              };
              this.uppy.emit('upload-progress', file, progressInfo);
            },
            (error) => {
              this.uppy.emit('upload-error', file, error);
              reject(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                const file = this.uppy.getFile(id);
                if (!file) return;
                file.downloadUrl = downloadUrl;
                this.uppy.emit('upload-success', file, uploadTask.snapshot, downloadUrl);
                fileRef.updateMetadata(metaData);

                resolve();
              });
            }
          );
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this.uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}
