import Rollbar from 'rollbar';
import { getConfig, isLocal, isDev } from '../config/env';

export const getAccessToken = () => {
  return getConfig()?.wsConfig?.rollbar?.accessToken || undefined;
};

export const getEnvironment = () => {
  return getConfig()?.wsConfig?.rollbar?.environment || undefined;
};

export const getCodeVersion = () => {
  return process.env?.REACT_APP_GIT_SHA || undefined;
};

export const getInitialPayload = () => {
  return {
    environment: getEnvironment(),
    code_version: getCodeVersion(),
    user_context: {},
    custom_context: {},
  };
};

export const getRollbarConfig = () => {
  return {
    accessToken: getAccessToken(),
    environment: getEnvironment(),
    captureUncaught: true,
    captureUnhandledRejections: true,
    autoInstrument: true,
    verbose: isDev(),
    enabled: !isLocal(),
    payload: {
      ...getInitialPayload(),
      client: {
        javascript: {
          source_map_enabled: true, // true by default
          code_version: getCodeVersion(),

          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true,
        },
      },
    },
  };
};
const initRollbar = () => {
  if (!window.Rollbar) {
    window.Rollbar = window.Rollbar || new Rollbar(getRollbarConfig());
  }
  return window.Rollbar;
};

export const getRollbar = () => initRollbar();
