import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { routes } from '../../config/routes';
import { formatFullName } from '../../helpers/utils';

const UserProfileMenu = ({ user, history, viewport = 'desktop', onClick = undefined }) => {
  const { t } = useTranslation();

  if (!user || !user.email) return null;

  const handleChange = (e, { value }) => {
    switch (value) {
      case 'profile':
        if (onClick) {
          onClick();
        }
        history.push(routes.profile);
        break;
      case 'settings':
        history.push(routes.settings);
        break;
      case 'sign-out':
        history.push(routes.signout);
        break;
      default:
        break;
    }
  };

  const trigger = (
    <span>
      <Avatar
        name={formatFullName(user, true)}
        round
        size={35}
        className="left floated"
        src={user.photoURL}
        data-testid="avatar"
      />
      <span>
        &nbsp;&nbsp;
        {user.displayName || user.email}
      </span>
    </span>
  );

  const options = [
    {
      key: 'profile',
      value: 'profile',
      text: t('Profile'),
      // icon: 'user'
    },

    {
      key: 'sign-out',
      value: 'sign-out',
      text: t('Sign out'),
      // icon: 'sign out'
    },
  ];

  const userMenu =
    viewport === 'mobile' ? (
      <Dropdown trigger={trigger} options={options} selectOnBlur={false} onChange={handleChange} />
    ) : (
      <Dropdown
        item
        trigger={trigger}
        options={options}
        selectOnBlur={false}
        onChange={handleChange}
        value="" // never select a menu item
        data-testid="user-profile"
      />
    );

  return userMenu;
};

UserProfileMenu.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
  }),
  onClick: PropTypes.func,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps, {})(UserProfileMenu));
