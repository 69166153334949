import { MenuPlanningEdit } from '@wonderschool/common-food-program-ui';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

interface MenuPlanningUpdateRouteParams {
  date: string;
}

const MenuPlanningUpdate = () => {
  useSegmentPage(PAGE_NAMES.foodProgramMenuPlanningUpdate);

  const { t } = useTranslation();
  const title = t('food.menu.planning');
  const { gotoRouteByName } = useRoutes();
  const { date } = useParams<MenuPlanningUpdateRouteParams>();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );
  const handleClickBack = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING, [
      {
        name: 'date',
        value: date,
      },
    ]);
  };
  const menuEventDate = date ? dayjs(date).toDate() : new Date();

  return (
    <>
      {renderPageHead()}
      <MenuPlanningEdit onClickBack={handleClickBack} menuEventDate={menuEventDate} />
    </>
  );
};
export default MenuPlanningUpdate;
