import { useTranslation } from 'react-i18next';
import './FeeDisclaimer.scss';

export type MonetizationType = {
  percentage: number;
  payer: 'provider' | 'parent';
  isEnabled: boolean;
};

type Props = {
  monetization?: MonetizationType;
  isGeneric?: boolean;
};

const FeeDisclaimer: React.FC<Props> = ({ monetization, isGeneric }) => {
  const { t } = useTranslation();

  if (!monetization?.isEnabled) return null;

  if (isGeneric) {
    return (
      <div className="fee-disclaimer" data-testid="fee-disclaimer-generic">
        {t('billing.invoiceDetails.feeDisclaimerGeneric')}
      </div>
    );
  }

  const { percentage, payer } = monetization;

  if (payer === 'provider') {
    return (
      <div className="fee-disclaimer" data-testid="fee-disclaimer-provider">
        {t('billing.invoiceDetails.feeDisclaimerProviderPay', {
          percentage: percentage,
        })}
      </div>
    );
  }

  if (payer === 'parent') {
    return (
      <div className="fee-disclaimer" data-testid="fee-disclaimer-parent">
        {t('billing.invoiceDetails.feeDisclaimerParentPay', {
          percentage: percentage,
        })}
      </div>
    );
  }

  return null;
};

export default FeeDisclaimer;
