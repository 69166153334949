import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { csvHeaders } from './importStudentsConst';
const ImportDataModalResult = ({ resultData }) => {
  const { t } = useTranslation();
  if (!resultData)
    return (
      <div className="text-black">
        <p>{t('csv wrong headers errors')}</p>
      </div>
    );
  if (resultData.errorCSVMessage)
    return (
      <div className="text-black">
        <p>{resultData.errorCSVMessage}</p>
      </div>
    );
  const { studentsError, totalData, roomError, duplicateData } = resultData;
  const validData = totalData?.filter(
    (student: any) =>
      student[csvHeaders.CLASS_ROOM_NAME] && (student[csvHeaders.PARENT_1_EMAIL] || student[csvHeaders.PARENT_2_EMAIL])
  );

  const uniqueClassrooms = new Set(validData?.map((student: any) => student[csvHeaders.CLASS_ROOM_NAME]));
  const uniqueParentEmail = new Set(
    validData?.map((student: any) => student[csvHeaders.PARENT_1_EMAIL] || student[csvHeaders.PARENT_2_EMAIL])
  );
  let hasRoomError: any, hasStudentNameError: any;
  let errorRoomMsg: any;
  if (roomError) {
    hasRoomError = (roomError.roomError?.filter((error: string) => error !== '') || []).join(', ');
    hasStudentNameError = (roomError.studentName?.filter((error: string) => error !== '') || []).join(', ');
  }
  if (hasRoomError && hasStudentNameError) {
    errorRoomMsg = t('{{hasRoomError}} {{hasStudentNameError}} has room error', {
      hasRoomError,
      hasStudentNameError,
    });
  }

  function countStudentsInClassrooms() {
    const classroomCounts = {};

    // Initialize counts for each unique classroom
    uniqueClassrooms.forEach((classroom: any) => {
      classroomCounts[classroom] = 0;
    });

    // Count students in each unique classroom
    validData?.forEach((student: any) => {
      const classroomName = student[csvHeaders.CLASS_ROOM_NAME];
      if (uniqueClassrooms.has(classroomName)) {
        classroomCounts[classroomName]++;
      }
    });
    return classroomCounts;
  }

  const classroomCounts = countStudentsInClassrooms();
  return (
    <>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
      <div className="flex w-96 flex-col justify-center">
        <div className="text-black">
          {studentsError?.length > 0 && (
            <ul className="mt-2 rounded-lg border border-solid border-red-500 bg-red-200 p-4">
              <p>
                {studentsError?.length} {t('errors found')}
              </p>
              {studentsError.map((data, index) => (
                <div key={index} className="pb-2">
                  <li>
                    {data.missingDataLength === 0 && !!data.studentName
                      ? `${data.studentName} ${t('has missing')} ${data.errorHeader}`
                      : `${data.studentName} ${t('has invalid')} ${data.errorHeader}`}
                  </li>
                </div>
              ))}
              {duplicateData?.length > 0 && (
                <div>
                  <p>
                    {/*  eslint-disable-next-line i18next/no-literal-string */}
                    <b>Duplicate Data errors:</b>
                  </p>
                  {duplicateData?.map((data: any, index: Key) => (
                    <div key={index} className="pb-2">
                      {data}
                    </div>
                  ))}
                </div>
              )}
            </ul>
          )}
          {errorRoomMsg && roomError?.roomError?.length > 0 && (
            <div className="mt-2 rounded-lg border border-solid border-red-500 bg-red-200 p-4">{errorRoomMsg}</div>
          )}
          {studentsError?.length === 0 && duplicateData?.length === 0 && !errorRoomMsg && (
            <div className="p-3">
              <p>
                <b>{t('Completed')}</b>
              </p>
              <ul>
                {Object.entries(classroomCounts).map(([classroom, count]) => (
                  <li
                    className="pb-3"
                    key={classroom}
                  >{`${count} Students were added to ${classroom} successfully.`}</li>
                ))}
              </ul>
              <ul>
                <li className="pb-3">
                  {uniqueParentEmail.size} {t('Emails added')}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportDataModalResult;
