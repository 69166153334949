import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

// Import components
import wsLogo from '../styles/img/wonderschool/logo-base.png';
import Loading from './Loading';
import { getTitlePrefix } from '../config/env';

const ExternalRedirect = ({ externalUrl, shouldUseReplace }) => {
  const { t } = useTranslation();
  const [didTimeout, setDidTimeout] = useState(false);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Redirecting') })}
      </title>
      <body className="auth redirect" />
    </Helmet>
  );

  useEffect(() => {
    let stillMounted = true;
    if (!didTimeout) {
      setTimeout(() => {
        if (stillMounted && !didTimeout) {
          setDidTimeout(true);
          if (shouldUseReplace) {
            window.location.replace(externalUrl);
          } else {
            window.location.assign(externalUrl);
          }
        }
      }, 200); // Give things a little time to settle before committing to the redirect. Makes things less jittery
    }
    return () => {
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalUrl]);

  return (
    <div className="container mx-auto px-4 py-8">
      {renderPageHead()}

      <div className="bg-white shadow-md rounded-lg p-8 text-center">
        <img src={wsLogo} alt="Wonderschool logo" className="mx-auto mb-4 h-16 w-auto" />
      </div>

      {didTimeout ? (
        <Loading>
          <a href={externalUrl}>{t('Click here to be redirected')}</a>
        </Loading>
      ) : (
        <Loading>{t('Redirecting ...')}</Loading>
      )}
    </div>
  );
};

export default ExternalRedirect;
