import Validator from 'validator';
import { StudentPersonalInfoFormData } from '../../StudentPersonalInformationForm';

export type StudentPersonalInformationFormValidationErrors = {
  firstName?: string;
  lastName?: string;
  doctorPhone?: string;
  kinderConnectId?: string;
};

export function validateStudentPersonalInformationForm(data: Partial<StudentPersonalInfoFormData>) {
  const errors: StudentPersonalInformationFormValidationErrors = {};

  // note: while they do not look like that, these are translation keys
  if (!data.firstName) {
    errors.firstName = 'First Name is required';
  }

  if (!data.lastName) {
    errors.lastName = 'Last Name is required';
  }

  if (data.doctorPhone && !Validator.isMobilePhone(data.doctorPhone, 'en-US')) {
    errors.doctorPhone = 'Phone is invalid';
  }

  return errors;
}
