import moment from 'moment-timezone';
import autoTable from 'jspdf-autotable';
import { formatHoursFromMinutes } from '../helpers/dates';
import { saveCSV, initializePDFDoc } from '../common';
import { SortDirectionEnum, SortDirectionType } from '../common';
import { DailyHoursType, StaffMapType, TimecardType, TotalHoursType } from './types';

export type DisplayTimeType = {
  clockedInDate: string; //MM/DD/YYYY
  clockedInTime: string; //h:mm a
  clockedOutDate?: string; //MM/DD/YYYY
  clockedOutTime: string; //h:mm a
  totalHours: string; //h:mm
};
export function convertTo24Hour(time) {
  // Extract the components of the time string
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':');

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Adjust hours based on the modifier
  if (modifier?.toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  } else if (modifier?.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  // Pad hours and minutes with leading zeros if necessary
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.padStart(2, '0');

  // Return the formatted 24-hour time string
  return `${hours}:${minutes}`;
}
// Takes an ISO string and returns a DisplayTimeType
export function toDisplayTime(timecard: TimecardType, includeLeadingZero?: boolean): DisplayTimeType {
  const displayTime: DisplayTimeType = {} as DisplayTimeType;

  const timeFormat = includeLeadingZero ? 'hh:mm a' : 'h:mm a';

  // use the clockedIn for the date
  const clockedInMoment = timecard.clockedIn ? moment(timecard.clockedIn) : null;
  const clockedOutMoment = timecard.clockedOut ? moment(timecard.clockedOut) : null;
  const totalMinutes = clockedInMoment && clockedOutMoment ? clockedOutMoment.diff(clockedInMoment, 'minutes') : null;

  displayTime.clockedInDate = clockedInMoment ? clockedInMoment.format('MM/DD/YYYY') : '';
  displayTime.clockedInTime = clockedInMoment ? clockedInMoment.format(timeFormat) : '';
  displayTime.clockedOutDate = clockedOutMoment ? clockedOutMoment.format('MM/DD/YYYY') : '';
  displayTime.clockedOutTime = clockedOutMoment ? clockedOutMoment.format(timeFormat) : '';
  displayTime.totalHours = totalMinutes !== null ? formatHoursFromMinutes(totalMinutes) : '';
  return displayTime;
}
export function formatDisplayTime(time: string, includeLeadingZero?: boolean) {
  const timeFormat = includeLeadingZero ? 'hh:mm a' : 'h:mm a';
  return moment(time).format(timeFormat);
}

// takes a string of the format 'MM/DD/YYYY hh:mm a' and returns an ISO string in UTC
export function parseDateString(display: string): Date {
  const momentDate = moment(display, 'MM/DD/YYYY hh:mm a', false);
  if (!momentDate.isValid()) throw new Error('Invalid date string');
  return momentDate.utc(false).toDate();
}

// This is only used for table display, which is why we define it down here, not at the top
const EMPTY_CHAR = '-';

// Create an array of StaffTotalHoursType for use in the StaffTotalHoursTable
// or for PDF and CSV exports
export function generateTotalHours(
  timecards: TimecardType[],
  staffMap: StaffMapType,
  roomsMap: Record<string, any>
): TotalHoursType[] {
  const timecardsGroupedByStaff = timecards.reduce(
    (map: any, timecard) => {
      const { contactId: _contactId, uid } = timecard;
      if (!map[uid]) {
        map[uid] = [];
      }
      map[uid].push(timecard);
      return map;
    },
    {} as Record<string, TimecardType[]>
  );

  const totalHours = Object.keys(timecardsGroupedByStaff).map((staffId) => {
    const staffTimecards = timecardsGroupedByStaff[staffId];

    const staffRoomNameMap = staffTimecards.reduce(
      (map: Record<string, string>, timecard: TimecardType) => {
        const room = roomsMap[timecard.roomId];
        if (room) {
          map[timecard.roomId] = room.name;
        }
        return map;
      },
      {} as Record<string, string>
    );

    const roomNameArray: string[] = Object.values(staffRoomNameMap);
    const roomNames = roomNameArray.sort((a, b) => a.localeCompare(b)).join(', ');

    type StaffTotalHoursType = {
      startDate?: Date;
      endDate?: Date | null;
      totalMinutes: number;
    };

    // For each staff member, iterate over their timecards and calculate the total hours for each
    const staffTotalHours: StaffTotalHoursType = staffTimecards.reduce(
      (staffTotalHours: StaffTotalHoursType, timecard: TimecardType) => {
        const { clockedIn, clockedOut } = timecard;
        if (!staffTotalHours.startDate || clockedIn.getTime() < staffTotalHours.startDate.getTime()) {
          staffTotalHours.startDate = clockedIn;
        }
        if (!staffTotalHours.endDate || (clockedOut?.getTime() ?? 0) > staffTotalHours.endDate.getTime()) {
          staffTotalHours.endDate = clockedOut;
        }
        const minutes = clockedOut ? (clockedOut.getTime() - clockedIn.getTime()) / 60000 : 0;
        staffTotalHours.totalMinutes += Math.round(minutes);
        return staffTotalHours;
      },
      { startDate: undefined, endDate: undefined, totalMinutes: 0 } as StaffTotalHoursType
    );

    const staff = staffMap[staffId];
    const startDateMoment = moment(staffTotalHours.startDate);
    const endDateMoment = moment(staffTotalHours.endDate);
    const startDateDisplay = staffTotalHours.startDate ? startDateMoment.format('MM/DD/YYYY') : EMPTY_CHAR;
    const endDateDisplay = staffTotalHours.endDate ? endDateMoment.format('MM/DD/YYYY') : EMPTY_CHAR;

    return {
      contactId: staffId,
      photoURL: staff?.photoURL,
      displayName: staff?.displayName || EMPTY_CHAR,
      rooms: roomNames || EMPTY_CHAR,
      status: staff?.status || EMPTY_CHAR,
      startDate: startDateDisplay,
      endDate: endDateDisplay,
      totalHours: formatHoursFromMinutes(staffTotalHours.totalMinutes) || EMPTY_CHAR,
      totalHoursSort: staffTotalHours.totalMinutes?.toString() || EMPTY_CHAR,
    };
  });
  return totalHours;
}

// DailyHours array
export function generateDailyHours(
  timecards: TimecardType[],
  staffMap: StaffMapType,
  roomsMap: Record<string, any>
): DailyHoursType[] {
  return timecards.map((timecard) => {
    const staff = staffMap[timecard.uid];
    const room = roomsMap[timecard.roomId];
    const clockedInMoment = moment(timecard.clockedIn || new Date());
    const clockedOutMoment = moment(timecard.clockedOut || new Date());

    const clockedInDate = timecard.clockedIn ? clockedInMoment.format('MM/DD/YYYY') : EMPTY_CHAR;
    const clockedInDateSort = timecard.clockedIn ? clockedInMoment.clone().utc(false).format() : EMPTY_CHAR;
    const clockedOutDate = timecard.clockedOut ? clockedOutMoment.format('MM/DD/YYYY') : EMPTY_CHAR;
    const clockedOutDateSort = timecard.clockedOut ? clockedInMoment.clone().utc(false).format() : EMPTY_CHAR;
    const clockedInTime = timecard.clockedIn ? clockedInMoment.format('hh:mm a') : EMPTY_CHAR;
    const clockedInTimeSort = timecard.clockedIn ? clockedInMoment.format('HH:mm') : EMPTY_CHAR;
    const clockedOutTime = timecard.clockedOut ? clockedOutMoment.format('hh:mm a') : EMPTY_CHAR;
    const clockedOutTimeSort = timecard.clockedOut ? clockedOutMoment.format('HH:mm') : EMPTY_CHAR;
    const totalMinutes = clockedOutMoment.diff(clockedInMoment, 'minutes');

    return {
      timecardId: timecard.id,
      photoURL: staff?.photoURL,
      displayName: staff?.displayName || EMPTY_CHAR,
      room: room?.name || EMPTY_CHAR,
      status: staff?.status || EMPTY_CHAR,
      clockedInDate,
      clockedInDateSort,
      clockedOutDate,
      clockedOutDateSort,
      clockedInTime,
      clockedInTimeSort,
      clockedOutTime,
      clockedOutTimeSort,
      totalHours: formatHoursFromMinutes(totalMinutes) || EMPTY_CHAR,
      totalHoursSort: totalMinutes?.toString() || EMPTY_CHAR,
    };
  }) as DailyHoursType[];
}

const DAILY_HOURS_COLUMNS = [
  { header: 'Name', dataKey: 'displayName' },
  { header: 'Room', dataKey: 'room' },
  { header: 'Date', dataKey: 'clockedInDate' },
  { header: 'Clock in', dataKey: 'clockedInTime' },
  { header: 'Clock out', dataKey: 'clockedOutTime' },
  { header: 'Total hours', dataKey: 'totalHours' },
];

export function generateDailyHoursPDF(dailyHours: DailyHoursType[]) {
  const doc = initializePDFDoc('Daily Hours');
  autoTable(doc, {
    columns: DAILY_HOURS_COLUMNS,
    body: dailyHours,
    startY: 45,
  });
  return doc.save('DailyHours.pdf');
}

export function generateDailyHoursCSV(dailyHours: DailyHoursType[]) {
  const headers = DAILY_HOURS_COLUMNS.map((column) => column.header).join(',') + '\n';
  const body = dailyHours
    .map((row) => {
      return [row.displayName, row.room, row.clockedInDate, row.clockedInTime, row.clockedOutTime, row.totalHours].join(
        ','
      );
    })
    .join('\n');
  const csv = headers + body;
  saveCSV(csv, 'DailyHours.csv');
}

const TOTAL_HOURS_COLUMNS = [
  { header: 'Name', dataKey: 'displayName' },
  { header: 'Rooms', dataKey: 'rooms' },
  { header: 'Start date', dataKey: 'startDate' },
  { header: 'End date', dataKey: 'endDate' },
  { header: 'Total hours', dataKey: 'totalHours' },
];

export function generateTotalHoursPDF(totalHours: TotalHoursType[]) {
  const doc = initializePDFDoc('Total Hours');
  autoTable(doc, {
    columns: TOTAL_HOURS_COLUMNS,
    body: totalHours,
    startY: 45,
  });
  doc.save('TotalHours.pdf');
  doc.autoPrint();
}
export function generateTotalHoursCSV(totalHours: TotalHoursType[]) {
  const headers = TOTAL_HOURS_COLUMNS.map((column) => column.header).join(',') + '\n';
  const body = totalHours

    .map((row) => {
      return [row.displayName, row.rooms, row.startDate, row.endDate, row.totalHours].join(',');
    })
    .join('\n');
  const csv = headers + body;
  saveCSV(csv, 'TotalHours.csv');
}

// Sort the collection by the given sortDirection.
// for any undefined value, use a space to sort it to the top or bottom
type CollectionType = DailyHoursType[] | TotalHoursType[];
export function sortCollection(collection: CollectionType, sortDirection: SortDirectionType): CollectionType {
  if (sortDirection.direction === SortDirectionEnum.NONE || !sortDirection.name) return collection;

  const newCollection = [...collection];

  newCollection.sort((a: any, b: any) => {
    const aVal = a?.[sortDirection.name]?.toLowerCase() ?? ' ';
    const bVal = b?.[sortDirection.name]?.toLowerCase() ?? ' ';
    return sortDirection.direction === SortDirectionEnum.ASC ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
  });
  return newCollection as CollectionType;
}
