import dayjs from 'dayjs';

/**
 * This utility function is used for converting the Date objects in ActivitiesFiltersType to a string that can be
 * consumed by <Input type="date">. If you no longer use this specific input, then you can delete this tiny helper.
 * @param {Date} date
 * @returns {string} in `yyyy-MM-DD` format.
 */
export function convertDateToInputSpecificString(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}
