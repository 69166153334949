import { updateOrganization } from '../api/firebase/organizations';

export const ORGANIZATION_PAYMENTS_ENABLED_DEFAULT = false;
export const USER_PAYMENTS_ENABLED_DEFAULT = true;

export const StripeOnboardingStatus = {
  NOT_STARTED: 'notStarted', // No calls to Stripe have been made yet
  STARTED: 'started', // The initial redirect to Stripe has occurred
  FAILED: 'failed', // User went through onboarding, but there was an error
  SUCCEEDED: 'succeeded', // User went through onboarding, but it succeeded
  COMPLETED: 'completed', // After a successful onboarding, the user dismisses the message.
};

export function didStripeOnboardingSucceed(organization) {
  return (
    !!organization.stripe?.accountId &&
    (isStripeOnboardingStatusSetToSucceeded(organization) || isStripeOnboardingStatusSetToCompleted(organization))
  );
}

// return true if no Stripe account id exists, or if one does, but onboarding status is not 'completed'
export function showSetupPayoutRibbon(organization) {
  const hasAccountId = !!organization?.stripe?.accountId;
  const arePaymentsEnabled = areStripePaymentsEnabledForOrganization(organization);
  const isCompleted = isStripeOnboardingStatusSetToCompleted(organization);

  return arePaymentsEnabled && (!hasAccountId || (hasAccountId && !isCompleted));
}

export function showSetupPayoutModal(organization) {
  const arePaymentsEnabled = areStripePaymentsEnabledForOrganization(organization);
  const hasAccountId = Boolean(organization?.stripe?.accountId);
  const isSetupPayoutModalDismissed = Boolean(organization?.stripe?.setupModalDismissed);

  const onboardingStatus = organization?.stripe?.onboardingStatus;
  const isSetupNotStarted = !onboardingStatus || onboardingStatus === StripeOnboardingStatus.NOT_STARTED;

  return arePaymentsEnabled && !hasAccountId && !isSetupPayoutModalDismissed && isSetupNotStarted;
}

// Finish just means either SUCCEEDED or FAILED, i.e. the full flow happened through Stripe
export function didStripeOnboardingFinish(organization) {
  return isStripeOnboardingStatusSetToFailed(organization) || isStripeOnboardingStatusSetToSucceeded(organization);
}

export function isStripeOnboardingAllowed(organization) {
  return organization?.stripe?.onboardingStatus !== StripeOnboardingStatus.NOT_ALLOWED;
}

export function isStripeOnboardingStatusSetToStarted(organization) {
  return organization?.stripe?.onboardingStatus === StripeOnboardingStatus.STARTED;
}

export function isStripeOnboardingStatusSetToFailed(organization) {
  return organization?.stripe?.onboardingStatus === StripeOnboardingStatus.FAILED;
}

export function isStripeOnboardingStatusSetToSucceeded(organization) {
  return organization?.stripe?.onboardingStatus === StripeOnboardingStatus.SUCCEEDED;
}
export function isStripeOnboardingStatusSetToCompleted(organization) {
  return organization?.stripe?.onboardingStatus === StripeOnboardingStatus.COMPLETED;
}

export function areStripePaymentsEnabledForUser(user) {
  return user?.stripe?.paymentsEnabled ?? false;
}

export function areStripePaymentsEnabledForOrganization(organization) {
  return organization?.stripe?.paymentsEnabled ?? ORGANIZATION_PAYMENTS_ENABLED_DEFAULT;
}
export function updateStripeOnboardingStatus(organizationId, stripeOnboardingStatus) {
  updateOrganization(organizationId, {
    'stripe.onboardingStatus': stripeOnboardingStatus,
  });
}

export async function dismissSetupPayoutModalFlag(organizationId) {
  await updateOrganization(organizationId, {
    'stripe.setupModalDismissed': true,
  });
}
