import BasePlugin from '@uppy/core/lib/BasePlugin.js';
import * as Papa from 'papaparse';
import CSVFileValidator from 'csv-file-validator';
import { csvHeaders, headerConfig } from './importStudentsConst';

/**
 * This plugin parses the CSV file and adds a new column 'invite' to the CSV file.
 * Then in the trigger that processes the CSV file, we can use the 'invite' column
 * to determine whether or not to send an invite email to the student.
 */
class StudentsCSVPlugin extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = 'StudentsCVSPlugin';
    this.type = 'csv-validator';
    this.shouldInviteCallback = opts.shouldInviteCallback;
    this.setResultData = opts.setResultData;
    this.setIsShowModalImportStudentDetails = opts.setIsShowModalImportStudentDetails;
    this.rooms = opts.rooms;

    this.prepareUpload = this.prepareUpload.bind(this);
  }

  prepareUpload = async (fileIds) => {
    const roomNames = this.rooms.map((room) => room.name);
    const shouldInvite = this.shouldInviteCallback();

    function checkUniqueSet(data) {
      let fullName = [];
      let duplicates = [];

      data.forEach((student) => {
        if (student[csvHeaders.STUDENT_MIDDLE_NAME]) {
          fullName.push(
            `${student[csvHeaders.STUDENT_FIRST_NAME]} ${student[csvHeaders.STUDENT_MIDDLE_NAME]} ${
              student[csvHeaders.STUDENT_LAST_NAME]
            }`
          );
        }
        fullName.forEach(function (value, index, array) {
          if (array.indexOf(value, index + 1) !== -1 && duplicates.indexOf(value) === -1) {
            duplicates.push(value);
          }
        });
      });
      return duplicates;
    }

    function CSVDataResult(data, msgs) {
      const duplicateData = checkUniqueSet(data);
      const studentsErrorMessages = [];
      const roomError = [];
      msgs.forEach((msg) => {
        const currentData = data[msg.rowNumber - 2];
        if (
          msg.headerName === csvHeaders.CLASS_ROOM_NAME ||
          msg.headerName === csvHeaders.STUDENT_FIRST_NAME ||
          msg.headerName === csvHeaders.STUDENT_LAST_NAME ||
          msg.headerName === csvHeaders.PARENT_1_FIRST_NAME ||
          msg.headerName === csvHeaders.PARENT_1_LAST_NAME ||
          msg.headerName === csvHeaders.PARENT_1_EMAIL ||
          msg.headerName === csvHeaders.PARENT_2_EMAIL ||
          msg.headerName === csvHeaders.PARENT_2_FIRST_NAME ||
          msg.headerName === csvHeaders.PARENT_2_LAST_NAME
        ) {
          const errorMsg = {
            studentName: currentData[csvHeaders.STUDENT_FIRST_NAME],
            errorHeader: msg.headerName,
            missingDataLength: currentData[msg.headerName].length,
          };
          studentsErrorMessages.push(errorMsg);
        }
      });
      const lowerCaseRooms = roomNames.map((room) => room.trim());
      let AllRoomsError = {};
      let studentName = [];
      data.forEach((item, index) => {
        data[index][csvHeaders.STUDENT_FIRST_NAME] = data[index][csvHeaders.STUDENT_FIRST_NAME].trim();
        data[index][csvHeaders.STUDENT_MIDDLE_NAME] = data[index][csvHeaders.STUDENT_MIDDLE_NAME].trim();
        data[index][csvHeaders.STUDENT_LAST_NAME] = data[index][csvHeaders.STUDENT_LAST_NAME].trim();

        const className = item[csvHeaders.CLASS_ROOM_NAME].trim();
        if (!lowerCaseRooms.includes(className)) {
          roomError.push(className);
          studentName.push(item[csvHeaders.STUDENT_FIRST_NAME]);
          AllRoomsError = { roomError, studentName };
        }
      });
      const formattedErrors = studentsErrorMessages.map((error) => {
        if (error.missingDataLength) {
          return { errorMessage: `${error.studentName} is missing ${error.errorHeader}` };
        } else {
          return { errorMessage: `${error.studentName} has invalid ${error.errorHeader}` };
        }
      });
      return {
        errorMsgs: formattedErrors,
        studentsError: studentsErrorMessages,
        totalData: data,
        roomError: AllRoomsError,
        duplicateData,
      };
    }

    for (const fileID of fileIds) {
      const file = this.uppy.getFile(fileID);
      const { data, inValidMessages } = await CSVFileValidator(file.data, headerConfig);
      const finalCSVResult = CSVDataResult(data, inValidMessages);
      this.setResultData(finalCSVResult);
      if (finalCSVResult.errorMsgs.length > 0 || finalCSVResult.roomError?.roomError?.length > 0) {
        this.setIsShowModalImportStudentDetails(true);
        this.uppy.removeFile(fileID);
        return Promise.resolve();
      }
    }

    return Promise.all(
      fileIds.map((id) => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);

          Papa.parse(file.data, {
            skipEmptyLines: true,
            complete: (results) => {
              const headers = results.data[0];

              // Empty headers, or already contains an invite column, so bail
              if (!headers?.length || headers[headers.length - 1].includes('shouldInvite')) {
                resolve(false);
                return;
              }
              results.data.forEach(function (row, rowIndex) {
                if (rowIndex === 0) {
                  //ignore header row}
                  row.push('shouldInvite');
                } else {
                  row.push(shouldInvite);
                }
                results.data[rowIndex].forEach((rawData, index) => {
                  if (typeof rawData == 'string') {
                    results.data[rowIndex][index] = rawData.trim();
                  }
                });
              });
              const csv = Papa.unparse(results.data);
              const newFile = new File([csv], file.name, {
                type: 'text/csv',
                lastModified: Date.now(),
                size: csv.length,
              });
              this.uppy.setFileState(id, { data: newFile });

              resolve(results);
            },
            error: function (error, file) {
              reject({ error: error, file: file });
            },
          });
        });
      })
    );
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export default StudentsCSVPlugin;
