import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, CalendarIcon, Dialog, SuccessIcon } from '@wonderschool/common-base-ui';
import { InvitationRecipientType, TuitionAndFeesType } from '../../types';
import { useMemo } from 'react';
import { getNextInvoiceDate, getTuitionAndFees } from '../../enrollmentsUtils';
import { Timestamp } from 'firebase/firestore';

type EnrollmentInvitationConfirmationModalProps = {
  student;
  invitationRecipients: InvitationRecipientType[];
  isOpen: boolean;
  onClose: () => void;
};

const EnrollmentInvitationConfirmationModal = ({
  student,
  invitationRecipients,
  isOpen,
  onClose,
}: EnrollmentInvitationConfirmationModalProps) => {
  const tuitionAndFees: TuitionAndFeesType = useMemo(() => getTuitionAndFees(student), [student]);
  const { t } = useTranslation();
  const nextInvoiceDate =
    Object.keys(tuitionAndFees).length > 0 && tuitionAndFees.tuition
      ? getNextInvoiceDate(
          tuitionAndFees.tuition.dueDate as unknown as Timestamp,
          tuitionAndFees.tuition.billingInterval,
          tuitionAndFees.tuition.proratedAmount
        )
      : null;
  const getInvitationRecipientsNames = () => {
    if (invitationRecipients.length > 1) {
      const displayNames = invitationRecipients.map((contact: InvitationRecipientType) => contact.displayName);
      return displayNames.join(',');
    }

    return invitationRecipients[0].displayName;
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} hasCloseButton={false}>
      <div className="pb-4 px-4 pt-10">
        <h1 className="text-xl pb-8 text-center">
          {invitationRecipients.length > 1
            ? t('enrollments.invitationModalTitle.single', {
                names: getInvitationRecipientsNames(),
              })
            : t('enrollments.invitationModalTitle.single', {
                name: getInvitationRecipientsNames(),
              })}
        </h1>
        <div className="flex gap-4 flex-col">
          {student.enrollment?.documents.length > 0 && (
            <div className="flex gap-2 items-center">
              <SuccessIcon className="h-10 w-10 text-blue-900" /> {t('enrollments.documentsSent')}
            </div>
          )}
          <div className="flex gap-2 items-center">
            <SuccessIcon className="h-10 w-10 text-blue-900" />{' '}
            {t('enrollments.studentAdded', {
              name: student.displayName,
              status: student.enrollment?.status,
            })}
          </div>
          {nextInvoiceDate && (
            <div className="flex gap-2 items-center">
              <SuccessIcon className="h-10 w-10 text-blue-900" />{' '}
              {t('enrollments.recurringPlanSet', { date: dayjs.utc(nextInvoiceDate).format('MM/DD') })}
            </div>
          )}
          {student.enrollment?.tuitionAndFees.tuition?.proratedAmount && (
            <div className="flex gap-2 items-center">
              <SuccessIcon className="h-10 w-10 text-blue-900" />{' '}
              {t('enrollments.oneTimeInvoiceSet', {
                date: dayjs.unix(student.enrollment?.tuitionAndFees?.tuition?.dueDate.seconds).utc().format('MM/DD'),
              })}
            </div>
          )}
          <div className="flex gap-2 items-center">
            <CalendarIcon className="h-10 w-10 text-blue-900" />{' '}
            {student.enrollment?.automaticallyEnroll
              ? t('enrollments.childAutomaticallyEnrolled', {
                  name: student.displayName,
                  date: dayjs.utc(student.enrollmentDate).format('MM/DD'),
                })
              : t('enrollments.childEnrolled', {
                  name: student.displayName,
                  date: dayjs.utc(student.enrollmentDate).format('MM/DD'),
                })}
          </div>
        </div>
        <div>
          <Button extraClasses="mt-10 w-full" primary onClick={onClose}>
            {t('common.done')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EnrollmentInvitationConfirmationModal;
