// TODO: Lodash should no longer be used here
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldPath,
  FirestoreError,
  getDoc,
  getDocs,
  limit as limitQ,
  onSnapshot,
  OrderByDirection,
  orderBy as orderByQ,
  QueryConstraint,
  QueryDocumentSnapshot,
  query as queryQ,
  serverTimestamp,
  setDoc,
  SetOptions,
  startAfter as startAfterQ,
  Transaction,
  updateDoc,
  WhereFilterOp,
  where as whereQ,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref as refS } from 'firebase/storage';
// eslint-disable-next-line no-restricted-imports
import { chunk, flatten } from 'lodash';

import { getAuth, getFirestore, getStorage } from '.';
import { logError, logLocal } from '../../rollbar';
import { buildObject, buildObjectsTree, DocWithId } from './helpers/firestoreUtils';

export const QUERY_CHUNK_SIZE = 10;

export const newDocument = ({ path: collectionPath }: { path: string }, firestoreCtx = getFirestore()) => {
  try {
    if (!collectionPath) throw Error('Path must be specified');
    return doc(collection(firestoreCtx, collectionPath));
  } catch (err: any) {
    logError(`Failed to get doc: ${err.message}`, err, { err, collectionPath });
    throw err;
  }
};

export const getDocument = async ({ path: docPath }: { path: string }, firestoreCtx = getFirestore()) => {
  try {
    if (!docPath) throw Error('Path must be specified');
    const docRef = doc(firestoreCtx, docPath);
    const snapshot = await getDoc(docRef);
    return buildObject(snapshot);
  } catch (err: any) {
    logError(`Failed to get doc: ${err.message}`, err, { err, docPath });
    throw err;
  }
};

export const startDocumentListener = (
  { path: docPath }: { path: string },
  next: (data: DocumentData) => void,
  error: ((error: FirestoreError) => void) | undefined,
  complete: (() => void) | undefined,
  firestoreCtx = getFirestore()
) => {
  if (!docPath) throw Error('Path must be specified');
  try {
    const docRef = doc(firestoreCtx, docPath);
    const unsubscribe = onSnapshot(
      docRef,
      (doc) =>
        next(
          doc.exists()
            ? {
                id: doc.id,
                ...doc.data(),
              }
            : {}
        ),
      error,
      complete
    );
    return unsubscribe;
  } catch (err: any) {
    logError(`Failed to listen to doc: ${err.message}`, err, { err, docPath });
    throw err;
  }
};

export type FirebaseQueryOrderBy = {
  field: FieldPath;
  direction: OrderByDirection; // asc | desc
};
export type FirebaseQueryCondition = {
  field: FieldPath;
  operation: WhereFilterOp;
  value: string | number | boolean | null | string[] | number[] | boolean[];
};
export type FirebaseChunkValueType = string | number | boolean;
export type FirebaseChunkableQueryCondition = {
  field: FieldPath;
  operation: WhereFilterOp;
  value: FirebaseChunkValueType[];
};
export type CollectionResults = {
  count?: number;
  list?: DocumentData[];
  last?: DocumentData;
};
export type FirebaseChunkedQueryOptions = {
  path: string;
  orderBy: FirebaseQueryOrderBy[];
  limit?: number | null;
  startAfter?: DocumentSnapshot | null;
  conditionsBase: FirebaseQueryCondition[];
  chunkableCondition: FirebaseChunkableQueryCondition | null;
};
export type FirebaseQueryOptions = {
  path: string;
  conditions: FirebaseQueryCondition[];
  orderBy: FirebaseQueryOrderBy[];
  limit?: number | null;
  startAfter?: DocumentSnapshot | null;
};

export const startChunkedCollectionListener = (
  {
    path: collectionPath,
    orderBy = [],
    limit = null,
    startAfter = null,
    conditionsBase = [], // (many) non-chunkable FirebaseQueryConditions
    chunkableCondition = null, // a single FirebaseQueryCondition where condition.value is an array
  }: FirebaseChunkedQueryOptions,
  next: (data: CollectionResults) => void,
  error: ((error: FirestoreError) => void) | undefined,
  complete: (() => void) | undefined,
  sortFn?: (a: DocumentData, b: DocumentData) => number
) => {
  try {
    if (!collectionPath) throw Error('Path must be specified');
    const query = {
      path: collectionPath,
      orderBy,
      limit,
      startAfter,
    };
    if (!chunkableCondition) {
      return startCollectionListener(
        {
          ...query,
          conditions: conditionsBase,
        },
        // resultProcessed is the response by startCollectionListener, not the standard firestore response
        // it is the snapshot but transformed into a map with count, list, last
        (collectionResults: CollectionResults) => {
          // if sortFn is provided, meaning sorting isn't part of the query
          // do the sort
          if (sortFn && collectionResults.list) {
            // list might be null if no results
            collectionResults.list.sort(sortFn); // in place
            collectionResults.last = collectionResults.list[collectionResults.list.length - 1];
          }
          next(collectionResults);
        },
        error,
        complete
      );
    } else {
      if (!chunkableCondition?.value?.length) {
        throw new Error('Invalid chunk condition');
      }
      const valueChunks = chunk<FirebaseChunkValueType>(chunkableCondition.value, QUERY_CHUNK_SIZE);
      const conditionChunks = valueChunks.map((oneChunk) => {
        return [
          ...(conditionsBase || []),
          {
            ...chunkableCondition,
            value: oneChunk,
          },
        ] as FirebaseQueryCondition[];
      });

      let allResults: Record<number, DocumentData[]> = {};

      const unsubscribes = conditionChunks.map((conditions, index) => {
        // for each chunk, create a listener
        return startCollectionListener(
          {
            ...query,
            conditions, // chunked rooms
          },
          // chunkResultProcessed is the response by startCollectionListener, not the standard firestore response
          // it is the snapshot but transformed into a map with count, list, last
          (chunkResultProcessed: CollectionResults) => {
            // join all listeners before responding to parent listener
            // into a map of lists
            allResults[index] = chunkResultProcessed.list || [];
            // extract into a list of lists
            const listOfLists = Object.values(allResults);
            // flatten into a single list, 1 level deep
            const combined = flatten(listOfLists);
            // if provided, sort the list based on custom operator
            if (sortFn) {
              combined.sort(sortFn); // in place
            }
            const reprocessedResults = {
              count: combined.length,
              list: combined,
              last: combined[combined.length - 1],
            };

            // respond to parent listener as one response
            next(reprocessedResults);
          },
          error,
          complete
        );
      });

      return () => {
        allResults = {}; // remove all stored data as a precaution, should be cleared by GC anyway
        unsubscribes.forEach((unsubscribe) => unsubscribe?.()); // call all unsubscribes
      };
    }
  } catch (err: any) {
    logError(`Failed to listen to chunked collection: ${err.message}`, err, {
      err,
      collectionPath,
      conditionsBase,
      chunkableCondition,
      orderBy,
      limit,
      startAfter,
    });
    throw err;
  }
};

export const startCollectionListener = (
  { path: collectionPath, conditions = [], orderBy = [], limit = null, startAfter = null }: FirebaseQueryOptions,
  next: (data: CollectionResults) => void,
  error: ((error: FirestoreError) => void) | undefined,
  complete: (() => void) | undefined,
  firestoreCtx = getFirestore()
) => {
  try {
    if (!collectionPath) throw Error('Path must be specified');
    const constraints: QueryConstraint[] = createQueryConstraints({
      path: collectionPath,
      conditions,
      orderBy,
      limit,
      startAfter,
    });

    const unsubscribe = onSnapshot(
      queryQ(collection(firestoreCtx, collectionPath), ...constraints),
      (querySnapshot) =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                list: querySnapshot.docs.map(buildObject),
                last: querySnapshot.docs[querySnapshot.docs.length - 1],
              }
            : {}
        ),
      error,
      complete
    );
    return unsubscribe;
  } catch (err: any) {
    logError(`Failed to listen to collection: ${err.message}`, err, {
      err,
      collectionPath,
      conditions,
      orderBy,
      limit,
      startAfter,
    });
    throw err;
  }
};

export const getDocuments = async (
  { path: collectionPath, conditions = [], orderBy = [], limit = null, startAfter = null }: FirebaseQueryOptions,
  firestoreCtx = getFirestore()
) => {
  try {
    const snapshot = await createQuerySnapshot(collectionPath, conditions, orderBy, limit, startAfter, firestoreCtx);
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];

    return {
      data: buildObjectsTree(snapshot.docs) || {},
      lastDocument: lastDocument || null,
    };
  } catch (err: any) {
    logError(`Failed to getDocuments: ${err.message}`, err, {
      err,
      collectionPath,
      conditions,
      orderBy,
      limit,
      startAfter,
    });
    throw err;
  }
};

export type DocumentsAsArray = {
  count: number;
  list: DocWithId[];
  lastDocument: QueryDocumentSnapshot<DocumentData, DocumentData> | null;
  hasMoreData: boolean;
};
export const getDocumentsAsArray = async (
  { path: collectionPath, conditions = [], orderBy = [], limit = null, startAfter = null }: FirebaseQueryOptions,
  firestoreCtx = getFirestore()
): Promise<DocumentsAsArray> => {
  try {
    const snapshot = await createQuerySnapshot(collectionPath, conditions, orderBy, limit, startAfter, firestoreCtx);
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];

    return {
      count: snapshot.size,
      list: snapshot.docs.map(buildObject),
      lastDocument: lastDocument || null,
      hasMoreData: snapshot.size === limit,
    };
  } catch (err: any) {
    logError(`Failed to getDocumentsAsArray: ${err.message}`, err, {
      err,
      collectionPath,
      conditions,
      orderBy,
      limit,
      startAfter,
    });
    throw err;
  }
};

// Take 2 objects that where returned from getDocumentsAsArray and merge them into a new object
// with source.lastDocument as the lastDocument (used for pagination)
export const mergeDocumentsAsArray = (target: DocumentsAsArray, source: DocumentsAsArray): DocumentsAsArray => {
  if (!target) return source;

  const list = [...target.list, ...source.list];
  return {
    list,
    lastDocument: source.lastDocument,
    count: list.length,
    hasMoreData: source.hasMoreData,
  };
};

export function createQueryConstraints({ conditions, orderBy, limit, startAfter }: FirebaseQueryOptions) {
  const constraints: QueryConstraint[] = [];

  if (startAfter && (!limit || !orderBy)) throw Error('OrderBy and limit are required during paginated calls');

  conditions?.forEach(({ field, operation, value }) => {
    if (field && operation) constraints.push(whereQ(field, operation, value));
  });

  orderBy?.forEach(({ field, direction = 'asc' }) => {
    if (field && direction) constraints.push(orderByQ(field, direction));
  });

  if (limit && typeof limit === 'number') constraints.push(limitQ(limit));

  if (startAfter && orderBy && startAfter.id) constraints.push(startAfterQ(startAfter));

  return constraints;
}
export async function createQuerySnapshot(
  collectionPath: string,
  conditions: FirebaseQueryCondition[],
  orderBy: FirebaseQueryOrderBy[],
  limit: number | null = null,
  startAfter: DocumentSnapshot | null = null,
  firestoreCtx = getFirestore()
) {
  const constraints: QueryConstraint[] = createQueryConstraints({
    path: collectionPath,
    conditions,
    orderBy,
    limit,
    startAfter,
  });
  const query = queryQ(collection(firestoreCtx, collectionPath), ...constraints);
  const snapshot = await getDocs(query);
  return snapshot;
}

// If the document contains an id, we concat it to the collectionPath and call
// 'set' to update the document. If the document doesn't contain an id, we call
// 'add' to create a new document. (or batch.create if batch is non null).
export const saveDocument = async (
  {
    collectionPath,
    data,
    batch = null,
    transaction = null,
    firestoreOptions = { merge: true },
  }: {
    collectionPath: string | ((data: DocumentData) => string);
    data: DocumentData;
    batch?: null | WriteBatch;
    transaction?: null | Transaction;
    firestoreOptions?: SetOptions;
  },
  firestoreCtx = getFirestore()
) => {
  if (!collectionPath) throw Error('collectionPath must be specified');
  if (!data) throw Error('data must be specified');

  const { id, createdAt, createdBy, ...rest } = data;

  let path = typeof collectionPath === 'function' ? collectionPath(data) : collectionPath;

  let docRef: DocumentReference | null = null;

  // If id is specified, update the document
  if (id) {
    path += `/${id}`;

    const updateData = {
      ...rest,
      updatedAt: serverTimestamp(),
      updatedBy: getChangedBy(),
    };
    docRef = doc(firestoreCtx, path);

    if (batch) {
      batch.set(docRef, updateData, firestoreOptions);
    } else if (transaction) {
      transaction.set(docRef, updateData, firestoreOptions);
    } else {
      setDoc(docRef, updateData, firestoreOptions);
    }

    // Otherwise, create a new document
  } else {
    const createData = {
      ...rest,
      createdAt: serverTimestamp(),
      createdBy: getChangedBy(),
    };
    const collectionRef = collection(firestoreCtx, path);

    if (batch) {
      docRef = doc(collectionRef);
      batch.set(docRef, createData);
    } else if (transaction) {
      docRef = doc(collectionRef);
      transaction.set(docRef, createData);
    } else {
      docRef = await addDoc(collectionRef, createData);
    }
  }
  return docRef;
};

export const addDocument = async (
  {
    path: collectionPath,
    data,
  }: {
    path: string;
    data: DocumentData;
  },
  firestoreCtx = getFirestore()
) => {
  try {
    if (!collectionPath) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');

    return await addDoc(collection(firestoreCtx, collectionPath), data);
  } catch (err: any) {
    logError(`Failed to addDocument: ${err.message}`, err, {
      err,
      collectionPath,
      data,
    });
    throw err;
  }
};

export const updateDocument = async (
  {
    path,
    data,
  }: {
    path: string;
    data: DocumentData;
  },
  firestoreCtx = getFirestore()
) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    return await updateDoc(doc(firestoreCtx, path), data);
  } catch (err: any) {
    logError(`Failed to updateDocument: ${err.message}`, err, {
      err,
      path,
      data,
    });
    throw err;
  }
};

export const deleteDocument = async ({ path }: { path: string }, firestoreCtx = getFirestore()) => {
  try {
    if (!path) throw Error('Path must be specified');
    return await deleteDoc(doc(firestoreCtx, path));
  } catch (err: any) {
    logError(`Failed to deleteDocument: ${err.message}`, err, {
      err,
      path,
    });
    throw err;
  }
};

export const setDocument = async (
  {
    path,
    data,
    merge = true,
  }: {
    path: string;
    data: DocumentData;
    merge: boolean;
  },
  firestoreCtx = getFirestore()
) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    return await setDoc(doc(firestoreCtx, path), data, { merge: true });
  } catch (err: any) {
    logError(`Failed to setDocument: ${err.message}`, err, {
      err,
      path,
      data,
      merge,
    });
    throw err;
  }
};

/**
 * Allows batching an unlimited number of documents in batches of size BATCH_SIZE.
 * collectionPath is either a string to the collection path where the documents will
 * be saved, or a function that returns the collection path.
 *
 * Usage:
 * batchSaveDocuments({
 *  collectionPath: 'students',
 *  documents: students
 * })
 *
 * OR
 * batchSaveDocuments({
 * collectionPath: (student) => `organizations/${organizationId}/students`,
 * documents: students
 * })
 */
const BATCH_SIZE = 450;
export const batchSaveDocuments = async (
  {
    collectionPath,
    documents,
    firestoreOptions = { merge: true },
  }: {
    collectionPath: string | ((data: DocumentData) => string);
    documents: DocumentData[];
    firestoreOptions: SetOptions;
  },
  firestoreCtx = getFirestore()
) => {
  if (!Array.isArray(documents)) throw new Error('Documents must be an array');

  if (typeof collectionPath !== 'function' && typeof collectionPath !== 'string')
    throw new Error('Collection path must be either a function or a string');

  logLocal(`Saving ${documents.length} documents`);

  let counter = 0;
  let total = 0;
  let batch = writeBatch(firestoreCtx);

  try {
    for (const document of documents) {
      saveDocument({ collectionPath, data: document, batch, firestoreOptions });

      counter++;
      total++;

      if (counter === BATCH_SIZE) {
        await batch.commit();
        batch = writeBatch(firestoreCtx);
        counter = 0;
        logLocal(`Saved ${total} of ${documents.length} documents`);
      }
    }
    if (counter > 0) {
      await batch.commit();
      logLocal(`Saved ${total} of ${documents.length} documents`);
    }
  } catch (err: any) {
    logError(`Failed to batchSaveDocuments: ${err.message}`, err, {
      err,
      collectionPath,
      documents,
      firestoreOptions,
    });
    throw err;
  }
};
export const downloadFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = refS(refS(getStorage(), path), refId);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (err: any) {
    logError(`Failed to downloadFile: ${err.message}`, err, {
      err,
      path,
      refId,
    });
    switch (err.code) {
      // File doesn't exist
      case 'storage/object-not-found':
        return err.message;

      // User doesn't have permission to access the object
      case 'storage/unauthorized':
        return err.message;

      // User canceled the upload
      case 'storage/canceled':
        return err.message;

      // Unknown error occurred, inspect the server response
      case 'storage/unknown':
        return err.message;
      default:
        return;
    }
  }
};

export const deleteFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = refS(refS(getStorage(), path), refId);
    return deleteObject(storageRef);
  } catch (err: any) {
    logError(`Failed to deleteFile: ${err.message}`, err, {
      err,
      path,
      refId,
    });
    throw err;
  }
};

export const getChangedBy = () => {
  const currentUser = getAuth().currentUser;
  return {
    uid: currentUser?.uid,
    email: currentUser?.email,
    name: currentUser?.displayName || currentUser?.email,
  };
};
