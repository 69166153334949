import { useTranslation } from 'react-i18next';

import { ViewComponentProps } from '../../../common';
import { dateFormatter } from '../../../helpers/dates';
import { capitalize } from '../../../helpers/utils';

type StudentViewProps = ViewComponentProps & {
  student: any;
};

const StudentView: React.FC<StudentViewProps> = ({ student }) => {
  const { t } = useTranslation();
  return (
    <div className="student-view">
      <div className="grid grid-cols-2 gap-4">
        <h2>{t('Student')}</h2>
        <h3>{student.displayName}</h3>
      </div>
      <div className="ws-view-form">
        <div className="row">
          <label>{t('Date of Birth')}</label>
          <div className="value">{dateFormatter(student.birthday) || 'N/A'}</div>
        </div>
        <div className="row">
          <label>{t('Gender')}</label>
          <div className="value">{t(capitalize(student.gender)) || 'N/A'}</div>
        </div>
      </div>
    </div>
  );
};
export default StudentView;
