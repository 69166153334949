import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useOrganization } from '../../hooks/useOrganizations';

import {
  collapseOnboardingBanner as collapseOnboardingBannerAction,
  expandOnboardingBanner as expandOnboardingBannerAction,
} from '../../redux/actions/onboardingActions';

import { OnboardingGoalIdEnum, OnboardingGoalStatusEnum, OnboardingGoalType } from './types';

export type UseOnboardingGoalsType = {
  enrollmentsGoal: OnboardingGoalType;
  familiesGoal: OnboardingGoalType;
  paymentsGoal: OnboardingGoalType;
  operationsGoal: OnboardingGoalType;
  // setOnboardingGoal: (onboardingGoal:OnboardingGoalType) => void;
};

const selectOnboardingIsCollapsed = createSelector(
  [(state) => state.onboarding],
  (onboarding) => !!onboarding?.isCollapsed
);

export function useOnboardingGoals(): UseOnboardingGoalsType {
  const { onboarding } = useOrganization();

  const enrollmentsGoal = useMemo<OnboardingGoalType>(() => {
    return {
      id: OnboardingGoalIdEnum.ENROLLMENTS,
      status: getOnboardingGoalStatus(onboarding, OnboardingGoalIdEnum.ENROLLMENTS),
    } as OnboardingGoalType;
  }, [onboarding]);

  const familiesGoal = useMemo<OnboardingGoalType>(() => {
    return {
      id: OnboardingGoalIdEnum.FAMILIES,
      status: getOnboardingGoalStatus(onboarding, OnboardingGoalIdEnum.FAMILIES),
    };
  }, [onboarding]);

  const paymentsGoal = useMemo<OnboardingGoalType>(() => {
    return {
      id: OnboardingGoalIdEnum.PAYMENTS,
      status: getOnboardingGoalStatus(onboarding, OnboardingGoalIdEnum.PAYMENTS),
    };
  }, [onboarding]);

  const operationsGoal = useMemo<OnboardingGoalType>(() => {
    return {
      id: OnboardingGoalIdEnum.OPERATIONS,
      status: getOnboardingGoalStatus(onboarding, OnboardingGoalIdEnum.OPERATIONS),
    };
  }, [onboarding]);

  return {
    enrollmentsGoal,
    familiesGoal,
    paymentsGoal,
    operationsGoal,
  };
}

// Because the organization listener is at the App level, we re-render every time the organization changes,
// causing the OnboardingBanner to re-render every time the organization changes. So we keep the
// collapsed state in redux. Don't love it, but it works.
export function useOnboardingBannerState() {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(selectOnboardingIsCollapsed);

  const collapseOnboardingBanner = useCallback(() => {
    dispatch(collapseOnboardingBannerAction());
  }, [dispatch]);

  const expandOnboardingBanner = useCallback(() => {
    dispatch(expandOnboardingBannerAction());
  }, [dispatch]);

  return { isCollapsed, collapseOnboardingBanner, expandOnboardingBanner };
}

function getOnboardingGoalStatus(onboarding: any, onboardingGoalId: OnboardingGoalIdEnum) {
  const organizationGoal = onboarding?.[onboardingGoalId];
  return organizationGoal
    ? OnboardingGoalStatusEnum[organizationGoal.toUpperCase() as keyof typeof OnboardingGoalStatusEnum]
    : OnboardingGoalStatusEnum.START;
}
