import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Button, Card, LinkOutIcon } from '@wonderschool/common-base-ui';
import PageTitle from '../../Components/Shared/PageTitle';
import { getTitlePrefix } from '../../config/env';

import { usePrimaryLocation } from '../../hooks/useLocations';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { shouldHideKinderSystemsAppStore } from '../../navigation/navigationUtils';
import BusinessIntakeIcon from './assets/biz-check.svg';
import ChildCareMattersIcon from './assets/ccm.png';
import EarlyDayIcon from './assets/early-day.png';
import KinderSystemsIcon from './assets/kinder-systems.svg';
import LearningBeyondPaperIcon from './assets/learning-beyond-paper-logo.png';
import { EARLY_DAY_CITY, REDIRECTION_LINKS } from './enums';
import { addUserParamsToUrl } from './utils';

const AppStore = () => {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();
  const location = usePrimaryLocation();
  const title = t('apps.title');

  const { isProviderIntakeVisibleAppStore } = useFlags();

  const items = [
    {
      id: 'early-day',
      title: 'EarlyDay',
      description: t('apps.earlyDayDescription'),
      icon: EarlyDayIcon,
      tags: [{ title: t('apps.tags.staffing'), color: 'yellow' }],
      button: {
        id: 'early-day-join-waitlist',
        label: t('apps.joinWaitlist'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.EARLY_DAY_GOOGLE_FORM),
        showPostIcon: true,
      },
      learnMore: {
        showButton: true,
        title: t('apps.learnMore'),
      },
      externalLink: REDIRECTION_LINKS?.EARLY_DAY_LEARN_MORE || '',
      startHiring: REDIRECTION_LINKS?.EARLY_DAY_START_HIRING || '',
      enabled: true,
    },
    {
      id: 'sub-pool',
      title: 'Substitute Teacher Pool',
      description: t('apps.subpoolDescription'),
      ctaLabel: t('apps.startHiring'),
      icon: ChildCareMattersIcon,
      tags: [{ title: t('apps.tags.temporaryStaffing'), color: 'green' }],
      button: {
        id: 'subpool-external',
        label: t('apps.startHiring'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.CHILDCARE_MATTERS_LEARN_MORE),
        showPostIcon: true,
      },
      externalLink: REDIRECTION_LINKS?.CHILDCARE_MATTERS_LEARN_MORE || '',
      enabled: true,
    },
    {
      id: 'learning-beyond-paper',
      title: 'Learning Beyond Paper',
      subTitle: '',
      pricing: '',
      description: t('apps.learningBeyondPaperDescription'),
      icon: LearningBeyondPaperIcon,
      tags: [{ title: t('apps.tags.lessonPlanning'), color: 'purple' }],
      button: {
        id: 'learning-beyond-paper-join-waitlist',
        label: t('apps.joinWaitlist'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.LEARNING_BEYOND_PAPER_GOOGLE_FORM),
        showPostIcon: true,
      },
      learnMore: {
        showButton: true,
        title: t('apps.learnMore'),
        link: RouteNameEnum.LEARNING_BEYOND_PAPER,
      },
      enabled: true,
    },
    {
      id: 'business-intake',
      title: 'Childcare Business Health Check',
      description: t('apps.healthCheckDescription'),
      ctaLabel: t('common.getStarted'),
      button: {
        id: 'business-intake-learn-more',
        label: t('common.getStarted'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.CHILDCARE_BUSINESS_HEALTH_CHECK),
        showPostIcon: true,
      },
      icon: BusinessIntakeIcon,
      tags: [{ title: t('apps.tags.businessSustainability'), color: 'orange' }],
      externalLink: REDIRECTION_LINKS?.CHILDCARE_BUSINESS_HEALTH_CHECK || '',
      enabled: isProviderIntakeVisibleAppStore,
    },
    {
      id: 'kinder-systems',
      title: 'KinderConnect',
      description: t('apps.kinderConnect.description'),
      ctaLabel: t('apps.kinderConnect.cta'),
      button: {
        id: 'kinder-systems-learn-more',
        link: RouteNameEnum.KINDER_CONNECT,
        internalRoute: true,
        showPostIcon: false,
      },
      icon: KinderSystemsIcon,
      tags: [{ title: t('apps.tags.subsidyManagement'), color: 'blue' }],
      enabled: !shouldHideKinderSystemsAppStore(),
    },
  ];

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
    </Helmet>
  );

  return (
    <div className="container mx-auto px-5">
      {renderPageHead()}
      <PageTitle title={t('apps.title')} />
      <div className="mt-10 grid grid-cols-1 gap-8 lg:grid-cols-2">
        {items.map(
          (item, index) =>
            item.enabled && (
              <Card key={index}>
                <div className="relative min-h-60 px-4 py-5 sm:px-6">
                  {item?.tags.map((tag, index) => (
                    <span
                      key={index}
                      className={`inline-flex items-center rounded-full bg-${tag.color}-200 px-3 py-0.5 text-xs font-semibold text-${tag.color}-950`}
                    >
                      {tag.title.toUpperCase()}
                    </span>
                  ))}
                  <div className="mt-4 flex space-x-3">
                    <div className="h-10 w-10 place-content-center rounded-full border border-gray-300">
                      <img className="mx-auto flex-shrink-0 rounded-full" src={item?.icon} alt={item?.title} />
                    </div>
                    <div className="m-auto text-2xl font-bold text-gray-800">{item?.title}</div>
                  </div>
                  {item?.subTitle && (
                    <div className="mt-2 text-sm font-bold text-gray-800">
                      {item.subTitle}
                      {item?.pricing && <span className="font-normal"> {item.pricing}</span>}
                    </div>
                  )}
                  {item?.description && <p className="mt-2 text-sm text-gray-800">{item.description}</p>}
                  <div className="mt-6">
                    {item?.id === 'early-day' &&
                      (location?.city && !EARLY_DAY_CITY.includes(location.city.trim().toLowerCase()) ? (
                        <>
                          <Button
                            label={t('apps.joinWaitlist')}
                            id={item?.button?.id}
                            primary
                            postIcon={item?.button?.showPostIcon ? <LinkOutIcon className="h-5 w-5" /> : undefined}
                            onClick={() => item?.button?.link && window.open(item.button.link, '_blank')}
                          />
                          <a
                            className="mx-4 cursor-pointer font-bold text-blue-900"
                            onClick={() => item?.externalLink && window.open(item.externalLink, '_blank')}
                          >
                            {item?.learnMore?.title || t('apps.learnMore')}
                          </a>
                        </>
                      ) : (
                        <Button
                          label={t('apps.startHiring')}
                          id={`${item.id}-start-hiring`}
                          primary
                          onClick={() => item?.startHiring && window.open(item.startHiring, '_blank')}
                          postIcon={<LinkOutIcon className="h-5 w-5" />}
                        />
                      ))}
                    {item?.id !== 'early-day' && (
                      <>
                        <Button
                          label={item?.ctaLabel || t('apps.joinWaitlist')}
                          id={item?.button?.id}
                          primary
                          postIcon={item?.button?.showPostIcon ? <LinkOutIcon className="h-5 w-5" /> : undefined}
                          onClick={() => {
                            if (item?.button?.link) {
                              if (item?.button?.internalRoute) {
                                gotoRouteByName(item.button.link);
                              } else {
                                window.open(item.button.link, '_blank');
                              }
                            }
                          }}
                        />
                        {item.learnMore && (
                          <a
                            className="mx-4 cursor-pointer font-bold text-blue-900"
                            onClick={() => item.learnMore.link && gotoRouteByName(item.learnMore.link)}
                          >
                            {item?.learnMore?.title || t('apps.learnMore')}
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Card>
            )
        )}
        {/* This div is for HotJar Survey */}
        <div className="rounded-lg p-4" id="appstore-page-hotjar"></div>
        {/* This div is for HotJar Survey */}
      </div>
    </div>
  );
};

export default AppStore;
