import { useTranslation } from 'react-i18next';
import { ChangeEvent, useMemo, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Combobox, InformationIcon, Input } from '@wonderschool/common-base-ui';
import { RoomDropdown, StaffDropdown, WsAvatar, WsDropdownOptionType, WsInputEventType } from '../../common';
import { useContacts } from '../../contacts';
import { formatHoursFromMinutes } from '../../helpers/dates';
import { convertTo24Hour } from '../timecardsUtils';
import {
  calculateClockInOutTimeDifference,
  calculateIfClockInOutDatesAreOvernight,
} from './utils/calculateClockInOutTimeDifference';
import useRooms from '../../hooks/useRooms';
import { fetchClockedInTimecards } from '../timecardsAPI';
import { useOrganization } from '../../hooks/useOrganizations';
import dayjs from 'dayjs';
import { StaffType } from '../types';

type DropdownOptionType = WsDropdownOptionType<string>;

const TimecardModalContent = ({
  formData,
  setFormData,
  errors,
  setErrors,
  staff,
  switchDeleteModal,
  isEdit,
  notClockedOut,
  setNotClockedOut,
}) => {
  const [currentStaff, setCurrentStaff] = useState<StaffType | null>(staff);
  const [clockedInRoom, setClockedInRoom] = useState<{ id: string; name: string } | undefined>();
  const { t } = useTranslation();
  const { staffContacts } = useContacts();
  const rooms: any[] = useRooms();
  const organization = useOrganization();

  const options: DropdownOptionType[] = useMemo(() => {
    const optionsLocal = rooms?.map((room) => ({
      id: room.id,
      name: room.name,
    }));
    return optionsLocal ?? [];
  }, [rooms]);

  const staffOptions = useMemo(() => {
    const staffRooms: string[] = [];
    staffRooms.length = 0;
    staffContacts?.map(({ rooms, id }) => {
      rooms?.length && formData.staffId === id && staffRooms.push(...rooms);
    });
    return staffRooms;
  }, [staffContacts, formData.staffId]);

  const onChange = ({ name, value }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const onChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setErrors((prev) => ({ ...prev, [event.target.name]: '' }));
  };

  const findRoomNotClockedOutRoom = async (staffContact) => {
    const timecardsLocal = await fetchClockedInTimecards({
      contactId: staffContact.id,
      uid: staffContact.uid,
      organizationId: organization.id,
    });

    if (timecardsLocal.length > 0) {
      setNotClockedOut(timecardsLocal[0]);
      const foundRoom = rooms.find((room) => room.id === timecardsLocal[0].roomId);
      setCurrentStaff(staffContact);
      setClockedInRoom(foundRoom);
    } else {
      setNotClockedOut(null);
      setCurrentStaff(null);
      setClockedInRoom(undefined);
    }
  };

  const onChangeStaff = ({ name, value }: WsInputEventType<string>) => {
    const staffContact = staffContacts?.find((staff: { id: string }) => staff.id === value);
    setFormData((prev) => ({
      ...prev,
      [name]: staffContact.id ?? '',
      ['uid']: staffContact.uid ?? '',
      ['contactId']: staffContact.id ?? '',
      ['locationId']: staffContact.defaultLocation ?? '',
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));

    if (staffContact) {
      findRoomNotClockedOutRoom(staffContact);
    }
  };

  const showTimeDifferece = () => {
    const { clockedInDate, clockedInTime, clockedOutDate, clockedOutTime } = formData;
    if (!clockedInDate || !clockedInTime) {
      return ' ';
    }
    const timeDifference = calculateClockInOutTimeDifference({
      clockedInDate,
      clockedInTime,
      clockedOutDate,
      clockedOutTime,
    });
    return t('timecards.totalHours', {
      totalTime: formatHoursFromMinutes(timeDifference.totalTime),
      overnight: timeDifference.isOvernight ? ` (${t('timecards.overnight')})` : '',
    });
  };

  const LabelView = ({ label }) => <label className="text-sm font-normal text-gray-800">{label}</label>;

  return (
    <div>
      <div className="mb-4">
        {isEdit ? (
          <>
            <label className="flex gap-3 text-gray-800">{t('timecards.staffMember')}</label>
            <div className="mt-2 flex gap-3 rounded-md border p-2">
              <div className="flex items-center justify-center gap-3">
                <WsAvatar photoURL={staff?.photoURL} size="small" data-testid="timecard-edit-avatar" />
                <div className="items-center justify-center gap-3 text-sm">{staff?.displayName ?? t('Unknown')}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="gap-5 text-gray-800">
                <Combobox
                  label={t('Room')}
                  name={'roomId'}
                  onChange={onChange}
                  options={options}
                  placeholder={t('Select a room')}
                  value={formData?.roomId ?? ''}
                  required={true}
                  data-testid="timecard-edit-room"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-4">
              <div className="gap-5 text-gray-800">
                <StaffDropdown
                  name="staffId"
                  label={t('timecards.staffMember')}
                  value={formData.staffId}
                  onChange={onChangeStaff}
                  error={t(errors.staffName)}
                  required={true}
                  data-testid="timesheet-filter-staff"
                />
                <div className="text-xs text-gray-800 mt-2">{t('timecards.staffDropdownWarning')}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="gap-5 text-gray-800">
                <RoomDropdown
                  name="roomId"
                  value={formData.roomId}
                  staffRooms={staffOptions}
                  disabled={!formData.staffId}
                  error={t(errors.roomId)}
                  onChange={onChange}
                  required={true}
                  data-testid="timecard-add-room"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="mt-4 border-gray-400 border-gray-400" />
      <div className="mb-2 mt-4">
        <LabelView label={t('timecards.clockedInLabel')} />
      </div>
      <div className="flex">
        <div className="flex">
          <div className="mr-2 w-3/6 md:w-full">
            <Input
              name="clockedInDate"
              label={t('Date')}
              inputType="date"
              onChange={(event) => {
                setFormData((prev) => ({ ...prev, clockedInDate: event.target.value }));
              }}
              value={dayjs(formData.clockedInDate).format('YYYY-MM-DD')}
              required={true}
              placeholder={t('selectDate')}
              error={t(errors.clockedInDate)}
              max={dayjs(new Date()).format('YYYY-MM-DD')}
              data-testid="timecard-add-clocked-in-date"
            />
          </div>
          <div className="ml-2 w-4/6 w-max min-w-max">
            <Input
              inputType="time"
              name="clockedInTime"
              required={true}
              label={t('Time')}
              value={formData?.clockedInTime && convertTo24Hour(formData?.clockedInTime)}
              error={t(errors.clockedInTime)}
              onChange={onChangeTime}
            />
          </div>
        </div>
      </div>
      <div className="mb-2 mt-4">
        <LabelView label={t('timecards.clockedOutLabel')} />
      </div>
      {!isEdit && errors.clockedOutRestriction && clockedInRoom && (
        <div className="bg-red-200 px-3 py-5 my-2 max-w-96">
          <InformationIcon className="text-red-950 h-5 mb-0.5" />{' '}
          {t('timecards.clockedOutError', {
            displayName: currentStaff?.displayName,
            roomName: clockedInRoom.name,
          })}
        </div>
      )}
      <div className="flex">
        <div className="flex">
          <div className="mr-2 w-3/6 text-left md:w-full">
            <Input
              name="clockedOutDate"
              label={t('Date')}
              inputType="date"
              onChange={(event) => {
                setFormData((prev) => ({ ...prev, clockedOutDate: event.target.value }));
              }}
              value={dayjs(formData.clockedOutDate).format('YYYY-MM-DD')}
              required={
                calculateIfClockInOutDatesAreOvernight(
                  new Date(formData.clockedInDate),
                  new Date(formData.clockedOutDate)
                ) || !!notClockedOut
              }
              placeholder={t('selectDate')}
              error={t(errors.clockedOutDate)}
              min={formData.clockedInDate ?? 1}
              max={dayjs(new Date()).format('YYYY-MM-DD')}
              data-testid="timecard-add-clocked-out-date"
            />
          </div>
          <div className="ml-2 w-4/6 w-max min-w-max">
            <Input
              inputType="time"
              name="clockedOutTime"
              required={
                calculateIfClockInOutDatesAreOvernight(
                  new Date(formData.clockedInDate),
                  new Date(formData.clockedOutDate)
                ) || !!notClockedOut
              }
              label={t('Time')}
              value={formData?.clockedOutTime && convertTo24Hour(formData?.clockedOutTime)}
              error={t(errors.clockedOutTime)}
              onChange={onChangeTime}
            />
          </div>
        </div>
      </div>
      {errors.locationId && <div className="my-4 text-sm text-red-950">{t(errors.locationId || '')}</div>}
      <hr className="mt-6 border-gray-400 border-gray-400" />
      <div className="py-2">
        <LabelView label={showTimeDifferece()} />
      </div>
      {switchDeleteModal && (
        <div
          className="w-full gap-3"
          onClick={() => switchDeleteModal(true)}
          style={{ background: '#F6F8FF', borderRadius: 8, padding: 20, gap: 32 }}
        >
          <button className="font-bold text-red-500">
            <TrashIcon className="h-6 w-6 gap-3" />
            <span className="p-2.5">{t('timecards.deleteTimecard')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default TimecardModalContent;
