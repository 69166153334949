import { useParams } from 'react-router-dom';

import WSA from '../WSA';
import ExternalRedirect from './ExternalRedirect';

const Signup: React.FC = () => {
  const { networkId } = useParams<{ networkId: string }>();

  const signupUrl = WSA.URLs.getSignup({ networkId });
  return <ExternalRedirect externalUrl={signupUrl} shouldUseReplace />;
};
export default Signup;
