import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultCurrencyOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export function CurrencyInput({ maskOptions, onChange, ...inputProps }) {
  const currencyMask = createNumberMask({
    ...defaultCurrencyOptions,
    ...maskOptions,
  });
  const onChangeHandler = (e) => {
    if (onChange) onChange(e, e.target);
  };
  return <MaskedInput mask={currencyMask} {...inputProps} onChange={onChangeHandler} />;
}

const defaultPercentOptions = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export function PercentInput({ maskOptions, onChange, ...inputProps }) {
  const percentMask = createNumberMask({
    ...defaultPercentOptions,
    ...maskOptions,
  });
  const onChangeHandler = (e) => {
    if (onChange) onChange(e, e.target);
  };
  return <MaskedInput mask={percentMask} {...inputProps} onChange={onChangeHandler} />;
}
