import jsPDF from 'jspdf';
import wonderschoolLogo from '../../styles/img/wonderschool/logo-base.png';

export function initializePDFDoc(title: string, orientation?: 'p' | 'portrait' | 'l' | 'landscape' | undefined): jsPDF {
  const doc = new jsPDF({
    orientation: orientation || 'p',
    unit: 'mm',
    format: 'letter',
  });
  doc.addImage(wonderschoolLogo, 'PNG', 10, 20, 40, 5);
  doc.setFontSize(14);
  doc.setFont('helvetica', 'normal', 'bold');
  doc.text(title, 10, 36);
  return doc;
}
