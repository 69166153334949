import { WsTooltipProps } from './types';

export function WsTooltip({ action = 'hover', 'data-testid': testId, content, children }: WsTooltipProps) {
  let containerClasses = `group relative w-max`;

  let tooltipClasses = `absolute rounded ring-2 ring-white bg-gray-100 left-0 -top-7 px-10 py-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 w-max`;

  if (action === 'hover') {
    containerClasses += ' hover: cursor-pointer ';
  } else if (action === 'click') {
    containerClasses += ' hover: cursor-pointer';
  } else if (action === 'none') {
    tooltipClasses += ' hidden';
  }

  return (
    <div data-testid={testId} className={containerClasses}>
      <div className={tooltipClasses}>{content}</div>
      {children}
    </div>
  );
}
export default WsTooltip;
