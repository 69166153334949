import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

// Import hooks.
import useWindowDimensions from '../../hooks/useWindowDimensions';

// Import helpers.
import { isOrganizationSetupComplete } from '../../helpers/organizations';
import { openSidebar } from '../../redux/actions/layoutActions';
import WSA from '../../WSA';
import { ImpersonateBanner } from '../Admin/Impersonation';
import DesktopNavigation from '../../navigation/components/DesktopNavigation';
import FooterNavigation from '../../navigation/components/FooterNavigation';
// Import components.
import TopNavigation from '../../navigation/components/TopNavigation';
import { useScrollToTop } from '../../hooks/useScroll';

const DesktopContainer = ({
  location,
  isSidebarOpen,
  sliderOpen,
  user: _user,
  currentOrganization,
  openSidebar,
  children,
}) => {
  const { width } = useWindowDimensions();
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();

  useScrollToTop({ id: 'content-sidebar-pusher' });

  const setupComplete = useMemo(() => {
    return isOrganizationSetupComplete(currentOrganization);
  }, [currentOrganization]);

  useEffect(() => {
    if (isReady && isAuthenticated && setupComplete) {
      location.pathname !== '/setup' && openSidebar(true);
    }
  }, [isAuthenticated, isReady, openSidebar, setupComplete, location]);

  // Calculate the sidebar size be no larger than 275px or 20%
  // of the screen width, whichever is larger.
  const sidebarSize = isSidebarOpen ? width * 0.2 : 0;
  const sidebarWidthSize = width > 768 && sidebarSize <= 275 ? sidebarSize : 275;
  const mainContentWidthSize = width - sidebarWidthSize;

  return (
    <Sidebar.Pushable>
      <DesktopNavigation visible={isSidebarOpen} style={{ width: sidebarWidthSize }} sidebarWidth={sidebarWidthSize} />
      <Sidebar.Pusher
        id="content-sidebar-pusher"
        style={{
          height: '100vh',
          width: mainContentWidthSize,
          transform: `translate3d(${sidebarWidthSize}px,0,0)`,
        }}
        dimmed={sliderOpen}
      >
        <div id="content" className={`${sliderOpen ? 'no-scroll' : ''}`}>
          <TopNavigation />

          <div id="main-content">
            <ImpersonateBanner />
            {children}
          </div>
        </div>

        <FooterNavigation />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  isSidebarOpen: state.layout.isSidebarOpen,
  sliderOpen: state.layout.sliderOpen,
});

export default connect(mapStateToProps, { openSidebar })(withRouter(DesktopContainer));
