import { MealInputPage, MealInputPageProps } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { logError } from '../../../rollbar';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MealInput = () => {
  const { mealId } = useParams<Pick<MealInputPageProps, 'mealId'>>();
  const { t } = useTranslation();
  const title = t(!mealId ? 'food.meals.create' : 'food.meals.update');
  const { gotoRouteByName } = useRoutes();
  useSegmentPage(mealId ? PAGE_NAMES.foodProgramMealCreate : PAGE_NAMES.foodProgramMealUpdate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  const handleGoBack = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEALS);
  };

  return (
    <>
      {renderPageHead()}
      <MealInputPage
        mealId={mealId}
        onSuccess={handleGoBack}
        onCancel={handleGoBack}
        onError={(error) => logError('MealInputPage', error)}
      />
    </>
  );
};
export default MealInput;
