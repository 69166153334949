export enum EnrollmentStatusEnum {
  PENDING = 'pending',
  ENROLLED = 'enrolled',
  UNENROLLED = 'unenrolled',
}
export enum EnrollmentSourceEnum {
  WONDERSCHOOL = 'wonderschool',
  PROVIDER = 'provider',
  NETWORK = 'network',
}

export enum BillingIntervalEnum {
  WEEKLY = 'weekly',
  BIWEEKLY = 'bi-weekly',
  TWICE_PER_MONTH = 'twice per month',
  MONTHLY = 'monthly',
}

export enum DayEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

// not an enum, but a constant
export const WEEKDAYS = [DayEnum.MONDAY, DayEnum.TUESDAY, DayEnum.WEDNESDAY, DayEnum.THURSDAY, DayEnum.FRIDAY];

export enum HourEnum {
  HOUR_00_00 = '00:00',
  HOUR_00_15 = '00:15',
  HOUR_00_30 = '00:30',
  HOUR_00_45 = '00:45',
  HOUR_01_00 = '01:00',
  HOUR_01_15 = '01:15',
  HOUR_01_30 = '01:30',
  HOUR_01_45 = '01:45',
  HOUR_02_00 = '02:00',
  HOUR_02_15 = '02:15',
  HOUR_02_30 = '02:30',
  HOUR_02_45 = '02:45',
  HOUR_03_00 = '03:00',
  HOUR_03_15 = '03:15',
  HOUR_03_30 = '03:30',
  HOUR_03_45 = '03:45',
  HOUR_04_00 = '04:00',
  HOUR_04_15 = '04:15',
  HOUR_04_30 = '04:30',
  HOUR_04_45 = '04:45',
  HOUR_05_00 = '05:00',
  HOUR_05_15 = '05:15',
  HOUR_05_30 = '05:30',
  HOUR_05_45 = '05:45',
  HOUR_06_00 = '06:00',
  HOUR_06_15 = '06:15',
  HOUR_06_30 = '06:30',
  HOUR_06_45 = '06:45',
  HOUR_07_00 = '07:00',
  HOUR_07_15 = '07:15',
  HOUR_07_30 = '07:30',
  HOUR_07_45 = '07:45',
  HOUR_08_00 = '08:00',
  HOUR_08_15 = '08:15',
  HOUR_08_30 = '08:30',
  HOUR_08_45 = '08:45',
  HOUR_09_00 = '09:00',
  HOUR_09_15 = '09:15',
  HOUR_09_30 = '09:30',
  HOUR_09_45 = '09:45',
  HOUR_10_00 = '10:00',
  HOUR_10_15 = '10:15',
  HOUR_10_30 = '10:30',
  HOUR_10_45 = '10:45',
  HOUR_11_00 = '11:00',
  HOUR_11_15 = '11:15',
  HOUR_11_30 = '11:30',
  HOUR_11_45 = '11:45',
  HOUR_12_00 = '12:00',
  HOUR_12_15 = '12:15',
  HOUR_12_30 = '12:30',
  HOUR_12_45 = '12:45',
  HOUR_13_00 = '13:00',
  HOUR_13_15 = '13:15',
  HOUR_13_30 = '13:30',
  HOUR_13_45 = '13:45',
  HOUR_14_00 = '14:00',
  HOUR_14_15 = '14:15',
  HOUR_14_30 = '14:30',
  HOUR_14_45 = '14:45',
  HOUR_15_00 = '15:00',
  HOUR_15_15 = '15:15',
  HOUR_15_30 = '15:30',
  HOUR_15_45 = '15:45',
  HOUR_16_00 = '16:00',
  HOUR_16_15 = '16:15',
  HOUR_16_30 = '16:30',
  HOUR_16_45 = '16:45',
  HOUR_17_00 = '17:00',
  HOUR_17_15 = '17:15',
  HOUR_17_30 = '17:30',
  HOUR_17_45 = '17:45',
  HOUR_18_00 = '18:00',
  HOUR_18_15 = '18:15',
  HOUR_18_30 = '18:30',
  HOUR_18_45 = '18:45',
  HOUR_19_00 = '19:00',
  HOUR_19_15 = '19:15',
  HOUR_19_30 = '19:30',
  HOUR_19_45 = '19:45',
  HOUR_20_00 = '20:00',
  HOUR_20_15 = '20:15',
  HOUR_20_30 = '20:30',
  HOUR_20_45 = '20:45',
  HOUR_21_00 = '21:00',
  HOUR_21_15 = '21:15',
  HOUR_21_30 = '21:30',
  HOUR_21_45 = '21:45',
  HOUR_22_00 = '22:00',
  HOUR_22_15 = '22:15',
  HOUR_22_30 = '22:30',
  HOUR_22_45 = '22:45',
  HOUR_23_00 = '23:00',
  HOUR_23_15 = '23:15',
  HOUR_23_30 = '23:30',
  HOUR_23_45 = '23:45',
}
