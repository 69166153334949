import React from 'react';

export default function ProviderFigure() {
  return (
    <svg width="113" height="121" viewBox="0 0 113 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.7426 120.27C87.5995 120.27 112.614 94.7501 112.614 63.2699C112.614 31.7897 87.5995 6.27002 56.7426 6.27002C25.8858 6.27002 0.871338 31.7897 0.871338 63.2699C0.871338 94.7501 25.8858 120.27 56.7426 120.27Z"
        fill="#F7F7F7"
      />
      <path
        d="M44.9939 1.19613C47.2411 -0.398708 50.9081 -0.398708 53.1461 1.19613C55.3841 2.79096 59.454 4.14595 62.1897 4.20202C64.9254 4.25809 67.8962 6.45721 68.7908 9.09242C69.6854 11.7276 72.2013 15.2568 74.3813 16.9389C76.5613 18.6209 77.694 22.1813 76.9002 24.8476C76.1064 27.514 76.1064 31.8811 76.9002 34.5506C77.694 37.2201 76.5613 40.7804 74.3813 42.4687C72.2013 44.157 69.6854 47.6862 68.7908 50.3245C67.8962 52.9628 64.9254 55.1619 62.1897 55.2149C59.454 55.2679 55.3841 56.6135 53.1461 58.2083C50.9081 59.8032 47.2411 59.8001 44.9939 58.2083C42.7468 56.6166 38.6768 55.2679 35.9442 55.2149C33.2115 55.1619 30.2407 52.9597 29.3431 50.3245C28.4454 47.6893 25.9296 44.1539 23.7526 42.4687C21.5757 40.7835 20.449 37.2232 21.2429 34.5506C22.0367 31.878 22.0367 27.514 21.2429 24.8476C20.449 22.1813 21.5787 18.6178 23.7526 16.9389C25.9265 15.2599 28.4454 11.7245 29.3431 9.10488C30.2407 6.48524 33.2115 4.27054 35.9442 4.21447C38.6768 4.1584 42.7498 2.79407 44.9939 1.19613Z"
        fill="#1D2F5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8709 61.4122L24.1051 64.6112C21.0483 65.4218 18.4485 67.3104 16.8546 69.8779C15.2606 72.4455 14.7974 75.4912 15.5626 78.3718L18.071 86.933L24.4045 114.078H66.2401H73.566H73.7225H83.9297L76.9414 73.3522C77.1901 71.517 77.2339 69.8771 75.932 68.5172C75.2277 66.8265 73.8461 65.2978 71.9284 64.1177C69.469 62.6042 66.2977 61.7687 63.0129 61.7689L61.1244 61.7689L61.0767 61.7489C60.0877 61.3076 59.2526 60.6137 58.6682 59.7476C58.0837 58.8815 57.7737 57.8786 57.774 56.8548V56.2041H40.0535C40.054 57.3781 39.6465 58.5206 38.8922 59.4598C38.138 60.3989 37.0777 61.084 35.8709 61.4122Z"
        fill="#1E8BCC"
      />
      <rect
        width="12.3256"
        height="24.0607"
        transform="matrix(0.829406 0.558647 -0.619692 0.784845 93.8948 47.8569)"
        fill="#49BA84"
      />
      <rect
        width="12.3256"
        height="24.0607"
        transform="matrix(0.829406 0.558647 -0.619692 0.784845 96.5198 52.3945)"
        fill="#6FCF97"
      />
      <path
        d="M54.1321 53.7288C57.2645 53.4933 60.1998 52.0803 62.3696 49.7636C64.5395 47.4469 65.7896 44.3912 65.8779 41.1882L66.2626 27.3393C66.4427 20.8883 61.7133 16.8389 55.4053 17.3124L48.5142 17.8263C42.1604 18.306 37.034 23.1746 38.2126 29.5602L40.7346 43.2097C41.3088 46.3371 42.9927 49.1389 45.4643 51.0795C47.9359 53.02 51.0218 53.9633 54.1321 53.7288Z"
        fill="#83442B"
      />
      <path
        d="M41.5285 39.9426C41.6249 40.4729 41.5113 41.0206 41.2125 41.4655C40.9138 41.9104 40.4542 42.2164 39.9347 42.3162C39.4129 42.4145 38.8741 42.2974 38.4368 41.9908C37.9994 41.6842 37.6992 41.2131 37.602 40.6808L36.7624 36.0708C36.7143 35.8079 36.7174 35.538 36.7717 35.2764C36.8259 35.0148 36.9301 34.7667 37.0784 34.5462C37.2268 34.3258 37.4162 34.1373 37.636 33.9916C37.8558 33.8459 38.1015 33.7459 38.3592 33.6972C38.8798 33.5988 39.4174 33.7151 39.8541 34.0205C40.2908 34.3259 40.591 34.7955 40.6889 35.3263L41.5285 39.9426Z"
        fill="#83442B"
      />
      <path
        d="M68.9954 35.1799C69.0104 34.3366 68.7799 33.5076 68.333 32.798C67.8862 32.0883 67.2431 31.5298 66.4851 31.193C65.727 30.8562 64.8882 30.7564 64.0745 30.9061C63.2609 31.0557 62.509 31.4482 61.914 32.0339C61.319 32.6195 60.9076 33.3721 60.7319 34.1963C60.5562 35.0205 60.6239 35.8793 60.9267 36.6642C61.2294 37.4491 61.7535 38.1248 62.4327 38.6057C63.1119 39.0867 63.9156 39.3514 64.7423 39.3663C65.8504 39.3863 66.9209 38.9566 67.7185 38.1715C68.516 37.3864 68.9753 36.3104 68.9954 35.1799Z"
        stroke="#86CDAD"
        strokeWidth="2.67"
        strokeMiterlimit="10"
      />
      <path
        d="M58.0801 34.9835C58.0951 34.1403 57.8646 33.3116 57.4179 32.602C56.9712 31.8924 56.3283 31.3338 55.5704 30.997C54.8126 30.6602 53.9739 30.5601 53.1603 30.7096C52.3467 30.859 51.5949 31.2512 50.9998 31.8365C50.4047 32.4219 49.9931 33.1741 49.817 33.9981C49.6409 34.8221 49.7083 35.6808 50.0105 36.4657C50.3128 37.2507 50.8365 37.9266 51.5152 38.4079C52.194 38.8892 52.9974 39.1545 53.8239 39.17C54.9323 39.19 56.0032 38.7603 56.8012 37.9754C57.5993 37.1904 58.0593 36.1143 58.0801 34.9835V34.9835Z"
        stroke="#86CDAD"
        strokeWidth="2.67"
        strokeMiterlimit="10"
      />
      <path
        d="M48.2668 17.428L55.2892 16.9016C61.701 16.4188 66.519 20.5398 66.3358 27.1029L66.2778 29.1961C63.5561 29.1519 60.8871 28.4242 58.5067 27.0774C56.1263 25.7306 54.1079 23.8062 52.6298 21.4743C52.5993 21.5864 52.5718 21.7017 52.5382 21.8169C51.5996 24.966 49.7805 27.7671 47.3042 29.8769C44.8278 31.9866 41.8018 33.3132 38.5972 33.6941L37.7973 29.3675C36.5882 22.8697 41.8031 17.917 48.2668 17.428Z"
        fill="#1D2F5B"
      />
      <path
        d="M55.3656 42.9981C55.3818 43.84 55.7251 44.6407 56.32 45.2243C56.9149 45.8079 57.7127 46.1265 58.5379 46.1099C59.363 46.0934 60.1479 45.7432 60.72 45.1362C61.292 44.5293 61.6042 43.7154 61.588 42.8735"
        fill="white"
      />
      <path
        d="M55.3505 36.6813C54.7232 36.6813 54.2147 36.1625 54.2147 35.5225C54.2147 34.8826 54.7232 34.3638 55.3505 34.3638C55.9778 34.3638 56.4863 34.8826 56.4863 35.5225C56.4863 36.1625 55.9778 36.6813 55.3505 36.6813Z"
        fill="#1D2F5B"
      />
      <path
        d="M64.4367 36.6813C63.8094 36.6813 63.3009 36.1625 63.3009 35.5225C63.3009 34.8826 63.8094 34.3638 64.4367 34.3638C65.064 34.3638 65.5725 34.8826 65.5725 35.5225C65.5725 36.1625 65.064 36.6813 64.4367 36.6813Z"
        fill="#1D2F5B"
      />
      <path
        d="M82.5659 62.9889C84.4232 60.0172 89.1288 58.0176 91.2741 57.7081C91.4754 57.6755 91.6816 57.7014 91.8692 57.7826C92.0568 57.8638 92.2182 57.9973 92.3351 58.1676C92.4519 58.338 92.5194 58.5384 92.53 58.7461C92.5405 58.9537 92.4936 59.1602 92.3946 59.342L83.3015 75.8743L76.2545 72.0443L79.1769 62.7217C79.2253 62.3926 79.3999 62.0965 79.6622 61.8987C79.9246 61.7009 80.2532 61.6175 80.5759 61.6668C80.8985 61.7162 81.1887 61.8943 81.3826 62.162C81.5765 62.4296 81.6583 62.7649 81.6099 63.0941L81.3352 65.0123C81.3352 65.0123 82.175 63.6143 82.5659 62.9889Z"
        fill="#83442B"
      />
      <path
        d="M52.679 21.5832C56.214 23.9586 60.5091 24.8559 64.6747 24.0893C68.8403 23.3227 72.5574 20.951 75.0561 17.4653C76.8056 19.2969 77.6514 22.3931 76.94 24.7823C76.8697 25.0128 76.8148 25.2713 76.7598 25.5236C74.9652 26.9845 72.8913 28.0468 70.6709 28.6427C68.4505 29.2385 66.1326 29.3547 63.8657 28.9837C61.5988 28.6128 59.4328 27.7629 57.5064 26.4886C55.5801 25.2142 53.9358 23.5434 52.679 21.5832Z"
        fill="#526184"
      />
      <path
        d="M40.6705 45.8141C39.0787 45.8141 37.7882 44.4976 37.7882 42.8736C37.7882 41.2496 39.0787 39.9331 40.6705 39.9331C42.2623 39.9331 43.5527 41.2496 43.5527 42.8736C43.5527 44.4976 42.2623 45.8141 40.6705 45.8141Z"
        fill="#86CDAD"
      />
      <path d="M57.8083 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M56.2207 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M54.6296 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M53.0422 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M51.4546 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M49.8638 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M48.2761 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M46.6885 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M45.0977 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M43.51 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M41.9224 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M40.3315 55.981V59.6971" stroke="#1D2F5B" strokeWidth="1.34" strokeMiterlimit="10" />
      <path
        d="M15.2604 78.5998C15.2604 78.5998 37.8652 101.351 45.0984 109.23C51.2293 115.905 62.1135 115.847 66.8813 108.093C71.6491 100.34 83.988 76.0709 83.988 76.0709L75.5034 71.233L55.682 100.287C55.682 100.287 55.682 100.287 40.1961 79.5463C37.1021 75.4025 28.7666 63.9947 22.7666 65.6641C14.0396 68.0923 15.2604 78.5998 15.2604 78.5998Z"
        fill="#1D2F5B"
      />
      <path d="M82.6653 76.998L80.1828 81.2336" stroke="#1E8BCC" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M80.8545 75.8911L78.372 80.1266" stroke="#1E8BCC" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M79.0422 74.7881L76.5598 79.0236" stroke="#1E8BCC" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M77.2317 73.6812L74.753 77.9184" stroke="#1E8BCC" strokeWidth="1.34" strokeMiterlimit="10" />
      <path d="M75.4233 72.5801L72.9409 76.8156" stroke="#1E8BCC" strokeWidth="1.34" strokeMiterlimit="10" />
    </svg>
  );
}
