import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button, Dropdown, Grid, Input, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DateRangePicker } from '../../Components/Shared/DatePicker';
import { TransactionType, isRevenueTransactionType, isExpenseTransactionType, isAnyTransactionType } from '../types';
import { createSearchConditions } from '../transactionsAPI';
import { selectFirstLocation } from '../../Components/DateTime/DateTime';

import styles from '../Transactions.module.scss';

export default function TransactionsSearchBar({ onSearch, loading }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('');
  const [transactionType, setTransactionType] = useState(TransactionType.ANY);
  const isDate = searchField === 'date';
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  return (
    <Grid stackable className={styles.transactionsSearchBar}>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Menu stackable compact data-testid="transactions-filter">
            <Menu.Item
              onClick={() => onFilter(TransactionType.ANY)}
              active={isAnyTransactionType(transactionType)}
              data-testid="all-transactions-filter"
            >
              {t('All Transactions')}
            </Menu.Item>
            <Menu.Item
              data-testid="expenses-filter"
              onClick={() => onFilter(TransactionType.EXPENSE)}
              active={isExpenseTransactionType(transactionType)}
            >
              {t('Expenses')}
            </Menu.Item>
            <Menu.Item
              data-testid="revenue-filter"
              onClick={() => onFilter(TransactionType.REVENUE)}
              active={isRevenueTransactionType(transactionType)}
            >
              {t('Revenue')}
            </Menu.Item>
          </Menu>
        </Grid.Column>

        <Grid.Column textAlign="justified" floated="right">
          {renderSearchInputFields()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  function renderSearchInputFields() {
    return (
      <>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={5} className="noPadding">
              <Dropdown
                data-testid="search-dropdown-filter"
                selection
                clearable
                fluid
                loading={loading}
                placeholder={t('Search by...')}
                options={getSearchDropdownOptions(t)}
                value={searchField}
                onChange={onSearchFieldChange}
              />
            </Grid.Column>
            {!isDate && (
              <Grid.Column textAlign="justified" width={11} className="noPaddingLeft">
                <Input
                  data-testid="search-input-filter"
                  fluid
                  label={<SearchButton />}
                  labelPosition="right"
                  disabled={!searchField}
                  placeholder={getSearchTextPlaceholder(searchField, t)}
                  onKeyPress={onSearchKeyPress}
                  onChange={onSearchTextChange}
                />
              </Grid.Column>
            )}
            {isDate && (
              <>
                <Grid.Column textAlign="justified" width={9} className="noPadding">
                  <DateRangePicker
                    data-testid="date-range-filter"
                    fluid
                    closable
                    className="dateRange"
                    allowSameEndDate
                    value={searchText}
                    onChange={onSearchTextChange}
                  />
                </Grid.Column>
                <Grid.Column width={2} className="noPaddingLeft">
                  <SearchButton fluid />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
        </Grid>
      </>
    );
  }
  function SearchButton(props) {
    return (
      <Button
        icon="search"
        onKeyPress={onSearchKeyPress}
        onClick={onSearchClick}
        loading={loading}
        {...props}
        data-testid="search-button"
      />
    );
  }
  function onSearchKeyPress(e) {
    if (e.key === 'Enter') {
      performSearch();
    }
  }
  function onSearchClick(_e) {
    performSearch();
  }
  function onSearchFieldChange(e, { value }) {
    setSearchField(value);
    if (!value) {
      resetSearch();
    } else if (value === 'date') {
      setSearchText('');
    }
  }
  function onSearchTextChange(e, { value }) {
    setSearchText(value);
  }
  function performSearch(currentTransactionType = null) {
    if (loading) return;
    currentTransactionType = currentTransactionType || transactionType;
    const searchConditions = createSearchConditions(searchField, searchText, currentTransactionType, timezone);
    if (onSearch) onSearch(searchConditions);
  }

  function resetSearch() {
    setSearchText('');
    const searchConditions = createSearchConditions(null, '', transactionType);
    if (onSearch) onSearch(searchConditions);
  }

  function onFilter(type) {
    if (transactionType === type) return;
    setTransactionType(type);
    performSearch(type);
  }
}

function getSearchDropdownOptions(t) {
  return [
    {
      key: 'date',
      text: t('Date Range'),
      value: 'date',
    },
    {
      key: 'category',
      text: t('Category'),
      value: 'category',
    },
    {
      key: 'payee',
      text: t('Payee'),
      value: 'payee',
    },
    {
      key: 'payer',
      text: t('Payer'),
      value: 'payer',
    },
    {
      key: 'notes',
      text: t('Notes'),
      value: 'notes',
    },
  ];
}
function getSearchTextPlaceholder(searchField, t) {
  switch (searchField) {
    case 'category':
      return t('Search categories...');
    case 'payee':
      return t('Search for payee...');
    case 'payer':
      return t('Search for payer...');
    case 'notes':
      return t('Search in notes...');
    default:
      return t('Search transactions...');
  }
}
