import { Redirect } from 'react-router-dom';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';
import WSA from '../../../WSA';
import { paths } from '../../paths';
import { useRouteDebounce } from '../../navigationHooks';

const VerifyRoute = ({ children }) => {
  const { isAuthenticated, needsVerification } = WSA.Components.useWSAuth();

  const needsDebounce = !isAuthenticated || needsVerification;
  const debounceString = JSON.stringify({
    isAuthenticated,
    needsVerification,
  });
  const isDebounced = useRouteDebounce(debounceString, needsDebounce);

  if (!isDebounced) {
    return <LoadingComponent />;
  } else if (isAuthenticated) {
    // ready and autheticated
    if (needsVerification) {
      return <>{children}</>;
    } else {
      return <Redirect to={paths.setup} />;
    }
  } else {
    // not authenticated, go to signin
    return <Redirect to={paths.signin} push={true} />;
  }
};
export default VerifyRoute;
