import Hotjar from '@hotjar/browser';
import { getConfig } from './config/env';
import { getChangedBy } from './api/firebase2/firestore';

export function initializeHotjar() {
  const { wsConfig } = getConfig();
  // hotjar stuff.
  const hotjarConfig = {
    siteId: wsConfig?.hotjar?.siteId,
    version: wsConfig?.hotjar?.version,
  };

  if (hotjarConfig.siteId && hotjarConfig.version && wsConfig.envName !== 'local') {
    Hotjar.init(hotjarConfig.siteId, hotjarConfig.version);
  }

  return null;
}

export function identifyHotjar() {
  const { uid: userId, email, name } = getChangedBy();

  if (!userId || !email || !name) return;
  // hotjar identify API requires 1st parameter as userId and 2nd parameter as object with key/value pairs.
  Hotjar.identify(userId, { email, name });
}
