import { useFileServiceQuota } from '@wonderschool/file-service-client';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Form, Input, InputOnChangeData, Label, Segment } from 'semantic-ui-react';
import { humanizeBytes } from '../../../utils/file';

interface FileQuotaSettingsProps {
  organizationId: string;
}

function FileQuotaSettings({ organizationId }: FileQuotaSettingsProps) {
  const { t } = useTranslation();
  const {
    getter: { data: settings, isLoading: isLoadingQuota, error: quotaLoadError },
    setter: { isLoading: isSavingQuota, error: quotaSaveError, setStorageQuota },
  } = useFileServiceQuota({
    organizationId,
  });

  const [newQuota, setNewQuota] = useState(0);

  const isLoaded = isLoadingQuota || isSavingQuota;
  const errorMessage = quotaSaveError?.message || quotaLoadError?.message;

  const quotaValue = newQuota || settings?.fileQuota || 0;
  const remainingQuotaValue = settings?.remainingFileQuota || 0;
  const usedTotalQuotaValue = settings?.usageTotal || 0;

  // Remaining Quota percentage
  const remainingQuotaPercentage =
    typeof settings?.fileQuota === 'number' && settings.fileQuota > 0
      ? (remainingQuotaValue / settings.fileQuota) * 100
      : 0;

  // Used Quota percentage
  const usedQuotaPercentage =
    typeof settings?.fileQuota === 'number' && settings.fileQuota > 0
      ? (usedTotalQuotaValue / settings?.fileQuota) * 100
      : 0;

  return (
    <Segment basic loading={isLoaded}>
      <Form.Field
        control={Input}
        step={100000000}
        type="number"
        id="file-storage"
        name="file-storage"
        label={t('File Storage Quota (Bytes)')}
        value={quotaValue}
        error={
          errorMessage
            ? {
                content: errorMessage,
              }
            : undefined
        }
        onChange={(_event: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
          setNewQuota(Number(value));
        }}
        placeholder={t('Enter quota in bytes')}
        action={{
          color: 'teal',
          icon: 'save',
          loading: isSavingQuota,
          labelPosition: 'right',
          content: t('Save'),
          type: 'button',
          onClick: () => {
            setStorageQuota({
              quota: quotaValue,
            });
          },
        }}
      />
      <Label>
        {t('Quota')}
        <Label.Detail>{humanizeBytes(quotaValue)}</Label.Detail>
      </Label>
      <Label color={remainingQuotaPercentage <= 25 ? 'red' : 'green'}>
        {t('Remaining')}
        <Label.Detail>{remainingQuotaPercentage.toFixed(2)}%</Label.Detail>
      </Label>
      <Label color={usedQuotaPercentage >= 75 ? 'red' : 'green'}>
        {t('Used')}
        <Label.Detail>{usedQuotaPercentage.toFixed(2)}%</Label.Detail>
      </Label>
    </Segment>
  );
}

// memo is used to prevent re-rendering of the component each time the parent component re-renders.
// We don't want to re-render this component as it sends a request to the server to fetch the quota settings.
export default memo(FileQuotaSettings);
