import { useState } from 'react';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line no-restricted-imports
import { Card } from 'semantic-ui-react';

import StudentCard from './StudentCard';
import StudentDetail from '../StudentDetail';
import withSlidingPanel from '../../../Components/Shared/withSlidingPanel';

import { useSelectedStudent, useStudents } from '../../studentsHooks';
import { studentSelectionCleared } from '../../studentsRedux';

const SLIDER_WIDTH = '85%';

const SlidingStudentDetail = withSlidingPanel(StudentDetail, {
  width: SLIDER_WIDTH,
});

const StudentsGalleryParent: React.FC = () => {
  const dispatch = useDispatch();
  const { list: students } = useStudents();
  const selectedStudent = useSelectedStudent();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  return (
    <>
      <Card.Group stackable itemsPerRow={4}>
        {students.map((student) => (
          <StudentCard
            key={student.id}
            student={student}
            onClick={() => {
              dispatch({ type: 'STUDENT_SELECTED', student });
              setIsEditOpen(true);
            }}
          />
        ))}
      </Card.Group>
      <SlidingStudentDetail
        title={selectedStudent?.displayName}
        image={selectedStudent?.picture}
        isOpen={isEditOpen}
        onClose={() => {
          dispatch(studentSelectionCleared());
          setIsEditOpen(false);
        }}
      />
    </>
  );
};

export default StudentsGalleryParent;
