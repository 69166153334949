import React from 'react';
import PropTypes from 'prop-types';
import Invoice from './Invoice';
import withInvoiceItemsEditor from '../withInvoiceItemsEditor';
import { routes } from '../../../../../config/routes';
import withSegmentHook from '../../../../../segment/hooks/withSegmentHook';
import { SEGMENT_EVENTS } from '../../../../../segment';

class InvoiceContainer extends React.Component {
  static propTypes = {
    paymentDetails: PropTypes.object,
  };

  state = {
    dateDue: null,
    dateServiceStart: null,
    dateServiceEnd: null,
  };

  componentDidMount() {
    if (this.props.paymentDetails && this.props.paymentDetails.isInvoice) {
      this.setState({
        dateDue: this.props.paymentDetails.dateDue,
        dateServiceStart: this.props.paymentDetails.dateServiceStart,
        dateServiceEnd: this.props.paymentDetails.dateServiceEnd,
      });
    }
  }

  handleChange = (e, { name, value }) => {
    // Enforce date selection
    if (name === 'dateDue') {
      this.setState({
        dateDue: value,
        dateServiceStart: null,
        dateServiceEnd: null,
      });
    } else if (name === 'dateServiceStart') {
      this.setState({
        dateServiceStart: value,
        dateServiceEnd: null,
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  saveInvoiceData = () => {
    let { invoiceItemList, total } = this.props;

    if (this.props.validateFields(true)) {
      let invoiceSummary = this.props.getInvoiceSummary();
      this.props.updatePaymentDetails({
        ...this.state,
        invoiceItemList,
        total,
        invoiceSummary,
        isInvoice: true,
      });
      if (!this.props.firstInvoiceCreated) {
        this.props.segmentTrack(SEGMENT_EVENTS.firstInvoiceDetailsSuccess, invoiceSummary);
      }
      this.props.history.push(routes.billing.select);
    }
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    //trigger submit from a parent form
    if (this.props.fireSubmit === true && prevProps.fireSubmit !== true) {
      this.saveInvoiceData();
    }
  }

  render() {
    return <Invoice onChange={this.handleChange} {...this.state} {...this.props} />;
  }
}

export default withSegmentHook(withInvoiceItemsEditor(InvoiceContainer));
