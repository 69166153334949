import { useCallback } from 'react';
import { WsWidgetSizeEnum } from './enums';

export type WsSpinnerProps = {
  size?: WsWidgetSizeEnum;
  spinnerColor?: string;
  overlayColor?: string;
  hasOverlay?: boolean;
};

export function WsSpinner({
  size = WsWidgetSizeEnum.SMALL,
  spinnerColor = 'white',
  overlayColor = 'white',
  hasOverlay = false,
}: WsSpinnerProps): JSX.Element {
  const spinnerClassName = `animate-spin ${getSizeClasses(size)} text-${spinnerColor}-500 z-20`;
  const overlayClassName = `absolute bg-${overlayColor} bg-opacity-80 z-10 h-full w-full flex items-center justify-center`;

  const SpinnerIcon = useCallback(() => {
    return (
      <svg className={spinnerClassName} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }, [spinnerClassName]);

  if (hasOverlay) {
    return (
      <div className={overlayClassName}>
        <SpinnerIcon />
      </div>
    );
  } else {
    return <SpinnerIcon />;
  }
}
function getSizeClasses(size: WsWidgetSizeEnum): string {
  switch (size) {
    case WsWidgetSizeEnum.SMALL:
      return 'h-5 w-5';
    case WsWidgetSizeEnum.MEDIUM:
      return 'h-10 w-10';
    case WsWidgetSizeEnum.LARGE:
      return 'h-16 w-16';
    default:
      return 'h-5 w-5';
  }
}
export default WsSpinner;
