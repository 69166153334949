import { Dropdown } from './Dropdown';

const options = [
  { key: 'Dr.', value: 'Dr.', text: 'Dr.' },
  { key: 'Father', value: 'Father', text: 'Father' },
  { key: 'Miss', value: 'Miss', text: 'Miss' },
  { key: 'Mr.', value: 'Mr.', text: 'Mr.' },
  { key: 'Mrs.', value: 'Mrs.', text: 'Mrs.' },
  { key: 'Ms.', value: 'Ms.', text: 'Ms.' },
  { key: 'Prof', value: 'Prof', text: 'Prof' },
  { key: 'Rev.', value: 'Rev.', text: 'Rev.' },
  { key: 'Sister', value: 'Sister', text: 'Sister' },
];

const SalutationPicker = ({ staticContext, control, children, ...rest }) => {
  return <Dropdown {...rest} options={options} selectOnBlur={false} clearable />;
};

export default SalutationPicker;
