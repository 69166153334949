import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Header, HeaderProps, List, ListProps } from 'semantic-ui-react';

const ResponsiveHeader = (args: HeaderProps) => (
  <>
    <Header {...args} className={'mt-0 hidden sm:block'} />
    <Header {...args} size={'small'} className={'mt-0 sm:hidden'} />
  </>
);

const ResponsiveList = (args: ListProps) => (
  <>
    <List {...args} className={'hidden sm:block'} />
    <List {...args} size={'small'} className={'sm:hidden'} />
  </>
);

const AppStoreLinksContainer = () => {
  const { t } = useTranslation();

  return (
    <div className={'mt-2 flex w-full flex-wrap gap-6 pt-2'}>
      <div className={'flex-none'}>
        <img src="/images/wonderschool-app.png" srcSet="/images/wonderschool-app@2x.png 2x" className={'h-40'} />
      </div>
      <div className={'min-w-40 flex-1 sm:flex-none'}>
        <ResponsiveHeader as="h3">{t('Download the Wonderschool Mobile App')}</ResponsiveHeader>
        <ResponsiveList as="ul">
          <List.Item as="li">{t('Real-time updates')}</List.Item>
          <List.Item as="li">{t('Mobile check in / check out')}</List.Item>
          <List.Item as="li">{t('Connect your bank account or credit card')}</List.Item>
          <List.Item as="li">{t('View up to year childcare cost and pay invoice online')}</List.Item>
          <List.Item as="li">{t('Turn on/off automatic payment')}</List.Item>
        </ResponsiveList>
      </div>

      <div className={'flex flex-none gap-3 lg:flex-col'}>
        <div className={'flex-none'}>
          <a href="https://apps.apple.com/us/app/wonderschool-2-0/id1560074981" target="_blank" rel="noreferrer">
            <img className={'h-10 w-auto'} src="/images/download-apple-store-badge.svg" />
          </a>
        </div>
        <div className={'flex-none'}>
          <a
            href="https://play.google.com/store/apps/details?id=com.moxitinc.wonderschool"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/download-google-play-badge2.png" className={'h-10 w-auto'} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppStoreLinksContainer;
