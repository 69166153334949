const TOTAL_WEEKS_PER_YEAR = 52;
const TOTAL_HOURS_PER_YEAR = 8760;

type GetTimePercentageInput = {
  weeklyActiveHours?: number;
  weeklyAdminHours?: number;
  inactiveWeeks?: number;
  weeklyHoursWhenClosed?: number;
};

type GetSpacePercentageInput = {
  homeSquareFootage?: number;
  exclusiveUseSquareFootage?: number;
  regularUseSquareFootage?: number;
};

/**
 * For a provider, time percentage is calculated as follows:
 * a = total hours per week they provide care
 * b = total hours a week they perform duties related to managing their child care program outside of the hours they provide care on average
 * c = total weeks that they are closed in a given year
 * d = hours per week they spend performing administrative duties in the home when the program is closed on average
 *
 * time percentage = (((a+b)(52-c)) + (dc)) / 8760
 *
 * Please see https://www.notion.so/wonderschool/Time-Space-Percentage-Calculator-5e5ad0c63a214776b112a8deca4b1c90
 * for more context.
 */
export const getTimePercentage = ({
  weeklyActiveHours,
  weeklyAdminHours,
  inactiveWeeks,
  weeklyHoursWhenClosed,
}: GetTimePercentageInput): number | null => {
  if (
    !(weeklyActiveHours || weeklyActiveHours === 0) ||
    !(weeklyAdminHours || weeklyAdminHours === 0) ||
    !(inactiveWeeks || inactiveWeeks === 0) ||
    !(weeklyHoursWhenClosed || weeklyHoursWhenClosed === 0)
  )
    return null;

  const hoursWorkedWhenOpen = (weeklyActiveHours + weeklyAdminHours) * (TOTAL_WEEKS_PER_YEAR - inactiveWeeks);
  const hoursWorkedWhenClosed = inactiveWeeks * weeklyHoursWhenClosed;
  const totalAnnualHoursWorked = hoursWorkedWhenOpen + hoursWorkedWhenClosed;
  const timePercentage = totalAnnualHoursWorked / TOTAL_HOURS_PER_YEAR;

  return timePercentage;
};

export const getSpacePercentage = (squareFootageUse?: number, homeSquareFootage?: number): number | null => {
  if (!homeSquareFootage || !(squareFootageUse || squareFootageUse === 0)) return null;

  return squareFootageUse / homeSquareFootage;
};

/**
 * For a provider, time space percentage is calculated as follows:
 * x = total square footage of the home
 * y = space (in square feet) that is exclusively used for the child care
 * w = space (in square feet) of regularly used area for the child care
 *
 * time space percentage = (y/x) + (w/x)*timePercentage
 */
export const getTimeSpacePercentage = (
  timeValues: GetTimePercentageInput,
  spaceValues: GetSpacePercentageInput
): number | null => {
  const timePercentage = getTimePercentage(timeValues);
  const regularSpacePercentage = getSpacePercentage(spaceValues.regularUseSquareFootage, spaceValues.homeSquareFootage);
  const exclusiveSpacePercentage = getSpacePercentage(
    spaceValues.exclusiveUseSquareFootage,
    spaceValues.homeSquareFootage
  );

  return timePercentage != null && regularSpacePercentage != null && exclusiveSpacePercentage != null
    ? exclusiveSpacePercentage + regularSpacePercentage * timePercentage
    : null;
};

export const getDisplayPercentage = (value: number): string => (value == null ? '--' : (value * 100).toFixed(2));
