import { useTranslation } from 'react-i18next';
import { useStudents } from '../../students/studentsHooks';
import { CONTACT_STATUS, EnrollmentStatusEnum } from '@wonderschool/common-base-types';
import { getEnrollmentStatus, isStudentArchived } from '../../students/studentsUtils';
import { useContacts } from '../../contacts';

interface LocationStatsProps {
  checkedInStaff: number;
  checkedInStudents: number;
}

type LocationOverviewItemProps = {
  title: string;
  value: number;
  highlight?: boolean;
};
const LocationOverviewItem = ({ title, value, highlight }: LocationOverviewItemProps) => (
  <div
    className={
      'ml-0 mr-auto flex space-x-2 text-nowrap border-none py-1.5' +
      (highlight === true ? ' rounded-lg border bg-purple-50 px-3' : '')
    }
  >
    <span>{title}:</span>
    <strong>{value}</strong>
  </div>
);

const LocationOverviewTable: React.FC<LocationStatsProps> = ({ checkedInStaff = 0, checkedInStudents = 0 }) => {
  const { t } = useTranslation();
  const students = useStudents();
  const { staffContacts } = useContacts();

  const activeStaffContactsCount = staffContacts.filter((staff) => staff.status === CONTACT_STATUS.verified).length;
  const enrolledStudents = students.list.filter((student) => {
    const enrollmentStatus = getEnrollmentStatus(student);
    return enrollmentStatus === EnrollmentStatusEnum.ENROLLED && !isStudentArchived(student);
  });
  const enrolledStudentsCount = enrolledStudents.length;

  return (
    <div className="flex flex-wrap gap-x-6">
      <div className="flex flex-col">
        <LocationOverviewItem title={t('overview.totalStudents')} value={enrolledStudentsCount} />
        <LocationOverviewItem title={t('overview.checkedInStudents')} value={checkedInStudents} highlight />
      </div>
      <div className="flex flex-col">
        <LocationOverviewItem title={t('overview.totalStaff')} value={activeStaffContactsCount} />
        <LocationOverviewItem title={t('overview.clockedInStaff')} value={checkedInStaff} highlight />
      </div>
    </div>
  );
};

const LocationStatsOverview: React.FC<LocationStatsProps> = ({ checkedInStaff = 0, checkedInStudents = 0 }) => {
  const { t } = useTranslation();

  return (
    <div className="border-b mb-4 pb-4">
      <h3 className="text-xl">{t('overview.schoolOverview')}</h3>
      <LocationOverviewTable checkedInStaff={checkedInStaff} checkedInStudents={checkedInStudents} />
    </div>
  );
};

export default LocationStatsOverview;
