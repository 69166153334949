import { useFlags } from 'launchdarkly-react-client-sdk';

import AttendancePage from './AttendancePage';
import AttendancePageV1 from '../../../Pages/Reports/Attendance';

export const Attendance: React.FC = () => {
  const { isTailwindReportsEnabled } = useFlags();

  return isTailwindReportsEnabled ? <AttendancePage /> : <AttendancePageV1 />;
};
export default Attendance;
