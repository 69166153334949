import { useMemo } from 'react';
import {
  BusinessHoursType,
  DocumentType,
  InvitationRecipientType,
  TuitionAndFeesType,
  ScheduledDayType,
} from './types';
import { HourEnum } from './enums';
import { getDocuments, getInvitationRecipients, getTuitionAndFees, getScheduledDays } from './enrollmentsUtils';
//import { usePrimaryLocation } from '../hooks/useLocations';

const DEFAULT_BUSINESS_HOURS: BusinessHoursType = {
  startTime: HourEnum.HOUR_08_00,
  endTime: HourEnum.HOUR_17_00,
};

export function useDefaultBusinessHours(): BusinessHoursType {
  //const location = usePrimaryLocation();
  return DEFAULT_BUSINESS_HOURS;
}
export function useDocuments(student: any): DocumentType[] {
  return useMemo<DocumentType[]>(() => getDocuments(student), [student]);
}

export function useInvitationRecipients(student: any): InvitationRecipientType[] {
  return useMemo<InvitationRecipientType[]>(() => getInvitationRecipients(student), [student]);
}

export function useTuitionAndFees(student: any): TuitionAndFeesType {
  return useMemo<TuitionAndFeesType>(() => getTuitionAndFees(student), [student]);
}

export function useScheduledDays(student: any): ScheduledDayType[] {
  const defaultBusinessHours = useDefaultBusinessHours();
  return useMemo<ScheduledDayType[]>(
    () => getScheduledDays(student, defaultBusinessHours),
    [student, defaultBusinessHours]
  );
}
