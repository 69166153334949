import { WsLabelProps } from './types';

export function WsLabel({ children, required = false, htmlFor = '', 'data-testid': testId = '' }: WsLabelProps) {
  return (
    <label htmlFor={htmlFor} className="block text-sm font-medium leading-6 text-gray-900" data-testid={testId}>
      {children}
      {required && (
        <span className="ml-1 text-red-600" aria-hidden="true">
          *
        </span>
      )}
    </label>
  );
}
export default WsLabel;
