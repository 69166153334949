import { timecardsOnListen } from './timecardsAPI';
import { TimecardsListType } from './types';
import { TimecardStatusEnum } from './enums';

const ORGANIZATION_TIMECARDS_FETCHED = 'ORGANIZATION_TIMECARDS_FETCHED';

type TimecardsFetchedActionType = {
  type: string;
  timecards: TimecardsListType;
};

export const timecardsFetchedAction = (timecards: TimecardsListType) => ({
  type: ORGANIZATION_TIMECARDS_FETCHED,
  timecards,
});

export const startOrganizationClockedInListener =
  (organizationId: string) => (dispatch: (event: TimecardsFetchedActionType) => void) => {
    const conditions = [{ field: 'status', operation: '==', value: TimecardStatusEnum.CLOCKED_IN } as const];

    return timecardsOnListen(
      { organizationId, conditions },
      (timecards: TimecardsListType) => {
        if (timecards) dispatch(timecardsFetchedAction(timecards));
      },
      (error) => console.log(error.message),
      () => null
    );
  };
const initialState = {
  count: 0,
  list: [],
} as TimecardsListType;

export const timecardsReducer = (state = initialState, action: TimecardsFetchedActionType) => {
  switch (action.type) {
    case ORGANIZATION_TIMECARDS_FETCHED:
      return {
        count: action.timecards.count,
        list: action.timecards.list,
      };
    default:
      return state;
  }
};
