import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Dimmer, Loader } from 'semantic-ui-react';

interface LoadingProps {
  children?: ReactNode;
  text?: string;
}

const Loading: React.FC<LoadingProps> = ({ children, text }) => (
  <Dimmer page active inverted>
    <Loader inline="centered">{text || children}</Loader>
  </Dimmer>
);

export default Loading;
