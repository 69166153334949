import { SortDirectionEnum } from '../enums';
import { SortDirectionType } from '../types';

// The SortDirectionType contains the name of the property to sort on and the direction in which to sort
export function sortCollection<T>(collection: T[], sortDirection: SortDirectionType): T[] {
  if (sortDirection.direction === SortDirectionEnum.NONE || !sortDirection.name) return collection;

  const newCollection: T[] = [...collection];

  newCollection.sort((a: T, b: T) => {
    const aVal = a?.[sortDirection.name];
    const bVal = b?.[sortDirection.name];

    if (aVal === undefined && bVal === undefined) return 0;
    if (aVal === undefined) return sortDirection.direction === SortDirectionEnum.ASC ? -1 : 1;
    if (bVal === undefined) return sortDirection.direction === SortDirectionEnum.ASC ? 1 : -1;

    if (typeof aVal === 'string' && typeof bVal === 'string') {
      const aValString = aVal.toLowerCase();
      const bValString = bVal.toLowerCase();
      return sortDirection.direction === SortDirectionEnum.ASC
        ? aValString.localeCompare(bValString)
        : bValString.localeCompare(aValString);
    } else if (typeof aVal === 'number' && typeof bVal === 'number') {
      return sortDirection.direction === SortDirectionEnum.ASC ? aVal - bVal : bVal - aVal;
    } else if (aVal instanceof Date && bVal instanceof Date) {
      return sortDirection.direction === SortDirectionEnum.ASC
        ? aVal.getTime() - bVal.getTime()
        : bVal.getTime() - aVal.getTime();
    }
    return 0;
  });
  return newCollection;
}
