import { Button, Tooltip } from '@wonderschool/common-base-ui';
import { Contact, CONTACT_STATUS, StudentType as Student } from '@wonderschool/common-base-types';
import { WsBlueBtnArrow, WsBtnArrow } from '../../icons';
import { toDateObject } from '../../helpers/dates';
import { useEffect, useState } from 'react';
import {
  SendInvitationConfirmationDialog,
  showInvitationErrorToast,
  showInvitationSuccessToast,
  useContacts,
} from '../../contacts';
import { inviteContact } from '../../contacts/contactsAPI';
import { WsSpinner, WsWidgetSizeEnum } from '../../common';
import { getMemberByIndex } from '../../helpers/utils';

interface Invitation {
  invitationId: string;
  email: string;
  sentAt: string;
  displayName: string;
}

export function InviteStatus(student: Student, t: any, index?: number) {
  const [allInvitations, setAllInvitations] = useState<Invitation[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [sendToContact, setSendToContact] = useState(null);
  const { contacts } = useContacts();

  const fetchAllInvitations = async () => {
    const invitations = contacts.map((invitation: Invitation) => ({
      invitationId: invitation.invitationId,
      email: invitation.email,
      sentAt: invitation.sentAt,
      displayName: invitation.displayName,
    }));
    setAllInvitations(invitations);
  };

  useEffect(() => {
    fetchAllInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  async function onSendInvitation(contact: Contact) {
    try {
      setLoader(true);
      contact.status = CONTACT_STATUS.inviting;
      await inviteContact(contact);
      showInvitationSuccessToast(contact);
    } catch (error) {
      setLoader(false);
      showInvitationErrorToast(contact, error);
    } finally {
      setSendToContact(null);
      setLoader(false);
    }
    return null;
  }

  const handleClickSend = (family: Contact) => {
    const findContact = contacts.find((obj: Contact) => obj.email === family.email || obj.id === family.id);
    setSendToContact(findContact);
  };

  function calculateInvitationTimeDifference(member: Contact) {
    if (!allInvitations) return '';
    const invitation = allInvitations.find(
      (invitation: Invitation) => invitation.email === member.email && invitation.displayName === member.displayName
    );

    if (!invitation || !invitation.sentAt) return '';

    const invitationSentDate = toDateObject(invitation.sentAt, 'MM/DD/YYYY');
    if (!invitationSentDate) return ''; // Add null check for invitationSentDate

    const timeDifference = Math.round((new Date().valueOf() - invitationSentDate.valueOf()) / (1000 * 3600 * 24));
    if (timeDifference > 0) {
      return `Last invited ${timeDifference} day${timeDifference > 1 ? 's' : ''} ago`;
    } else {
      const hoursDifference = Math.round((new Date().valueOf() - invitationSentDate.valueOf()) / (1000 * 3600));
      if (hoursDifference === 0) return 'Just Now';
      return `Last invited ${hoursDifference} hour${hoursDifference > 1 ? 's' : ''}`;
    }
  }

  function displayButton(member: Contact) {
    const isEmailEmpty = !!member.email;
    const button = (
      <Button
        primary={isEmailEmpty && member.status === CONTACT_STATUS.new}
        disabled={!isEmailEmpty}
        data-testid="parents-invite-btn"
        extraClasses="w-full w-36 min-h-58 py-1 min-h-10"
        onClick={() => handleClickSend(member)}
        preIcon={
          member.status === CONTACT_STATUS.invited ? (
            <WsBlueBtnArrow className="h-5 w-5 font-normal" />
          ) : (
            <WsBtnArrow className="h-5 w-5 font-normal text-white" />
          )
        }
        label={member.status === CONTACT_STATUS.invited ? t('Resend') : t('Invite')}
      />
    );

    return !isEmailEmpty ? (
      <Tooltip content={t('EmailRequiredTooltip')} action="hover">
        <div className="min-h-58 min-h-10 w-full max-w-36 py-1">{button}</div>
      </Tooltip>
    ) : (
      button
    );
  }

  const renderInviteStatus = (member: Contact, index: number) => (
    <>
      <SendInvitationConfirmationDialog
        onClose={() => setSendToContact(null)}
        onYes={onSendInvitation}
        contact={sendToContact}
      />
      <div
        key={index}
        className={`mb-3 min-h-12 content-center ${member.status === CONTACT_STATUS.inviting ? 'max-w-36 text-center' : 'm-auto'}`}
      >
        {member.status === CONTACT_STATUS.inviting && (
          <Button extraClasses="max-w-36 min-h-10 w-full" preIcon={<WsSpinner size={WsWidgetSizeEnum.SMALL} />}>
            {t('parentsListing.sending')}
          </Button>
        )}
        {member.status === CONTACT_STATUS.invited || member.status === CONTACT_STATUS.new ? (
          <>
            {displayButton(member)}
            <div className="w-full max-w-36 text-center text-xs">
              {loader && member.status === CONTACT_STATUS.invited ? (
                <Button extraClasses="max-36 w-full text-center" />
              ) : (
                calculateInvitationTimeDifference(member)
              )}
            </div>
          </>
        ) : (
          member.status === CONTACT_STATUS.verified && (
            <p className="max-w-36 pb-3 text-center text-gray-800">{t('parentsListing.inviteAccepted')}</p>
          )
        )}
      </div>
    </>
  );

  if (student?.family && Object.values(student?.family).length > 0) {
    if (typeof index === 'number') {
      const member = getMemberByIndex(student, index);
      return renderInviteStatus(member, index);
    }
    return Object.values(student?.family).map((member: Contact, index: number) => {
      return renderInviteStatus(member, index);
    });
  } else {
    return (
      <div>
        <Tooltip content={t('EmailRequiredTooltip')} action="hover">
          <Button
            primary
            disabled={true}
            data-testid="parents-invite-btn"
            extraClasses="disabled:border-gray-400 min-h-10 w-full min-w-36"
            preIcon={<WsBtnArrow className="font-grey-900 h-5 w-5 font-normal" />}
            label={t('Invite')}
          ></Button>
        </Tooltip>
      </div>
    );
  }
}
