import React from 'react';

export default function CoinIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12"
        cy="12.4995"
        r="9"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0001 12.4996L17.5715 12.4995"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42852 12.4995H5.99995"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2426 8.25695L15.9395 8.55994"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75744 8.2569L8.06043 8.56"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75739 16.7421L8.06049 16.4391"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2426 16.7421L15.9396 16.439"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 8.49939V9.30725" stroke="#287BBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10 10.9231C10.1168 9.93056 11.005 9.21302 12 9.30741"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 16.4996V15.6918" stroke="#287BBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14 14.0759C13.8832 15.0685 12.995 15.786 12 15.6916"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9491 10.5705C13.6482 9.76643 12.8569 9.2536 12 9.30732"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0509 14.4285C10.3518 15.2326 11.1431 15.7454 12 15.6917"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.9231V10.9231C10 11.6071 10.4828 12.1961 11.1536 12.3302L12.8464 12.6688C13.5172 12.803 14 13.3919 14 14.0759V14.0759"
        stroke="#287BBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
