import { useTranslation } from 'react-i18next';

import { useRoutes } from '../../../navigation';

import { WsEdit } from '../../../icons';

type SummaryViewTitleProps = {
  title?: string;
  hash?: string;
};
export default function SummaryViewTitle({ title }: SummaryViewTitleProps) {
  const { t } = useTranslation();
  const { goBack } = useRoutes();

  if (!title) return null; // an empty title means no title

  return (
    <h2 data-testid="summary-view-wrapper">
      {t(title ?? 'enrollments.scheduleFormTitle')}{' '}
      <a href="#" className="edit" onClick={() => goBack()} data-testid="summary-view-btn">
        <WsEdit />
      </a>
    </h2>
  );
}
