// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import store from '../../redux/store';
import { auth } from './firebase';
import { provisionOrganizationUserAccount } from './organizations';
/**
 * Checks if the current authenticated user has a specific role
 * @param role - The role to check for
 * @returns {Promise<boolean>} True if user has the role, false otherwise
 * Steps:
 * 1. Validates role exists and user is authenticated and email verified
 * 2. Gets the ID token result containing user claims
 * 3. Checks if the role claim exists and is true
 */
export const userHasRole = async (role) => {
  const currentUser = auth().currentUser;
  if (role && currentUser?.emailVerified) {
    const idTokenResult = await currentUser.getIdTokenResult();
    if (idTokenResult.claims[role] && idTokenResult.claims[role] === true) return true;
  }
  return false;
};

export const userHasOneOfRoles = (roles) => {
  const user = store.getState().user || {};
  const currentUser = auth().currentUser;
  return roles && roles.length && currentUser?.emailVerified && !_.isEmpty(user) && !_.isEmpty(user.claims)
    ? roles.some((role) => user.claims[role] === true)
    : false;
};

// Rule to check restriction or orgType.
export const hasNoOrgRestriction = (routeName) => {
  const { currentOrganization } = store.getState().organizations;

  // Restrict Billing for home care.
  if (currentOrganization?.programType === 'familyChildCare' && routeName === 'Rooms') return false;

  return true; // if no org, totally ok
};

export const userHasPermission = (permissionKey) => {
  const permissions = store.getState().permissions || {};
  const user = store.getState().user || {};

  // Override all permissions for moxitAdmin claims.
  // Not this doesn't mean data is protected. Firestore rule MUST be in place
  // to control data read/write.
  if (user && user.claims && !!user.claims.moxitAdmin) return true;
  if (permissionKey && permissions[permissionKey] && permissions[permissionKey].roles) {
    const allowedRoles = permissions[permissionKey].roles || [];
    return userHasOneOfRoles(allowedRoles);
  }
  return false;
};

export const startProvisioning = (userData) => {
  const user = auth().currentUser;
  if (!user?.uid) return;

  const profileData = {
    uid: user.uid,
    email: user.email,
    ...userData,
  };

  if (userData.firstName && userData.lastName) profileData.displayName = `${userData.firstName} ${userData.lastName}`;

  return provisionOrganizationUserAccount(profileData);
};

export const sendResetPasswordEmail = async (email) => auth().sendPasswordResetEmail(email);
