import PropTypes from 'prop-types';
import CN from 'classnames';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import { Card, List, Label } from 'semantic-ui-react';
import Avatar from 'react-avatar';

// Import utils
import { formatFullName } from '../../../helpers/utils';

// Import style
import styles from '../students.module.scss';

const FamilyAvatars = ({ student }) => {
  const { t } = useTranslation();

  const ParentAvatar = ({ round, size, ...restProps }) => <Avatar {...restProps} round size={35} />;

  const EmptyParentAvatar = (/* no external props */) => <ParentAvatar name=" " initials={() => ' '} color="white" />;

  const MoreParentsAvatar = ({ count }) => <ParentAvatar name=" " initials={() => `+${count}`} color="grey" />;

  const ParentName = ({ name, truncate = false }) => (
    <List.Content>
      <List.Header
        as="h5"
        className={CN({
          textTruncate: truncate,
          [styles.parentAvatarDimensions]: truncate,
        })}
      >
        {name}
      </List.Header>
    </List.Content>
  );

  // \u00a0 => unicode character for nbsp
  const EmptyParentName = (/* no props */) => <ParentName name={'\u00a0'} truncate />;

  const ParentGroup = ({ children }) => (
    <List.Item style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0 }}>{children}</List.Item>
  );

  let familyAvatars = student.family
    ? Object.keys(student.family).map((key) => {
        const member = student.family[key];

        if (member) {
          return (
            <ParentGroup key={key}>
              <ParentAvatar name={formatFullName(member, true)} src={member.picture} />
              <ParentName name={member.firstName} truncate />
            </ParentGroup>
          );
        }
        return null;
      })
    : [];

  if (familyAvatars.length === 0) {
    familyAvatars = [
      <ParentGroup key="empty">
        <EmptyParentAvatar />
        <ParentName name={<Label content={t('No Parents Listed')} />} />
      </ParentGroup>,
    ];
  } else if (familyAvatars.length > 3) {
    const extraCount = familyAvatars.length - 3;
    familyAvatars = [
      ...familyAvatars.slice(0, 3),
      <ParentGroup key="extra">
        <MoreParentsAvatar count={extraCount} />
        <EmptyParentName />
      </ParentGroup>,
    ];
  }

  return (
    <Card.Description>
      <List horizontal relaxed>
        {familyAvatars}
      </List>
    </Card.Description>
  );
};

FamilyAvatars.propTypes = {
  student: PropTypes.shape({
    family: PropTypes.objectOf(
      PropTypes.shape({
        picture: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default FamilyAvatars;
