import { useTranslation } from 'react-i18next';

import { WsDownload, WsPrint } from '../../icons';

type ExportToolbarProps = {
  onExportPDF?: () => void;
  onExportCSV?: () => void;
  disabled?: boolean;
};

const ExportToolbar: React.FC<ExportToolbarProps> = ({ onExportPDF, onExportCSV, disabled }) => {
  const { t } = useTranslation();

  const disabledClasses = `text-gray-300 cursor-default hover:text-gray-300`;
  const enabledClasses = `text-blue-900 hover:text-blue-600 font-bold cursor-pointer`;

  if (!onExportPDF && !onExportCSV) return null;

  return (
    <div className={'mt-2 flex h-full flex-row items-center justify-end gap-5'}>
      {!!onExportPDF && (
        <a
          href="#"
          className={disabled ? disabledClasses : enabledClasses}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            } else if (onExportPDF) {
              onExportPDF();
            }
          }}
          data-testid="export-print-button"
        >
          <WsPrint data-testid="print-icon" className="mr-0.5" />
          {t('Print')}
        </a>
      )}
      {!!onExportCSV && (
        <a
          href="#"
          className={disabled ? disabledClasses : enabledClasses}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            } else if (onExportCSV) {
              onExportCSV();
            }
          }}
          data-testid="export-download-button"
        >
          <WsDownload data-testid="download-icon" className="mr-0.5" />
          {t('Download')}
        </a>
      )}
    </div>
  );
};
export default ExportToolbar;
