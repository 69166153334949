// These consts should match with ones in the server
// TS Enums must have one value at least
export enum BillingFeaturesEnum {
  'T+0' = 'T+0',
  inHouseInvoicing = 'in_house_invoicing',
}
export const BILLING_FEATURES_KEYS: BillingFeaturesEnum[] = [];

export interface OrgBillingFeatures {
  name: string;
  enabled: boolean;
}
