// Only get students that are enrolled in the food program if isEnrolledInFoodProgram is true,
// otheriwse, get all students
import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useRooms from '../hooks/useRooms';
import { useAllStudents } from '../students/studentsHooks';
import { FoodProgramTierEnum } from '../common/enums';

const VALID_FOOD_PROGRAM_TIERS = [FoodProgramTierEnum.TIER_1, FoodProgramTierEnum.TIER_2];

export function useStudentsMap(isEnrolledInFoodProgram?: boolean): Record<string, any> {
  const { list: students } = useAllStudents();
  const { foodProgramFlag } = useFlags();

  const studentsMap = useMemo(() => {
    return (
      students?.reduce((acc, student) => {
        if (foodProgramFlag && !!isEnrolledInFoodProgram && !VALID_FOOD_PROGRAM_TIERS.includes(student.foodProgramTier))
          return acc;
        acc[student.id] = student;
        return acc;
      }, {}) ?? {}
    );
  }, [foodProgramFlag, isEnrolledInFoodProgram, students]);
  return studentsMap;
}

export function useRoomsMap(): Record<string, any> {
  const rooms = useRooms();
  const roomsMap = useMemo(() => {
    return (
      rooms?.reduce((acc, room) => {
        acc[room.id] = room;
        return acc;
      }, {}) ?? {}
    );
  }, [rooms]);
  return roomsMap;
}
