import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Card, Checkbox, Divider, Form, Header, Segment } from 'semantic-ui-react';

// Import actions
import { useFlags } from 'launchdarkly-react-client-sdk';
import { saveLocations } from '../../api/firebase/locations';
import { useLocations, usePrimaryLocation } from '../../hooks/useLocations';
import { useOrganization } from '../../hooks/useOrganizations';
import { buildMarketplaceLink, RouteNameEnum, useRoutes } from '../../navigation';
import { locationSelected } from '../../redux/actions/locationActions';
import { logError } from '../../rollbar';

// Import Components
import LocationForm from '../Forms/Location/LocationForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import LocationCard from './LocationCard';
import OrganizationQRCode from './OrganizationQRCode';

import './Location.scss';

const SLIDER_WIDTH = '80%';

const DEFAULT_VALUES = {
  staffTimecardsEnabled: false,
  staffTimecardsQRCodeRequired: false,
};

const SlidingLocationFormEdit = withSlidingPanel(LocationForm, {
  title: 'Edit Location',
  width: SLIDER_WIDTH,
});

function LocationsList({ itemsPerRow = 4 }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useLocations();
  const primaryLocation = usePrimaryLocation();
  const { getActiveRoute } = useRoutes();
  const organization = useOrganization();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dataToSave, setDataToSave] = useState([]);
  const [formValues, setFormValues] = useState(primaryLocation?.settings ?? DEFAULT_VALUES);
  const formValuesRef = useRef(primaryLocation?.settings ?? DEFAULT_VALUES);
  const { isLocationDetailsEditEnabled } = useFlags();

  useEffect(() => {
    if (!locations.list?.length || formValuesRef.current === formValues) return;

    const dataToSaveLocal = locations.list.map((location) => {
      return {
        id: location.id,
        organization: location.organization,
        settings: { ...formValues },
      };
    });
    formValuesRef.current = formValues;
    setDataToSave(dataToSaveLocal);
  }, [formValues, locations.list]);

  useEffect(() => {
    (async () => {
      try {
        if (!dataToSave?.length) return;
        await saveLocations(dataToSave);
        setDataToSave([]);
      } catch (error) {
        logError('*** Error locations to Firestore ***', error);
      }
    })();
  }, [dataToSave]);

  const onChange = useCallback((_, { name, checked }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
  }, []);

  const renderPageTitle = useCallback(() => {
    if (locations.list) {
      return (
        <Segment basic clearing>
          <h1 className="text-3xl font-bold text-left">
            {t('Locations ({{locationsCounter}})', {
              locationsCounter: locations.list.length,
            })}
          </h1>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {t('Locations')}
        </Header>
      </Segment>
    );
  }, [locations.list, t]);

  const renderLocationList = useCallback(() => {
    return (
      <Segment basic>
        <Card.Group stackable itemsPerRow={itemsPerRow}>
          {locations.list.map((location) => (
            <LocationCard
              key={location.id}
              location={location}
              onClick={() => {
                const route = getActiveRoute(RouteNameEnum.LOCATION_EDIT);

                // user or org does not have permission to edit location.
                if (!route) {
                  return null;
                }
                if (isLocationDetailsEditEnabled) {
                  dispatch(locationSelected(location));
                  setIsEditOpen(true);
                } else {
                  const url = buildMarketplaceLink({ organization, location, path: route?.path });
                  window.location.assign(url);
                }
              }}
            />
          ))}
        </Card.Group>
      </Segment>
    );
  }, [itemsPerRow, locations.list, getActiveRoute, isLocationDetailsEditEnabled, dispatch, organization]);

  const renderSlidingForms = useCallback(() => {
    return (
      <div>
        <SlidingLocationFormEdit
          isOpen={isEditOpen}
          onClose={() => {
            setIsEditOpen(false);
          }}
        />
      </div>
    );
  }, [isEditOpen]);

  const renderClockSettings = () => {
    return (
      <Segment basic clearing>
        <Card.Header as="h3" floated="left">
          {t('Clock-in/Clock-out settings')}
        </Card.Header>
        <Card fluid className="no-padding">
          <Card.Content>
            {t('QR Code')}
            <Divider />
            {t('locations.qrcode.content')}
            <div className="margin top">
              <OrganizationQRCode floated="left" className="margin top" />
            </div>
          </Card.Content>
        </Card>
        <Card fluid className="no-padding">
          <Card.Content>
            <div className="clock-wrap">
              {t('Employee Clock-in/Clock-out')}
              <Form.Radio
                toggle
                name="staffTimecardsEnabled"
                checked={!!formValues.staffTimecardsEnabled}
                control={Checkbox}
                onChange={onChange}
                data-testid="enable-timecards"
              />
            </div>
            <Divider />
            {t('locations.clockinout.content')}
            <div className="margin top align-checkbox">
              <Form.Checkbox
                name="staffTimecardsQRCodeRequired"
                checked={!!formValues.staffTimecardsQRCodeRequired}
                onChange={onChange}
                data-testid="scan-qr-to-clockon"
              />
              {t('scan.qrcode')}
            </div>
          </Card.Content>
        </Card>
      </Segment>
    );
  };

  return (
    <div>
      {renderPageTitle()}
      {renderLocationList()}
      {renderSlidingForms()}
      {renderClockSettings()}
    </div>
  );
}

export default LocationsList;
