import { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import StaffList from '../Components/Staff/StaffList';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { useOrganization, useOrganizationContactsListener } from '../hooks/useOrganizations';
import { useUser } from '../hooks/useUser';

const Staff = (_props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.staff);

  const organization = useOrganization();

  const { isLocationAdminHighestClaim, defaultLocation } = useUser();

  const contactsConditions = useMemo(() => {
    if (isLocationAdminHighestClaim && defaultLocation) {
      return [
        {
          field: 'defaultLocation',
          operation: '==',
          value: defaultLocation,
        },
        {
          field: 'type',
          operation: '==',
          value: 'staff',
        },
        {
          field: 'staffType',
          operation: 'array-contains-any',
          value: ['teacher', 'supportStaff'],
        },
      ];
    }
    return [];
  }, [isLocationAdminHighestClaim, defaultLocation]);

  useOrganizationContactsListener(organization?.id, contactsConditions, [defaultLocation, isLocationAdminHighestClaim]);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Staff') })}
      </title>
      <body className="staff" />
    </Helmet>
  );

  return (
    <MainContentLayout>
      {renderPageHead()}
      <StaffList />
    </MainContentLayout>
  );
};

export default Staff;
