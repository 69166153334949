import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import jsPDF from 'jspdf';

import { WsModal, WsSpinner, WsWidgetSizeEnum } from '../../common';

import { PDFGeneratorFunction, ReportType } from '../types';

type PDFModalProps<T> = {
  isOpen?: boolean;
  onClose?: () => void;
  report?: T;
  pdfGenerator: PDFGeneratorFunction<T>;
};

export function PDFModal<T extends ReportType>({ isOpen = false, onClose, report, pdfGenerator }: PDFModalProps<T>) {
  const { t } = useTranslation();
  const [filename, setFilename] = useState<string>();
  const [pdf, setPdf] = useState<jsPDF>();

  const onCloseLocal = useCallback(() => {
    setFilename(undefined);
    setPdf(undefined);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    (async () => {
      if (isOpen && report && !filename && !pdf) {
        const returnValue = await pdfGenerator(report); // the generator may be async
        setFilename(returnValue.filename);
        setPdf(returnValue.pdf);
      }
    })();
  }, [isOpen, report, pdfGenerator, onClose, filename, pdf]);

  useEffect(() => {
    if (pdf && filename && isOpen) {
      pdf.autoPrint();
      pdf.save(filename);
      pdf.output('dataurlnewwindow');
      setFilename(undefined);
      setPdf(undefined);
      onCloseLocal();
    }
  }, [pdf, filename, onCloseLocal, isOpen]);

  return (
    <WsModal isOpen={isOpen} onClose={onCloseLocal} title={t('Print PDF')}>
      <div className="flex w-96 flex-col items-center justify-center gap-2">
        <p>{t('We are generating a report. This might take a few seconds. Please wait...')}</p>
        <WsSpinner size={WsWidgetSizeEnum.MEDIUM} />
      </div>
    </WsModal>
  );
}
export default PDFModal;
