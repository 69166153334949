import { TFunction } from 'i18next';
import { MONTH_LIST, QUARTER_LIST, REPORT_COLUMN_ORDER } from './constants';
import { CSVListItem, FinancialSummaryDropdownOption, FinancialSummaryMonthRange, FormattedRow } from './types';
import { TransactionType } from '../../../../transactions/types';
import moment from 'moment-timezone';
import { ReportTitleEnum } from './enums';

const fillMissingKeys = (formattedRow: FormattedRow, selectedMonths: string[]) => {
  selectedMonths.forEach((month) => {
    formattedRow[month.toLowerCase()] = formattedRow[month.toLowerCase()] || 0;
  });
};

const roundNumber = (amoount: number, decimalPlaces: number) => {
  return parseFloat(amoount.toFixed(decimalPlaces));
};

const addFixStaticRow = (title: string, total: number, selectedMonths: string[], formattedData: FormattedRow[]) => {
  const formattedRow: FormattedRow = {
    title,
    ...Object.fromEntries(selectedMonths.map((month) => [month.toLowerCase(), 0])),
    total,
  };
  formattedData.push(formattedRow);
};
const processTransactionData = (
  list: any[],
  transactionType: string,
  selectedMonths: string[],
  transactionLabel: string,
  transactionTotalLable: string,
  timezone: string,
  formattedData: FormattedRow[]
) => {
  const filteredData = list.filter((row) => row.transactionType === transactionType);
  const distinctData = getDistinctDataForMonth(filteredData, timezone);
  addFixStaticRow(transactionLabel, 0, selectedMonths, formattedData);
  const totalAmounts: Record<string, number> = {};
  const formattedDataByType: FormattedRow[] = distinctData.reduce((acc: FormattedRow[], row: any) => {
    const rowMonth = MONTH_LIST[parseInt(row.month) - 1];
    if (selectedMonths.includes(rowMonth)) {
      const existingEntry = acc.find((entry) => entry.title === row.category);
      if (!existingEntry) {
        const formattedRow: FormattedRow = {
          title: row.category,
          [rowMonth]: row.amount,
          total: row.amount,
        };
        acc.push(formattedRow);
      } else {
        existingEntry[rowMonth] = roundNumber((existingEntry[rowMonth] || 0) + row.amount, 2);
        existingEntry.total = roundNumber(existingEntry.total + row.amount, 2);
      }
      totalAmounts[rowMonth] = roundNumber((totalAmounts[rowMonth] || 0) + row.amount, 2);
    }
    return acc;
  }, []);
  formattedDataByType
    .sort((a, b) => (b.total as number) - (a.total as number))
    .forEach((formattedRow) => {
      fillMissingKeys(formattedRow, selectedMonths);
    });
  formattedData.push(...formattedDataByType);
  // Add a row for the total amounts across all categories
  const totalRow: FormattedRow = {
    title: transactionTotalLable,
    ...totalAmounts,
    total: roundNumber(
      selectedMonths.reduce((sum, month) => sum + (totalAmounts[month] || 0), 0),
      2
    ),
  };
  fillMissingKeys(totalRow, selectedMonths);
  formattedData.push(totalRow);
};

const getDistinctDataForMonth = (data: any[], timezone: string) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.reduce((acc, item) => {
    const itemDateSeconds = item.date?.seconds || 0;
    // month index (zero-based) plus 1.
    const month = moment.unix(itemDateSeconds)?.tz(timezone).month() + 1;
    const existingEntry = acc.find((entry: any) => entry.month === month && entry.category === item.category);
    if (existingEntry) {
      existingEntry.amount = roundNumber(existingEntry.amount + item.amount, 2);
    } else {
      acc.push({ month, amount: item.amount, category: item.category });
    }
    return acc;
  }, []);
};

export const formatData = (list: any[], selectedMonths: string[], timezone = 'utc') => {
  if (!list || list.length === 0) {
    return [];
  }
  const formattedData: FormattedRow[] = [];
  processTransactionData(
    list,
    TransactionType.REVENUE,
    selectedMonths,
    ReportTitleEnum.REVENUE,
    ReportTitleEnum.TOTAL_REVENUE,
    timezone,
    formattedData
  );
  processTransactionData(
    list,
    TransactionType.EXPENSE,
    selectedMonths,
    ReportTitleEnum.EXPENSE,
    ReportTitleEnum.TOTAL_EXPENSE,
    timezone,
    formattedData
  );
  if (formattedData.length === 4) {
    return []; // No Data for selected Month as we have 4 fixed row.
  }
  // Find 'Total' rows for revenue and expense
  const revenueTotalRow = formattedData.find((row) => row.title === ReportTitleEnum.TOTAL_REVENUE) || {
    total: 0,
  };
  const expenseTotalRow = formattedData.find((row) => row.title === ReportTitleEnum.TOTAL_EXPENSE) || {
    total: 0,
  };
  const { total: revenueTotal } = revenueTotalRow;
  const { total: expenseTotal } = expenseTotalRow;
  // Add a final row for total profit
  const totalProfitRow: FormattedRow = {
    title: ReportTitleEnum.TOTAL_PROFIT,
    ...Object.fromEntries(
      selectedMonths.map((month) => [
        month.toLowerCase(),
        roundNumber((revenueTotalRow[month.toLowerCase()] || 0) - (expenseTotalRow[month.toLowerCase()] || 0), 2),
      ])
    ),
    total: roundNumber(Number(revenueTotal) - Number(expenseTotal), 2),
  };
  formattedData.push(totalProfitRow);
  return formattedData.map((item) => {
    const sortedItem: FormattedRow = {};
    REPORT_COLUMN_ORDER.forEach((key) => {
      sortedItem[key] = item[key];
    });
    return sortedItem;
  });
};

export function getYearOptions(startYear: number, currentYear: number): FinancialSummaryDropdownOption[] {
  const yearsOptionList: FinancialSummaryDropdownOption[] = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearsOptionList.push({ value: String(year), text: String(year), type: null, parent: null });
  }
  return yearsOptionList;
}

export function getMonthOptions(t: TFunction): FinancialSummaryDropdownOption[] {
  const monthsList = MONTH_LIST;
  const quarters = QUARTER_LIST;

  const monthsOptionList = monthsList.flatMap((month, index) => {
    const quarter = Math.floor(index / 3); // Calculate the quarter based on the index
    const quarterOption =
      quarter < quarters.length && index % 3 === 0
        ? [{ value: quarters[quarter], text: t(quarters[quarter]), type: 'parent', parent: null }]
        : [];

    return [
      ...quarterOption,
      { value: month.toLowerCase(), text: t(month.toLowerCase()), type: 'child', parent: quarters[quarter] },
    ];
  });

  return monthsOptionList;
}

export function isStringArray(value: any): value is string[] {
  return Array.isArray(value) && value.every((item) => typeof item === 'string');
}

export function getStartEndMonths(
  selectedMonths: string[] = [],
  selectedYear = '',
  timezone = ''
): FinancialSummaryMonthRange {
  const monthNumbers = selectedMonths.map((month) => moment().month(month).month());
  const startMonth = Math.min(...monthNumbers);
  const endMonth = Math.max(...monthNumbers);
  // month index (zero-based) plus 1.
  return {
    start: moment(`${selectedYear}-${startMonth + 1}`, 'YYYY-MM')
      ?.tz(timezone, true)
      ?.startOf('month'),
    end: moment(`${selectedYear}-${endMonth + 1}`, 'YYYY-MM')
      ?.tz(timezone, true)
      ?.endOf('month'),
  };
}

export function convertListToCSV(data: CSVListItem[], columns: string[], t: any): string {
  if (data.length === 0) {
    return '';
  }
  const headers = Object.keys(data[0]);
  // Filter out columns with undefined values
  const filteredHeaders = headers.filter((header) => data.some((item) => item[header] !== undefined));
  // Create header row
  const headerRow = columns.map((item) => t(item)).join(', ') + '\n';
  // Create data rows, including only values for filtered columns
  const dataRows = data.map(
    (item) =>
      filteredHeaders
        .map((header) => (item[header] !== undefined ? escapeCSVField(t(String(item[header]))) : ''))
        .join(',') + '\n'
  );
  // Combine header and data rows
  const csv = headerRow + dataRows.join('');
  return csv;
}

// Helper function to escape CSV field with quotes if it contains commas
function escapeCSVField(field: string): string {
  return field.includes(',') ? `"${field}"` : field;
}
