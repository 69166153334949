import { getChangedBy } from '../../api/firebase2/firestore';

export const addUserParamsToUrl = (url: string) => {
  const { email, name } = getChangedBy();

  if (!url) return '';
  if (!email && !name) return url;
  // Gooogle form params
  // ?usp=pp_url&entry.337943888=<name>&entry.1130562394=<emailId>
  const params = new URLSearchParams();
  params.append('usp', 'pp_url');
  name && params.append('entry.337943888', name);
  email && params.append('entry.1130562394', email);
  return `${url}?${params.toString()}`;
};
