import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import MaskedInput, { PipeConfig } from 'react-text-mask';
import WsLabel from './WsLabel';
import WsValidationError from './WsValidationError';
import { WsInputFieldProps } from './types';

export type WsInputMaskedProps = WsInputFieldProps<string> & {
  mask: any[] | ((value: string) => any[]);
  guide?: boolean;
  keepCharPositions?: boolean;
  pipe?:
    | ((
        conformedValue: string,
        config: PipeConfig
      ) => string | false | { value: string; indexesOfPipedChars: number[] })
    | undefined;
};

export function WsInputMasked({
  name,
  label,
  placeholder = '',
  value,
  required = false,
  error,
  onChange,
  wrapperClassName = '',
  mask = [],
  disabled = false,
  pipe,
  guide = false,
  keepCharPositions = false,
  'data-testid': testId,
}: WsInputMaskedProps) {
  const inputClassName = error
    ? 'block w-full rounded-md border-0 py-1.5 pl-3 sm:text-sm sm:leading-6 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500'
    : 'min-h-9 block w-full rounded-md border-0 py-1.5 pl-3 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600';

  return (
    <div className={wrapperClassName}>
      <WsLabel htmlFor={name} required={required} data-testid={`${testId}-label`}>
        {label}
      </WsLabel>
      <div className="relative rounded-md shadow-sm">
        <MaskedInput
          name={name}
          id={name}
          className={inputClassName}
          value={value}
          mask={mask}
          disabled={disabled}
          guide={guide}
          pipe={pipe}
          placeholder={placeholder}
          keepCharPositions={keepCharPositions}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          data-testid={testId}
          onChange={(e) => {
            onChange({ name: e.target.name, value: e.target.value });
          }}
        />
        {!!error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      <WsValidationError error={error} data-testid={`${testId}-error`} />
    </div>
  );
}
export default WsInputMasked;
