import { AttendanceTotalsReportType } from '../types';

import { saveCSV } from '../../../common';
import { formatHoursFromMinutes } from '../../../helpers/dates';

const COLUMNS = [
  { header: 'Student', dataKey: 'studentName' },
  { header: 'Room', dataKey: 'roomName' },
  { header: 'Days Present', dataKey: 'daysPresent' },
  { header: 'Days Absent', dataKey: 'daysAbsent' },
  { header: 'Total Hours Attended', dataKey: 'totalMinutesAttended' },
];
export function generateAttendanceTotalsCSV(attendanceTotalsReport: AttendanceTotalsReportType) {
  const { items: attendanceTotals, endDate, startDate } = attendanceTotalsReport;
  const filename = `Attendance Totals (${startDate} - ${endDate}).csv`;

  const headers = COLUMNS.map((column) => column.header).join(',') + '\n';
  const body = attendanceTotals
    .map((row) => {
      return [
        row.studentName,
        row.roomName,
        row.daysPresent,
        row.daysAbsent,
        formatHoursFromMinutes(row.totalMinutesAttended),
      ].join(',');
    })
    .join('\n');
  const csv = headers + body;
  saveCSV(csv, filename);
}
