import { TFunction } from 'i18next';
import { getFailurePopupText } from './userStatus';

export enum INVOICE_STATUS {
  SCHEDULED = 'scheduled',
  INVOICE_SENT = 'invoiceSent',
  NOTIFICATION_SENT = 'notificationSent',
  PAST_DUE = 'pastDue',
  PROCESSING = 'processing',
  CHARGE_FAILED = 'chargeFailed',
  TRANSFERRING = 'transferring',
  TRANSFER_FAILED = 'transferFailed',
  QUICK_DEPOSITED = 'quickDeposited',
  DEPOSITED = 'deposited',
  PAID_MANUALLY = 'paidManually',
  PAYOUT_REVERSED = 'payoutReversed',
  PAYOUT_REVERSED_FAILED = 'payoutReversedFailed',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}

export enum INVOICE_STATUS_DESCRIPTION {
  SCHEDULED = 'scheduled',
  INVOICE_SENT = 'invoiceSent',
  NOTIFICATION_SENT = 'notificationSent',
  PAST_DUE = 'pastDue',
  PROCESSING = 'processing',
  CHARGE_FAILED = 'chargeFailed',
  TRANSFERRING = 'transferring',
  TRANSFER_FAILED = 'transferFailed',
  QUICK_DEPOSITED = 'quickDeposited',
  DEPOSITED = 'paid',
  PAID_MANUALLY = 'paidManually',
  PAYOUT_REVERSED = 'payoutReversed',
  PAYOUT_REVERSED_FAILED = 'payoutReversedFailed',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
  // transfer failed specific descriptions
  TRANSFER_FAILED_ACCOUNT_CLOSED = 'transferFailed.accountClosed',
  TRANSFER_FAILED_RESTRICTED = 'transferFailed.restricted',
  TRANSFER_FAILED_COULD_NOT_PROCESS = 'transferFailed.couldNotProcess',
  TRANSFER_FAILED_DECLINED = 'transferFailed.declined',
  TRANSFER_FAILED_LIMIT_EXCEEDED = 'transferFailed.limitExceeded',
  TRANSFER_FAILED_EXPIRED_CARD = 'transferFailed.expiredCard',
  TRANSFER_FAILED_INVALID_NUMBER = 'transferFailed.invalidNumber',
  TRANSFER_FAILED_LOST_STOLEN = 'transferFailed.lostOrStolen',
  TRANSFER_FAILED_NO_ACCOUNT = 'transferFailed.noAccount',
}

const InvoiceV2StatusMap: { [key: string]: { text: string; color?: string } } = {
  [INVOICE_STATUS.SCHEDULED]: {
    text: 'Scheduled',
    color: 'grey',
  },
  [INVOICE_STATUS.INVOICE_SENT]: {
    text: 'status.invoiceSent',
    color: 'blue',
  },
  [INVOICE_STATUS.NOTIFICATION_SENT]: {
    text: 'status.notificationSent',
    color: 'blue',
  },
  [INVOICE_STATUS.PAST_DUE]: {
    text: 'status.pastDue',
    color: 'red',
  },
  [INVOICE_STATUS.PROCESSING]: {
    text: 'Processing',
    color: 'yellow',
  },
  [INVOICE_STATUS.CHARGE_FAILED]: {
    text: 'status.chargeFailed',
    color: 'red',
  },
  [INVOICE_STATUS.TRANSFERRING]: {
    text: 'Transferring',
    color: 'teal',
  },
  [INVOICE_STATUS.TRANSFER_FAILED]: {
    text: 'status.transferFailed',
    color: 'red',
  },
  [INVOICE_STATUS.PAYOUT_REVERSED]: {
    text: 'status.payoutReversed',
    color: 'red',
  },
  [INVOICE_STATUS.PAYOUT_REVERSED_FAILED]: {
    text: 'status.payoutReversedFailed',
    color: 'red',
  },
  [INVOICE_STATUS.DEPOSITED]: {
    text: 'status.deposited',
    color: 'green',
  },
  [INVOICE_STATUS.QUICK_DEPOSITED]: {
    text: 'status.quickDeposited',
    color: 'olive',
  },
  [INVOICE_STATUS.PAID_MANUALLY]: {
    text: 'status.paidManually',
    color: 'green',
  },
  [INVOICE_STATUS.UNCOLLECTIBLE]: {
    text: 'status.uncollectible',
    color: 'pink',
  },
  [INVOICE_STATUS.VOID]: {
    text: 'status.void',
    color: 'red',
  },
};

export type InvoiceV2Status = {
  text: string;
  color?: string;
  popupText: string;
  status: string;
};
export const invoiceV2StatusFormatter = (
  {
    status,
    statusDescription,
    failureReason,
    nextPaymentAttempt,
  }: {
    status: string;
    statusDescription: string;
    failureReason?: string;
    nextPaymentAttempt?: string;
  },
  t: TFunction
): InvoiceV2Status => {
  const format = InvoiceV2StatusMap[status];

  if (status === INVOICE_STATUS.CHARGE_FAILED)
    return {
      ...format,
      popupText: getFailurePopupText(t, failureReason, nextPaymentAttempt),
      status,
    };

  return {
    ...format,
    popupText: `userStatusDescription.${statusDescription ?? status}`, // v1 status descriptions already translated, continue using
    status,
  };
};
