import { useEffect, useRef } from 'react';

import { useAuthUser } from '../../hooks/useUser';
import { useOrganization } from '../../hooks/useOrganizations';

import { UNKNOWN_TEXT } from '../types';
import { callSegmentIdentify } from '../api';

export default function useSegmentIdentify() {
  const { currentUser } = useAuthUser();
  const organization = useOrganization();
  const userIdRef = useRef(currentUser?.uid);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      !currentUser?.uid ||
      !organization?.id ||
      userIdRef.current === currentUser.uid
    )
      return;

    userIdRef.current = currentUser.uid;
    const data = {
      userId: currentUser.uid,
      traits: {
        email: currentUser.email,
        displayName: currentUser.displayName || UNKNOWN_TEXT,
        organizationId: organization.id,
        organizationName: organization.name || UNKNOWN_TEXT,
      },
    };
    callSegmentIdentify(data);
  }, [currentUser, organization]);
}
