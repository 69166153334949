import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { logError } from '../../rollbar';

import { useRoomsMap, useStudentsMap } from '../reportsHooks';
import { ActivityTypeEnum } from '../enums';

import {
  AttendanceActivityType,
  AttendanceFiltersType,
  AttendanceType,
  HealthCheckType,
  StudentAttendanceTotalsType,
} from './types';
import { fetchActivities } from '../reportsAPI';
import { matchAttendanceActivities } from './attendanceUtils';

const EMPTY_TEXT = '-';
dayjs.extend(utc);
dayjs.extend(timezone);

export function useAttendanceActivities(filters?: AttendanceFiltersType) {
  const studentsMap = useStudentsMap(filters?.isEnrolledInFoodProgram);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activities, setActivities] = useState<AttendanceActivityType[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (!filters) {
          setActivities([]);
          return;
        }

        setIsLoading(true);
        const attendanceActivities = await fetchActivities(filters);
        const matchedActivities = matchAttendanceActivities(attendanceActivities);

        const filteredActivities = matchedActivities.filter((activity) => {
          const student = studentsMap[activity.student?.id];
          if (!student) return false;
          return activity;
        });
        setActivities(filteredActivities);
      } catch (err) {
        logError('Error while getting attendance report data: ', err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters, setIsLoading, studentsMap]);

  return { activities, studentsMap, isLoading };
}

export function useDailyAttendance(activities: AttendanceActivityType[]): AttendanceType[] {
  const roomsMap = useRoomsMap();
  const [dailyAttendance, setDailyAttendance] = useState<AttendanceType[]>([]);

  useEffect(() => {
    const dailyAttendanceLocal: AttendanceType[] = activities.map((activity) => {
      const { id: activityId, checkIn, checkOut, student } = activity;
      const { fullName: studentName, id: studentId } = student;
      const {
        activityTime: checkInActivityTime,
        addedBy: checkInAddedBy,
        signatureUri: checkInSignatureUri,
        timezone: checkInTimezone,
        type: checkInType,
        preCheck,
        preCheckData,
        note,
        kinderSystems: kinderSystemsCheckIn,
        isTransferred: isTransferredCheckIn,
      } = checkIn;
      const {
        activityTime: checkOutActivityTime,
        addedBy: checkOutAddedBy,
        signatureUri: checkOutSignatureUri,
        timezone: checkOutTimezone,
        kinderSystems: kinderSystemsCheckOut,
        isTransferred: isTransferredCheckOut,
      } = checkOut;

      const checkInMoment = checkInActivityTime
        ? checkInTimezone
          ? dayjs.tz(checkInActivityTime, checkInTimezone)
          : dayjs(checkInActivityTime)
        : null;

      const checkOutMoment = checkOutActivityTime
        ? checkOutTimezone
          ? dayjs.tz(checkOutActivityTime, checkOutTimezone)
          : dayjs(checkOutActivityTime)
        : null;

      const isAbsent = checkInType === ActivityTypeEnum.ABSENT;
      const healthCheck = generateHealthCheck(preCheck, preCheckData);
      const kinderSystemsTransferred = getKinderSystemsTransferred({
        kinderSystemsCheckIn,
        kinderSystemsCheckOut,
        isTransferredCheckIn,
        isTransferredCheckOut,
      });

      return {
        activityId,
        studentId,
        studentName: studentName || EMPTY_TEXT,
        roomName: roomsMap[activity.room]?.name || EMPTY_TEXT,
        checkInDate: checkInMoment?.format('MM/DD/YYYY'),
        checkInTime: checkInMoment?.format('h:mma'),
        checkInTimeSort: isAbsent ? '00:00' : checkInMoment?.format('kk:mm'),
        checkInName: checkInAddedBy?.fullName || EMPTY_TEXT,
        checkInSignatureUri,
        checkOutDate: checkOutMoment?.format('MM/DD/YYYY'),
        checkOutTime: checkOutMoment?.format('h:mma'),
        checkOutTimeSort: isAbsent ? '00:00' : checkOutMoment?.format('kk:mm') ?? '00:00',
        checkOutName: checkOutAddedBy?.fullName || EMPTY_TEXT,
        checkOutSignatureUri,
        isAbsent,
        healthCheck,
        note,
        kinderSystemsTransferred,
      };
    });
    setDailyAttendance(dailyAttendanceLocal);
  }, [activities, roomsMap]);
  return dailyAttendance;
}

export function useAttendanceTotals(activities: AttendanceActivityType[]): StudentAttendanceTotalsType[] {
  const roomsMap = useRoomsMap();

  const attendanceTotalsMap = useMemo<Record<string, StudentAttendanceTotalsType>>(() => {
    return activities.reduce((acc, activity) => {
      const {
        checkIn,
        checkOut,
        student: { id: studentId, fullName: studentName },
      } = activity;
      const { activityTime: checkInActivityTime, timezone: checkInTimezone, type: checkInType } = checkIn;
      const { activityTime: checkOutactivityTime, timezone: checkOutTimezone } = checkOut;

      const checkInMoment = checkInActivityTime
        ? dayjs.tz(checkInActivityTime, checkInTimezone)
        : dayjs(checkInActivityTime);

      const checkOutMoment = checkOutactivityTime
        ? dayjs.tz(checkOutactivityTime, checkOutTimezone)
        : dayjs(checkOutactivityTime);

      const isAbsent = checkInType === ActivityTypeEnum.ABSENT;
      const room = roomsMap[activity.room];

      const studentAttendanceTotals: StudentAttendanceTotalsType = acc[studentId] ?? {
        studentId,
        studentName: studentName || EMPTY_TEXT,
        roomName: room?.name || EMPTY_TEXT,
        totalMinutesAttended: 0,
        daysPresent: 0,
        daysAbsent: 0,
      };

      studentAttendanceTotals.totalMinutesAttended += isAbsent ? 0 : checkOutMoment.diff(checkInMoment, 'minutes');
      studentAttendanceTotals.daysPresent += isAbsent ? 0 : 1;
      studentAttendanceTotals.daysAbsent += isAbsent ? 1 : 0;

      acc[studentId] = studentAttendanceTotals;
      return acc;
    }, {});
  }, [activities, roomsMap]);

  return useMemo<StudentAttendanceTotalsType[]>(() => {
    return Object.values(attendanceTotalsMap);
  }, [attendanceTotalsMap]);
}

// Since some of the activities have preCheck, which is a form url, we need to check if that exists.
// The newer version of the app populate preCheckData, which is an object with the preCheck data.
// If preCheckData.exposure is null, then no precheck was done
function generateHealthCheck(preCheck: string, preCheckData: any): HealthCheckType | undefined {
  if (!preCheck && (!preCheckData || preCheckData.exposure === null)) return undefined;

  const symptoms: string[] = [...(preCheckData?.symptoms ?? [])];
  let notes: string = preCheckData?.notes ?? '';

  if (preCheck) {
    const url = new URL(preCheck);
    symptoms.push(...url.searchParams.getAll('symptoms'));
    //symptoms.push(...url.searchParams.getAll('familySymptoms'));
    notes += url.searchParams.get('notes') ?? '';
  }

  return {
    wasExposed: !!preCheckData?.exposure,
    symptoms: symptoms?.join(', ') ?? '',
    notes: notes,
    formUrl: preCheck,
  };
}

function getKinderSystemsTransferred(data: any) {
  const { kinderSystemsCheckIn, kinderSystemsCheckOut, isTransferredCheckIn, isTransferredCheckOut } = data;

  if (isTransferredCheckIn && isTransferredCheckOut) {
    return kinderSystemsCheckOut;
  }
  if (isTransferredCheckIn && !isTransferredCheckOut) {
    return kinderSystemsCheckOut;
  }
  if (!isTransferredCheckIn && isTransferredCheckOut) {
    return kinderSystemsCheckIn;
  }
  return kinderSystemsCheckOut || kinderSystemsCheckIn;
}
