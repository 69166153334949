import { DatesRangeInput } from 'semantic-ui-calendar-react-yz';
import { useTranslation } from 'react-i18next';
import { deriveLanguage } from '../../../helpers/utils';
import 'moment/locale/es';

function DateRangePicker({ onChange, value, ...rest }) {
  const { t } = useTranslation();

  return (
    <DatesRangeInput
      dateFormat="MM/DD/YYYY"
      placeholder={t('Choose a date range')}
      popupPosition="bottom right"
      name="datesRange"
      hideMobileKeyboard
      clearable={true}
      value={value}
      iconPosition="right"
      autoComplete="off"
      onChange={onChange}
      localization={deriveLanguage()}
      {...rest}
    />
  );
}

export default DateRangePicker;
