import { ISOString } from '@wonderschool/ws-types';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { defaultsDeep, find, isEmpty, omit } from 'lodash';
import { ChangeEvent, Component, FormEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Icon,
  InputOnChangeData,
  Modal,
  Popup,
  Segment,
  Select,
} from 'semantic-ui-react';

// Import components
import LocationPicker from '../Locations/LocationPicker';
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';

// Import actions
import { ageRangeMonths, ageRangeYears } from '../../config';
import { organizationAddRoom, organizationUpdateRoom, roomSelectionCleared } from '../../redux/actions/roomActions';

interface DefaultRoomForm {
  id?: string;
  name: string;
  location: string;
  enabledActivities?: Record<string, string>;
  ratio: string;
  enabled: boolean;
  usesExactAgeRange?: boolean;
  createdBy?: {
    email?: string;
    uid?: string;
  } | null;
  createdAt?: ISOString | null;
  ageRange: {
    from: { months: number; years: number };
    to: { months: number; years: number };
  };
}

type Errors = {
  [K in keyof Omit<DefaultRoomForm, 'id' | 'createdBy' | 'createdAt'>]?: string;
};

export const INITIAL_ROOM: DefaultRoomForm = {
  id: '',
  name: '',
  location: '',
  enabledActivities: {},
  ratio: '',
  ageRange: {
    from: {
      months: ageRangeMonths[0].value,
      years: ageRangeYears[0].value,
    },
    to: {
      months: ageRangeMonths[0].value,
      years: ageRangeYears[0].value,
    },
  },
  enabled: true,
  usesExactAgeRange: false,
  createdBy: null,
  createdAt: null,
};

interface ReduxStateProps {
  user: {
    uid: string;
    email?: string;
  };
  currentOrganization: {
    id: string;
  };
  rooms: {
    selectedRoom: {
      id: string;
    };
    list: {
      id: string;
    }[];
  };
  students: {
    selectedStudent: {
      id: string;
    };
    list: {
      id: string;
      rooms: string[];
    }[];
  };
}

interface ReduxSaveActionData extends DefaultRoomForm {
  organization: string;
}

interface ReduxActions {
  organizationAddRoom: (organizationId: string, data: ReduxSaveActionData) => Promise<any>;
  organizationUpdateRoom: (organizationId: string, data: ReduxSaveActionData) => Promise<any>;
  roomSelectionCleared: () => { type: string };
}

interface Props extends ReduxStateProps, ReduxActions {
  t: (text: string) => string;
  onClose?: (x?: 'updated' | 'added') => void;
}

interface State {
  data: DefaultRoomForm;
  loading: boolean;
  errors: Errors;
  disableModalOpen: boolean;
}

class RoomForm extends Component<Props> {
  state: State = {
    data: {
      ...INITIAL_ROOM,
    },
    loading: false,
    errors: {},
    disableModalOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      rooms: { selectedRoom },
    } = nextProps;

    if (!isEmpty(selectedRoom.id) && prevState.data.id !== selectedRoom.id) {
      return {
        ...prevState,
        data: ensureCorrectRoomValues(selectedRoom),
      };
    }

    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.roomSelectionCleared();
  }

  onChange = (e: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: omit(this.state.errors, name),
    });
  };

  onDateRangeChange = (e: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    //format: "from-years" ... "to-months"
    const keys = name.split('-');

    this.setState({
      data: {
        ...this.state.data,
        ageRange: {
          ...this.state.data.ageRange,
          [keys[0]]: { ...this.state.data.ageRange[keys[0]], [keys[1]]: value },
        },
      },
      errors: omit(this.state.errors, 'ageRange'),
    });
  };

  tryToggleRoomEnabled = (e: FormEvent<HTMLInputElement>, { name, checked }: CheckboxProps) => {
    // if we're disabling the room, check if there are students associated
    if (checked === false) {
      const {
        students: { list },
      } = this.props;
      const {
        data: { id },
      } = this.state;

      const roomHasStudent = list?.find((student) => {
        return student.rooms.find((roomId) => roomId === id);
      });

      if (roomHasStudent) {
        this.setState({
          disableModalOpen: true,
        });
        return;
      }
    }

    this.setState({
      data: {
        ...this.state.data,
        enabled: checked,
      },
      // name can be undefined which will cause `omit` to throw
      errors: name ? omit(this.state.errors, name) : this.state.errors,
    });
  };

  forceDisableRoom = () => {
    this.setState({
      data: {
        ...this.state.data,
        enabled: false,
      },
      errors: omit(this.state.errors, 'enabled'),
      disableModalOpen: false,
    });
  };

  validate = (data) => {
    const { t } = this.props;
    const errors: Errors = {};

    const { value: fromYears } = find(ageRangeYears, (o) => o.value === data.ageRange?.from?.years) ?? {
      value: INITIAL_ROOM.ageRange.from.years,
    };
    const { value: fromMonths } = find(ageRangeMonths, (o) => o.value === data.ageRange?.from?.months) ?? {
      value: INITIAL_ROOM.ageRange.from.months,
    };
    const { value: toYears } = find(ageRangeYears, (o) => o.value === data.ageRange?.to?.years) ?? {
      value: INITIAL_ROOM.ageRange.to.years,
    };
    const { value: toMonths } = find(ageRangeMonths, (o) => o.value === data.ageRange?.to?.months) ?? {
      value: INITIAL_ROOM.ageRange.to.months,
    };

    if (!data.name) errors.name = t('Room name is required');
    if (!data.location) errors.location = t('Location is required');
    if (data.ratio && data.ratio < 1)
      errors.ratio = t('The student ratio for one teacher can not be less than one student.');

    if (fromYears > toYears) {
      errors.ageRange = t('The age range for this room is not set correctly.');
    } else if (fromYears === toYears && fromMonths > toMonths) {
      errors.ageRange = t('The age range for this room is not set correctly.');
    }

    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (isEmpty(errors)) {
      const {
        data: { id, ...rest },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateRoom(currentOrganization.id, {
              id,
              organization: currentOrganization.id,
              ...rest,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddRoom(currentOrganization.id, {
              ...rest,
              organization: currentOrganization.id,
              createdBy: {
                email: this.props?.user?.email || '',
                uid: this.props?.user?.uid || '',
              },
              createdAt: new Date().toISOString(),
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors, disableModalOpen } = this.state;
    const { t } = this.props;

    const translatedAgeMonthOptions = ageRangeMonths.map((x) => {
      x.text = t(x.text);
      return x;
    });
    const translatedAgeYearOptions = ageRangeYears.map((x) => {
      x.text = t(x.text);
      return x;
    });

    return (
      <Segment basic textAlign="left">
        <Form id="room-form" onSubmit={this.onSubmit} loading={loading} noValidate data-testid="room-form">
          <ShowError errors={errors} />
          <Form.Field error={!!errors.name} data-testid="room-field">
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label={t('common.name')}
              placeholder={t('Room name')}
              value={data.name}
              onChange={this.onChange}
              data-testid="room-name"
            />
            {errors.name && <InlineError text={errors.name} data-testid="name-error" />}
          </Form.Field>

          <Form.Field error={!!errors.location} data-testid="location-field">
            <Form.Field
              required
              id="location"
              name="location"
              label={t('Location')}
              control={LocationPicker}
              placeholder={t('Select location')}
              value={data.location}
              selection
              search
              onChange={this.onChange}
              data-testid="room-location"
            />
            {errors.location && <InlineError text={errors.location} data-testid="location-error" />}
          </Form.Field>

          <Form.Field error={!!errors.ratio} data-testid="ratio-field">
            <Form.Input
              type="text"
              id="ratio"
              name="ratio"
              label={t('Student ratio for one teacher')}
              placeholder={t('Example: 4')}
              value={data.ratio}
              onChange={this.onChange}
              data-testid="student-ratio"
            />
            {errors.ratio && <InlineError text={errors.ratio} data-testid="ratio-error" />}
          </Form.Field>

          <Form.Field
            label={t('This class enrolls students at least age')}
            error={!!errors.ageRange}
            control={() => (
              <>
                <div className="room-age-input-split">
                  <Form.Field
                    name="from-years"
                    className="age-input"
                    clearable
                    value={data.ageRange?.from?.years || translatedAgeYearOptions[0].value}
                    options={translatedAgeYearOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                    data-testid="room-age-from-years"
                  />

                  <Form.Field
                    name="from-months"
                    className="age-input"
                    clearable
                    value={data.ageRange?.from?.months || translatedAgeMonthOptions[0].value}
                    options={translatedAgeMonthOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                    data-testid="room-age-from-months"
                  />
                </div>
              </>
            )}
            data-testid="age-field-least"
          />
          <Form.Field
            label={t('And no older than')}
            error={!!errors.ageRange}
            control={() => (
              <>
                <div className="room-age-input-split">
                  <Form.Field
                    name="to-years"
                    className="age-input"
                    clearable
                    value={data.ageRange?.to?.years || translatedAgeYearOptions[0].value}
                    options={translatedAgeYearOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                    data-testid="room-age-to-years"
                  />

                  <Form.Field
                    name="to-months"
                    className="age-input"
                    value={data.ageRange?.to?.months || translatedAgeMonthOptions[0].value}
                    options={translatedAgeMonthOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                    data-testid="room-age-to-months"
                  />
                </div>
                {errors.ageRange && <InlineError text={errors.ageRange} data-testid="age-range-error" />}
              </>
            )}
            data-testid="age-field-older"
          />

          {
            // @TODO: Replace the condition with a feature flag
            false && (
              <Form.Field error={!!errors.enabled} data-testid="room-form-error-message">
                <label>
                  {t('Room enabled?')}
                  <Popup
                    content={t(
                      "Rooms are enabled as default. This is a flag for you to keep track of your rooms. Disabling it won't impact anything."
                    )}
                    position="right center"
                    offset={[4, 0]}
                    trigger={<Icon name="info circle" />}
                  />
                </label>
                <Form.Radio
                  toggle
                  id="enabled"
                  name="enabled"
                  onChange={this.tryToggleRoomEnabled}
                  control={Checkbox}
                  checked={data.enabled}
                  data-testid="toggle-room-enabled"
                />
                {errors.enabled && <InlineError text={errors.enabled ?? ''} data-testid="room-field-enabled" />}
              </Form.Field>
            )
          }

          <Modal open={disableModalOpen} data-testid="disable-room-modal">
            <Modal.Header>{t('Are you sure?')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {t('There are enrolled students in this room. Are you sure you want to disable this room?')}
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <Button color="red" onClick={() => this.setState({ disableModalOpen: false })} data-testid="no-btn">
                No
              </Button>
              <Button
                content="Yes"
                labelPosition="right"
                icon="checkmark"
                onClick={this.forceDisableRoom}
                positive
                data-testid="yes-btn"
              />
            </Modal.Actions>
          </Modal>
          <p>&nbsp;</p>

          <Form.Group>
            <Form.Button primary content={data.id ? t('Update') : t('Save')} data-testid="update-btn" />
            <Form.Button
              basic
              content={t('common.cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
              data-testid="cancel-btn"
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export function ensureCorrectRoomValues(roomData, defaultRoom = INITIAL_ROOM) {
  // remove empty string from ageRange.to/from so that we can use a default instead
  if (roomData?.ageRange?.to === '') {
    roomData.ageRange.to = undefined;
  }
  if (roomData?.ageRange?.from === '') {
    roomData.ageRange.from = undefined;
  }
  return {
    ...defaultsDeep({}, { ...roomData }, { ...defaultRoom }),
  };
}

const mapStateToProps = (state): ReduxStateProps => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms,
  students: state.students,
});

export default withTranslation()(
  connect(mapStateToProps, {
    organizationAddRoom,
    organizationUpdateRoom,
    roomSelectionCleared,
  })(RoomForm)
);
