export const CATEGORY_OPTIONS = [
  { key: 'Tuition', text: 'Tuition', value: 'Tuition' },
  { key: 'Late Fee', text: 'Late Fee', value: 'Late Fee' },
  { key: 'Meal Fee', text: 'billing.dropdown.MealFee', value: 'Meal Fee' },
  {
    key: 'Registration Fee',
    text: 'billing.dropdown.registrationFee',
    value: 'Registration Fee',
  },
  {
    key: 'Processing Fee',
    text: 'billing.dropdown.ProcessingFee',
    value: 'Processing Fee',
  },
];

export const ITEM_OPTIONS_BY_CATEGORY = {
  Tuition: [
    {
      key: 'Infants - 24 months',
      text: 'billing.dropdown.Infants-24months',
      value: 'Infants - 24 months',
    },
    {
      key: '2 years - 3.5 years',
      text: 'billing.dropdown.2years-3.5years',
      value: '2 years - 3.5 years',
    },
    {
      key: '3.5 years - 4.5 years',
      text: 'billing.dropdown.3.5years-4.5years',
      value: '3.5 years - 4.5 years',
    },
    {
      key: '4.5 years - 5 years',
      text: 'billing.dropdown.4.5years-5years',
      value: '4.5 years - 5 years',
    },
  ],
  'Late Fee': [{ key: 'Late Fee', text: 'Late Fee', value: 'Late Fee' }],
  'Meal Fee': [
    { key: 'Breakfast', text: 'Breakfast', value: 'Breakfast' },
    {
      key: 'Lunch or Supper',
      text: 'billing.dropdown.LunchorSupper',
      value: 'Lunch or Supper',
    },
    { key: 'Snack', text: 'Snack', value: 'Snack' },
  ],
  'Registration Fee': [
    { key: 'Enrollment Fee', text: 'billing.dropdown.EnrollmentFee', value: 'Enrollment Fee' },
    {
      key: 'After-care Enrollment Fee',
      text: 'billing.dropdown.After-careEnrollmentFee',
      value: 'After-care Enrollment Fee',
    },
  ],
  'Processing Fee': [{ key: 'Processing Fee', text: 'billing.dropdown.ProcessingFee', value: 'Processing Fee' }],
};

export const AMOUNT_BY_CATEGORY = {
  'Infants - 24 months': 1423,
  '2 years - 3.5 years': 1233,
  '3.5 years - 4.5 years': 1143,
  '4.5 years - 5 years': 964,
  'Late Fee': 39,
  Breakfast: 22,
};

export const DISCOUNT_OPTIONS = [
  { key: 'Credit', text: 'Credit', value: 'Credit' },
  {
    key: 'Employee Discount',
    text: 'billing.dropdown.EmployeeDiscount',
    value: 'Employee Discount',
  },
  { key: 'Financial Aid', text: 'billing.dropdown.FinancialAid', value: 'Financial Aid' },
  { key: 'Holiday/Closure', text: 'Holiday/Closure', value: 'Holiday/Closure' },
  { key: 'Referral', text: 'Referral', value: 'Referral' },
  {
    key: 'Sibling/Family Discount',
    text: 'billing.dropdown.Sibling/FamilyDiscount',
    value: 'Sibling/Family Discount',
  },
];

export const INVOICE_ITEM = {
  category: '',
  item: '',
  notes: '',
  amount: '',
  itemOptions: [],
  //discounts or subsidies
  discounts: [],
};
