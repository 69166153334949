import { InvoicePlan } from '@wonderschool/common-base-types';
import { ColumnConfig, DataTable, SortByEnum } from '@wonderschool/common-base-ui';
import { t } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DefaultDateFormat } from '../../../../helpers/dates';
import { isRecurringPlanElegibleForArchive } from '../../../../helpers/invoicePlans';
import { currencyFormatter } from '../../../../helpers/utils';
import DateTime from '../../../DateTime/DateTime';
import { getStudentNames } from './InvoicePlanDetailSlideover';
import { InvoicePlanStatusLabel } from './InvoicePlanStatusLabel';

export type InvoicePlansDataTableProps = {
  data: InvoicePlan[];
  onClick: (invoicePlan: InvoicePlan) => void;
  onEdit: (invoicePlan: InvoicePlan) => void;
  onChangeSelected: (selectedPlans: InvoicePlan[]) => void;
};
export function InvoicePlansDataTable({ data, onClick, onEdit, onChangeSelected }: InvoicePlansDataTableProps) {
  const columns: ColumnConfig<InvoicePlan>[] = useMemo(
    () => [
      {
        fieldName: 'student.displayName',
        label: t('Students'),
        sortBy: SortByEnum.CLIENT,
        sortFunction: (a, b) => {
          if (a.students.length === 0 || b.students.length === 0) {
            return 0;
          }
          const aNames = getStudentNames(a.students);
          const bNames = getStudentNames(b.students);
          return aNames.localeCompare(bNames);
        },
        renderCell: (invoicePlan: InvoicePlan, index?: number) => {
          const isArchived = invoicePlan.isArchived;
          return (
            <>
              <div className="flex max-w-24 flex-wrap" data-testid={`recurring-plan-student-names-${index}`}>
                {getStudentNames(invoicePlan.students)}
              </div>
              {isArchived ? (
                <div data-testid={`recurring-plan-archived-label-${index}`} className="invoice-archived-label">
                  {t('recurringPlanList.archived.text')}
                </div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        fieldName: 'billingInterval',
        label: t('billing.invoicePlanList.BillingInteral'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (invoicePlan: InvoicePlan, index?: number) => (
          <div data-testid={`recurring-plan-billing-interval-${index}`}>{t(invoicePlan.billingInterval)}</div>
        ),
      },
      {
        fieldName: 'invoiceItemList',
        label: t('Description'),
        renderCell: (invoicePlan: InvoicePlan, index?: number) => (
          <span data-testid={`recurring-plan-description-column-${index}`}>
            {invoicePlan.invoiceItemList
              .map((c) => {
                return t(c.item || c.description || '');
              })
              .join(', ')}
          </span>
        ),
      },
      {
        fieldName: 'total',
        label: t('Amount'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (invoicePlan: InvoicePlan, index?: number) => (
          <span data-testid={`recurring-plan-total-amount-${index}`}>
            {currencyFormatter(invoicePlan.total, { precision: 2 })}
          </span>
        ),
      },
      {
        fieldName: 'dateSend',
        label: t('billing.invoicePlanList.NextInvoiceDate'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (invoicePlan: InvoicePlan, index?: number) =>
          invoicePlan.dateSend && (!invoicePlan.dateEnd || moment(invoicePlan.dateEnd).isAfter(invoicePlan.dateDue)) ? (
            <span data-testid={`recurring-plan-send-date-${index}`}>
              <DateTime epoch={invoicePlan.dateSend} format={DefaultDateFormat} />
            </span>
          ) : (
            'n/a'
          ),
      },
      {
        fieldName: 'dateDue',
        label: t('Next Due Date'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (invoicePlan: InvoicePlan, index?: number) =>
          invoicePlan.dateDue && (!invoicePlan.dateEnd || moment(invoicePlan.dateEnd).isAfter(invoicePlan.dateDue)) ? (
            <span data-testid={`recurring-plan-due-date-${index}`}>
              <DateTime epoch={invoicePlan.dateDue} format={DefaultDateFormat} />
            </span>
          ) : (
            'n/a'
          ),
      },
      {
        fieldName: 'status',
        label: t('common.status'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (invoicePlan: InvoicePlan, index?: number) => {
          return <InvoicePlanStatusLabel data-testid={`recurring-plan-status-${index}`} status={invoicePlan.status} />;
        },
      },
      {
        fieldName: 'actions',
        label: '', // we don't want a header for this column
        renderCell: (invoicePlan: InvoicePlan, rowIndex?: number) => (
          <Link
            data-testid={`recurring-plan-edit-link-${rowIndex}`}
            to="#"
            onClick={() => onEdit(invoicePlan)}
            className={'px-4 text-center text-blue-900 hover:underline'}
          >
            {t('common.edit')}
          </Link>
        ),
      },
    ],
    [onEdit]
  );

  const handleChangedSelectedRows = (selectedRows: InvoicePlan[]) => {
    onChangeSelected(selectedRows);
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      onClickRow={onClick}
      canSelect
      canSelectAll
      onChangeSelectedRows={handleChangedSelectedRows}
      isSelectionDisabled={(row) => !isRecurringPlanElegibleForArchive(row)}
    />
  );
}
