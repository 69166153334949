import { functions } from './firebase';
import { getDocumentsAsArray } from './firestore';

const paths = {
  kinderSystemsReports: () => `kinderSystemsReports`,
  kinderSystemsReport: (id) => `kinderSystemsReports/${id}`,
};

export const getkinderSystemsReports = async ({ organizationID, locationID, limit = 5 }) => {
  try {
    if (!locationID) return;
    return getDocumentsAsArray({
      path: paths.kinderSystemsReports(),
      conditions: [
        { field: 'organizationID', operation: '==', value: organizationID },
        { field: 'locationID', operation: '==', value: locationID },
      ],
      orderBy: [{ field: 'dateOfSubmission', direction: 'desc' }],
      limit,
    });
  } catch (error: any) {
    console.log(error);
    throw new Error(error?.message || 'Error in while fetching Kinder Systems Report.');
  }
};

export const submitKSReport = async (data) => {
  return functions().httpsCallable('callables-integration-submitKSReport')(data);
};
