// eslint-disable-next-line no-restricted-imports
import { Form, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components
import { DatePicker } from '../../../../Shared/DatePicker';

// Import style.
import './Invoice.scss';

const Invoice = ({ dateDue, dateServiceStart, dateServiceEnd, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group>
        <Form.Field width={4}>
          <Label basic className="label-required" style={{ border: 'none' }}>
            {t('Due Date')}
          </Label>
          <DatePicker
            required
            data-testid="invoice-due-date"
            placeholder={t('Due Date')}
            name={'dateDue'}
            value={dateDue}
            onChange={onChange}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group className={'row-from-to'}>
        <Form.Field width={4}>
          <Label basic style={{ border: 'none' }}>
            {t('Service Dates (Optional)')}
          </Label>
          <DatePicker
            data-testid="invoice-service-start"
            placeholder={t('From')}
            name={'dateServiceStart'}
            value={dateServiceStart}
            onChange={onChange}
            disabled={dateDue ? false : true}
          />
        </Form.Field>
        <Form.Field width={4}>
          <DatePicker
            data-testid="invoice-service-end"
            placeholder={t('To')}
            name={'dateServiceEnd'}
            value={dateServiceEnd}
            onChange={onChange}
            disabled={dateServiceStart ? false : true}
            minDate={dateServiceStart}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default Invoice;
