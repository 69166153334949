/* eslint-disable i18next/no-literal-string */
import { Button, ButtonLink, Input, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { updateOrganizationLocations } from '../../../api/firebase/locations';
import { usePrimaryLocation } from '../../../hooks/useLocations';
import { useOrganization } from '../../../hooks/useOrganizations';
import { updateOrganization } from '../../../api/firebase/organizations';

const selectUserState = createSelector([(state) => state.user], (user) => user ?? {});

type FormInputs = {
  providerID: string;
  password: string;
  agencyID: string;
  isEnabled: boolean;
};

const KinderConnectCredentials: React.FC = () => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();
  const user = useSelector(selectUserState);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>();

  const password = watch('password');

  useEffect(() => {
    const fetchCredentials = () => {
      try {
        const credentials = location?.integrations?.kinderSystems || null;
        if (credentials) {
          setValue('providerID', credentials.providerID);
          setValue('password', credentials.password);
          setValue('agencyID', credentials.agencyID);
          setValue('isEnabled', credentials.isEnabled);
        }
      } catch (error) {
        console.error('Error fetching KinderSystems credentials:', error);
      } finally {
        setLoading(false);
      }
    };

    if (organization?.isKinderSystemsEnabled) {
      fetchCredentials();
    } else {
      setLoading(false);
    }
  }, [organization.id, user.defaultLocation, setValue, location, organization]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      setLoading(true);
      location.integrations = {
        ...location.integrations,
        kinderSystems: {
          ...location?.integrations?.kinderSystems,
          providerID: data.providerID,
          password: data.password,
          agencyID: data?.agencyID || '1',
          isEnabled: data?.isEnabled || true,
        },
      };
      await updateOrganizationLocations(organization.id, [location]);
      await updateOrganization(organization.id, {
        isKinderSystemsEnabled: location?.integrations?.kinderSystems?.isEnabled ?? false,
      });
      showMessage('success');
      console.log('Credentials updated successfully');
    } catch (error) {
      showMessage('error');
      console.error('Error updating KinderSystems credentials:', error);
    }
  };

  const showMessage = useCallback(
    (messageType) => {
      showToast(
        messageType === 'success' ? ToastTypeEnum.Success : ToastTypeEnum.Error,
        messageType === 'success'
          ? t('KinderSystems credentials updated successfully')
          : t('Error updating KinderSystems credentials')
      );
      setLoading(false);
    },
    [t]
  );

  return (
    <div className="max-w-md ">
      <h2 className="text-2xl font-bold mb-4">Login Details</h2>
      <p className="mb-6 text-gray-600">You can get these from KinderConnect</p>

      <form className="space-y-4">
        <div>
          <Input
            label={t('Provider ID')}
            type="text"
            placeholder={t('Provider ID')}
            {...register('providerID', {
              required: t('Provider ID is required'),
              pattern: {
                value: /^\d+$/,
                message: t('Provider ID should only contain numbers'),
              },
            })}
            error={errors.providerID?.message}
          />
        </div>
        <div>
          <Input
            label={t('Agency ID')}
            type="text"
            placeholder={t('Agency ID')}
            {...register('agencyID', {
              required: t('Agency ID is required'),
              pattern: {
                value: /^\d+$/,
                message: t('Agency ID should only contain numbers'),
              },
            })}
            error={errors.agencyID?.message}
          />
        </div>

        <div>
          <Input
            label={t('common.passwordLabel')}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('common.passwordLabel')}
            {...register('password', { required: t('Password is required') })}
            error={errors.password?.message}
          />
          {password && (
            <div>
              <ButtonLink
                onClick={() => setShowPassword(!showPassword)}
                content={showPassword ? t('Hide Password') : t('Show Password')}
              />
            </div>
          )}
        </div>

        <Button
          primary
          loading={loading}
          disabled={loading}
          data-testid="kinderSystem-button"
          label={t('save')}
          onClick={handleSubmit(onSubmit)}
        ></Button>
      </form>
    </div>
  );
};

export default KinderConnectCredentials;
