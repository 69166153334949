export const descriptionMap = {
  BUSINESS_TYPE: 'billing.requirement.businessType',
  BUSINESS_PROFILE: 'billing.requirement.businessProfile',
  EXTERNAL_ACCOUNT: 'billing.requirement.externalAccount',
  TOS_ACCEPTANCE: 'billing.requirement.tosAcceptance',
  PERSONAL: 'billing.requirement.personal',
  SSN: 'billing.requirement.ssn',
  DOCUMENT: 'billing.requirement.document',
  OTHER: 'billing.requirement.generic',
};

export const PAYOUT_ACCOUNT_DISABLED_REASON = {
  REQUIREMENTS_PAST_DUE: 'REQUIREMENTS_PAST_DUE',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
};
