import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Confirm, Divider, Form, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty, omit } from 'lodash';

import { logError } from '../../../rollbar';

import { useOrganization } from '../../../hooks/useOrganizations';
import { dateFormatter, formatStringAsUtcMillisOrNull, toDateObject } from '../../../helpers/dates';
import { OnSaveCallbackParamsType } from '../../../common';
import { saveStudent } from '../../studentsAPI';
import { useAllStudents, useSelectedStudent, useStudentEnrollment } from '../../studentsHooks';
// Import components
import { DatePicker } from '../../../Components/Shared/DatePicker';
import { InlineError, ShowError } from '../../../Components/Messages';
import RoomPicker from '../../../Components/Rooms/RoomPicker';

import {
  EnrollmentFormDataType,
  EnrollmentStatusEnum,
  getScheduledDaysAbbreviated,
  OnSaveDataType,
  ScheduledDayType,
  ScheduleForm,
  TuitionAndFeesForm,
  TuitionAndFeesType,
  useScheduledDays,
  useTuitionAndFees,
} from '../../../enrollments';
import { useMarketplaceAPI } from '../../../integrations';
import { saveContact } from '../../../contacts/contactsAPI';
import { getRelatedStudentsByContact } from '../../studentsUtils';
import { StudentFormUnenrollmentReasons } from './StudentFormUnenrollmentReasons';
import { StudentFormPendingEnrollment } from './StudentFormPendingEnrollment';
import {
  StudentEnrollmentFormPotentialErrors,
  validateStudentEnrollmentForm,
} from './utils/validateStudentEnrollmentForm';

type StudentEnrollmentFormProps = {
  student: any;
  onClose: () => void;
};

type OnSaveParamsType = OnSaveCallbackParamsType<ScheduledDayType[] | TuitionAndFeesType>;

type OnFormChangeType = {
  name: string;
  value: string;
};

export default function StudentEnrollmentForm({ student, onClose }: StudentEnrollmentFormProps): JSX.Element {
  const { t } = useTranslation();
  const organization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const { sendEnrollmentStatus } = useMarketplaceAPI();
  const students = useAllStudents();

  const [isSaving, setIsSaving] = useState(false);
  const [saveData, setSaveData] = useState<OnSaveDataType>({});

  const [isOpenUnenrollConfirm, setIsOpenUnenrollConfirm] = useState(false);
  const [errors, setErrors] = useState<StudentEnrollmentFormPotentialErrors>({});
  const [formData, setFormData] = useState<EnrollmentFormDataType>({});

  const scheduledDays = useScheduledDays(student);
  const tuitionAndFees = useTuitionAndFees(student);
  const { automaticallyEnroll, enrollmentStatus, enrollmentDate, unenrollmentData } = useStudentEnrollment(student);

  useEffect(() => {
    if (!student) return;

    setFormData({
      isEnrolled: enrollmentStatus === EnrollmentStatusEnum.ENROLLED,
      isPending: enrollmentStatus === EnrollmentStatusEnum.PENDING,
      startDate: dateFormatter(enrollmentDate) || '',
      automaticallyEnroll: automaticallyEnroll,
      room: student.rooms?.length ? student.rooms[0] : '',
      unenrollmentInitiatedBy: unenrollmentData?.initiatedBy ?? '',
      unenrollmentReason: unenrollmentData?.reason ?? '',
      unenrollmentDate: dateFormatter(unenrollmentData?.date) || '',
      unenrollmentNotes: unenrollmentData?.notes ?? '',
      scheduledDays: scheduledDays,
      tuitionAndFees: tuitionAndFees,
    });
  }, [automaticallyEnroll, enrollmentDate, enrollmentStatus, scheduledDays, student, tuitionAndFees, unenrollmentData]);

  const onUnenrollConfirm = useCallback(() => {
    setIsOpenUnenrollConfirm(false);
    setFormData((prev) => ({ ...prev, isEnrolled: false }));
  }, []);

  const onSubmit = useCallback(() => {
    const errorsLocal = validateStudentEnrollmentForm(formData);
    if (!isEmpty(errorsLocal)) {
      setErrors((prev) => ({ ...prev, ...errorsLocal }));
    } else if (isEmpty(errors)) {
      setIsSaving(true);
    }
  }, [errors, formData]);

  const shouldSaveToFirestore = useCallback(() => {
    return isSaving && isEmpty(errors) && Object.keys(saveData).length === 2;
  }, [errors, isSaving, saveData]);

  const dataToSaveToFirestore = useMemo(() => {
    const data = {
      id: student.id ?? '',
      enrollmentStatus: formData.isEnrolled,
      enrollmentDate: formData.startDate ? formatStringAsUtcMillisOrNull(formData.startDate) : null,
      defaultRoom: formData.room,
      rooms: formData.room ? [formData.room] : [],
      enrollment: {
        startDate: toDateObject(formData.startDate),
        automaticallyEnroll: formData.automaticallyEnroll,
        status: formData.isEnrolled
          ? EnrollmentStatusEnum.ENROLLED
          : formData.isPending
            ? EnrollmentStatusEnum.PENDING
            : EnrollmentStatusEnum.UNENROLLED,
        tuitionAndFees: saveData.tuitionAndFees ?? {},
        scheduledDays: saveData.scheduledDays ?? [],
      },
    } as any;

    // This is legacy and should be deprecated
    data.schedule = getScheduledDaysAbbreviated(data);
    if ((formData.isEnrolled && !formData.isPending) || formData?.unenrollmentDate) {
      data.enrollment.unenrolled = {
        initiatedBy: formData.unenrollmentInitiatedBy,
        reason: formData.unenrollmentReason,
        date: formData.unenrollmentDate,
        notes: formData.unenrollmentNotes,
      };
    }
    return data;
  }, [formData, saveData, student]);

  const hydrateStudentForSave = useCallback(() => {
    // if room has not changed, then no formatting is needed
    if (selectedStudent.defaultRoom === dataToSaveToFirestore.defaultRoom) {
      return dataToSaveToFirestore;
    }

    const familyContactIds = Object.keys(selectedStudent.family || {});

    // if no family object, then no formatting is needed
    if (!familyContactIds.length) {
      return dataToSaveToFirestore;
    }

    const hydratedStudent = {
      ...dataToSaveToFirestore,
      family: selectedStudent.family,
    };

    for (const contactId of familyContactIds) {
      hydratedStudent.family[contactId] = {
        ...hydratedStudent.family[contactId],
        defaultRoom: dataToSaveToFirestore.defaultRoom,
        rooms: [dataToSaveToFirestore.defaultRoom],
      };
    }

    return hydratedStudent;
  }, [dataToSaveToFirestore, selectedStudent]);

  const updateContactsRoom = useCallback(async () => {
    const familyContactIds = Object.keys(selectedStudent.family || {});
    if (!familyContactIds.length) return;

    // check if room has changed and update contact
    if (selectedStudent.defaultRoom !== dataToSaveToFirestore.defaultRoom) {
      for (const contactId of familyContactIds) {
        // get all related students EXCEPT the one we're editing now.
        const relatedStudents = getRelatedStudentsByContact(
          contactId,
          students?.list?.filter((student) => student.id !== selectedStudent.id)
        );

        const approvedRooms = new Set([dataToSaveToFirestore.defaultRoom]);

        relatedStudents.forEach((relatedStudent) => {
          approvedRooms.add(relatedStudent.rooms[0]);
        });

        const abbreviatedContact = {
          id: contactId,
          defaultRoom: dataToSaveToFirestore.defaultRoom,
          rooms: Array.from(approvedRooms),
        };
        await saveContact(organization.id, abbreviatedContact);
      }
    }
    return true;
  }, [dataToSaveToFirestore, selectedStudent, organization, students]);

  useEffect(() => {
    const saveLocal = async () => {
      try {
        const studentWithUpdatedFamily = hydrateStudentForSave();
        await saveStudent(organization.id, studentWithUpdatedFamily);

        // only performs a write if the room has changed
        await updateContactsRoom();

        if (student?.enrollment?.interestedFamily?.id)
          await sendEnrollmentStatus(dataToSaveToFirestore.enrollment.status, student.enrollment.interestedFamily.id);
        if (onClose) onClose();
      } catch (error) {
        logError('Error while saving student', error);
      } finally {
        setIsSaving(false);
        setSaveData({});
      }
    };
    if (shouldSaveToFirestore()) {
      saveLocal();
    }
  }, [
    isSaving,
    organization,
    shouldSaveToFirestore,
    student,
    dataToSaveToFirestore,
    onClose,
    sendEnrollmentStatus,
    hydrateStudentForSave,
    updateContactsRoom,
  ]);

  const onSaveChildForm = useCallback(
    ({ data, errors: errorsLocal }: OnSaveParamsType, fieldName: string) => {
      if (!isSaving) return false;

      if (errorsLocal && !isEmpty(errorsLocal)) {
        setIsSaving(false);
        setSaveData({});
        setErrors((prev) => ({ ...prev, ...errorsLocal }));
      } else {
        setSaveData((prev) => ({ ...prev, [fieldName]: data }));
      }
    },
    [isSaving]
  );

  const onChange = useCallback((_: FormEvent<HTMLInputElement>, { name, value }: OnFormChangeType) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => omit(prev, name));
  }, []);

  const onChangeDate = useCallback(
    (_: any, { name, value, valid }: any) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (valid || isEmpty(value)) {
        setErrors((prev) => omit(prev, name));
        if (name === 'unenrollmentDate') {
          const selectedDate = new Date(value);
          if (selectedDate > new Date()) {
            setFormData((prev) => ({ ...prev, isEnrolled: true }));
          } else {
            setFormData((prev) => ({ ...prev, isEnrolled: false }));
          }
        }
      } else {
        setErrors((prev) => ({ ...prev, [name]: t('Invalid Date') }));
      }
    },
    [t]
  );

  const onChangeEnrollmentStatus = useCallback((_: any, { name, checked }: any) => {
    setIsOpenUnenrollConfirm(!checked);
    setFormData((prev) => ({ ...prev, isEnrolled: checked, isPending: false }));
    setErrors((prev) => omit(prev, name));
  }, []);

  const translatedErrorMemo = useMemo(
    () =>
      Object.entries(errors)
        .map(([key, value]) => ({
          [key]: t(value),
        }))
        .reduce((accumulated, current) => Object.assign({}, accumulated, current), {}),
    [errors, t]
  );

  return (
    <>
      <Segment basic textAlign="left">
        <ShowError errors={translatedErrorMemo} data-testid="student-enrollment-form-errors" />
        <Form onSubmit={onSubmit} loading={isSaving} noValidate data-testid="student-enrollment-form">
          <Form.Field error={!!errors.room} data-testid="room-field-error">
            <RoomPicker
              error={!!errors.room}
              id="room"
              name="room"
              label={t('Room')}
              placeholder={t('Select a room')}
              value={formData?.room ?? ''}
              selection
              search
              required
              onChange={onChange}
              data-testid="room-picker"
            />
            {errors.room && <InlineError text={t(errors.room)} data-testid="room-error-msg" />}
          </Form.Field>

          <Form.Field error={!!errors.startDate} data-testid="startDate-field-error">
            <DatePicker
              id="startDate"
              name="startDate"
              value={formData?.startDate ?? ''}
              onChange={onChangeDate}
              label={t('enrollments.enrollmentInfoStartDateLabel')}
              placeholder={t('Select an enrollment date')}
              error={!!errors.startDate}
              closable
              required
              data-testid="startDate"
            >
              {errors.startDate && <InlineError text={t(errors.startDate)} data-testid="startDate-error-msg" />}
            </DatePicker>
          </Form.Field>

          {enrollmentStatus === EnrollmentStatusEnum.PENDING && (
            <StudentFormPendingEnrollment
              automaticallyEnroll={Boolean(formData?.automaticallyEnroll)}
              setFormData={setFormData}
              studentId={student.id}
            />
          )}
          <Form.Field>
            <Divider />
          </Form.Field>
          <Form.Field>
            <ScheduleForm
              scheduledDays={formData?.scheduledDays ?? []}
              isSaving={isSaving && !saveData.scheduledDays}
              onSave={(d) => onSaveChildForm(d, 'scheduledDays')}
            />
          </Form.Field>
          <Form.Field>
            <TuitionAndFeesForm
              tuitionAndFees={formData?.tuitionAndFees ?? { fees: [], tuition: {} }}
              isSaving={isSaving && !saveData.tuitionAndFees}
              onSave={(d) => onSaveChildForm(d, 'tuitionAndFees')}
            />
          </Form.Field>
          <Form.Field error={!!errors.enrollmentStatus} data-testid="enrollmentStatus-field--error">
            <label>{t('Enrollment Status')}</label>
            <Form.Radio
              toggle
              id="enrollmentStatus"
              name="enrollmentStatus"
              onClick={onChangeEnrollmentStatus}
              checked={formData?.isEnrolled ?? true}
              control={Checkbox}
              data-testid="enrollmentStatus"
            />
          </Form.Field>
          {(!formData.isEnrolled || formData?.unenrollmentDate) && !formData.isPending && (
            <StudentFormUnenrollmentReasons
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
              onChangeDate={onChangeDate}
              unenrollmentReason={formData?.unenrollmentReason}
              unenrollmentDate={formData?.unenrollmentDate}
              unenrollmentInitiatedBy={formData?.unenrollmentInitiatedBy}
            />
          )}

          <Form.Group>
            <Form.Button
              primary
              content={t('Save')}
              disabled={isSaving || !isEmpty(errors)}
              loading={isSaving}
              data-testid="enrollment-edit-form-save-btn"
            />

            <Form.Button
              basic
              content={t('common.cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (onClose) onClose();
              }}
              data-testid="enrollment-edit-form-cancel-btn"
            />
          </Form.Group>
        </Form>
      </Segment>
      <Confirm
        open={isOpenUnenrollConfirm}
        content={t('unenrollConfirm')}
        onConfirm={onUnenrollConfirm}
        onCancel={() => setIsOpenUnenrollConfirm(false)}
        cancelButton={t('common.cancel')}
        confirmButton={t('Confirm')}
      />
    </>
  );
}
