import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Container } from 'semantic-ui-react';

interface TransactionsStatsBarProps {
  count: number;
  totalCount: number;
}

export default function TransactionsStatsBar({ count, totalCount }: TransactionsStatsBarProps) {
  const { t } = useTranslation();

  if (!totalCount) return null;

  return (
    <Container style={{ textAlign: 'left', width: '100%', paddingBottom: '2px' }} data-testid="transactions-stats-bar">
      {t('Showing') + ' '}
      <strong>{count}</strong> {t('of')} <strong>{totalCount}</strong> {t('transactions')}
    </Container>
  );
}
