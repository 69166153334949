import './utils/polyfills';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';

import App from './App';

import { FeatureFlagsProvider } from './feature-flags';
import { initializeHotjar } from './hotjar';
import { LaunchDarklyContextInitializer, makeLDContext } from './launch-darkly';
import { RollbarLoggingProvider } from './rollbar';

// Import store
import store from './redux/store';

// translations
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Import styles.
import 'instantsearch.css/themes/algolia.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'semantic-ui-css/semantic.min.css';
import 'uppy/dist/uppy.min.css';
import './styles/css/bootstrap-iso.min.css';
import './styles/css/main.css';

// Moxit specific style.
import './styles/scss/index.scss';

(async () => {
  try {
    initializeHotjar();
  } catch (error) {
    // swallow the error
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);

  const clientSideID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID ?? '';

  const LDProvider = await asyncWithLDProvider({ clientSideID, context: makeLDContext(/* anonymous */) });
  root.render(
    <LDProvider>
      <I18nextProvider i18n={i18n}>
        <FeatureFlagsProvider>
          <BrowserRouter>
            <Provider store={store}>
              <RollbarLoggingProvider>
                <HelmetProvider>
                  <Suspense fallback="Loading...">
                    {/* This is required in order to show toast() in child components position and animation can be set here. */}
                    <SemanticToastContainer />
                    <LaunchDarklyContextInitializer />
                    <App />
                  </Suspense>
                </HelmetProvider>
              </RollbarLoggingProvider>
            </Provider>
          </BrowserRouter>
        </FeatureFlagsProvider>
      </I18nextProvider>
    </LDProvider>
  );
})();
