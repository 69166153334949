import PageHeader from '../Components/Shared/PageHeader';
import { useOrganization, useOrganizationInvoicesListener } from '../hooks/useOrganizations';
import { PAGE_NAMES, SEGMENT_EVENTS, useSegmentPage, useSegmentTrack } from '../segment';

// Import components
import InvoicesList from '../Components/Billing/Invoices/InvoicesList';
import SetupPayoutModal from '../Components/SetupPayoutModal';
import { RouteNameEnum } from '../navigation';
import { useRoutes } from '../navigation/navigationHooks';

export default function Invoices() {
  const organization = useOrganization();
  const { gotoRouteByName } = useRoutes();
  const segmentTrack = useSegmentTrack();
  useSegmentPage(PAGE_NAMES.invoices);
  useOrganizationInvoicesListener(organization.id);

  return (
    <>
      <PageHeader pageName={'Billing'} classes="billing" />
      <InvoicesList
        onClickCreateNewInvoice={() => {
          if (!organization?.setup?.firstInvoiceCreated) {
            segmentTrack(SEGMENT_EVENTS.firstInvoiceStart);
          }
          gotoRouteByName(RouteNameEnum.INVOICE_EDIT_DETAILS);
        }}
      />
      <SetupPayoutModal />
    </>
  );
}
