import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ParentsListingFilterForm, ParentsListingTable } from '../Components/ParentsListing';
import { getTitlePrefix } from '../config/env';
import { useAuthUser } from '../hooks/useUser';
import { PAGE_NAMES, useSegmentPage } from '../segment';
import { useStudents } from '../students/studentsHooks';

const ParentsListing = () => {
  const [state, setState] = useState({
    searchText: '',
    isEditOpen: false,
    filters: {
      room: '',
      relationship: '',
      autopay: '',
      invite: '',
    },
    newStudentId: null,
    count: {
      all: 0,
    },
  });
  let { listFiltered } = useStudents(state);
  listFiltered = listFiltered.filter((item) => item.enrollmentStatus === true);
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.contacts);
  const { token } = useAuthUser();

  if (!token) return null;

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('Family&contacts')}
      </title>
      <body className="familyListing" />
    </Helmet>
  );

  return (
    <>
      <div className="no-padding no-margin">
        {renderPageHead()}
        <div className="px-8 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <Heading1>{t('Family&contacts')}</Heading1>
          </div>
        </div>

        <ParentsListingFilterForm state={state} setState={setState}></ParentsListingFilterForm>
        <ParentsListingTable studentsList={listFiltered} stateFilter={state} />
      </div>
    </>
  );
};

function Heading1({ children }: { children: React.ReactNode }) {
  return (
    <h1 data-testid="contacts-heading" className="py-6 text-3xl font-bold leading-tight tracking-tight text-gray-900">
      {children}
    </h1>
  );
}

export default ParentsListing;
