import useSegmentTrack from '../../segment/hooks/useSegmentTrack';

const withSegmentHook = (WrappedComponent) => {
  return function WithCustomHook(props) {
    const segmentTrack = useSegmentTrack();
    return <WrappedComponent {...props} segmentTrack={segmentTrack} />;
  };
};

export default withSegmentHook;
