import { ScheduledDayType } from '@wonderschool/common-base-types';
import { DayEnum } from './enums';
import { InvitationRecipientType, TuitionAndFeesType } from './types';

// Map legacy days to DayEnum days
export const LEGACY_TO_DAY_ENUM_MAP: Record<string, DayEnum> = {
  M: DayEnum.MONDAY,
  T: DayEnum.TUESDAY,
  W: DayEnum.WEDNESDAY,
  Th: DayEnum.THURSDAY,
  F: DayEnum.FRIDAY,
  S: DayEnum.SATURDAY,
  Su: DayEnum.SUNDAY,
};

export const DAY_ENUM_TO_LEGACY_MAP: Record<DayEnum, string> = {
  [DayEnum.MONDAY]: 'M',
  [DayEnum.TUESDAY]: 'T',
  [DayEnum.WEDNESDAY]: 'W',
  [DayEnum.THURSDAY]: 'Th',
  [DayEnum.FRIDAY]: 'F',
  [DayEnum.SATURDAY]: 'S',
  [DayEnum.SUNDAY]: 'Su',
};

// define these as constants so they are not redefined on every function call, causing unnecessary re-renders
export const SCHEDULED_DAYS_EMPTY: ScheduledDayType[] = [];
export const INVITATION_RECIPIENTS_EMPTY: InvitationRecipientType[] = [];
export const DOCUMENTS_EMPTY: DocumentType[] = [];
export const TUITION_AND_FEES_EMPTY: TuitionAndFeesType = {
  tuition: {},
  fees: [],
};

export const DAYS_OF_THE_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const DAY_ENUM_TO_DAYJS_DAY: Record<DayEnum, number> = {
  [DayEnum.SUNDAY]: 0,
  [DayEnum.MONDAY]: 1,
  [DayEnum.TUESDAY]: 2,
  [DayEnum.WEDNESDAY]: 3,
  [DayEnum.THURSDAY]: 4,
  [DayEnum.FRIDAY]: 5,
  [DayEnum.SATURDAY]: 6,
};

export const NUMBERS_OF_THE_MONTH_OPTIONS = Array.from({ length: 31 }, (_, i) => {
  const number = i + 1;
  let suffix = 'th';

  if (number % 10 === 1 && number !== 11) suffix = 'st';
  else if (number % 10 === 2 && number !== 12) suffix = 'nd';
  else if (number % 10 === 3 && number !== 13) suffix = 'rd';

  return {
    label: `${number}${suffix}`,
    value: number.toString(),
  };
});
