import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../../hooks/useOrganizations';
import WSA from '../../WSA';

import SetupBanner from './SetupBanner';

import {
  isStripeOnboardingStatusSetToFailed,
  isStripeOnboardingStatusSetToSucceeded,
  showSetupPayoutRibbon,
  StripeOnboardingStatus,
  updateStripeOnboardingStatus,
} from '../../helpers/stripe';
import { SEGMENT_EVENTS } from '../../segment';
import { BillingPageEnum } from '../../common';

interface SetupPayoutBannerProps {
  onError: (error: string) => void;
  page: string;
}

export default function SetupPayoutBanner(props: SetupPayoutBannerProps) {
  const { onError, page } = props;
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const shouldShowSetupBanner = showSetupPayoutRibbon(currentOrganization);
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();

  if (!shouldShowSetupBanner || !isReady || !isAuthenticated) return null;

  const handleDismissSucceededMessage = () => {
    updateStripeOnboardingStatus(currentOrganization.id, StripeOnboardingStatus.COMPLETED);
  };

  const segmentEventMap = {
    [BillingPageEnum.INVOICE]: SEGMENT_EVENTS.addPayoutAccountInvoiceConnectAccount,
    [BillingPageEnum.INVOICE_PLAN]: SEGMENT_EVENTS.addPayoutAccountInvoicePlanConnectAccount,
    [BillingPageEnum.TRANSACTION]: SEGMENT_EVENTS.addPayoutAccountTransactionConnectAccount,
    [BillingPageEnum.HOME]: SEGMENT_EVENTS.addPayoutAccountHomeConnectAccount,
  };

  const segmentEvent = segmentEventMap[page] || '';

  if (isStripeOnboardingStatusSetToFailed(currentOrganization)) {
    return (
      <SetupBanner
        color="#F9E6E6"
        message={t('setupPayoutBanner.failedMessage')}
        organizationId={currentOrganization.id}
        showLink
        onError={onError}
        segmentEvent={segmentEvent}
      />
    );
  }
  if (isStripeOnboardingStatusSetToSucceeded(currentOrganization)) {
    return (
      <SetupBanner
        color="#CCF3EB"
        message={t('setupPayoutBanner.successMessage')}
        organizationId={currentOrganization.id}
        isDismissable
        onDismiss={() => handleDismissSucceededMessage()}
      />
    );
  }
  return (
    <SetupBanner
      color="#FFF5CC"
      message={t('setupPayoutBanner.pendingMessage')}
      organizationId={currentOrganization.id}
      onError={onError}
      showLink
      segmentEvent={segmentEvent}
    />
  );
}
