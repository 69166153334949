import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Grid, Segment } from 'semantic-ui-react';

import { Area51Container } from '../Components/Admin/Area51';

// Import Admin Components
import OrganizationAdmin from '../Components/Admin/Providers';
import { UsersListContainer } from '../Components/Admin/Users';
// Import components
import { MigrationReport } from '../Components/Admin/MigrationReport';
import { AdminDocuments } from '../Components/Admin/AdminDocuments/AdminDocuments';
import PageHeader from '../Components/Shared/PageHeader';
import { NetworksAdminContainer } from '../networks';

function Admin() {
  const { pathname } = useLocation();

  const renderContent = () => {
    switch (pathname) {
      case '/admin/users':
        return <UsersListContainer />;

      case '/admin/networks':
        return <NetworksAdminContainer />;

      case '/admin/area-51':
        return <Area51Container />;

      case '/admin/providers':
        return <OrganizationAdmin />;

      case '/admin/migration-report':
        return <MigrationReport />;

      case '/admin/documents':
        return <AdminDocuments />;

      default:
        return null;
    }
  };

  return (
    <Segment basic>
      <PageHeader pageName={'Admin'} classes="admin" data-testid="admin-provider" />

      <Grid stackable>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          {renderContent()}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
export default Admin;
