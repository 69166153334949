import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Combobox } from '@wonderschool/common-base-ui';
import { WsDropdownOptionType, WsDropdownProps } from '../../../common';
import { ReportTypeEnum } from '../../enums';

type DropdownOptionType = WsDropdownOptionType<ReportTypeEnum>;
type ReportTypeDropdownProps = Omit<WsDropdownProps<ReportTypeEnum>, 'options'>;

const ReportTypeDropdown: React.FC<ReportTypeDropdownProps> = ({ name, onChange, value, error }) => {
  const { t } = useTranslation();

  const options: DropdownOptionType[] = useMemo(
    () => [
      { id: ReportTypeEnum.DAILY_HOURS, name: t('timecards.reportTypeDailyOption') },
      { id: ReportTypeEnum.TOTAL_HOURS, name: t('timecards.reportTypeTotalOption') },
    ],
    [t]
  );
  return (
    <Combobox
      label={t('timecards.reportTypeLabel')}
      name={name ?? 'reportType'}
      options={options}
      placeholder={t('timecards.reportTypePlaceholder')}
      required
      value={value ?? ''}
      onChange={onChange}
      error={error}
      immediate
    />
  );
};

export default ReportTypeDropdown;
