import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CATEGORY_OPTIONS, DISCOUNT_OPTIONS, INVOICE_ITEM, ITEM_OPTIONS_BY_CATEGORY } from './initialState';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatePaymentDetails } from '../../../../redux/actions/billingActions';
import InvoiceItemsEditor from './InvoiceItemsEditor/InvoiceItemsEditor';
import { parseCurrency } from '../../../../helpers/utils';
import { DiscountAmountType } from '../../../../helpers/invoices';
import withSegmentHook from '../../../../segment/hooks/withSegmentHook';
import { SEGMENT_EVENTS } from '../../../../segment';

export default function withInvoiceItemsEditor(Component) {
  class WrappedComponent extends React.Component {
    static propTypes = {
      children: PropTypes.any,
    };

    state = {
      discountOptions: DISCOUNT_OPTIONS,
      categoryOptions: CATEGORY_OPTIONS,
      itemOptionsByCategory: ITEM_OPTIONS_BY_CATEGORY,
      invoiceItemList: [Object.assign({}, INVOICE_ITEM)],
      total: 0,
      validation: [],
      validationError: false,
    };

    componentDidMount() {
      if (this.props.paymentDetails) {
        this.setState({
          invoiceItemList: this.props.paymentDetails.invoiceItemList,
          total: this.props.paymentDetails.total,
        });
      }
    }

    validateFields = (nextClicked = false) => {
      let validationError = false;
      let validation = this.state.invoiceItemList.map((item) => {
        if (item.category === '' || item.item === '') {
          validationError = true;
        }
        let discountTypeErrors =
          !!item.discounts &&
          item.discounts.map((discountItem) => {
            return discountItem.discountType === '' && !!discountItem.amount;
          });
        if (discountTypeErrors.length > 0 && discountTypeErrors.some((value) => value === true)) {
          validationError = true;
        }
        if (this.state.total < 0) {
          validationError = true;
        }
        return {
          categoryError: item.category === '',
          itemError: item.item === '',
          discountTypeErrors: discountTypeErrors,
          totalError: this.state.total < 0,
        };
      });
      if (this.state.invoiceItemList.length === 0) {
        validationError = true;
      }
      this.setState({
        validation: validation,
        validationError: validationError,
      });
      if (nextClicked && validationError && !this.props.firstInvoiceCreated) {
        this.props.segmentTrack(SEGMENT_EVENTS.firstInvoiceDetailsError, { errors: validation });
      }
      return !validationError;
    };

    getInvoiceSummary = () => {
      return {
        total: this.state.total,
        items: this.state.invoiceItemList.map((item) => {
          return {
            label: item.item,
            amount: item.amount,
            discounts: item.discounts.map((option) => {
              return {
                label: option.discountType,
                amount: option.amount,
                amountType: option.amountType,
              };
            }),
          };
        }),
      };
    };
    calcTotal = () => {
      this.setState((state) => ({
        total: state.invoiceItemList
          .map((item) => {
            let invoiceDiscount = 0;
            if (item.discounts.length) {
              invoiceDiscount = item.discounts
                .map((discount) => {
                  if (discount.amountType === DiscountAmountType.PERCENT) return item.amount * (discount.amount / 100);
                  return discount.amount;
                })
                .reduce((a, b) => a + b, 0);
            }
            //If total discount are more then total invoice value, then 0
            // return item.amount >= invoiceDiscount ? item.amount - invoiceDiscount : 0;
            return item.amount - invoiceDiscount;
          })
          .reduce((a, b) => a + b, 0),
      }));
    };
    handleChangeItem = (e, { name, value }, index) => {
      let isAmount = name === 'amount';
      let isCategory = name === 'category';
      let isItem = name === 'item';

      if (isAmount) value = value ? +parseCurrency(value) : 0;

      let itemOptionsProp = {};
      if (isCategory) {
        let categoryNotEmpty = value && this.state.itemOptionsByCategory[value];
        itemOptionsProp.itemOptions = categoryNotEmpty ? [...this.state.itemOptionsByCategory[value]] : [];
        //reset item value if category dropdown was cleared
        if (!categoryNotEmpty) {
          itemOptionsProp.item = '';
        }
      }

      this.setState(
        (state) => ({
          invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
            if (index === itemIndex) {
              return {
                ...item,
                ...itemOptionsProp,
                [name]: value,
              };
            }
            return { ...item };
          }),
        }),
        () => {
          if (isAmount) {
            this.calcTotal();
          }
          if (isCategory || isItem) {
            this.validateFields();
          }
        }
      );
    };
    handleChangeItemOptions = (e, { name, value }, index) => {
      this.setState(
        (state) => ({
          invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
            if (index === itemIndex) {
              return {
                ...item,
                [name]: value,
              };
            }
            return { ...item };
          }),
        }),
        () => {
          this.validateFields();
        }
      );
    };
    handleAdditionItemOptions = (e, { name, value }, index) => {
      this.setState((state) => ({
        invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
          if (index === itemIndex) {
            return {
              ...item,
              itemOptions: [...item.itemOptions, { key: value, text: value, value }],
              [name]: value,
            };
          }
          return { ...item };
        }),
      }));
    };
    handleAddition = (e, { name, value }) => {
      this.setState({
        [`${name}Options`]: [{ key: value, text: value, value }, ...this.state[`${name}Options`]],
      });
    };
    handleAddInvoiceItem = () => {
      this.setState((state) => ({
        invoiceItemList: [...state.invoiceItemList, Object.assign({}, INVOICE_ITEM)],
      }));
    };
    handleDeleteInvoiceItem = (index) => {
      this.setState(
        (state) => ({
          invoiceItemList: state.invoiceItemList.filter((_, i) => i !== index),
        }),
        () => {
          this.calcTotal();
        }
      );
    };
    handleAddDiscount = (index) => {
      this.setState((state) => ({
        invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
          if (index === itemIndex) {
            return {
              ...item,
              discounts: [
                ...item.discounts,
                {
                  amount: '',
                  amountType: DiscountAmountType.CURRENCY,
                  discountType: '',
                },
              ],
            };
          }
          return { ...item };
        }),
      }));
    };
    handleChangeDiscount = (e, { name, value }, index, discountIndex) => {
      let isAmount = name === 'amount';
      let isAmountType = name === 'amountType';
      let isDiscountType = name === 'discountType';

      if (isAmount) value = value ? +parseCurrency(value) : 0;

      this.setState(
        (state) => ({
          invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
            if (index === itemIndex) {
              return {
                ...item,
                discounts: item.discounts.map((discount, dIndex) => {
                  if (discountIndex === dIndex) {
                    return {
                      ...discount,
                      [name]: value,
                    };
                  }
                  return { ...discount };
                }),
              };
            }
            return { ...item };
          }),
        }),
        () => {
          if (isAmount || isAmountType) {
            this.calcTotal();
          }
          if (isDiscountType) {
            this.validateFields();
          }
        }
      );
    };
    handleDeleteDiscount = (index, discountIndex) => {
      this.setState(
        (state) => ({
          invoiceItemList: state.invoiceItemList.map((item, itemIndex) => {
            if (index === itemIndex) {
              return {
                ...item,
                discounts: [...item.discounts.filter((discount, dIndex) => dIndex !== discountIndex)],
              };
            }
            return { ...item };
          }),
        }),
        () => {
          this.calcTotal();
          this.validateFields();
        }
      );
    };

    render() {
      let { invoiceItemList } = this.state;

      return (
        <>
          <Component
            getInvoiceSummary={this.getInvoiceSummary}
            validateFields={this.validateFields}
            {...this.state}
            {...this.props}
          />
          {invoiceItemList && (
            <InvoiceItemsEditor
              {...this.state}
              isSubmitDetails={this.props.isSubmitDetails}
              onAddition={this.handleAddition}
              onAdditionItemOptions={this.handleAdditionItemOptions}
              onChangeItemOptions={this.handleChangeItemOptions}
              onChange={this.handleChangeItem}
              onDeleteDiscount={this.handleDeleteDiscount}
              onAddDiscount={this.handleAddDiscount}
              onChangeDiscount={this.handleChangeDiscount}
              onAddInvoiceItem={this.handleAddInvoiceItem}
              onDeleteInvoiceItem={this.handleDeleteInvoiceItem}
            />
          )}
        </>
      );
    }
  }
  const mapStateToProps = (state) => {
    return {
      paymentDetails: state.paymentDetails,
      firstInvoiceCreated: state.organizations.currentOrganization?.setup?.firstInvoiceCreated,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        updatePaymentDetails,
      },
      dispatch
    );
  };

  return withSegmentHook(withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)));
}
