import { useEffect } from 'react';
import {
  Provider as RollbarProvider,
  ErrorBoundary as InternalRollbarErrorBoundary,
  historyContext,
} from '@rollbar/react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';

import ErrorPage from '../Pages/Error';

import { getRollbar } from './config';
import { logLocal, isRollbarEnabled } from './api';

const localHistoryListener = (location) => {
  logLocal(`History Context Change (Rollbar): ${location.pathname}`, {
    location,
  });
};
const localErrorListener = (error, info) => {
  logLocal('Local Error Boundary (Rollbar):', { error, info });
};
export const LocalErrorBoundary = ({ children, fallback = ErrorPage, errorListener = localErrorListener }) => {
  return (
    <ReactErrorBoundary FallbackComponent={fallback} onError={errorListener}>
      {children}
    </ReactErrorBoundary>
  );
};
const RollbarErrorBoundary = ({ children, fallback = ErrorPage }) => {
  return <InternalRollbarErrorBoundary fallbackUI={fallback}>{children}</InternalRollbarErrorBoundary>;
};

/**
 * Makes the Rollbar context available
 */
export const RollbarLoggingProvider = ({ children }) => {
  const rollbar = getRollbar();
  const history = useHistory();

  useEffect(() => {
    return history.listen(isRollbarEnabled() ? historyContext(rollbar) : localHistoryListener);
  }, [history, rollbar]);

  if (isRollbarEnabled()) {
    return (
      <>
        {/* Provider instantiates Rollbar client instance handling any uncaught
        errors or unhandled promises in the browser */}
        <RollbarProvider instance={rollbar}>
          {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
          <RollbarErrorBoundary>{children}</RollbarErrorBoundary>
        </RollbarProvider>
      </>
    );
  } else {
    return <LocalErrorBoundary>{children}</LocalErrorBoundary>;
  }
};
