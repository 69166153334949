import { useEffect, useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
// eslint-disable-next-line no-restricted-imports
import { Input } from 'semantic-ui-react';

const TYPE_DELAY = 500;

export const CustomSearchBox = connectSearchBox(
  ({
    currentRefinement,
    isSearchStalled,
    refine,
    indexContextValue, // Omitted to avoid Input error
    createURL, // Omitted to avoid Input error
    delay = TYPE_DELAY,
    ...inputProps
  }) => {
    const [searchQuery, setSearchQuery] = useState(currentRefinement);

    useEffect(() => {
      // Debounce search refinement until user stops typing
      // This is to prevent too many requests to the server default is set to 500ms
      const timeout = setTimeout(() => {
        refine(searchQuery);
      }, delay);

      return () => clearTimeout(timeout);
    }, [currentRefinement, delay, refine, searchQuery]);

    const handleChange = (_e, { value }) => {
      setSearchQuery(value);
    };

    return (
      <form noValidate action="" role="search">
        <Input
          data-testid="search-input"
          type="search"
          icon="search"
          placeholder="Search...."
          loading={isSearchStalled}
          value={searchQuery}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (searchQuery.length > 0) refine(searchQuery);
            }
          }}
          {...inputProps}
        />
      </form>
    );
  }
);
