// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { showSetupPayoutRibbon } from '../../../helpers/stripe';
import { useFinanceAtGlance } from '../../../hooks/useFinanceAtGlance';
import { useOrganization } from '../../../hooks/useOrganizations';
import { selectFirstLocation } from '../../DateTime/DateTime';
import { ShowErrors } from '../../Messages';
import SetupPayoutBanner from '../../SetupPayoutBanner';
import FinanceAtGlanceHeader from './FinanceAtGlanceHeader';
import InvoiceSection from './InvoiceSection';
import TransactionSection from './TrasactionsSection';
import { BillingPageEnum } from '../../../common';

function FinanceAtGlanceOverview() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isRefreshToggle, setIsRefreshToggle] = useState(false);
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const [selectedMonth, setSelectedMonth] = useState(moment().tz(timezone, true));

  const organization = useOrganization();

  const refreshHandler = () => {
    setIsRefreshToggle(!isRefreshToggle);
  };

  const { isLoadingInvoices, isLoadingTransactions, invoicesData, transactionData } = useFinanceAtGlance(
    selectedMonth,
    '',
    isRefreshToggle
  );
  return (
    <>
      <FinanceAtGlanceHeader
        selectedMonth={setSelectedMonth}
        refreshHandler={refreshHandler}
        isLoading={isLoadingInvoices && isLoadingTransactions}
      />
      {showSetupPayoutRibbon(organization) ? (
        <div style={{ marginTop: '10px' }}>
          <SetupPayoutBanner onError={(error) => setErrorMessage(error)} page={BillingPageEnum.HOME} />
          {errorMessage && <ShowErrors content={errorMessage} />}
        </div>
      ) : (
        <div style={{ marginTop: '10px' }}>
          <div>
            <InvoiceSection
              title="Invoices"
              upcoming={invoicesData?.statusUpcoming}
              processing={invoicesData?.statusProcessing}
              transferring={invoicesData?.statusTransferring}
              depositedPaid={invoicesData?.statusDepositedOrPaid}
              pastDue={invoicesData?.statusPastDue}
              failed={invoicesData?.statusFailed}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <TransactionSection
              revenue={transactionData?.revenue}
              expenses={transactionData?.expense}
              title="Transactions"
            />
          </div>
        </div>
      )}
    </>
  );
}
export default FinanceAtGlanceOverview;
