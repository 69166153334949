import { useEffect, useMemo } from 'react';
import { FileDocument, useDownloadFiles, useGetStudentFiles } from '@wonderschool/file-service-client';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

// utils
import { capitalize } from '../../helpers/utils';
import { renderDate } from '../../utils/date';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Icon } from 'semantic-ui-react';
import { FileDocumentFieldValue } from '../dictionary';
import { StudentDocument } from '../types';

interface IParentDocumentsTableProps {
  student: StudentDocument;
  liftStudentFileIds: (studentId: string, fileIds: string[]) => void;
}

const ParentDocumentsTable: React.FC<IParentDocumentsTableProps> = ({ student, liftStudentFileIds }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetStudentFiles({
    studentId: student.id,
    roomId: student.rooms?.[0] || '',
    locationId: student.location,
  });

  const { downloadFiles } = useDownloadFiles();

  useEffect(() => {
    if (!isLoading && data) {
      if (data.documents.length) {
        liftStudentFileIds(
          student.id,
          data.documents.map((doc) => doc.id)
        );
      }
    }
  }, [isLoading, student, data, liftStudentFileIds]);

  const columns = useMemo(
    () => [
      {
        dataField: 'name',
        text: t('common.name'),
        sort: true,
        formatter: (cell: FileDocumentFieldValue) => (
          <>
            <Icon name="file alternate outline" />
            {cell}
          </>
        ),
      },
      {
        dataField: 'updatedAt',
        text: t('Updated'),
        sort: true,
        formatter: (_cell: any, row: FileDocument) => {
          const date = renderDate(row.updatedAt, 'MM/DD/YYYY');
          const updatedBy = row.updatedBy?.displayName;
          return t('{{date}} by {{updatedBy}}', { date, updatedBy });
        },
      },
      {
        dataField: 'fileAction',
        text: t('File Type'),
        formatter: (cell: FileDocument['fileAction'], row: FileDocument) => (
          <a
            href={`#${row.id}`}
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              downloadFiles([row.id]);
            }}
          >
            {capitalize(t(cell))}
          </a>
        ),
      },
    ],
    [t, downloadFiles]
  );

  const getRowClasses = (_row: FileDocument) => {
    // TODO: when the file-service-client allows for differentiating between viewed/downloaded files
    // and new files, this needs to be updated.
    // eslint-disable-next-line no-constant-condition
    if (false) {
      return 'is-unread';
    }
    return 'is-read';
  };

  return (
    <ToolkitProvider bootstrap4 keyField="id" data={data?.documents || []} columns={columns}>
      {({ baseProps }) => (
        <div className="bootstrap-iso">
          <BootstrapTable
            hover
            classes={'align-middle'}
            bordered={false}
            headerClasses="table-header"
            rowClasses={getRowClasses}
            noDataIndication={
              <>{isLoading ? <div>{t('Loading data')}...</div> : <div>{t('No forms/documents found')}</div>}</>
            }
            defaultSorted={[
              {
                dataField: 'updatedAt',
                order: 'desc',
              },
            ]}
            {...baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default ParentDocumentsTable;
