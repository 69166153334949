import { useEffect } from 'react';

import { useSegmentIdentify, useSegmentTrackInitializeAction } from '../segment';

import { useOrganization } from '../hooks/useOrganizations';
import { useAuthUser } from '../hooks/useUser';
import { logError } from '../rollbar';

import { useUserflow } from '../integrations';

export default function useTrackers() {
  const { currentUser } = useAuthUser();
  const currentOrganization = useOrganization();

  useSegmentTrackInitializeAction();
  useSegmentIdentify();
  const { userflowInitialize } = useUserflow();

  useEffect(() => {
    (async () => {
      try {
        // Identify the user and the organization  from userFlow.
        await userflowInitialize();
      } catch (error) {
        logError(`Error initializing userflow`, error, { error });
      }
    })();
  }, [userflowInitialize, currentOrganization, currentUser]);
}
