// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { ROOMS_FETCHED, ROOM_SELECTED, ROOM_SELECTION_CLEARED, ROOM_UPDATED } from '../actions/types';

const initialState = {
  selectedRoom: {},
  count: 0,
  list: [],
};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case ROOMS_FETCHED:
      return {
        ...state,
        count: action.rooms.count,
        list: action.rooms.list,
      };

    case ROOM_SELECTED:
      return { ...state, selectedRoom: action.room };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case ROOM_UPDATED: {
      return {
        ...state,
        selectedRoom: _.find(state.list, { id: state.selectedRoom.id }),
      };
    }

    case ROOM_SELECTION_CLEARED:
      return { ...state, selectedRoom: {} };

    default:
      return state;
  }
};

export default rooms;
