import { StaffPage } from '@wonderschool/ccms-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { fetchEmailUsage } from '../api/firebase/users';
import { getTitlePrefix } from '../config/env';
import { RouteNameEnum, useRoutes } from '../navigation';
import OldStaffPage from './OldStaff';

const Staff = () => {
  const { gotoRouteByName } = useRoutes();
  const { isStaffV2Enabled } = useFlags();
  const { t } = useTranslation();

  const handleStaffRowClick = (staffId: string) => {
    return gotoRouteByName(RouteNameEnum.STAFF_DETAILS, [{ name: 'staffId', value: staffId }]);
  };

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Staff') })}
      </title>
      <body className="staff" />
    </Helmet>
  );

  const validateEmail = async (email: string, organizationId: string) => {
    const emailUsage = await fetchEmailUsage(email, organizationId);
    return emailUsage.isInUse;
  };

  return isStaffV2Enabled ? (
    <>
      {renderPageHead()}
      <StaffPage onNavigateToStaffDetails={handleStaffRowClick} isEmailTaken={validateEmail} />
    </>
  ) : (
    <OldStaffPage />
  );
};

export default Staff;
