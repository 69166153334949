import { EnrollmentInformationType, ParentType } from './types';
import { EnrollmentStatusEnum, EnrollmentSourceEnum } from '../enrollments/enums';
import { MarketplaceRevenueSourceEnum, MarketplaceStatusEnum } from '../integrations/marketplace/types';

export const PARENT_NOTES = 'Parent or Guardian';
export const CONTACT_NOTES = 'Other';

export const RELATIONSHIP_TYPES = {
  parent: 'parent',
  guardian: 'guardian',
  contact: 'emergencyContact',
};

export const RELATIONSHIP_TYPE_OPTIONS = [
  {
    key: RELATIONSHIP_TYPES.parent,
    text: PARENT_NOTES,
    value: RELATIONSHIP_TYPES.parent,
  },
  {
    key: RELATIONSHIP_TYPES.contact,
    text: CONTACT_NOTES,
    value: RELATIONSHIP_TYPES.contact,
  },
];

export const RESPONSIBLE_FOR_BILLING_TYPE = {
  parent: 'parent',
  invitation: 'invitation',
};

export const DEFAULT_PARENT = {
  shouldSendInvitation: true,
} as ParentType;

export const DEFAULT_ENROLLMENT_INFORMATION = {
  automaticallyEnroll: true,
} as EnrollmentInformationType;

// When adding a 'contact' to a student.family, only include the following fields:
export const STUDENT_FAMILY_CONTACT_FIELDS = [
  'uid',
  'invitationId',
  'type',
  'status',
  'email',
  'firstName',
  'middleName',
  'lastName',
  'displayName',
  'phone',
  'relationship',
  'relationshipNotes',
  'allowLoginWithPin',
  'loginPin',
];

export const MARKETPLACE_STATUS_MAP = {
  [MarketplaceStatusEnum.ENROLLED]: EnrollmentStatusEnum.ENROLLED,
  [MarketplaceStatusEnum.UNENROLLED]: EnrollmentStatusEnum.UNENROLLED,
};

export const MARKETPLACE_REVENUE_SOURCE_MAP = {
  [MarketplaceRevenueSourceEnum.WONDERSCHOOL]: EnrollmentSourceEnum.WONDERSCHOOL,
  [MarketplaceRevenueSourceEnum.NETWORK]: EnrollmentSourceEnum.NETWORK,
  [MarketplaceRevenueSourceEnum.PROVIDER]: EnrollmentSourceEnum.PROVIDER,
  [MarketplaceRevenueSourceEnum.NULL]: EnrollmentSourceEnum.PROVIDER,
};

export const STUDENT_PROGRAMS = {
  CACFP: 'cacfp',
  KINDER_CONNECT: 'kinderConnect',
};
