import { useTranslation } from 'react-i18next';

// Import component.
import LanguagePicker from './LanguagePicker';
import { useUserflow } from '../../integrations';

import { changeLanguage, DEFAULT_LANGUAGE } from '../../i18n';

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || DEFAULT_LANGUAGE;
  const { userflowInitialize } = useUserflow();

  const onChange = async (e, { value }) => {
    e.preventDefault();
    changeLanguage(value);
    await userflowInitialize();
  };

  return (
    <LanguagePicker
      inline
      defaultValue={DEFAULT_LANGUAGE}
      value={currentLanguage}
      clearable={false}
      onChange={onChange}
      data-testid="language-picker-link"
    />
  );
};

export default ChangeLanguage;
