import { BillingFeaturesEnum, OrgBillingFeatures } from './types';

export function getBillingFeatureFromOrganization(
  name: BillingFeaturesEnum,
  organization: { billingFeatures: OrgBillingFeatures[] }
) {
  if (!name || !organization?.billingFeatures) return null;
  return organization.billingFeatures.find((feature) => feature.name === name);
}

export function isBillingFeatureEnabled(
  organization: { billingFeatures: OrgBillingFeatures[] },
  billingFeatureName: BillingFeaturesEnum
) {
  return organization?.billingFeatures?.some(({ name, enabled }) => name === billingFeatureName && enabled) ?? false;
}
