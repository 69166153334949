/**
 * For use in class components that can't use the hooks, we add the segmentTracker
 * the redux store, and include an action to call the segmentTracker api directly.
 */
export const SEGMENT_TRACK_INITIALIZE = 'SEGMENT_TRACK_INITIALIZE';

export const segmentTrackInitializeAction = (segmentTrack) => ({
  type: SEGMENT_TRACK_INITIALIZE,
  segmentTrack: segmentTrack,
});

const initialState = {
  segmentTrack: null,
};

export const segmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEGMENT_TRACK_INITIALIZE:
      if (state.segmentTrack) return state;

      return {
        ...state,
        segmentTracker: action.segmentTrack,
      };

    default:
      return state;
  }
};
