import { useTranslation } from 'react-i18next';
import emptyReportImage from '../../styles/img/empty-report.svg';

// Pass in a title prop to override the default text.
type EmptyReportProps = {
  title?: string;
  img?: string;
};

export default function EmptyReport({ title, img }: EmptyReportProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="container" data-testid="empty-report-wrapper">
      <div className="mx-auto mt-10 flex h-full w-80 flex-col items-center justify-center gap-5">
        <img src={img ? img : emptyReportImage} alt="Empty Report" className="w-28" data-testid="empty-report-img" />
        <p className="text-center text-2xl text-gray-700" data-testid="empty-report-p">
          {title ? title : t('Select options above to create a report')}
        </p>
      </div>
    </div>
  );
}
