const HTTP = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
};

const fetchAPI = async (endpoint, errorMessage, method = HTTP.GET, body = null) => {
  const url = `${process.env.REACT_APP_WS_PAYMENTS_API}/${endpoint}`;
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    const json = await response.json();

    if (response.ok) {
      return { data: json.data, meta: json.meta };
    } else {
      throw new Error(`${errorMessage}: ${json.status.message}`);
    }
  } catch (error) {
    console.error(errorMessage, error);
    throw new Error(error);
  }
};

export const createInvoice = async (data) => {
  return fetchAPI('invoices', 'Error creating invoice', HTTP.POST, data);
};

export const getInvoice = (id) => fetchAPI(`invoices/${id}`, `Error getting invoice ${id}`);

export const getInvoiceItems = (id) =>
  fetchAPI(`invoices/${id}/items`, `Error getting invoice items for invoice ${id}`);

export const getInvoicePayments = (id) =>
  fetchAPI(`invoices/${id}/payments`, `Error getting payments for invoice ${id}`);

export const getInvoicePayouts = (id) => fetchAPI(`invoices/${id}/payouts`, `Error getting payouts for invoice ${id}`);

export const getParentInvoices = (userId) =>
  fetchAPI(`invoices/parent/${userId}`, `Error getting parent invoices for user ${userId}`);

export const getParentPaymentMethods = (userId) =>
  fetchAPI(`payment-methods/parent/${userId}`, `Error getting parent payment methods for user ${userId}`);

export const sendInvoice = async (id, resendEmail) => {
  const response = await fetchAPI(`invoices/send/${id}`, 'Error sending invoice', HTTP.PUT, { id, resendEmail });
  return response?.meta?.updateFields;
};

export const setInvoiceVoid = async (id) => {
  const response = await fetchAPI(`invoices/void/${id}`, 'Error setting invoice void', HTTP.PUT, { id });
  return response?.meta?.updateFields;
};

export const setInvoicePaidManually = async (id) => {
  const response = await fetchAPI(`invoices/paid-manually/${id}`, 'Error setting invoice paid(O)', HTTP.PUT, { id });
  return response?.meta?.updateFields;
};

export const setInvoiceUncollectible = async (id) => {
  const response = await fetchAPI(`invoices/uncollectible/${id}`, 'Error setting invoice uncollectible', HTTP.PUT, {
    id,
  });
  return response?.meta?.updateFields;
};
