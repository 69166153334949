import { useTranslation } from 'react-i18next';

import { capitalize } from '../../helpers/utils';

import { OnboardingGoalStatusEnum, OnboardingGoalType } from './types';

import markerStart from './assets/MarkerStart.png';
import markerIncomplete from './assets/MarkerIncomplete.png';
import markerComplete from './assets/MarkerComplete.png';

const markers = {
  [OnboardingGoalStatusEnum.START]: markerStart,
  [OnboardingGoalStatusEnum.INCOMPLETE]: markerIncomplete,
  [OnboardingGoalStatusEnum.COMPLETE]: markerComplete,
};

type OnboardingGoalButtonProps = {
  label: string;
  onboardingGoal: OnboardingGoalType;
  onClick: () => void;
};

const OnboardingGoalButton: React.FC<OnboardingGoalButtonProps> = ({ label, onboardingGoal, onClick }) => {
  const { t } = useTranslation();
  const { status } = onboardingGoal;
  const statusText = t(capitalize(status));

  const isComplete = status === OnboardingGoalStatusEnum.COMPLETE;

  return (
    <div className="check-button-container" onClick={onClickLocal}>
      <div className={`check-button ${status}`}>
        <img className="marker" alt={statusText} src={markers[status]} />
        <div className="onboarding-label">{t(label)}</div>
        <div className="onboarding-status">{statusText}</div>
      </div>
    </div>
  );
  function onClickLocal(e: React.MouseEvent) {
    e.preventDefault();
    if (!isComplete) {
      onClick();
    }
  }
};
export default OnboardingGoalButton;
