import { useState } from 'react';

import { useOrganization, useOrganizationInvoicesListener } from '../hooks/useOrganizations';
import { PAGE_NAMES, useSegmentPage } from '../segment';

import ShowErrors from '../Components/Messages/ShowError';
import SetupPayoutRibbon from '../Components/Setup/SetupPayoutRibbon';
import PageHeader from '../Components/Shared/PageHeader';

// Import components
import { MainContentLayout } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { AgingContainer } from '../Components/Billing/Reports/Aging';
import PageTitle from '../Components/Shared/PageTitle';

export default function InvoiceReports() {
  const [errorMessage, setErrorMessage] = useState(null);
  const organization = useOrganization();
  const { t } = useTranslation();

  useSegmentPage(PAGE_NAMES.invoiceReports);
  useOrganizationInvoicesListener(organization.id);

  return (
    <MainContentLayout>
      <PageTitle title={t('Aging Report')} />
      <PageHeader pageName={'Reports'} classes="billing" />
      {errorMessage && <ShowErrors content={errorMessage} />}
      <SetupPayoutRibbon onError={(err) => setErrorMessage(err)} />

      <AgingContainer />
    </MainContentLayout>
  );
}
