import validator from 'validator';

export const csvHeaders = {
  CLASS_ROOM_NAME: 'Class Room Name',
  STUDENT_FIRST_NAME: 'Student First Name',
  STUDENT_MIDDLE_NAME: 'Student Middle Name',
  STUDENT_LAST_NAME: 'Student Last Name',
  PARENT_1_FIRST_NAME: 'Parent 1 First Name',
  PARENT_1_LAST_NAME: 'Parent 1 Last Name',
  PARENT_1_EMAIL: 'Parent 1 Email',
  PARENT_1_PHONE: 'Parent 1 Phone',
  PARENT_2_FIRST_NAME: 'Parent 2 First Name',
  PARENT_2_LAST_NAME: 'Parent 2 Last Name',
  PARENT_2_EMAIL: 'Parent 2 Email',
  PARENT_2_PHONE: 'Parent 2 Phone',
};

const requiredError = (headerName: any, rowNumber: any, columnNumber: any) => {
  return { headerName: headerName, rowNumber: rowNumber, columnNumber: columnNumber };
};

const getHeaderIndex = (headerName: string) => {
  return headerConfig.headers.findIndex((header: { name: string }) => header.name === headerName);
};

const checkParentInfo = (email: any, row: any) => {
  const parentOneEmailIndex = getHeaderIndex(csvHeaders.PARENT_1_EMAIL);
  const parentTwoEmailIndex = getHeaderIndex(csvHeaders.PARENT_2_EMAIL);
  const parentOneEmail = row[parentOneEmailIndex]?.trim();
  const parentTwoEmail = row[parentTwoEmailIndex]?.trim();
  if (parentOneEmail) {
    return validator.isEmail(parentOneEmail);
  }
  if (parentTwoEmail) {
    return validator.isEmail(parentTwoEmail);
  }

  if (!parentOneEmail && !parentTwoEmail) {
    return false; // At least one parent email is required.
  }
};

export const headerConfig = {
  headers: [
    {
      name: csvHeaders.STUDENT_FIRST_NAME,
      inputName: csvHeaders.STUDENT_FIRST_NAME,
      required: true,
      requiredError,
    },
    {
      name: csvHeaders.STUDENT_MIDDLE_NAME,
      inputName: csvHeaders.STUDENT_MIDDLE_NAME,
      required: false,
    },
    {
      name: csvHeaders.STUDENT_LAST_NAME,
      inputName: csvHeaders.STUDENT_LAST_NAME,
      required: true,
      requiredError,
    },
    {
      name: csvHeaders.CLASS_ROOM_NAME,
      inputName: csvHeaders.CLASS_ROOM_NAME,
      required: true,
      requiredError,
    },
    {
      name: csvHeaders.PARENT_1_FIRST_NAME,
      inputName: csvHeaders.PARENT_1_FIRST_NAME,
      required: false,
      dependentValidate: function checkParentOneName(parent: any, row: any) {
        const parentOneFirstNameIndex = getHeaderIndex(csvHeaders.PARENT_1_FIRST_NAME);
        const parentOneEmailIndex = getHeaderIndex(csvHeaders.PARENT_1_EMAIL);
        const parentOneFirstName = row[parentOneFirstNameIndex]?.trim();
        const parentOneEmail = row[parentOneEmailIndex]?.trim();
        return parentOneEmail ? !!parentOneFirstName : true;
      },
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_1_LAST_NAME,
      inputName: csvHeaders.PARENT_1_LAST_NAME,
      required: false,
      dependentValidate: function checkParentOneLastName(parent: any, row: any) {
        const parentOneLastNameIndex = getHeaderIndex(csvHeaders.PARENT_1_LAST_NAME);
        const parentOneEmailIndex = getHeaderIndex(csvHeaders.PARENT_1_EMAIL);
        const parentOneLastName = row[parentOneLastNameIndex]?.trim();
        const parentOneEmail = row[parentOneEmailIndex]?.trim();
        return parentOneEmail ? !!parentOneLastName : true;
      },
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_1_EMAIL,
      inputName: csvHeaders.PARENT_1_EMAIL,
      required: false,
      requiredError,
      dependentValidate: checkParentInfo,
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_1_PHONE,
      inputName: csvHeaders.PARENT_1_PHONE,
      required: false,
      requiredError,
      validate: (phone) => {
        return validator.isMobilePhone(phone, 'en-US');
      },
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_2_FIRST_NAME,
      inputName: csvHeaders.PARENT_2_FIRST_NAME,
      required: false,
      dependentValidate: function checkParentTwoFirstName(parent: any, row: any) {
        const parentTwoFirstNameIndex = getHeaderIndex(csvHeaders.PARENT_2_FIRST_NAME);
        const parentTwoEmailIndex = getHeaderIndex(csvHeaders.PARENT_2_EMAIL);
        const parentTwoFirstName = row[parentTwoFirstNameIndex]?.trim();
        const parentTwoEmail = row[parentTwoEmailIndex]?.trim();

        return parentTwoEmail ? !!parentTwoFirstName : true;
      },
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_2_LAST_NAME,
      inputName: csvHeaders.PARENT_2_LAST_NAME,
      required: false,
      dependentValidate: function checkParentTwoLastName(parent: any, row: any) {
        const parentTwoLastNameIndex = getHeaderIndex(csvHeaders.PARENT_2_LAST_NAME);
        const parentTwoEmailIndex = getHeaderIndex(csvHeaders.PARENT_2_EMAIL);
        const parentTwoLastName = row[parentTwoLastNameIndex]?.trim();
        const parentTwoEmail = row[parentTwoEmailIndex]?.trim();
        return parentTwoEmail ? !!parentTwoLastName : true;
      },
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_2_EMAIL,
      inputName: csvHeaders.PARENT_2_EMAIL,
      required: false,
      requiredError,
      dependentValidate: checkParentInfo,
      validateError: requiredError,
    },
    {
      name: csvHeaders.PARENT_2_PHONE,
      inputName: csvHeaders.PARENT_2_PHONE,
      required: false,
      optional: true,
    },
  ],
};
