import { Select } from '@wonderschool/common-base-ui';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selectRoomsList = createSelector([(state) => state.rooms], (rooms) => rooms?.list ?? []);

const RoomPicker = ({
  location = undefined,
  dependent = false,
  needToAddAllRooms = false,
  returnAllRooms = false,
  customOptions = [],
  value,
  onChange,
  ...rest
}) => {
  let rooms = useSelector(selectRoomsList);
  const [selectedRoom, setSelectedRoom] = useState(rooms.find((room) => room.id === value)?.id);

  const options = useMemo(() => {
    if (!rooms?.length) return [];

    // Not sure why this is important, copied from legacy RoomPicker.js
    if (dependent && !location?.length) return [];

    let allRooms = rooms;

    // If location is set, filter rooms by location
    if (location?.length) {
      allRooms = allRooms.filter((room) => room?.location === location);
    }

    // Build dropdown options. { value, text }
    allRooms = allRooms?.map((room) => ({
      key: room.id,
      text: room.name,
      label: room.name,
      value: room.id,
    }));

    // Add custom options
    allRooms = [...allRooms, ...customOptions];

    return allRooms;
  }, [dependent, location, customOptions, rooms]);

  useEffect(() => {
    if (needToAddAllRooms) {
      returnAllRooms(options);
    }
  }, [needToAddAllRooms, options, returnAllRooms]);

  const handleChange = ({ value }) => {
    setSelectedRoom(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      clearable
      options={options}
      value={options.find((option) => option.value === selectedRoom)}
      onChange={handleChange}
      {...rest}
      data-testid="dropdown-room-picker"
    />
  );
};

export default RoomPicker;
