export const CONTACT_TYPES = {
  staff: 'staff',
  family: 'family',
  device: 'device',
};

export const CONTACT_STATUS = {
  new: 'new',
  inviting: 'inviting', // transient status: set the contact to inviting to cause invitation to be sent
  invited: 'invited',
  verified: 'verified',
  disabled: 'disabled',
  deleted: 'deleted',
};

export const INVITATION_TYPES = {
  parent: 'parent',
  staff: 'staff',
  enrollment: 'enrollment',
};

export const SERVICE_DIRECTION = {
  Previous: 'previous',
  Next: 'next',
};

export const DISPLAY_INTERVAL = {
  Month: 'month',
  Weekly: 'week',
  Biweekly: 'twoWeeks',
};
