const USERFLOW_INITIALIZED = 'USERFLOW_INITIALIZED';

export const userflowInitializedAction = () => ({
  type: USERFLOW_INITIALIZED,
  isInitialized: true,
});

const initialState = {
  isInitialized: false,
};

export const userflowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USERFLOW_INITIALIZED:
      return {
        ...state,
        isInitialized: action.isInitialized,
      };

    default:
      return state;
  }
};
