import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { List } from 'semantic-ui-react';

import { auth, firestore } from '../../api/firebase/firebase';
import { renderDate } from '../../utils/date';

import { useUser } from '../../hooks/useUser';

import SectionCard from '../Shared/SectionCard';
import withPermission from '../Shared/withPermission';

const INVITATION_DESCRIPTIONS = {
  enrollment: 'Complete Enrollment Form',
};

const InvitationList = (invitations, t) => {
  if (!invitations.length) {
    return (
      <div className="text-center">
        <p>{t('You have no invitations')}</p>
      </div>
    );
  }

  return (
    <List divided relaxed>
      {invitations.map((invitation) => (
        <List.Item key={invitation.id}>
          <List.Icon name={`${invitation.completed ? 'check' : 'arrow right'}`} />
          <List.Content>
            <List.Header
              {...(invitation.completed
                ? {}
                : {
                    as: 'a',
                    target: '_blank',
                    href: `${process.env.REACT_APP_WS_FORMS_APP_URL}/forms/invitation/${invitation.id}`,
                  })}
            >
              {INVITATION_DESCRIPTIONS[invitation.type]}
            </List.Header>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <List.Description>Sent to: {invitation.email}</List.Description>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <List.Description>Sent at: {renderDate(invitation.sentAt)}</List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const Invitations = () => {
  const { t } = useTranslation();
  const { isParent } = useUser();
  const [invitations, setInvitations] = React.useState([]);

  // Start listening for invitations
  useEffect(() => {
    const unsubscribe = firestore()
      .collection('invitations')
      .where('uid', '==', auth().currentUser?.uid)
      .orderBy('completed', 'asc')
      .orderBy('sentAt', 'desc')
      .limit(5)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            setInvitations([]);
          } else {
            const invitationDocs: any = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            setInvitations(invitationDocs);
          }
        },
        (error) => {
          console.error(error);
        }
      );

    return () => {
      unsubscribe();
    };
  }, []);

  // hide empty invitations container for parents
  if (isParent && !invitations.length) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="w-full">
        <SectionCard header={t('My Enrollment Invitations')} component={() => InvitationList(invitations, t)} />
      </div>
    </div>
  );
};

export default withPermission(Invitations, 'can_view_own_invitations');
