export enum GenderEnum {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
}

export enum AuditTypeEnum {
  CHECKED_IN = 'checkedIn',
  CHECKED_OUT = 'checkedOut',
  CREATED = 'created',
  DELETED = 'deleted',
  DISABLED = 'disabled',
  ENROLLED = 'enrolled',
  INVITED = 'invited',
  SENT = 'sent',
  UNENROLLED = 'unenrolled',
  UPDATED = 'updated',
}

export enum SortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export enum DayOfWeekEnum {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum FoodProgramTierEnum {
  TIER_0 = 0, // same as None
  TIER_1 = 1,
  TIER_2 = 2,
}

export enum CACFPMealCategoryEnum { // These are the CACFP categories
  BREAKFAST = 'B',
  MORNING_SNACK = 'AS',
  LUNCH = 'L',
  AFTERNOON_SNACK = 'PS',
  DINNER = 'D',
  EVENING_SNACK = 'ES',
}

export enum CACFPMealItemCategoryEnum { // These are the CACFP categories
  FLUID = 'fluid',
  FRUIT = 'fruit',
  GRAIN = 'grain',
  PROTEIN = 'protein',
  VEGETABLE = 'vegetable',
}

export enum MealRecordBottleContentsEnum {
  BREAST_MILK = 'breast-milk',
  FORMULA = 'formula-milk',
}

export enum MealRecordBottleQuantityEnum {
  NONE = 'none',
  '1_OZ' = '1oz',
  '2_OZ' = '2oz',
  '3_OZ' = '3oz',
  '4_OZ' = '4oz',
  '5_OZ' = '5oz',
  '6_OZ' = '6oz',
  '7_OZ' = '7oz',
  '8_OZ' = '8oz',
  '9_OZ' = '9oz',
  '10_OZ' = '10oz',
}

// Note: the SNACK is not the CACFP category. We may need to derive them based on the
// time of day the snack was served, but that seems pretty hacky
export enum MealRecordCategoryEnum {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
  SNACK_MORNING = 'snack-morning',
  SNACK_AFTERNOON = 'snack-afternoon',
  SNACK_EVENING = 'snack-evening',
}

export enum MealRecordCupContentsEnum {
  MILK = 'milk',
  WATER = 'water',
  JUICE = 'juice',
}

// For both food and cup meal types
export enum MealRecordQuantityEnum {
  NONE = 'none',
  SOME = 'some',
  MOST = 'most',
  ALL = 'full',
}

// These are the values for what is being logged in the activites collection for meal recording
export enum MealRecordTypeEnum {
  BOTTLE = 'bottle',
  CUP = 'cup',
  FOOD = 'food',
}

export type WeekStartingType = string; // The date of the monday of the week, formatted as YYYY-MM-DD
export type StudentIdType = string; // The id of the student

export enum BillingPageEnum {
  INVOICE = 'invoice',
  INVOICE_PLAN = 'invoice_plan',
  TRANSACTION = 'transaction',
  HOME = 'home',
}
