import { Button, DataTable, PageLoading } from '@wonderschool/common-base-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrollmentStatusEnum } from '../../../enrollments/enums';
import { logError } from '../../../rollbar';
import { useStudents } from '../../../students/studentsHooks';
import { StudentMappingModal } from './StudentMappingModal';

export const KinderConnectStudentMappings: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const studentsFilter = {
    searchText: '',
    filters: {
      enrollmentStatus: EnrollmentStatusEnum.ENROLLED,
    },
  };

  const { listFiltered: students } = useStudents(studentsFilter);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      setLoading(true);
    } catch (err) {
      logError('Error fetching students: ', err);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      fieldName: 'displayName',
      label: t('common.name'),
    },
    {
      fieldName: 'kinderSystems.kinderConnectId',
      label: t('KinderSystems ID'),
      renderCell: (student) => student.kinderSystems?.kinderConnectId || t('No ID'),
    },
    {
      fieldName: 'actions',
      label: t('Actions'),
      renderCell: (student) => <Button onClick={() => handleEditMapping(student)}>{t('common.edit')}</Button>,
    },
  ];

  const handleEditMapping = (student: any) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };

  if (loading) return <PageLoading />;

  if (!students.length) {
    return <p className="text-gray-600">{t('No Students Mapped')}</p>;
  }

  return (
    <div className="overflow-x-auto">
      <DataTable
        data={students}
        columns={columns}
        noResults={<p className="text-center py-4">{t('No students found')}</p>}
      />
      {selectedStudent && (
        <StudentMappingModal isOpen={isModalOpen} onClose={handleCloseModal} student={selectedStudent} />
      )}
    </div>
  );
};
