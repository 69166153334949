export const firebaseConfig = {
  // Project: moxit-wonderschool-dev
  // App: Dashboard (dev)
  // Hosting: moxit-wonderschool-dev.web.app
  apiKey: 'AIzaSyAuuCN8lxEJERlc-UUd5b__S67jMnq6xc4',
  authDomain: 'moxit-wonderschool-dev.firebaseapp.com',
  projectId: 'moxit-wonderschool-dev',
  storageBucket: 'moxit-wonderschool-dev.appspot.com',
  messagingSenderId: '295197937196',
  appId: '1:295197937196:web:e8efcb4cd11803bb3c8b89',
  measurementId: 'G-J519JQ3J5Q',
};

export const wsConfig = {
  envName: 'dev',
  rollbar: {
    accessToken: '87e02a03ad474af5bba15e3a57a833f7',
    environment: 'development',
  },
  hotjar: {
    siteId: 3759584,
    version: 6,
  },
};
