import { analytics } from './analytics';
import { isProd } from '../config/env';

/**
 * These functions are only meant to be called from the hooks.
 */
const SHOULD_SEND_ANALYTICS = process.env.REACT_APP_SEGMENT_ENABLED === 'true';

const FUNCTION_NAME = {
  track: 'track',
  identify: 'identify',
  page: 'page',
};

export function callSegmentIdentify(data) {
  callSegmentFunction(FUNCTION_NAME.identify, data);
}
export function callSegmentPage(data) {
  callSegmentFunction(FUNCTION_NAME.page, data);
}
export function callSegmentTrack(data) {
  callSegmentFunction(FUNCTION_NAME.track, data);
}

function callSegmentFunction(functionName, data) {
  try {
    if (SHOULD_SEND_ANALYTICS) {
      if (functionName === FUNCTION_NAME.identify) {
        analytics.identify(data.userId, data.traits);
      } else if (functionName === FUNCTION_NAME.track) {
        analytics.track(data.event, data.properties);
      } else if (functionName === FUNCTION_NAME.page) {
        // TODO: Commented to pause the analytics for pages.
        // Reason: We temporarily paused `analytics.page` because it triggers multiple events,
        // resulting in excessive log entries. This will be re-enabled once we optimize the
        // logging behavior and ensure page tracking doesn't overwhelm the system.
        // analytics.page(data.name, data.category, data.properties);
      }
    } else if (!isProd()) {
      console.log(`Segment ${functionName}`, data);
    }
  } catch (error) {
    console.log(`Segment ${functionName}`, error, data);
  }
}
