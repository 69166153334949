import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Button, LinkOutIcon } from '@wonderschool/common-base-ui';
import { getTitlePrefix } from '../../../config/env';

import LearningBeyondPaperIcon from '../assets/learning-beyond-paper-logo.png';
import MacBook16 from '../assets/MacBook-Pro-16.png';
import MacBook17 from '../assets/MacBook-Pro-17.png';
import { REDIRECTION_LINKS } from '../enums';
import { addUserParamsToUrl } from '../utils';

const LearningBeyondPaper = () => {
  const { t } = useTranslation();
  const title = 'Learning Beyond Paper';

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
    </Helmet>
  );

  return (
    <>
      <div className="container mx-auto px-5">
        {renderPageHead()}
        <Breadcrumbs
          items={[
            {
              current: false,
              href: '/appstore',
              label: 'Apps',
            },
            {
              current: true,
              href: '/learningBeyondPaper',
              label: 'Lesson Planning',
            },
          ]}
        />
        <div className="container py-10">
          <div className="grid grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center gap-x-6">
              <div className="h-16 w-16 place-content-center rounded-full ring-1 ring-gray-900/10">
                <img className="mx-auto" src={LearningBeyondPaperIcon} alt="Learning Beyond Paper" />
              </div>
              {/*  eslint-disable-next-line i18next/no-literal-string */}
              <h1 className="my-auto text-3xl font-bold text-gray-900">Learning Beyond Paper</h1>
            </div>
            <div className="flex flex-col gap-x-4 sm:gap-x-6 lg:items-end">
              <Button
                label={t('apps.joinWaitlist')}
                id="learning-beyond-paper-join-waitlist"
                primary
                postIcon={<LinkOutIcon className="h-5 w-5" />}
                onClick={() =>
                  REDIRECTION_LINKS?.LEARNING_BEYOND_PAPER_GOOGLE_FORM &&
                  window.open(addUserParamsToUrl(REDIRECTION_LINKS.LEARNING_BEYOND_PAPER_GOOGLE_FORM), '_blank')
                }
              />
            </div>
          </div>
          <div className="mt-4 text-xl font-bold text-gray-800">{t('apps.learningBeyondPaper.header')}</div>
          <div className="mt-1 text-sm font-normal text-gray-800">{t('apps.learningBeyondPaper.description')}</div>
          <div className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
            <div className="rounded-lg bg-green-500 py-2">
              <img src={MacBook16} alt="" className="m-auto" />
            </div>
            <div className="rounded-lg bg-green-500 py-2">
              <img src={MacBook17} alt="" className="m-auto" />
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-6">
            <div className="text-gray-800">
              <p className="mb-2.5 text-base font-bold">{t('apps.learningBeyondPaper.cta1.title')}</p>
              <p className="text-sm">{t('apps.learningBeyondPaper.cta1.description')}</p>
            </div>
            <div className="text-gray-800">
              <p className="mb-2.5 text-base font-bold">{t('apps.learningBeyondPaper.cta2.title')}</p>
              <p className="text-sm">{t('apps.learningBeyondPaper.cta2.description')}</p>
            </div>
            <div className="text-gray-800">
              <p className="mb-2.5 text-base font-bold">{t('apps.learningBeyondPaper.cta3.title')}</p>
              <p className="text-sm">{t('apps.learningBeyondPaper.cta3.description')}</p>
            </div>
            <div className="text-gray-800">
              <p className="mb-2.5 text-base font-bold">{t('apps.learningBeyondPaper.cta4.title')}</p>
              <p className="text-sm">{t('apps.learningBeyondPaper.cta4.description')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningBeyondPaper;
