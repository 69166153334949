export const SAFE_CHAR_SET = '23456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';

/**
 * Generate a random password
 * @description Generate a random password with a given length and character set
 * Since '0' '1' 'l' 'o' 'I' 'O' are confusing, they are not included in the default character set
 *
 * @param length password length
 * @param charSet character set to use
 * @returns randomly generated password
 */
export function generatePassword(length?: number, charSet?: string) {
  if (typeof length !== 'number' || length < 1) length = 10;

  if (typeof charSet !== 'string' || charSet.length < 1) charSet = SAFE_CHAR_SET;

  let password = '';
  for (let i = 0; i < length; i++) {
    const randomPosition = Math.floor(Math.random() * charSet.length);
    password += charSet.substring(randomPosition, randomPosition + 1);
  }
  return password;
}
