import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Dimmer, Loader } from 'semantic-ui-react';

/**
 * Shows a basic loader
 */
const LoadingComponent = ({ title }) => {
  return (
    <Dimmer page active inverted>
      <Loader inline="centered">{title}</Loader>
    </Dimmer>
  );
};

LoadingComponent.propTypes = {
  title: PropTypes.string,
};

export default LoadingComponent;
