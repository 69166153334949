// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { intersection } from 'lodash';
import store from '../redux/store';

import { isDev, isLocal } from '../config/env';
import { getLDClient } from '../launch-darkly';
import { hideMenuForNetworks, menuConfigForNetworks } from './menuConfig';
import { paths } from './paths';
import { RouteParamType, RouteType } from './types';

type BuildMarketplaceLinkType = {
  organization: any;
  location?: any;
  channelId?: string;
  path: string;
  params?: Record<string, string>;
};
export function buildMarketplaceLink({
  organization,
  location,
  channelId,
  path = '',
  params = {},
}: BuildMarketplaceLinkType): string {
  if (!path) return '';

  const root = isLocal()
    ? // only use the default if local
      process.env.REACT_APP_WS_MARKETPLACE_APP_URL || 'http://lego:4000'
    : process.env.REACT_APP_WS_MARKETPLACE_APP_URL;

  Object.keys(params).forEach((key) => {
    const value = params[key];
    path = path.replaceAll(`{${key}}`, value);
  });

  const url = (root + path)
    .replaceAll('{marketplaceSchoolId}', location?.marketplaceSchoolId ?? organization.marketplaceSchoolId ?? '')
    .replaceAll('{marketplaceSchoolName}', organization.name ?? '')
    .replaceAll('{locationId}', location?.id ?? '')
    .replaceAll('{channelId}', channelId ?? '');

  return encodeURI(url);
}

export function buildHelpCenterLink(path: string): string {
  const root = isLocal()
    ? // only use the default if local
      process.env.REACT_APP_WS_ZENDESK_APP_URL || 'https://wonderschool.zendesk.com'
    : process.env.REACT_APP_WS_ZENDESK_APP_URL;

  return root + path;
}

// replace params in the path with the values passed in
// the path should be in the format of /path/{param1}/path/{param2} or
// /path/:param1/path/:param2
export function buildParameterizedPath(route: RouteType, params?: RouteParamType[]): string {
  let path = route.path;
  params?.forEach((param) => {
    path = path.replace(`{${param.name}}`, param.value); // try handlebar style
    path = path.replace(`:${param.name}`, param.value); // try rails style
  });
  return path;
}

// Have to special case the onboarding link because the staging host is not the same as all other MP links
export function buildOnboardingLink(route: RouteType): string {
  const root = isDev() // notice how we are checking for dev here, not local like the function above
    ? 'https://www.wonderschool.io'
    : process.env.REACT_APP_WS_MARKETPLACE_APP_URL || (isLocal() ? 'http://lego:4000' : '');

  return root + route.path;
}

export function shouldHideForNonFCCs(): boolean {
  const {
    organizations: { currentOrganization },
  } = store.getState() as any;
  return currentOrganization?.programType !== 'familyChildCare';
}
export function shouldHideForFCCs(): boolean {
  const {
    organizations: { currentOrganization },
  } = store.getState() as any;
  return currentOrganization?.programType === 'familyChildCare';
}

// Should Hide from non-directors, but allow moxit admins
export function shouldHideForNonDirectors(): boolean {
  const {
    organizations: { currentOrganization },
    user,
  } = store.getState() as any;
  if (!currentOrganization || !user) return true;
  // Provide access if user is moxit admin
  // (neftaly) Added for troubleshooting on headless pages
  if (user?.claims?.moxitAdmin) return false; // don't hide for moxit admins
  return currentOrganization?.createdBy !== user?.uid; // hide if the created by is not the current user
}

/**
 * Should hide Menu for Networks
 * This is the universal function that can used for other networks too.
 **/
export function shouldHideForNetwork(routeName): boolean {
  // Fetch the network ids for respective environments
  const {
    organizations: { currentOrganization },
  } = store.getState() as any;

  if (!currentOrganization) return true; // default to hide if no current organization

  if (currentOrganization?.networks?.length && hideMenuForNetworks?.length) {
    // if network id is present, and menu is present in the menuConfigForNetworks, show the menu
    // else hide the menu
    const foundNetworkIds = intersection(currentOrganization.networks, hideMenuForNetworks);
    if (foundNetworkIds.length) return !menuConfigForNetworks[foundNetworkIds[0]][routeName];
    else return false;
  }
  //if no network id is present, show all the menus
  return false;
}

/**
 * Should hide Menu for MP features that haven't been enabled for the Provider
 **/
export function shouldHideIfNotEnabledOnMarketplace(feature: string): boolean {
  // Fetch the network ids for respective environments
  const {
    organizations: { currentOrganization },
    marketplace: { featuresEnabled },
  } = store.getState() as any;

  if (!currentOrganization) return true; // default to hide if no current organization
  if (!currentOrganization.marketplaceSchoolId) return true; // default to hide if no marketplaceSchoolId

  return !featuresEnabled[feature];
}

export function shouldHideFoodProgram(): boolean {
  return shouldHideLaunchDarkly('is-food-program-report-enabled');
}
export function shouldHideCalendar(): boolean {
  return shouldHideLaunchDarkly('is-calendar-enabled');
}
export function shouldHideContacts(): boolean {
  return shouldHideLaunchDarkly('is-contacts-family-list-enabled');
}
export function shouldHideClaimsManagement(): boolean {
  return shouldHideLaunchDarkly('is-claims-management-enabled');
}
export function shouldHideMealPlanning(): boolean {
  return shouldHideLaunchDarkly('is-meal-planning-enabled');
}
export function shouldHideMealRecording(): boolean {
  return shouldHideLaunchDarkly('is-desktop-meal-recording-enabled');
}
export function shouldHideMenuPlanning(): boolean {
  return shouldHideLaunchDarkly('is-menu-planning-enabled');
}
export function shouldHideAppStore(): boolean {
  return shouldHideLaunchDarkly('is-app-store-enabled');
}
export function shouldHideKinderSystemsAppStore(): boolean {
  return shouldHideLaunchDarkly('is-appstore-kindersystems-enabled');
}
export function shouldHideParentHandbook(): boolean {
  return shouldHideLaunchDarkly('parentHandbook');
}
function shouldHideLaunchDarkly(flag: string): boolean {
  const ldClient = getLDClient();
  const ldEnabled = ldClient?.variation(flag, false);
  return !ldEnabled;
}

export function getPaths() {
  return paths;
}
