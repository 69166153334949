import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Option, SelectProps, MultiSelectProps, SingleSelectProps } from '@wonderschool/common-base-ui';
import { OrganizationProgramTypeEnum } from '@wonderschool/common-base-types';

type OrganizationProgramTypeSelectProps = Omit<SelectProps, 'options'> & (MultiSelectProps | SingleSelectProps);

export const OrganizationProgramTypeSelect: FC<OrganizationProgramTypeSelectProps> = ({ ...selectProps }) => {
  const { t } = useTranslation();

  const programTypeOptions: Option[] = useMemo(
    () => [
      ...Object.values(OrganizationProgramTypeEnum).map((programType) => ({
        label: t(`common.organizationProgramType.${programType}`),
        value: programType,
      })),
    ],
    [t]
  );

  return (
    <Select
      label={t('common.organizationProgramType', { count: 100 })}
      placeholder={t('common.all')}
      options={programTypeOptions}
      {...selectProps}
    />
  );
};

export default OrganizationProgramTypeSelect;
