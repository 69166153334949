import { InterestedFamilyType } from './types';

const INTERESTED_FAMILIES_FETCHED = 'INTERESTED_FAMILIES_FETCHED';
const HEADLESS_TOKENS_CREATED = 'HEADLESS_TOKENS_CREATED';
const FEATURE_FLAGS_FETCHED = 'FEATURE_FLAGS_FETCHED';
const CHANNEL_ID_SELECTED = 'CHANNEL_ID_SELECTED';

export const interestedFamiliesFetched = (interestedFamilies: InterestedFamilyType[]) => ({
  type: INTERESTED_FAMILIES_FETCHED,
  interestedFamilies,
});

export const headlessTokensCreated = (tokens) => ({
  type: HEADLESS_TOKENS_CREATED,
  tokens,
});

export const channelIdSelected = (channelId) => ({
  type: CHANNEL_ID_SELECTED,
  channelId,
});

export const featureFlagsFetched = (featureFlags) => ({
  type: FEATURE_FLAGS_FETCHED,
  featureFlags,
});

const initialState = {
  interestedFamilies: {
    list: [],
    fetched: false,
    count: 0,
  },
  featuresEnabled: {
    fetched: false,
    tourRequests: false,
  },
  headless: {
    refreshToken: null,
    accessToken: null,
    channelId: null,
  },
};

export const marketplaceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INTERESTED_FAMILIES_FETCHED:
      return {
        ...state,
        interestedFamilies: {
          list: action.interestedFamilies ?? [],
          fetched: true,
          count: action.interestedFamilies?.length ?? 0,
        },
      };

    case HEADLESS_TOKENS_CREATED:
      return {
        ...state,
        headless: {
          refreshToken: action.tokens.refreshToken,
          accessToken: action.tokens.accessToken,
          channelId: null,
        },
      };

    case CHANNEL_ID_SELECTED:
      return {
        ...state,
        headless: {
          ...state.headless,
          channelId: action.channelId,
        },
      };

    case FEATURE_FLAGS_FETCHED:
      return {
        ...state,
        featuresEnabled: {
          fetched: true,
          tourRequests: action.featureFlags.tourRequests,
          messaging: action.featureFlags.messaging,
        },
      };
    default:
      return state;
  }
};
