import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Card, Header, Label } from 'semantic-ui-react';
// Import utils
import { formatFullName, capitalize } from '../../../helpers/utils';
// Import actions.
import { getRoomById } from '../../../redux/actions/roomActions';
// Import components
import FamilyAvatars from '../family/FamilyAvatars';
import StudentRooms from '../StudentRooms';
import { getEnrollmentStatus } from '../../studentsUtils';

const StudentCard = ({ student, onClick, currentOrganization: { programType } }) => {
  const { t } = useTranslation();

  const StudentAvatar = () => (
    <Card.Content className="no-border" extra>
      <Avatar
        name={formatFullName(student, true)}
        round
        size={75}
        className="left floated"
        src={student.picture}
        style={{ float: 'unset' }}
      />
    </Card.Content>
  );

  const StudentName = () => (
    <Header as="h3" className="textTruncate">
      {`${formatFullName(student)} ${student.nickName ? `(${student.nickName})` : ''}`}
    </Header>
  );

  const Schedule = ({ student }) => {
    return (
      <>
        {student?.schedule?.length && (
          <Label.Group size="tiny">
            {student.schedule.map((s, key) => {
              if (!s) return null;

              return <Label basic key={key} content={t(s)} />;
            })}
          </Label.Group>
        )}
      </>
    );
  };

  const studentEnrollmentStatus = <Card.Content extra>{t(capitalize(getEnrollmentStatus(student)))}</Card.Content>;

  return (
    <Card className="center student shadow" onClick={onClick} key={student.id}>
      <StudentAvatar />

      <Card.Content className="no-border" extra>
        <Card.Header>
          <StudentName />
          {(programType === null || (programType && programType !== 'familyChildCare')) && (
            <StudentRooms rooms={student?.rooms} />
          )}
          <Schedule />
        </Card.Header>
        <Card.Description>
          <br />
        </Card.Description>
        <FamilyAvatars student={student} />
      </Card.Content>
      {studentEnrollmentStatus}
    </Card>
  );
};

StudentCard.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    picture: PropTypes.string,
    rooms: PropTypes.array,
    schedule: PropTypes.array,
  }),
  onClick: PropTypes.func,
  currentOrganization: PropTypes.shape({
    prgramType: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps, { getRoomById })(StudentCard);
