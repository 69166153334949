import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Segment, Message, Icon, Loader, Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { isOrganizationSetupComplete } from '../../helpers/organizations';
import { getCreatePayoutAccountUrl } from '../../api/firebase/organizations';

// Import helpers.
import withPermission from '../Shared/withPermission';
import {
  didStripeOnboardingFinish,
  isStripeOnboardingStatusSetToCompleted,
  isStripeOnboardingStatusSetToFailed,
  isStripeOnboardingStatusSetToSucceeded,
  updateStripeOnboardingStatus,
  showSetupPayoutRibbon,
  StripeOnboardingStatus,
} from '../../helpers/stripe';

import WSA from '../../WSA';

function SetupPayoutRibbon({ currentOrganization, onError }) {
  const { t } = useTranslation();

  const setupComplete = isOrganizationSetupComplete(currentOrganization);
  const [doSetupPayout, setDoSetupPayout] = useState(false);
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();

  useEffect(() => {
    async function setupPayout() {
      try {
        const setupPayoutUri = await getCreatePayoutAccountUrl(currentOrganization.id);
        window.location.href = setupPayoutUri;
        setDoSetupPayout(false);
      } catch (error) {
        setDoSetupPayout(false);
        console.error(error.message);
        if (onError) onError(error.message);
      } finally {
        setDoSetupPayout(false);
      }
    }

    if (doSetupPayout) {
      setupPayout();
    }
  }, [currentOrganization.id, doSetupPayout, onError]);

  // Hide on unauthenticated pages.
  if (!isReady || !isAuthenticated) {
    return null;
  }

  // Remove if they haven't finished setup of rooms or students or locations,
  // or if they have already setup a payout account
  if (!setupComplete || !showSetupPayoutRibbon(currentOrganization)) return null;

  return (
    <Segment basic textAlign="center" className="setup-ribbon no-padding">
      {doSetupPayout && (
        <Dimmer page active inverted>
          <Loader inverted size="large" content={t('Initiating your payout account setup...')} />
        </Dimmer>
      )}
      {renderSetupPayoutMessageIfNeeded(currentOrganization)}
      {renderSetupFailedMessageIfNeeded(currentOrganization)}
      {renderSetupSucceededMessageIfNeeded(currentOrganization)}
    </Segment>
  );

  // only render this if the user hasn't gone through onboarding yet
  function renderSetupPayoutMessageIfNeeded(organization) {
    if (didStripeOnboardingFinish(organization) || isStripeOnboardingStatusSetToCompleted(organization)) return null;

    return (
      <Message warning className="major">
        {t('We use Stripe to make sure you get paid on time and keep your personal and bank details secure.')}
        {renderSetupPayoutLink(organization)}
      </Message>
    );
  }

  // only render this if the user has gone through onboarding, but the status is set to FAILED
  function renderSetupFailedMessageIfNeeded(organization) {
    if (!isStripeOnboardingStatusSetToFailed(organization)) return null;

    return (
      <Message error className="major" onDismiss={() => dismissFailedMessage(organization)}>
        <Icon name="exclamation triangle" size="large" />
        {t('Oops! Your Stripe Connect onboarding failed 😬. Please try again later.')}{' '}
        {renderSetupPayoutLink(organization)}
      </Message>
    );
  }

  function renderSetupPayoutLink() {
    return (
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          setDoSetupPayout(true);
        }}
      >
        &nbsp;{t('Setup your payout account')} &#8594;
      </Link>
    );
  }

  // only render this if the user has gone through onboarding, but the status is set to SUCCEEDED
  function renderSetupSucceededMessageIfNeeded(organization) {
    if (!isStripeOnboardingStatusSetToSucceeded(organization)) return null;

    return (
      <Message success className="major" onDismiss={() => dismissSucceededMessage(organization)}>
        {t('Congratulations!! 🥳 You have successfully completed your Stripe Connect onboarding.')}
      </Message>
    );
  }
}

function dismissFailedMessage(organization) {
  updateStripeOnboardingStatus(organization.id, StripeOnboardingStatus.NOT_STARTED);
}
function dismissSucceededMessage(organization) {
  updateStripeOnboardingStatus(organization.id, StripeOnboardingStatus.COMPLETED);
}
const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(connect(mapStateToProps)(withPermission(SetupPayoutRibbon, 'can_setup_organization')));
