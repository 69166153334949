import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Dimmer, Loader } from 'semantic-ui-react';

interface BusyIndicatorProps {
  label?: string;
  style?: React.CSSProperties;
}

export function BusyIndicator({ label = '', style = {} }: BusyIndicatorProps) {
  return (
    <Dimmer active inverted>
      <Loader inverted content={label} style={style} />
    </Dimmer>
  );
}

interface LoadingIndicatorProps {
  style?: React.CSSProperties;
}

export function LoadingIndicator({ style = {} }: LoadingIndicatorProps) {
  const { t } = useTranslation();
  return <BusyIndicator label={t('Loading')} style={style} />;
}
