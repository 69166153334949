import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line no-restricted-imports
import { Accordion, Button, Checkbox, Form, Header, Icon, Modal, List } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

// Import component
import {
  fetchOrganizationLocations,
  updateOrganizationLocations,
  updateOrganizationLocation,
} from '../../api/firebase/locations';
import { fetchOrganizationRooms } from '../../api/firebase/rooms';
import { fetchOrganizationUsers } from '../../api/firebase/users';
import { fetchOrganizationStudents } from '../../../src/students/studentsAPI';

import { updateMoxitOrganization } from '../../redux/actions/organizationActions';

import { initOrganizationPreCheck } from '../../helpers/preCheck';
import { areStripePaymentsEnabledForOrganization } from '../../helpers/stripe';
import { BILLING_FEATURES_KEYS } from '../../helpers/billingFeatures/types';

import PreCheckSetup from '../Admin/PreCheckSetup';

import NetworkEditModal from './NetworkEditModal';
import Integrations from '../Integrations/Integrations';
import { logError } from '../../rollbar';
import { LoadingIndicator } from '../Shared/BusyIndicator';
import { FileQuotaSettings } from './settings';
import { useOrganizationFeatureFlag } from '../../hooks/useOrganizations';
import { FeatureFlagEnum } from '../../navigation/types';

const OrganizationDetail = ({ organization, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const [billingFeatures, setBillingFeatures] = useState([]);
  const [preCheckData, setPreCheckData] = useState({ precheck: {} });
  const [activeIndex, setActiveIndex] = useState(0);
  const [paymentsEnabled, setPaymentsEnabled] = useState(null);
  const [requireIdVerification, setRequireIdVerification] = useState(null);
  // const [wasRequireIdVerifyChanged, setWasRequireIdVerifyChanged] = useState(false);
  const [locations, setLocations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [users, setUsers] = useState([]);
  const [students, setStudents] = useState([]);
  const [locationsToSave, setLocationsToSave] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [shouldHideFromSearchResults, setShouldHideFromSearchResults] = useState(false);
  const [locLoading, setLocLoading] = useState(false);
  const { isFeatureEnabled } = useOrganizationFeatureFlag();

  const createFeatures = useCallback((organization) => {
    const organizationFeatures = {};
    organization.features?.forEach((feature) => {
      organizationFeatures[feature.name] = feature.enabled;
    });

    const _features = [];

    Object.values(FeatureFlagEnum).forEach((name) => {
      _features.push({ name, enabled: organizationFeatures[name] ?? true });
    });
    return _features;
  }, []);

  const mapBillingFeatures = useCallback((organization) => {
    const orgBillingFeatures = organization?.billingFeatures ?? [];
    return BILLING_FEATURES_KEYS.map((featureKey) => {
      const orgBillingFeature = orgBillingFeatures.find((feature) => feature.name === featureKey);
      return { name: featureKey, enabled: orgBillingFeature?.enabled ?? false };
    });
  }, []);

  useEffect(() => {
    if (!organization) return;

    setPreCheckData(initOrganizationPreCheck(organization));
    setPaymentsEnabled(areStripePaymentsEnabledForOrganization(organization));
    setRequireIdVerification(organization.requireIdVerification);
    setShouldHideFromSearchResults(organization.shouldHideFromSearchResults);
    setFeatures(createFeatures(organization));
    setBillingFeatures(mapBillingFeatures(organization));

    try {
      setLocLoading(true);
      fetchOrganizationLocations(organization.id).then((locations) => {
        setLocations(locations);
      });
    } catch (err) {
      logError('Error while fetching locations: ', err);
    } finally {
      setLocLoading(false);
    }
    fetchOrganizationRooms(organization.id).then((rooms) => setRooms(rooms));
    fetchOrganizationUsers(organization.id).then((users) => setUsers(users));
    fetchOrganizationStudents(organization.id).then((students) => setStudents(students));
  }, [createFeatures, mapBillingFeatures, organization]);

  const onFeatureChange = (_e, { name, checked }) => {
    const _features = features.map((f) => {
      if (f.name === name) return { name, enabled: checked };
      return f;
    });
    setFeatures(_features);
  };

  const onBillingFeatureChange = (_e, { name, checked }) => {
    const newBillingfeatures = billingFeatures.map((feature) => {
      if (feature.name === name) return { name, enabled: checked };
      return feature;
    });
    setBillingFeatures(newBillingfeatures);
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const onPaymentsEnabledChange = (e, { checked }) => {
    setPaymentsEnabled(checked);
  };

  // const onRequireIdVerificationChange = (e, { checked }) => {
  //   setRequireIdVerification(checked);
  //   setWasRequireIdVerifyChanged(true);
  // };

  const renderForm = () => {
    const featureToggles =
      features &&
      features.map((feature, index) => (
        <Form.Radio
          toggle
          key={index}
          id={feature.name}
          name={feature.name}
          label={feature.name}
          checked={feature.enabled}
          value={feature.enabled}
          onChange={onFeatureChange}
          control={Checkbox}
        />
      ));

    return (
      <>
        <Form onSubmit={onSubmit}>
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Locations')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <List divided verticalAlign="middle">
                <OrganizationLocations />
              </List>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Features')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <Form.Field>{featureToggles}</Form.Field>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Pre-check Questionnaire')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <PreCheckSetup preCheckData={preCheckData} onPreCheckDataChange={setPreCheckData} />
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Stripe Payments')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <Form.Radio
                toggle
                id="paymentsEnabled"
                name="paymentsEnabled"
                label={t('Allow Stripe Payments')}
                checked={paymentsEnabled}
                onChange={onPaymentsEnabledChange}
                control={Checkbox}
              />
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 6} index={6} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Search Indexing')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              <Form.Field>
                <Checkbox
                  label={t('Hide from search results')}
                  name="shouldHideFromSearchResults"
                  value={shouldHideFromSearchResults}
                  checked={shouldHideFromSearchResults}
                  onChange={(_e, { checked }) => setShouldHideFromSearchResults(checked)}
                />
              </Form.Field>
            </Accordion.Content>

            {isFeatureEnabled('Documents') && (
              <>
                <Accordion.Title active={activeIndex === 7} index={7} onClick={handleAccordionClick}>
                  <Icon name="dropdown" />
                  {t('Organization Settings')}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <FileQuotaSettings organizationId={organization.id} />
                </Accordion.Content>
              </>
            )}

            <Accordion.Title active={activeIndex === 8} index={8} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('organizations.organizationDetail.BillingFeatures')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              <Form.Field>
                {billingFeatures.map(({ name, enabled }) => (
                  <Form.Radio
                    toggle
                    key={`billing_feature_${name}`}
                    id={name}
                    name={name}
                    label={name}
                    checked={enabled}
                    value={enabled}
                    onChange={onBillingFeatureChange}
                    control={Checkbox}
                  />
                ))}
              </Form.Field>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 9} index={9} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {t('Integrations')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 9}>
              {locLoading && <LoadingIndicator />}
              <Integrations
                locations={locations}
                locationsToSaveData={locationsToSave}
                onLocationsToSaveDataChange={setLocationsToSave}
              />
            </Accordion.Content>
          </Accordion>
          <br />

          {/* Hide buttons to avoid user confusion: e.g.: <FileQuotaSettings/> has it's own save button */}
          {activeIndex !== 7 && (
            <>
              <Button primary type="submit" loading={loading} content={t('Save')} />
              <Button
                basic
                loading={loading}
                content={t('common.cancel')}
                onClick={(e) => {
                  e.preventDefault();
                  if (onClose) onClose(e);
                }}
              />
            </>
          )}
        </Form>
      </>
    );
  };

  function LocationStats({ locationRoomCount, locationUsersCount, locationStudentsCount }) {
    const renderRoomListItem =
      locationRoomCount === 0
        ? 'No room'
        : locationRoomCount > 1
          ? `${locationRoomCount} rooms`
          : `${locationRoomCount} room`;

    const renderUsersListItem =
      locationUsersCount === 0
        ? 'No users'
        : locationUsersCount > 1
          ? `${locationUsersCount} users`
          : `${locationUsersCount} user`;

    const renderStudentsListItem =
      locationStudentsCount === 0
        ? 'No students'
        : locationStudentsCount > 1
          ? `${locationStudentsCount} students`
          : `${locationStudentsCount} student`;

    return (
      <List bulleted horizontal>
        <List.Item content={renderRoomListItem} />
        <List.Item content={renderUsersListItem} />
        <List.Item content={renderStudentsListItem} />
      </List>
    );
  }

  function OrganizationLocations() {
    if (!locations?.count) {
      return (
        <>
          <Header as="h4">{t('Locations')}</Header>
          <p>{t('No locations found')}</p>
        </>
      );
    }

    return (
      <>
        {locations?.list.map((location) => {
          const locationRoomCount = (rooms && rooms?.list?.filter((r) => r.location === location.id)?.length) || 0;

          const locationUsersCount = (users && users?.list?.filter((r) => r.location === location.id)?.length) || 0;

          const locationStudentsCount =
            (students && students?.list?.filter((r) => r.location === location.id)?.length) || 0;

          const isLocationArchivable =
            locationRoomCount === 0 && locationUsersCount === 0 && locationStudentsCount === 0 ? true : false;

          const ArchiveButton = () => {
            if (location.isArchived === true)
              return (
                <Button basic>
                  <Icon name="archive" /> {t('Archived')}
                </Button>
              );

            return isLocationArchivable ? (
              <Button
                basic
                color="blue"
                size="small"
                compact
                floated="right"
                loading={loading}
                onClick={async (e) => {
                  try {
                    e.preventDefault();

                    setLoading(true);

                    await updateOrganizationLocation(organization.id, {
                      ...location,
                      isArchived: true,
                      active: false,
                      enabled: false,
                    });

                    fetchOrganizationLocations(organization.id).then((locations) => setLocations(locations));

                    setLoading(false);
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                  }
                }}
              >
                <Icon name="archive" /> {t('Archive this location')}
              </Button>
            ) : (
              <Button basic content="Can't archive this location" />
            );
          };

          return (
            <List.Item key={location.id} disabled={location?.isArchived}>
              <List.Content floated="right">
                <ArchiveButton />
              </List.Content>
              <List.Content>
                {location.name}

                <List.Description>
                  <LocationStats
                    locationId={location.id}
                    locationRoomCount={locationRoomCount}
                    locationUsersCount={locationUsersCount}
                    locationStudentsCount={locationStudentsCount}
                  />
                </List.Description>
              </List.Content>
            </List.Item>

            // Disabled it for now until we decide to
            // implement the network management feature.
            // <div>
            //   <Segment basic>
            //     <Header as="h5">
            //       {location.name}

            //       <LocationStats locationId={location.id} />

            //       {/* <Button
            //       basic
            //       color="blue"
            //       size="mini"
            //       compact
            //       floated="right"
            //       onClick={(e) => {
            //         e.preventDefault();
            //         onClickAddNetwork(location);
            //       }}
            //     >
            //       <Icon name="add" /> {t('Add Network')}
            //     </Button> */}
            //     </Header>
            //     {location.networks?.length ? (
            //       <NetworksTable location={location} />
            //     ) : null}
            //   </Segment>

            //   <Divider />
            // </div>
          );
        })}
      </>
    );
  }

  // function NetworksTable({ location }) {
  //   const { networks } = location;
  //   return (
  //     <Table compact basic>
  //       <Table.Header>
  //         <Table.Row>
  //           <Table.HeaderCell width={8}>{t('Network')}</Table.HeaderCell>
  //           <Table.HeaderCell width={6}>{t('Geo Area')}</Table.HeaderCell>
  //           <Table.HeaderCell width={2}></Table.HeaderCell>
  //         </Table.Row>
  //       </Table.Header>
  //       <Table.Body>
  //         {networks.map((network) => {
  //           const geoArea = network.geoData?.join(', ') ?? '';
  //           return (
  //             <Table.Row key={network.id}>
  //               <Table.Cell>{network.name}</Table.Cell>
  //               <Table.Cell>{geoArea}</Table.Cell>

  //               <Table.Cell textAlign="right" singleLine>
  //                 <Button
  //                   basic
  //                   compact
  //                   key="#delete-row"
  //                   icon="trash"
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     onClickDeleteNetwork(location, network);
  //                   }}
  //                 />

  //                 <Button
  //                   basic
  //                   compact
  //                   key="#edit-row"
  //                   icon="pencil"
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     onClickEditNetwork(location, network);
  //                   }}
  //                 />
  //               </Table.Cell>
  //             </Table.Row>
  //           );
  //         })}
  //       </Table.Body>
  //     </Table>
  //   );
  // }

  return (
    <>
      <Modal
        size="medium"
        closeIcon
        centered={false}
        onClose={onCloseLocal}
        open={isOpen}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>{`${organization?.name} (${organization?.id})`}</Modal.Header>
        <Modal.Content>{renderForm()}</Modal.Content>
      </Modal>
      <NetworkEditModal
        organization={organization}
        location={selectedLocation}
        network={selectedNetwork}
        isOpen={isNetworkModalOpen}
        onClose={() => {
          setSelectedLocation(null);
          setSelectedNetwork(null);
          setIsNetworkModalOpen(false);
        }}
        onSave={onSaveNetwork}
      />
    </>
  );

  async function onSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const stripeData = organization.stripe ?? {};
      const organizationData = {
        id: organization.id,
        shouldHideFromSearchResults: shouldHideFromSearchResults ?? false, // default to false as undefined is not allowed
        features,
        ...preCheckData,
        stripe: {
          ...stripeData,
          paymentsEnabled,
        },
        requireIdVerification,
        // ...(wasRequireIdVerifyChanged ? { isRequireIdControlled: true } : {}),
        billingFeatures,
      };

      // To set the kinder systems enabled flag, we need to check if any of the locations have it enabled
      const isKinderSystemsEnabled = Object.values(locationsToSave).some(
        (loc) => loc?.integrations?.kinderSystems?.isEnabled
      );
      organizationData.isKinderSystemsEnabled = isKinderSystemsEnabled;

      setLocLoading(true);

      await updateOrganizationLocations(organization.id, Object.values(locationsToSave));

      await dispatch(updateMoxitOrganization(organizationData));

      Object.assign(organization, organizationData);

      if (onClose) onClose(e);
    } catch (error) {
      console.error(error);
    } finally {
      setLocLoading(false);
      setLoading(false);
    }
  }

  function onSaveNetwork(network) {
    setIsNetworkModalOpen(false);

    if (!selectedLocation) return;

    if (!selectedLocation.networks) selectedLocation.networks = [];

    const index = selectedLocation.networks.findIndex((n) => n.id === network.id);
    if (index > -1) {
      selectedLocation.networks[index] = network;
    } else {
      selectedLocation.networks.push(network);
    }
    setLocationsToSave({
      ...locationsToSave,
      [selectedLocation.id]: selectedLocation,
    });
    setSelectedLocation(null);
    setSelectedNetwork(null);
    setLocations({ ...locations }); // force re-render
  }

  function onCloseLocal(e) {
    e.preventDefault();
    if (onClose) onClose(e);
  }

  // function onClickAddNetwork(location) {
  //   setSelectedLocation(location);
  //   setIsNetworkModalOpen(true);
  // }

  // function onClickDeleteNetwork(location, network) {
  //   const index = location.networks?.findIndex((n) => n.id === network.id);
  //   if (index > -1) {
  //     location.networks.splice(index, 1);
  //     setLocations({ ...locations }); // force re-render
  //     setLocationsToSave({ ...locationsToSave, [location.id]: location });
  //   }
  // }

  // function onClickEditNetwork(location, network) {
  //   setSelectedLocation(location);
  //   setSelectedNetwork(network);
  //   setIsNetworkModalOpen(true);
  // }
};

export default OrganizationDetail;
