import { createContext } from 'react';

type FlagType = {
  name: string;
  enabled: boolean;
  toggle: () => void;
};

const defaultFlagObject: FlagType = {
  name: '',
  enabled: false,
  toggle: () => undefined,
};

export const FeatureFlagsContext = createContext({
  FeatureFlagsUIFlag: { ...defaultFlagObject }, // whether to show flags ui
  RollbarFlag: { ...defaultFlagObject }, // whether to use rollbar logging
  MainMenuBillingPages: { ...defaultFlagObject }, // wheather to show billing pages on main menu
} as Record<string, FlagType>);
