import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Grid, Icon, Modal, Segment } from 'semantic-ui-react';
import { UPLOAD_STATE_CONSTS } from '../../dictionary';

interface IUploadProgressProps {
  uploadState: UPLOAD_STATE_CONSTS | null;
  filename: string;
  isModalOpen: boolean;
  closeModal: () => void;
}

const UploadProgressModal: React.FC<IUploadProgressProps> = ({
  uploadState,
  filename,
  isModalOpen = false,
  closeModal,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isModalOpen} onClose={() => closeModal()} {...rest}>
      {uploadState === UPLOAD_STATE_CONSTS.uploading && (
        <>
          <Modal.Header>
            <Grid verticalAlign="middle">
              <Grid.Column verticalAlign="middle" width="1">
                <Icon.Group size="large">
                  {/* include the empty icon to ensure proper positioning */}
                  <Icon data-testid="upload-progress-icon" />
                  <Icon size="small" name="circle outline" color="blue" data-testid="upload-progress-icon-small" />
                  <Icon size="tiny" name="info" color="blue" aria-label="info" datatestid="upload-progress-icon-tiny" />
                </Icon.Group>
              </Grid.Column>
              <Grid.Column width="15">{t("Hang tight, we're processing your file!")}</Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Grid>
                <Grid.Row>
                  <strong>{filename}</strong>
                </Grid.Row>
                <Grid.Row>{t('You will be able to access this document once it is done uploading.')}</Grid.Row>
                <Grid.Row>
                  <Button primary onClick={closeModal} data-testid="upload-done-btn">
                    {t('common.done')}
                  </Button>
                </Grid.Row>
              </Grid>
            </Segment>
          </Modal.Content>
        </>
      )}
      {uploadState === UPLOAD_STATE_CONSTS.error && (
        <>
          <Modal.Header data-testid="upload-progress-header">
            <Grid verticalAlign="middle">
              <Grid.Column verticalAlign="middle" width="1">
                <Icon.Group size="large">
                  {/* include the empty icon to ensure proper positioning */}
                  <Icon data-testid="upload-error-icon-1" />
                  <Icon size="small" name="circle outline" color="red" data-testid="upload-error-icon-small" />
                  <Icon
                    size="tiny"
                    name="info"
                    color="red"
                    aria-label="upload-error"
                    data-testid="upload-error-icon-tiny"
                  />
                </Icon.Group>
              </Grid.Column>
              <Grid.Column width="15" data-testid="file-upload-error">
                {t('Error uploading your file')}
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content data-testid="upload-progress-content">
            <Segment basic>
              <Grid>
                <Grid.Row data-testid="upload-progress-header-error">
                  {t('An error occurred when uploading the file:')}
                </Grid.Row>
                <Grid.Row>
                  <strong>{filename}</strong>
                </Grid.Row>
                <Grid.Row data-testid="upload-progress-sub-error">
                  {t('Please try uploading again or uploading a different file.')}
                </Grid.Row>
                <Grid.Row>
                  <Button primary onClick={closeModal} data-testid="upload-error-done-btn">
                    {t('common.done')}
                  </Button>
                </Grid.Row>
              </Grid>
            </Segment>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};
export default UploadProgressModal;
