import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Popup } from 'semantic-ui-react';
import { HealthCheckType } from '../types';

type HealthCheckTooltipProps = {
  children: React.ReactNode;
  healthCheck?: HealthCheckType;
};

export function HealthCheckTooltip({ children, healthCheck }: HealthCheckTooltipProps) {
  const { t } = useTranslation();

  return (
    <Popup
      trigger={children}
      size="small"
      on="hover"
      mouseEnterDelay={200}
      content={
        <div className="flex flex-col">
          <div className="text-sm font-bold">{t('Health Check Performed')}</div>
          <div className="text-xs">
            <span className="font-bold">{t('Symptoms')}:</span> {healthCheck?.symptoms || t('No Symptoms')}
          </div>
          <div className="text-xs">
            <span className="font-bold">{t('Notes')}:</span> {healthCheck?.notes || t('No Notes')}
          </div>
        </div>
      }
    />
  );
}

export default HealthCheckTooltip;
