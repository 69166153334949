import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Card, Image, List, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components
import EmployeesList from '../../SelectEmployees/EmployeesList/EmployeesList';

// Import style
import './EmployeeWidget.scss';

const EmployeeWidget = ({ selectedEmployees, onRemove }) => {
  const { t } = useTranslation();

  return (
    <Card fluid>
      <Card.Content className={'employee-summary-card-header'}>
        <Card.Header data-testid="selected-employees-text" textAlign="center">
          {t('{{selectedStudentsCount}} students to be invoiced', {
            selectedStudentsCount: selectedEmployees.length,
          })}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List selection verticalAlign="middle" className={'employee-summary-list'}>
          {selectedEmployees.map((student, index) => (
            <List.Item key={student.id} data-testid={`selected-employee-${index}`}>
              <Image avatar src={student.picture} data-testid={`selected-employee-avatar-${index}`} />
              <List.Content data-testid={`selected-employee-name-${index}`}>
                <List.Header>{student.displayName}</List.Header>
              </List.Content>
              <List.Content floated="right" data-testid={`selected-employee-remove-${index}`}>
                <Icon name="remove" onClick={() => onRemove(index)} />
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
};

EmployeesList.propTypes = {
  selectedEmployees: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
};

export default EmployeeWidget;
