// eslint-disable-next-line no-restricted-imports
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { dateFormatter } from '../../helpers/dates';
import { capitalize, phoneNumberParse } from '../../helpers/utils';
import { FoodProgramTierEnum } from '../../common';
import { connect } from 'react-redux';
import { KinderSystems } from '../types';

interface StudentPersonalInformationProps {
  selectedStudent: {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    nickName: string;
    birthday: number | { seconds: number; nanoseconds: number };
    gender: string;
    allergies: string;
    medications: string;
    doctorName: string;
    doctorPhone: string;
    notes: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    race: string;
    ethnicity: string;
    photosAllowed: boolean;
    foodProgramTier: FoodProgramTierEnum;
    kinderSystems: KinderSystems;
  };
}

function StudentPersonalInformation({
  selectedStudent: {
    id,
    displayName,
    nickName,
    birthday,
    gender,
    allergies,
    medications,
    doctorName,
    doctorPhone,
    notes,
    address1,
    address2,
    city,
    state,
    zipcode,
    race,
    ethnicity,
    photosAllowed,
  },
}: StudentPersonalInformationProps) {
  const { t } = useTranslation();

  if (!id) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>{t('common.name')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{displayName}</strong>
        </Grid.Column>
      </Grid.Row>
      {/* Show Nickname only if available. */}
      {nickName?.length ? (
        <Grid.Row>
          <Grid.Column width={6}>{t('Nickname')}</Grid.Column>
          <Grid.Column width={10}>
            <strong>{nickName}</strong>
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row>
        <Grid.Column width={6}>{t('Birthday')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{birthday ? dateFormatter(birthday) : t('No birthday')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Gender')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{gender ? capitalize(gender) : t('No gender selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Allergies')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{allergies || t('No allergies added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Medications')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{medications || t('No medications added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Doctor')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{doctorName || t('students.noDoctorNameAdded')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t(`Doctor's phone number`)}</Grid.Column>
        <Grid.Column width={10}>
          <strong data-testid={'doctorPhone'}>
            {phoneNumberParse(doctorPhone) || t('students.noDoctorPhoneAdded')}
          </strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Notes')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{notes || t('There are no notes added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Address')}</Grid.Column>
        <Grid.Column width={10}>
          {address1 ? (
            <p>
              <strong>{address1}</strong>
            </p>
          ) : (
            <strong>{t('No address added')}</strong>
          )}
          {address2 && (
            <p>
              <strong>{address2}</strong>
            </p>
          )}

          <p>
            <strong>
              {city && `${city}, `}
              {state && `${state}, `}
              {zipcode && `${zipcode}`}
            </strong>
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Race')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{race || t('Race is not selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Ethnicity')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{ethnicity || t('Ethnicity is not selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Photos Allowed')}</Grid.Column>
        <Grid.Column width={10}>
          <strong> {photosAllowed ? t('common.yes') : t('common.no')} </strong>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  selectedStudent: state.students.selectedStudent,
});

export default connect(mapStateToProps)(StudentPersonalInformation);
