// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { PAYOUT_ACCOUNT_DISABLED_REASON, descriptionMap } from '../../../helpers/setupRequirements';
import { StripeOnboardingStatus } from '../../../helpers/stripe';
import SetupBanner from '../../SetupPayoutBanner/SetupBanner';
import { BillingPageEnum } from '../../../common';
import { SEGMENT_EVENTS } from '../../../segment';

const getErrorMessages = (t, errorCodes): string[] => {
  const errorMessages = (errorCodes || []).map((errorCode) => {
    const translationKey = `billing.requirementError.${errorCode}`;
    const isGenericError = t(translationKey) === translationKey;
    return isGenericError ? t('billing.requirementError.generic') : t(translationKey);
  });

  return Array.from(new Set(errorMessages));
};

const SetupPayoutRequirements = ({ organizationId, onError, accountRequirements, onboardingStatus, page }) => {
  const { t } = useTranslation();

  if (!accountRequirements) return null;

  const { currentlyDue, pastDue, disabledReason, currentDeadline, requirementErrorCodes } = accountRequirements;

  const hasRequirementsDue = currentlyDue?.length > 0;
  const hasRequirementsPastDue = pastDue?.length > 0;

  const isOnboardingComplete =
    onboardingStatus === StripeOnboardingStatus.SUCCEEDED || onboardingStatus === StripeOnboardingStatus.COMPLETED;
  const shouldShowPayoutRestrictBanner = isOnboardingComplete && (hasRequirementsDue || hasRequirementsPastDue);

  const daysToDueDate = currentDeadline && moment.unix(currentDeadline).diff(moment(), 'days');
  const deadlineMessage =
    currentDeadline && daysToDueDate >= 0
      ? `${t('billing.requirement.pleaseUpdate')} ${
          daysToDueDate == 0
            ? t('billing.requirement.byEod')
            : t('billing.requirement.withinDays', { days: daysToDueDate })
        }`
      : '';
  const currentlyDueMessage = `${t('billing.requirement.currentlyDue')}${deadlineMessage}:`;

  const isAccountDisabled = Boolean(disabledReason);
  const payoutRestrictMessage = `${t('billing.requirement.yourStripe')} ${
    isAccountDisabled ? t('billing.requirement.currentlyRestricted') : t('billing.requirement.restrictedSoon')
  }. ${t('billing.requirement.paymentsDeposited')}`;

  const isPendingVerification = disabledReason === PAYOUT_ACCOUNT_DISABLED_REASON.PENDING_VERIFICATION;
  const requirementErrorMessages = getErrorMessages(t, requirementErrorCodes);

  const segmentEventMap = {
    [BillingPageEnum.INVOICE]: SEGMENT_EVENTS.addPayoutAccountInvoiceFixIssues,
    [BillingPageEnum.INVOICE_PLAN]: SEGMENT_EVENTS.addPayoutAccountInvoicePlanFixIssues,
  };
  const segmentEvent = segmentEventMap[page] || '';

  return (
    <>
      {shouldShowPayoutRestrictBanner ? (
        <SetupBanner
          color="#F9E6E6"
          message={payoutRestrictMessage}
          organizationId={organizationId}
          showLink
          onError={onError}
        />
      ) : null}
      {isPendingVerification ? (
        <SetupBanner
          color="#FFF5CC"
          message={t('billing.requirement.pendingVerification')}
          organizationId={organizationId}
        />
      ) : null}
      {hasRequirementsDue ? (
        <SetupBanner
          color="#FFF5CC"
          message={currentlyDueMessage}
          isMessageBold
          items={currentlyDue.map((reason) => t(descriptionMap[reason]))}
          organizationId={organizationId}
          showLink
          onError={onError}
          linkText={t('billing.requirement.retryConnect')}
          segmentEvent={segmentEvent}
        />
      ) : null}
      {hasRequirementsPastDue ? (
        <SetupBanner
          color="#F9E6E6"
          message={t('billing.requirement.pastDue')}
          isMessageBold
          items={pastDue.map((reason) => t(descriptionMap[reason]))}
          organizationId={organizationId}
          showLink
          onError={onError}
          linkText={t('billing.requirement.retryConnect')}
          segmentEvent={segmentEvent}
        />
      ) : null}
      {requirementErrorMessages?.length > 0 ? (
        <SetupBanner
          color="#F9E6E6"
          message={t('billing.requirement.error')}
          isMessageBold
          items={requirementErrorMessages}
          organizationId={organizationId}
          showLink
          onError={onError}
          linkText={t('billing.requirement.retryConnect')}
          segmentEvent={segmentEvent}
          showHelpLink
        />
      ) : null}
    </>
  );
};

export default SetupPayoutRequirements;
