import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumberShorthand } from '../../../../helpers/utils';

interface TotalSummary {
  first: number;
  second: number;
  third: number;
  fourth: number;
  fifth: number;
  total: number;
}

interface ReportTotalSummaryProps {
  totalSummary: TotalSummary;
}

const ReportTotalSummary: React.FC<ReportTotalSummaryProps> = ({ totalSummary }) => {
  const { t } = useTranslation();
  const currencyOptions = {
    symbol: '$',
    decimal: '.',
    thousand: ',',
    precision: 0,
  };

  const items = [
    {
      key: 'firstTotal',
      label: '1-6',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.first)}`,
    },
    {
      key: 'secondTotal',
      label: '7-13',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.second)}`,
    },
    {
      key: 'thirdTotal',
      label: '14-20',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.third)}`,
    },
    {
      key: 'fourthTotal',
      label: '21-28',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.fourth)}`,
    },
    {
      key: 'fifthTotal',
      label: '29+',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.fifth)}`,
    },
    {
      key: 'total',
      label: 'Total',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.total)}`,
    },
  ];

  const stats = items.map((item, index) => (
    <div
      key={index}
      className={`flex flex-col justify-between ${item.key === 'total' ? 'sm:col-span-2 md:col-span-3 lg:col-span-1' : ''}`}
    >
      <div>
        <p className="text-lg font-medium text-gray-700">{item.label}</p>
        {item.key !== 'total' && <p className="text-base text-gray-600">{t('Days total')}</p>}
      </div>
      <h3 className="mt-1 text-2xl font-semibold text-gray-900 text-left">{item.value}</h3>
    </div>
  ));

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {stats.slice(0, -1)}
        <hr className="col-span-2 sm:hidden mt-4 mb-2" />
        {stats.slice(-1)}
      </div>
    </div>
  );
};

export default ReportTotalSummary;
