import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import components.
import { WsSpinner, WsWidgetSizeEnum } from '../../../common';
import PageHeader from '../../../Components/Shared/PageHeader';
import PageTitle from '../../../Components/Shared/PageTitle';
import EmptyReport from '../../components/EmptyReport';

import AttendanceFiltersForm from './AttendanceFiltersForm';
import AttendanceTotalsTable from './AttendanceTotalsTable';
import DailyAttendanceTable from './DailyAttendanceTable';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { ReportTypeEnum } from '../../enums';
import { useAttendanceActivities } from '../attendanceHooks';
import { AttendanceFiltersType } from '../types';

const Attendance = ({ hideFoodProgramFilter = false, kinderSystemsReport = false }) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<AttendanceFiltersType>();
  const { activities, isLoading } = useAttendanceActivities(filters);

  const onFilter = useCallback((filters: AttendanceFiltersType) => {
    setFilters(filters);
  }, []);

  const onClear = useCallback(() => {
    setFilters(undefined);
  }, []);

  const emptyTitle = useMemo<string | undefined>(() => {
    return filters ? t('No data was found for the selected options.') : undefined;
  }, [filters, t]);

  return (
    <MainContentLayout>
      {isLoading && <WsSpinner hasOverlay={true} spinnerColor="sky" size={WsWidgetSizeEnum.LARGE} />}
      {!kinderSystemsReport && (
        <>
          <PageHeader pageName={'Attendance'} classes="attendance" />
          <PageTitle title={t('Attendance')} />
        </>
      )}

      <AttendanceFiltersForm
        onFilter={onFilter}
        onClear={onClear}
        loading={isLoading}
        hideFoodProgramFilter={hideFoodProgramFilter}
        kinderSystemsReport={kinderSystemsReport}
      />

      {isLoading ? null : activities?.length === 0 ? (
        <EmptyReport title={emptyTitle} />
      ) : filters?.reportType === ReportTypeEnum.ATTENDANCE_DAILY ? (
        <DailyAttendanceTable
          filters={filters}
          activities={activities}
          isLoading={isLoading}
          kinderSystemsReport={kinderSystemsReport}
        />
      ) : filters?.reportType === ReportTypeEnum.ATTENDANCE_TOTAL ? (
        <AttendanceTotalsTable filters={filters} activities={activities} isLoading={isLoading} />
      ) : null}
    </MainContentLayout>
  );
};

export default Attendance;
