import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Form, Icon, Popup } from 'semantic-ui-react';

import { assignPin } from '../../api/firebase/tabletPins';

type BaseLoginPinGeneratorProps = {
  uid: string;
  organizationId: string;
  loginPin: string;
  onGenerating?: () => void;
  onGenerated: (loginPin: string) => void;
  onError: (error: Error) => void;
};
type LoginPinGeneratorProps = BaseLoginPinGeneratorProps & {
  pinType: string;
  labelText: string;
  popupText?: string;
};

const LoginPinGenerator: React.FC<LoginPinGeneratorProps> = ({
  uid,
  organizationId,
  loginPin,
  pinType,
  labelText,
  popupText,
  onGenerating,
  onGenerated,
  onError,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Form.Input
      readOnly
      label={
        <label>
          {labelText}
          {!!popupText && (
            <Popup content={popupText} position="right center" offset={[-1, 0]} trigger={<Icon name="info circle" />} />
          )}
        </label>
      }
      value={loginPin}
      action={{
        color: 'teal',
        icon: 'sync',
        loading: isSaving,
        onClick: async (e: any) => {
          e.preventDefault();

          if (onGenerating) onGenerating();
          setIsSaving(true);

          if (uid && organizationId) {
            try {
              // Generate PIN.
              const loginPin = await assignPin(uid, organizationId, pinType);

              onGenerated(loginPin);
            } catch (error: any) {
              onError(error);
            } finally {
              setIsSaving(false);
            }
          }
        },
      }}
      data-testid="login-pin-gen-input"
    />
  );
};

const StaffLoginPinGenerator = (props: BaseLoginPinGeneratorProps) => {
  const { t } = useTranslation();
  return (
    <LoginPinGenerator
      {...props}
      pinType="staffPin"
      labelText={t('The PIN code is used to log in to the shared device.')}
      data-testid="staff-pin"
    />
  );
};

const FamilyLoginPinGenerator = (props: BaseLoginPinGeneratorProps) => {
  const { t } = useTranslation();
  return (
    <LoginPinGenerator
      {...props}
      pinType="familyPin"
      labelText={t('Drop Off/Pick Up PIN Code')}
      popupText={t(
        "The pin code is used on the shared device where you can have a kiosk for parents to check in/out kids using their designated pin. This pin also shows up on the parent app by clicking on parent info under child's profile."
      )}
      data-testid="family-pin"
    />
  );
};

export { LoginPinGenerator, StaffLoginPinGenerator, FamilyLoginPinGenerator };
