import dayjs from 'dayjs';
import { parseDateString } from '../../timecardsUtils';
import { TimecardStatusEnum } from '../../enums';
import { FormDataType } from '../../types';

export const getClockedOutStatus = (formData: FormDataType) => {
  const { clockedInDate, clockedInTime, clockedOutDate, clockedOutTime } = formData;
  const clockedIn = parseDateString(dayjs(clockedInDate).format('MM/DD/YYYY') + ' ' + clockedInTime);
  const clockedOut =
    clockedOutDate && clockedOutTime
      ? parseDateString(
          dayjs(clockedOutDate).format('MM/DD/YYYY') + (clockedOutTime ? ' ' + clockedOutTime : ' 00:00 am')
        )
      : null;
  let status = TimecardStatusEnum.CLOCKED_IN;
  if (clockedOut) {
    status = TimecardStatusEnum.CLOCKED_OUT;
  }

  return { clockedIn, clockedOut, status };
};
