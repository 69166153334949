import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'semantic-ui-react';
import { firestore } from '../../api/firebase/firebase';
import InlineError from '../Messages/InlineError';

const selectLocationsList = createSelector([(state) => state.locations], (locations) => locations?.list ?? []);
const FormSelector = ({
  name = '',
  label = '',
  placeholder = '',
  value = '',
  error = '',
  required = false,
  onChange,
  location = '',
  published = true,
}) => {
  const [forms, setForms] = useState([]);
  const locations = useSelector(selectLocationsList);

  const state = useMemo(() => locations?.find((l) => l.id === location)?.state, [locations, location]);

  const getForms = useCallback(
    async (locationState) => {
      try {
        let formsRef = firestore().collection('forms');

        // Filter by published state.
        formsRef = formsRef.where('published', '==', published);

        // Filtered by tags.
        formsRef = formsRef.where('tags', 'array-contains-any', [locationState.toLowerCase()]);

        const querySnapshot = await formsRef.limit(25).orderBy('title', 'asc').get();

        if (querySnapshot.empty) return;
        const formsList = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          value: doc.id,
          text: doc.data().title,
        }));

        setForms(formsList);
      } catch (error) {
        console.log(error);
      }
    },
    [published]
  );

  useEffect(() => {
    if (!state?.length) return;
    getForms(state);
  }, [state, getForms]);

  return (
    <Form.Field error={!!error}>
      <Form.Select
        name={name}
        search
        disabled={!location.length}
        label={label}
        required={required}
        value={value}
        placeholder={placeholder}
        options={forms}
        onChange={onChange}
      />
      {error?.length ? <InlineError text={error} /> : null}
    </Form.Field>
  );
};

export default React.memo(FormSelector, (prevProps, nextProps) => {
  // Compare prevProps with nextProps and return boolean.
  // If true (props are equal), component will not re-render.
  // Note that this comparison is ignoring props that are functions.
  return (
    prevProps.name === nextProps.name &&
    prevProps.label === nextProps.label &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.required === nextProps.required &&
    prevProps.location === nextProps.location &&
    prevProps.published === nextProps.published
  );
});
