import { Button, Dialog } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationModalProps = {
  openDeleteModal: boolean;
  onCancelDeletion: () => void;
  onConfirmDeletion: () => void;
};

export function TimecardDeleteConfirmationModal({
  openDeleteModal,
  onCancelDeletion,
  onConfirmDeletion,
}: DeleteConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog hasCloseButton={true} onClose={onCancelDeletion} isOpen={openDeleteModal}>
      <Dialog.Panel>
        <Dialog.Title>{t('timecards.deleteTimecard')}</Dialog.Title>
        <div>{t('timecards.deleteConfirmation')}</div>
        <div>{t('timecards.undoneConfirmation')}</div>

        <Dialog.Actions>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
            <Button label={t('common.cancel')} extraClasses="w-full sm:w-auto " onClick={onCancelDeletion} />
            <Button
              label={t('timecards.delete')}
              onClick={onConfirmDeletion}
              extraClasses="w-full sm:w-auto bg-red-900 hover:bg-red-900 border-red-900 text-white"
              data-testid="timecard-edit-save"
            />
          </div>
        </Dialog.Actions>
      </Dialog.Panel>
    </Dialog>
  );
}
