/**
 * Converts a dollar amount to cents
 *
 * @example convertToCents(1.23) // returns 123
 * @example convertToCents(0.99) // returns 99
 * @param amount The amount to convert to cents
 * @returns The amount in cents
 */
export function convertToCents(amount: number) {
  return Math.round(amount * 100);
}

/**
 * Converts a cent amount to dollars
 *
 * @example convertToDollars(123) // returns 1.23
 * @example convertToDollars(99) // returns 0.99
 * @param amount The amount to convert to dollars
 * @returns The amount in dollars
 */
export function convertToDollars(amount: number) {
  return amount / 100;
}
