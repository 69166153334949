import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useRooms from '../../../hooks/useRooms';

import { Combobox } from '@wonderschool/common-base-ui';
import { WsDropdownOptionType, WsDropdownProps } from '../widgets';

type DropdownOptionType = WsDropdownOptionType<string>;
type RoomDropdownProps = Omit<WsDropdownProps<string>, 'options'>;

export const RoomDropdown: React.FC<RoomDropdownProps> = ({
  label,
  name,
  onChange,
  staffRooms,
  value,
  disabled,
  error,
  required = false,
  'data-testid': dataTestId = 'room-select',
}) => {
  const { t } = useTranslation();
  const rooms: any[] = useRooms();

  const options: DropdownOptionType[] = useMemo(() => {
    const optionsLocal = rooms?.map((room) => ({
      id: room.id,
      name: room.name,
    }));

    if (staffRooms && Array.isArray(staffRooms)) {
      return optionsLocal.filter(({ id }) => staffRooms.includes(id)) ?? [];
    }
    return optionsLocal ?? [];
  }, [rooms, staffRooms]);

  return (
    <Combobox
      label={label ?? t('Room')}
      name={name ?? 'rooms'}
      onChange={onChange}
      options={options}
      placeholder={t('Select a room')}
      value={value ?? ''}
      required={required}
      disabled={disabled}
      error={error}
      data-testid={dataTestId}
      immediate
    />
  );
};

export default RoomDropdown;
