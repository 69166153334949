import { WsValidationErrorProps } from './types';

export function WsValidationError({
  error,
  id = '',
  'data-testid': testId,
}: WsValidationErrorProps): JSX.Element | null {
  return error ? (
    <p className="mt-1 text-sm text-red-950" id={id} data-testid={testId}>
      {error}
    </p>
  ) : null;
}

export default WsValidationError;
