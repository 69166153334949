import { useTranslation } from 'react-i18next';
import OnboardingGoalButton from './OnboardingGoalButton';
import { OnboardingGoalType } from './types';
import { showErrorToast } from '../Shared/showToast';
import { useRoutes, RouteNameEnum } from '../../navigation';

import { EnrollmentsTooltip, PaymentsTooltip, OperationsTooltip } from './OnboardingGoalTooltips';
{
  /* //FamiliesTooltip (add in imports above) commented code for 2434 'Communicate with families' This block of code will be enabled in future. */
}

type GoalButtonProps = {
  onboardingGoal: OnboardingGoalType;
  onClick?: (onboardingGoal: OnboardingGoalType) => void;
};
const EnrollmentsGoalButton: React.FC<GoalButtonProps> = ({ onboardingGoal }) => {
  const { t } = useTranslation();

  const { getRoutePath, getRoute } = useRoutes();

  return (
    <EnrollmentsTooltip>
      <OnboardingGoalButton
        label="onboarding.buttonEnrollments" // gets tranlated in OnboardingGoalButton
        onboardingGoal={onboardingGoal}
        onClick={async () => {
          const route = getRoute(RouteNameEnum.ONBOARDING);
          if (route) {
            const path = getRoutePath(route);
            window.location.assign(path);
          } else {
            showErrorToast(t('Error'), t('onboarding.navigationError', { path: RouteNameEnum.UPDATE_WEBSITE }));
          }
        }}
      />
    </EnrollmentsTooltip>
  );
};
/* commented code for 2434 'Communicate with families' This block of code will be enabled in future. */
/* const FamiliesGoalButton: React.FC<GoalButtonProps> = ({ onboardingGoal, onClick }) => {
  return (
    <FamiliesTooltip>
      <OnboardingGoalButton
        label="onboarding.buttonFamilies" // gets tranlated in OnboardingGoalButton
        onboardingGoal={onboardingGoal}
        onClick={() => {
          if (onClick) onClick(onboardingGoal);
        }}
      />
    </FamiliesTooltip>
  );
};
*/
const PaymentsGoalButton: React.FC<GoalButtonProps> = ({ onboardingGoal, onClick }) => {
  return (
    <PaymentsTooltip>
      <OnboardingGoalButton
        label="onboarding.buttonPayments" // gets tranlated in OnboardingGoalButton
        onboardingGoal={onboardingGoal}
        onClick={() => {
          if (onClick) onClick(onboardingGoal);
        }}
      />
    </PaymentsTooltip>
  );
};
const OperationsGoalButton: React.FC<GoalButtonProps> = ({ onboardingGoal, onClick }) => {
  return (
    <OperationsTooltip>
      <OnboardingGoalButton
        label="onboarding.buttonOperations" // gets tranlated in OnboardingGoalButton
        onboardingGoal={onboardingGoal}
        onClick={() => {
          if (onClick) onClick(onboardingGoal);
        }}
      />
    </OperationsTooltip>
  );
};

export { EnrollmentsGoalButton, PaymentsGoalButton, OperationsGoalButton }; // FamiliesGoalButton
