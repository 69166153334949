import { useFlags } from 'launchdarkly-react-client-sdk';

import { useOrganization } from './useOrganizations';

// import logos
import wsLogo from '../styles/img/wonderschool/logo-base.png';
import wsLogoFlorida from '../styles/img/wonderschool/FDOE_Logo.png';

// Function return Logo URL/Logo
export function useNetworkLogo(): string {
  const FLORIDA_ID = process.env.REACT_APP_FLORIDA_NETWORK_ID;
  const { isFloridaNetworkEnabled } = useFlags();
  const organization = useOrganization();

  if (isFloridaNetworkEnabled && organization?.networks?.length && organization?.networks?.includes(FLORIDA_ID)) {
    return wsLogoFlorida;
  }
  return wsLogo;
}
